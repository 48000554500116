import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(theme => ({
  settingsTableWrap: {
    width: '100%',
    background: '#FFFFFF',
    borderRadius: theme.spacing(2),
    boxShadow: '0px 5px 10px -8px #212346',
  },
  boxWrap: {
    width: '100%',
  },
  spentTimeTableRoot: {
    backgroundColor: '#ffffff',
    '& .MuiTableCell-root': {
      border: '1px solid #e4e4ef',
      borderBottom: 0,
      borderTop: 0,
    },
    '& .MuiTableBody-root': {
      borderBottom: '1px solid #e4e4ef',
    },
    '& .MuiTableCell-head': {
      borderTop: '1px solid #e4e4ef',
      borderBottom: '1px solid #e4e4ef',
    },
  },
}));

export default useStyles;
