import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  saveSettingButton: {
    borderRadius: '16px !important',
  },
  block: {
    backgroundColor: '#FFFFFF',
    borderRadius: theme.spacing(4),
    border: '1px solid #E4E4EF',
  },
}));

export default useStyles;
