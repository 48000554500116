import React, { useState, useEffect, useCallback, useRef, useReducer } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, ClickAwayListener, Grid, Menu, MenuItem, Paper, Popover, Typography } from '@mui/material';
import useStyles from './AgileTable.styles';
import clsx from 'clsx';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import TableSecondStepPopover from '../TableSecondStepPopover/TableSecondStepPopover';
import { getIssuesActionMenu, patchIssues, deleteIssue } from '../../../../packages/common/api';
import WarningModal from '../../../../packages/common/shared-ui/src/components/WarningModal';
import { Link } from 'react-router-dom';
import { getTranslatedText } from 'Common/utils/getTranslatedText';

const AgileTable = ({ agileData, agileRulesData, projectId, intl }) => {
  const [agileColumns, setAgileColumns] = useState([]);
  const [agileRules, setAgileRules] = useState([]);
  const classes = useStyles();
  const [, forceUpdate] = useReducer(x => x - 1, 0);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [availiableColumns, setAvailiableColumns] = useState([]);
  const [tableSelectedValues, setTableSelectedValues] = useState([]);
  const [showDeleteIssueWarning, setShowDeleteIssueWarning] = useState(false);
  const [contextMenuData, setContextMenuData] = useState({
    isSecondStepVisible: false,
    secondPopoverStepValue: null,
    data: [],
    currentRow: {
      services: {
        canEdit: false,
      },
    },
  });

  const handleUnselect = useCallback(() => {
    setIsContextMenuOpen(false);
    setContextMenuData(prevState => ({
      ...prevState,
      isSecondStepVisible: false,
      currentRow: {},
    }));
    setTableSelectedValues([]);
  }, [setContextMenuData]);
  const contextPopperStylePosition = useRef({
    left: 0,
    top: 0,
    maxWidth: 180,
  });
  useEffect(() => {
    const newAgileColumns = {};
    if (agileData && agileData.statuses) {
      agileData.statuses.forEach(status => {
        newAgileColumns[status.statusId] = {
          statusId: `${status.statusId}`,
          statusName: status.statusName,
          position: status.position,
          isDisabled: status.isDisabled,
          issues: [],
        };
      });
      if (agileData.rows && agileData.rows.length > 1) {
        agileData.rows[1].groups.forEach(group => {
          newAgileColumns[group.statusId].issues = group.issues;
        });
      }
      setAgileColumns(newAgileColumns);
    } else {
      setAgileColumns([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agileData]);
  useEffect(() => {
    const newAgileRules = {};
    if (newAgileRules) {
      agileRulesData.forEach(status => {
        newAgileRules[`${status.statusId}_${status.trackerId}`] = {
          statusId: `${status.statusId}`,
          trackerId: `${status.trackerId}`,
          projectId: `${status.projectId}`,
          statuses: status.statuses,
        };
      });
      setAgileRules(newAgileRules);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agileRulesData]);

  const handleEditIssue = useCallback(() => {
    const url = `/issues/${contextMenuData.currentRow.issueId}/edit?project=${contextMenuData.currentRow.services.projectId}&agile`;
    window.open(url, '_blank');
    handleUnselect();
  }, [contextMenuData, handleUnselect]);

  const handleCopyIssue = useCallback(() => {
    const url = `/issues/${contextMenuData.currentRow.issueId}/copy?projectId=${contextMenuData.currentRow.services.projectId}&project=${contextMenuData.currentRow.services.projectId}&trackerId=${contextMenuData.currentRow.services.trackerId}&agile`;
    window.open(url, '_blank');
    handleUnselect();
  }, [contextMenuData, handleUnselect]);

  const handleDeleteIssue = useCallback(
    async (force = false) => {
      try {
        let response;
        let params = {};
        force && (params.force = true);
        response = await deleteIssue(contextMenuData.currentRow.issueId, params);
        if (response) {
          let columnToUpdate = agileColumns[contextMenuData.currentRow.services.statusId];
          columnToUpdate.issues = columnToUpdate.issues.filter(
            issue => issue.issueId !== contextMenuData.currentRow.issueId,
          );
          setAgileColumns({
            ...agileColumns,
            [columnToUpdate.statusId]: columnToUpdate,
          });
          handleUnselect();
        }
      } catch (error) {
        error && error?.response?.status === 420 && setShowDeleteIssueWarning(true);
        console.error('ERROR WITH DELETE', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contextMenuData.currentRow.issueId],
  );

  const onDragStart = start => {
    const { draggableId } = start;
    const [statusId, issueId, trackerId] = draggableId.split('--');

    let currentRule = agileRules[`${statusId}_${trackerId}`].statuses;
    currentRule.push(parseInt(statusId));
    if (currentRule) {
      setAvailiableColumns(currentRule);
    }
  };
  const onDragEnd = async result => {
    try {
      const { destination, source, draggableId } = result;
      const [statusId, issueId, trackerId] = draggableId.split('--');

      if (!destination) {
        setAvailiableColumns([]);
        return;
      }

      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        setAvailiableColumns([]);
        return;
      }

      const start = agileColumns[source.droppableId];
      const finish = agileColumns[destination.droppableId];

      if (start === finish) {
        const newIssues = Array.from(start.issues);
        const movedIssue = newIssues.splice(source.index, 1)[0];
        newIssues.splice(destination.index, 0, movedIssue);

        const newColumn = {
          ...start,
          issues: newIssues,
        };

        setAgileColumns({
          ...agileColumns,
          [newColumn.statusId]: newColumn,
        });
      } else {
        const startIssues = Array.from(start.issues);
        const movedIssue = startIssues.splice(source.index, 1)[0];
        const newStart = {
          ...start,
          issues: startIssues,
        };

        const finishIssues = Array.from(finish.issues);
        finishIssues.splice(destination.index, 0, movedIssue);
        const newFinish = {
          ...finish,
          issues: finishIssues,
        };

        setAgileColumns({
          ...agileColumns,
          [newStart.statusId]: newStart,
          [newFinish.statusId]: newFinish,
        });
        // setAvailiableColumns([]);
      }
      let updateIssuesQuery = {
        ids: [parseInt(issueId)],
        issue: { statusId: parseInt(destination.droppableId) },
      };
      await patchIssues(updateIssuesQuery);
      setAvailiableColumns([]);
    } catch (e) {
      console.error('Error while dragging', e);
      setAvailiableColumns([]);
    }
  };
  const handleChooseSubItem = useCallback(
    async (value, type) => {
      setIsContextMenuOpen(false);
      let updateIssuesQuery = {
        ids: tableSelectedValues,
        issue: {},
      };
      if (type === 'assigned') {
        const [userId, userName] = value.split('&&');
        setContextMenuData(prevState => {
          const newAssignedTo = prevState.data.assignedTo.map(item => {
            item.isChecked = false;
            if (item.userId === userId) {
              item.isChecked = true;
            }
            return item;
          });
          prevState.data.assignedTo = newAssignedTo;
          return { ...prevState, isSecondStepVisible: false };
        });
        updateIssuesQuery.issue.assignedTo = userId;
        await patchIssues(updateIssuesQuery);
        const prevRow = agileColumns[contextMenuData.currentRow.services.statusId];
        const newIssues = prevRow.issues.map(item => {
          if (item.issueId === contextMenuData.currentRow.issueId) {
            const newFields = item.fields.map(field => {
              if (field.fieldId === 'issue.assigned') {
                field.valueId = `${userId}`;
                field.value = userName;
              }
              return field;
            });
            item.fields = newFields;
          }
          return item;
        });
        prevRow.issues = newIssues;
        setAgileColumns({
          ...agileColumns,
          [contextMenuData.currentRow.services.statusId]: prevRow,
        });
      } else if (type === 'status') {
        setContextMenuData(prevState => {
          const newStatuses = prevState.data.statuses.map(item => {
            item.isChecked = false;
            if (item.statusId === value) {
              item.isChecked = true;
            }
            return item;
          });
          prevState.data.statuses = newStatuses;
          return { ...prevState, isSecondStepVisible: false };
        });
        updateIssuesQuery.issue.statusId = value;
        await patchIssues(updateIssuesQuery);
        const prevRow = agileColumns[parseInt(contextMenuData.currentRow.services.statusId)];
        prevRow.issues = prevRow.issues.filter(item => item.issueId !== contextMenuData.currentRow.issueId);
        const newIssue = contextMenuData.currentRow;
        newIssue.statusId = value;
        newIssue.services.statusId = `${value}`;
        const newRow = agileColumns[value];
        if (newRow) {
          newRow.issues.push(newIssue);
          setAgileColumns(prevState => {
            return {
              ...prevState,
              [value]: newRow,
            };
          });
        } else {
          setAgileColumns({
            ...agileColumns,
            [prevRow.statusId]: prevRow,
          });
        }
      }
    },
    [agileColumns, contextMenuData, tableSelectedValues],
  );
  const handleChooseSubItemType = useCallback(
    (type, value, itemPosition) => {
      const itemsCount = 5; // number of items in first step
      setContextMenuData(prevState => {
        return {
          ...prevState,
          isSecondStepVisible: !prevState.isSecondStepVisible,
          secondPopoverStepValue: value,
          secondStepType: type,
          positionInfo: {
            itemPosition: itemPosition,
            positionDelta: itemsCount - itemPosition - value.length,
          },
        };
      });
    },
    [setContextMenuData],
  );
  const handleRowContextMenu = useCallback(
    async e => {
      e.preventDefault();
      if (!e.currentTarget) {
        return;
      }
      const cardId = e.currentTarget.getAttribute('data-rbd-draggable-id');
      const [statusId, issueId, trackerId] = cardId.split('--');
      const cardData = agileColumns[statusId].issues.find(item => item.issueId === issueId);
      const contexIssueMenuData = await getIssuesActionMenu(issueId);
      if (!isContextMenuOpen) {
        setContextMenuData(prevState => {
          prevState.currentRow = cardData;
          prevState.currentRows = issueId;
          prevState.isSecondStepVisible = false;
          prevState.secondStepType = '';
          prevState.data = contexIssueMenuData.data;
          return prevState;
        });
        contextPopperStylePosition.current.left = e.clientX;
        contextPopperStylePosition.current.top = e.clientY;
        setTableSelectedValues([parseInt(issueId)]);
        setIsContextMenuOpen(true);
        forceUpdate();
      }
    },
    [agileColumns, isContextMenuOpen],
  );
  const handleModalAction = useCallback(
    async e => {
      switch (e.currentTarget.attributes.value.value) {
        case 'save':
          await handleDeleteIssue(true);
          setShowDeleteIssueWarning(false);
          break;
        case 'delete':
          setShowDeleteIssueWarning(false);
          break;
      }
    },
    [handleDeleteIssue],
  );

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Grid container className={classes.agileTableContainer} spacing={2.5}>
          {agileColumns &&
            Object.values(agileColumns)
              .sort((a, b) => a.position - b.position)
              .map(column => {
                const isColumnDisabled =
                  availiableColumns.length > 0 && !availiableColumns.includes(parseInt(column.statusId));
                return (
                  <Grid item key={column.statusId} marginRight={2.5} className={classes.columnContainer}>
                    <Box
                      className={clsx(classes.title, {
                        [classes.disabledItem]: isColumnDisabled,
                      })}
                    >
                      <Typography variant="h3" fontWeight={700}>
                        {column.statusName}
                      </Typography>
                    </Box>
                    <Droppable droppableId={column.statusId} isDropDisabled={isColumnDisabled}>
                      {(provided, snapshot) => (
                        <>
                          <Box className={classes.columnPaddingTop} />
                          <Paper
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={clsx(classes.column, {
                              [classes.disabledItem]: isColumnDisabled,
                            })}
                          >
                            {Object.values(column.issues).map((task, index) => (
                              <Draggable
                                key={`${column?.statusId}--${task?.issueId}--${task?.services?.trackerId}`}
                                draggableId={`${column?.statusId}--${task?.issueId}--${task?.services?.trackerId}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    key={task?.issueId}
                                    onContextMenu={e => handleRowContextMenu(e)}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={classes.card}
                                  >
                                    <Link to={`/issues/${task?.issueId}?project=${projectId}&agile`} target={'_blank'}>
                                      <Typography variant="h4" fontWeight={400}>
                                        {task.issueName}
                                      </Typography>
                                    </Link>
                                    {task.fields.map(field => (
                                      <div key={field.fieldId}>
                                        <Typography variant="h5" color="#656985" fontWeight={700}>
                                          {getTranslatedText(intl, 'filter', field.fieldId, field.fieldName)}
                                        </Typography>
                                        <Box marginBottom={1}>
                                          <Typography variant="h5" lineHeight="14px" fontWeight={700}>
                                            {field.value}
                                          </Typography>
                                        </Box>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </Paper>
                          <Box className={classes.columnPaddingBottom} />
                        </>
                      )}
                    </Droppable>
                  </Grid>
                );
              })}
        </Grid>
      </DragDropContext>
      <WarningModal
        issue
        simple
        isWarningModalOpen={showDeleteIssueWarning}
        handleToggle={handleModalAction}
        closeWarningModal={() => setShowDeleteIssueWarning(false)}
        title={
          /* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */
          `Вы уверены, что хотите удалить выбранные задачи? Так же будут удалены подзадачи и таймшиты. Удалить?`
        }
      />
      {isContextMenuOpen && (
        <ClickAwayListener onClickAway={handleUnselect}>
          <Popover
            keepMounted
            open={isContextMenuOpen}
            style={contextPopperStylePosition.current}
            anchorPosition={contextPopperStylePosition.current}
            className={classes.contextPopover}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorReference="none"
          >
            <TableSecondStepPopover
              value={contextMenuData.secondPopoverStepValue}
              type={contextMenuData.secondStepType}
              data={contextMenuData}
              handleChooseSubItem={handleChooseSubItem}
              isSecondStepVisible={contextMenuData.isSecondStepVisible}
              contextPopperStylePosition={contextPopperStylePosition}
              classes={classes}
              positionInfo={contextMenuData.positionInfo}
            />
            <Grid container direction="column">
              <Grid
                item
                xs={12}
                padding="6px 20px"
                className={clsx(classes.contextMenuItem, {
                  [classes.contextMenuItemDisabled]: !contextMenuData.data.canEdit,
                })}
                onClick={handleEditIssue}
              >
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                <Typography variant="h5">Редактировать</Typography>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                padding="6px 0 6px 20px"
                className={clsx(classes.contextMenuItem, {
                  [classes.contextMenuItemDisabled]: !contextMenuData?.currentRow?.services?.canEdit,
                })}
                wrap="nowrap"
                alignItems="center"
                onClick={() => handleChooseSubItemType('status', contextMenuData?.data?.statuses, 1)}
              >
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                <Typography variant="h5">Статус</Typography>
                <Box width={24} height={24}>
                  <ChevronIcon direction="right" color="rgba(228, 228, 239, 1)" viewBox="0 0 24 24" />
                </Box>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                padding="6px 0 6px 20px"
                className={clsx(classes.contextMenuItem, {
                  [classes.contextMenuItemDisabled]: !contextMenuData?.currentRow?.services?.canEdit,
                })}
                wrap="nowrap"
                alignItems="center"
                onClick={() => handleChooseSubItemType('assigned', contextMenuData.data.assignedTo, 2)}
              >
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                <Typography variant="h5">Назначена</Typography>
                <Box width={24} height={24}>
                  <ChevronIcon direction="right" color="rgba(228, 228, 239, 1)" viewBox="0 0 24 24" />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                padding="6px 20px"
                className={clsx(classes.contextMenuItem, {
                  [classes.contextMenuItemDisabled]: !contextMenuData.data.canCopy,
                })}
                onClick={() => handleCopyIssue()}
              >
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                <Typography variant="h5">Копировать</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                padding="6px 20px"
                className={clsx(classes.contextMenuItem, {
                  [classes.contextMenuItemDisabled]: !contextMenuData.data.canDelete,
                })}
                onClick={() => handleDeleteIssue()}
              >
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                <Typography variant="h5">Удалить</Typography>
              </Grid>
            </Grid>
          </Popover>
        </ClickAwayListener>
      )}
    </>
  );
};

export default AgileTable;
