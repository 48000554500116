import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  UserAvatarRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 32,
    minHeight: 32,
    maxWidth: 32,
    maxHeight: 32,
    borderRadius: theme.spacing(4),
    overflow: 'hidden',
    backgroundColor: '#37C670',
  },
}));

export default useStyles;
