import React from 'react';
import { Box, Grid, Popover, Typography } from '@mui/material';
import clsx from 'clsx';
import DoneIcon from '../../../../packages/common/shared-ui/src/icons/DoneIcon';

const TableSecondStepPopover = ({
  value,
  type,
  data,
  isSecondStepVisible,
  contextPopperStylePosition,
  handleChooseSubItem,
  classes,
  positionInfo,
}) => {
  return (
    <>
      {value && (
        <Popover
          open={!!isSecondStepVisible}
          style={{
            left: contextPopperStylePosition.current.left + 182,
            top: contextPopperStylePosition.current.top + positionInfo.itemPosition * 32,
            maxWidth: 180,
            height: 230,
            overflowY: 'auto',
          }}
          anchorPosition={contextPopperStylePosition.current}
          className={clsx(
            classes.contextPopover,
            classes.contextPopoverSubItems,
            {
              [classes.contextLeftBottomCorner]: positionInfo.itemPosition === 0 && positionInfo.positionDelta > 0,
            },
            {
              [classes.contextLeftUpperCorner]: positionInfo.itemPosition !== 0 && positionInfo.positionDelta <= 0,
            },
            {
              [classes.contextLeftSideCorners]: positionInfo.itemPosition !== 0 && positionInfo.positionDelta > 0,
            },
          )}
          anchorReference="none"
          keepMounted
        >
          <Grid container direction="column">
            {value?.map((item, index) => {
              return (
                <Grid
                  container
                  className={classes.contextMenuSubItem}
                  wrap="nowrap"
                  alignItems="center"
                  key={index}
                  onClick={() =>
                    handleChooseSubItem(item.userId ? `${item.userId}&&${item.fullName}` : item.statusId, type)
                  }
                  data-value="approve"
                >
                  {item.isChecked && (
                    <Box width={16} height={16}>
                      <DoneIcon color="rgba(55, 198, 112, 1)" viewBox="0 0 24 24" />
                    </Box>
                  )}
                  {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                  <Typography variant="h5" paddingLeft={item.isChecked ? 1 : 5}>
                    {item.fullName ? item.fullName : item.statusName}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Popover>
      )}
    </>
  );
};

export default TableSecondStepPopover;
