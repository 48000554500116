import React, { memo, useCallback, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Box, Button, CircularProgress, Grid, useMediaQuery } from '@mui/material';
import CustomCalendar from '../CustomCalendar';
import CalendarException from '../CalendarException';
import CalendarExceptionCreate from '../CalendarExceptionCreate';
import useStyles from './CalendarBody.styles';
import * as PropTypes from 'prop-types';
import CalendarYearSwitcher from '../CalendarYearSwitcher';
import WarningModal from '../../../../../packages/common/shared-ui/src/components/WarningModal';
import { FormattedMessage, useIntl } from 'react-intl';

const CalendarBody = ({
  values,
  workHours,
  setFieldValue,
  errors,
  handleChange,
  customDays,
  customExclusions,
  isLoading,
  handleBackYear,
  handleForwardYear,
  year,
  handleUpdateCalendars,
  removeException,
  isCreateModalOpen,
  toggleCreateModalOpen,
  submitForm,
  handleUpdateRequests,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('lg'));
  const [newExceptionDate, setNewExceptionDate] = useState(dayjs().format());
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [actionDirection, setActionDirection] = useState('');

  const { current: openWarningModal } = useRef(direction => {
    setActionDirection(direction);
    setIsWarningModalOpen(true);
  });

  const { current: closeWarningModal } = useRef(() => {
    setIsWarningModalOpen(false);
  });

  const { current: formattedMonths } = useRef(
    new Array(12).fill(null).map((month, i) => {
      return dayjs().month(i).format('MM');
    }),
  );

  const { current: getCurrentDay } = useRef((date, days) => {
    if (days.hasOwnProperty(dayjs(date).format('YYYY-MM-DD'))) {
      return days[dayjs(date).format('YYYY-MM-DD')];
    }
    return { date };
  });

  const handleToggleExclusionMenu = useCallback(
    date => {
      if (typeof date !== 'string') {
        return toggleCreateModalOpen(false);
      }
      setNewExceptionDate(date);
      setFieldValue('date', dayjs(date).format('YYYY-MM-DD'));
      return toggleCreateModalOpen(status => !status);
    },
    [toggleCreateModalOpen, setFieldValue],
  );

  const handleOpenExclusionMenu = useCallback(
    e => {
      handleToggleExclusionMenu(dayjs().set('year', year).format());
    },
    [handleToggleExclusionMenu, year],
  );

  const handleToggleYear = useCallback(
    async e => {
      if (actionDirection) {
        if (e.currentTarget.value === 'cancel') {
          setActionDirection('');
          return closeWarningModal();
        }
        if (e.currentTarget.value === 'delete') {
          setFieldValue('newCustomDates', []);
        }
        if (e.currentTarget.value === 'save') {
          await submitForm(year);
        }
        if (actionDirection === 'left') {
          closeWarningModal();
          setActionDirection('');
          handleBackYear();
          return setFieldValue('newCustomDates', []);
        }
        if (actionDirection === 'right') {
          closeWarningModal();
          setActionDirection('');
          handleForwardYear();
          return setFieldValue('newCustomDates', []);
        }
        return handleUpdateRequests();
      }
      if (!e.currentTarget.attributes.direction.nodeValue) {
        return;
      }
      if (values.newCustomDates.length > 0 && !isWarningModalOpen) {
        return openWarningModal(e.currentTarget.attributes.direction.nodeValue);
      }
      if (e.currentTarget.attributes.direction.nodeValue === 'left') {
        return handleBackYear();
      }
      if (e.currentTarget.attributes.direction.nodeValue === 'right') {
        return handleForwardYear();
      }
    },
    [
      actionDirection,
      closeWarningModal,
      handleBackYear,
      handleForwardYear,
      handleUpdateRequests,
      isWarningModalOpen,
      openWarningModal,
      setFieldValue,
      submitForm,
      values.newCustomDates.length,
      year,
    ],
  );

  return (
    <>
      <Grid container direction="column" className={classes.CalendarBodyRoot}>
        <WarningModal
          isWarningModalOpen={isWarningModalOpen}
          openWarningModal={openWarningModal}
          closeWarningModal={closeWarningModal}
          handleToggle={handleToggleYear}
          title={intl.formatMessage({ id: 'save_changes' })}
        />
        <CalendarExceptionCreate
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          handleChange={handleChange}
          handleToggleExclusionMenu={handleToggleExclusionMenu}
          isCreateModalOpen={isCreateModalOpen}
          newExceptionDate={newExceptionDate}
          setNewExceptionDate={setNewExceptionDate}
          handleUpdateCalendars={handleUpdateCalendars}
        />
        <Grid container alignItems="flex-end">
          <CalendarYearSwitcher year={year} handleToggleYear={handleToggleYear} actionDirection={actionDirection} />
          <Box marginBottom={4.5} marginLeft={5}>
            <Button
              variant="defaultBluePrimary"
              onClick={handleOpenExclusionMenu}
              value={newExceptionDate}
              disableRipple
            >
              <FormattedMessage id="add_text" defaultMessage="Add" />
            </Button>
          </Box>
        </Grid>
        <Grid container wrap={isSmallScreen ? 'wrap-reverse' : 'nowrap'} justifyContent={'center'}>
          <Grid container alignItems="center" justifyContent={isSmallScreen ? 'center' : 'unset'} wrap="wrap">
            {!isLoading ? (
              formattedMonths.map(month => {
                return (
                  <CustomCalendar
                    year={year}
                    month={month}
                    key={month}
                    customDays={customDays}
                    handleToggleExclusionMenu={handleToggleExclusionMenu}
                    getCurrentDay={getCurrentDay}
                    setFieldValue={setFieldValue}
                    workHours={workHours}
                  />
                );
              })
            ) : (
              <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
                <CircularProgress color="secondary" />
              </Grid>
            )}
          </Grid>
          {customExclusions && <CalendarException exclusions={customExclusions} removeException={removeException} />}
        </Grid>
      </Grid>
    </>
  );
};

CalendarBody.propTypes = {
  values: PropTypes.shape({
    date: PropTypes.string.isRequired,
    hours: PropTypes.number.isRequired,
    newCustomDays: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})).isRequired, PropTypes.any]),
    offDays: PropTypes.arrayOf(PropTypes.number).isRequired,
    workHours: PropTypes.number.isRequired,
  }).isRequired,
  errors: PropTypes.shape({}).isRequired,
  customDays: PropTypes.shape({}).isRequired,
  customExclusions: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  year: PropTypes.string.isRequired,
  isCreateModalOpen: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBackYear: PropTypes.func.isRequired,
  handleForwardYear: PropTypes.func.isRequired,
  handleUpdateCalendars: PropTypes.func.isRequired,
  removeException: PropTypes.func.isRequired,
  toggleCreateModalOpen: PropTypes.func.isRequired,
};
export default memo(CalendarBody);
