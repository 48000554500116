import { useGridApiContext } from '@mui/x-data-grid';
import { useParams, useSearchParams } from 'react-router-dom';
import React, { memo, useCallback, useState } from 'react';
import useStyles from './IssuesTable/IssuesTable.styles';
import { Box, CircularProgress, ClickAwayListener, Grid, IconButton, Popper, Typography } from '@mui/material';
import DownloadButton from '../../../packages/common/shared-ui/src/icons/DownloadButton';
import { getIssuesCsv } from '../../../packages/common/api';
import dayjs from 'dayjs';

const IssuesToolbar = () => {
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isExportPopperOpen, setIsExportPopperOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onExportCsvClick = () => apiRef.current.exportDataAsCsv({ delimiter: ';', utf8WithBom: true });
  const onExportPdfClick = () => apiRef.current.exportDataAsPrint({});
  const classes = useStyles();

  const togglePopper = useCallback(e => {
    setIsExportPopperOpen(prevState => !prevState);
    setAnchorEl(e.currentTarget);
  }, []);
  const downloadCsv = useCallback(
    e => {
      async function downloadTimesheetCsv() {
        try {
          setIsLoading(true);
          let query = searchParams.toString();
          query = query.replaceAll(/ThisWeek|LastWeek|LastTwoWeeks|ThisMonth|LastMonth|ThisYear/gi, 'btw');
          query = query.replaceAll('Today', 'eq');
          if (query?.length > 0) {
            const { data } = await getIssuesCsv(query);
            const url = window.URL.createObjectURL(new Blob(['\ufeff', data], { type: 'text/csv;charset=utf-8;' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `issues-${dayjs().format('YYYY-MM-DD')}`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      }
      downloadTimesheetCsv();
    },
    [searchParams, setIsLoading],
  );
  const closePopper = useCallback(e => {
    setIsExportPopperOpen(false);
    setAnchorEl(null);
  }, []);

  return (
    <Grid position="relative">
      <ClickAwayListener onClickAway={closePopper}>
        <Box>
          <Popper
            open={isExportPopperOpen}
            anchorEl={anchorEl}
            className={classes.exportItems}
            placement="bottom-start"
          >
            <Grid container direction="column">
              <Grid className={classes.exportItem} container onClick={onExportCsvClick} height={36}>
                <Typography variant="h5">CSV</Typography>
              </Grid>
              <Grid className={classes.exportItem} container onClick={onExportPdfClick} height={36}>
                <Typography variant="h5">PDF</Typography>
              </Grid>
            </Grid>
          </Popper>
          <Box position="absolute" right={0} top={-77}>
            {!isLoading ? (
              <>
                <IconButton onClick={e => downloadCsv(e)} disableRipple>
                  <DownloadButton width="24" height="24" viewBox="0 0 24 24" />
                </IconButton>
              </>
            ) : (
              <>
                <CircularProgress color="secondary" />
              </>
            )}
          </Box>
        </Box>
      </ClickAwayListener>
    </Grid>
  );
};

export default memo(IssuesToolbar);
