export default {
  styleOverrides: {
    root: {
      width: 'inherit',
      height: 'inherit',
      '&.MuiSvgIcon-fontSizeSmall': {
        width: 24,
        height: 24,
      },
    },
  },
};
