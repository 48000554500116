import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  modalRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.MuiModal-root': {
      zIndex: 2000,
    },
  },
  modalPaper: {
    backgroundColor: '#F1F2F8',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    borderRadius: theme.spacing(4),
    maxWidth: 310,
    maxHeight: '97%',
    overflowY: 'auto',
  },
}));

export default useStyles;
