import React from 'react';
import { SvgIcon } from '@mui/material';

const TreeArrowRight = props => {
  return (
    <SvgIcon {...props}>
      <path d="M0 10L5 5L0 0V10Z" fill="#C7CAE6" />
    </SvgIcon>
  );
};

export default TreeArrowRight;
