import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  calendarHeaderRoot: {
    padding: theme.spacing(5, 10),
    borderRadius: theme.spacing(4),
    backgroundColor: '#ffffff',
    [theme.breakpoints.maxWidth('sm')]: {
      padding: theme.spacing(5),
      '& > div': {
        flexDirection: 'column',
      },
    },
  },
  workHoursField: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      appearance: 'none',
      '-webkit-appearance': 'none',
    },
    '& input': {
      ...theme.typography.h5,
    },
  },
  offDaysSelect: {
    padding: theme.spacing(1),
    width: '100%',
    maxWidth: 353,
    '& > div': {
      padding: 0,
      overflowY: 'hidden !important',
      overflowX: 'scroll !important',
      minHeight: '30px !important',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
  chosenOffDay: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#ffffff',
    border: '1px solid #E4E4EF',
    borderRadius: theme.spacing(4),
    marginRight: theme.spacing(1),
    maxHeight: 30,
    zIndex: 1400,
    ...theme.typography.h5,
    '& .MuiChip-label': {
      padding: 0,
    },
  },
  dayOfItems: {
    '& .MuiPaper-root': {
      marginTop: theme.spacing(3),
    },
    '& .MuiList-root': {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  offDayChip: {
    '&.MuiChip-root': {
      height: 'unset',
      backgroundColor: 'transparent',
      textTransform: 'capitalize',
    },
  },
  offDayRemove: {
    '&.MuiButtonBase-root': {
      padding: 0,
      marginLeft: theme.spacing(1),
    },
  },
  menuItem: {
    '&.Mui-selected': {
      background: '#DCDFF4 !important',
    },
  },
}));

export default useStyles;
