import React, { useCallback, useEffect } from 'react';
import cookies from 'Common/utils/cookies';
import { generateRedirectUrl } from 'Common/utils/getRedirectUrl';

const LogoutContainer = () => {
  const handleLogout = useCallback(() => {
    cookies.removeCookie({
      key: 'access_token',
      host: `${window.location.protocol}//${window.location.hostname}`,
    });
    if (process.env.NODE_ENV === 'development') {
      window.location.href = '/login';
    } else {
      window.location.href = generateRedirectUrl({ host: window.location.origin, path: '/my/logout' });
    }
  }, []);

  useEffect(handleLogout, [handleLogout]);

  return <></>;
};

export default LogoutContainer;
