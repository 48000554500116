import React from 'react';
import { SvgIcon } from '@mui/material';

function FileDeleteIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.998698 10.6667C0.998698 11.4 1.5987 12 2.33203 12H7.66536C8.3987 12 8.9987 11.4 8.9987 10.6667V2.66667H0.998698V10.6667ZM9.66537 0.666667H7.33203L6.66536 0H3.33203L2.66536 0.666667H0.332031V2H9.66537V0.666667Z"
          fill="#7174AC"
        />
      </svg>
    </SvgIcon>
  );
}

export default FileDeleteIcon;
