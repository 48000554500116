import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './SpreadsheetMobileEditIssue.styles';
import { Box, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import DoneIcon from '../../../../packages/common/shared-ui/src/icons/DoneIcon';
import { formatWeek } from '../formatWeek';
import SpreadsheetMobileCreateRow from '../SpreadsheetMobileCreateRow';

const SpreadsheetMobileEditIssue = ({
  query,
  setQuery,
  formattedIssues,
  intl,
  timeType,
  timeTypes,
  setEditedFormattedIssues,
  handleSaveSpreadsheet,
}) => {
  const classes = useStyles();
  const currentIssue = formattedIssues[query.editIssue] || {};
  const currentWeek =
    Object.keys(currentIssue).length !== 0
      ? currentIssue[timeType === timeTypes.MAIN_HOURS ? 'weekDays' : 'overTimeWeekDays']
      : [];
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('lg'));
  const [weekSum, setWeekSum] = useState(currentWeek.reduce((acc, day) => acc + day.hours, 0));

  const handleSaveMobileRow = useCallback(async () => {
    const saveResult = await handleSaveSpreadsheet();
    setQuery({ ...query, editIssue: null });
  }, [handleSaveSpreadsheet, query, setQuery]);

  useEffect(() => {
    if (!formattedIssues[query.editIssue] || !isSmallScreen) {
      return setQuery({ editIssue: null });
    }
  }, [formattedIssues, isSmallScreen, query.editIssue, setQuery]);

  return (
    <Grid container direction="column">
      <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="h3" fontWeight={700}>
            <FormattedMessage id="spreadsheet_issue_edit" />
          </Typography>
          <Typography variant="h5">
            {dayjs(query.start).format('DD MMMM YYYY')} - {dayjs(query.end).format('DD MMMM YYYY')}
          </Typography>
        </Box>
        <IconButton disableRipple className={classes.editSaveIssueButton} onClick={handleSaveMobileRow}>
          <DoneIcon width={24} height={24} viewBox="0 0 24 24" />
        </IconButton>
      </Grid>
      <Grid container direction="column" padding={4}>
        <Box marginBottom={2.5}>
          <Typography variant="h6">
            <FormattedMessage id="spreadsheet_table_project" />
          </Typography>
          <Typography variant="h4">{currentIssue?.projectName}</Typography>
        </Box>
        <Box marginBottom={2.5}>
          <Typography variant="h6">
            <FormattedMessage id="spreadsheet_table_issues" />
          </Typography>
          <Typography variant="h4">{currentIssue?.issueName}</Typography>
        </Box>
      </Grid>
      {currentWeek.map(day => {
        return (
          <SpreadsheetMobileCreateRow
            key={day.date}
            day={day}
            intl={intl}
            currentWeek={currentWeek}
            currentIssue={currentIssue}
            setWeekSum={setWeekSum}
            timeType={timeType}
            timeTypes={timeTypes}
            setEditedFormattedIssues={setEditedFormattedIssues}
            type="edit"
          />
        );
      })}
      <Box marginY={2.5}>
        <Typography variant="h4">
          <FormattedMessage id="spreadsheet_week_sum" /> {weekSum} <FormattedMessage id="hour" />
        </Typography>
      </Box>
    </Grid>
  );
};

export default SpreadsheetMobileEditIssue;
