import React from 'react';
import { SvgIcon } from '@mui/material';

function CheckboxIconChecked(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M10.6667 0H1.33333C0.593333 0 0 0.6 0 1.33333V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4067 12 12 11.4 12 10.6667V1.33333C12 0.6 11.4067 0 10.6667 0ZM4.66667 9.33333L1.33333 6L2.27333 5.06L4.66667 7.44667L9.72667 2.38667L10.6667 3.33333L4.66667 9.33333Z"
        fill="#3448FF"
      />
    </SvgIcon>
  );
}

export default CheckboxIconChecked;
