import typography from '../typography';

export default {
  styleOverrides: {
    root: {
      width: '100%',
      flexDirection: 'column-reverse',
      '&.formTextField': {
        flexDirection: 'row',
        alignItems: 'center',
        '& .MuiInputBase-root.MuiOutlinedInput-root': {
          height: 'auto',
          maxWidth: '353px',
          border: '1px solid #E4E4EF',
          '& .MuiInputBase-input.MuiOutlinedInput-input': {
            padding: '8px 16px',
            '&::placeholder': {
              opacity: '1!important',
              fontSize: '12px',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-disabled': {
            borderColor: 'transparent',
            '& .MuiInputBase-input.MuiOutlinedInput-input': {
              fontSize: '14px',
              padding: '0px 16px',
              color: '#212346',
              WebkitTextFillColor: '#212346',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          },
        },
        '&.labelLeft': {
          '& .MuiFormLabel-root.MuiInputLabel-root': {
            position: 'static',
            fontSize: '12px',
            lineHeight: '20px',
            color: '#41424E',
            flex: 1,
            transform: 'none',
            maxWidth: '200px',
            '& .MuiFormLabel-asterisk': {
              color: '#E03737',
            },
            '&.Mui-disabled': {
              color: '#41424E',
              fontSize: '14px',
              fontWeight: 700,
              '& .MuiFormLabel-asterisk': {
                display: 'none',
              },
              '&:after': {
                content: '":"',
              },
            },
          },
        },
      },
      '& .MuiFormHelperText-root': {
        ...typography.h5,
      },
    },
  },
};
