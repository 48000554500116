export const getTranslatedText = (intl, forPage, value, defaultValue) => {
  const currentValue =
    typeof value !== 'object'
      ? `${forPage.length > 0 ? `${forPage}_` : ''}${value.split('.').join('_').trim()}`
      : `${forPage.length > 0 ? `${forPage}_` : ''}${value.fieldId.split('.').join('_').trim()}`;

  return intl.formatMessage(
    {
      id: currentValue,
      defaultMessage: defaultValue,
    },
    typeof value !== 'object'
      ? {
          [currentValue]: defaultValue,
        }
      : {
          [currentValue]: value.fieldName,
        },
  );
};
