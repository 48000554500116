import useStyles from './ResourcePlanTableCell.styles';
import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import dayjs from 'dayjs';
import {
  Grid,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Select,
  MenuItem,
  Box,
  TextField,
  Tooltip,
} from '@mui/material';
import useResPlanWebsockets from '../useResplanWebSockets';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import PlusIcon from 'Common/shared-ui/src/icons/PlusIcon';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import clsx from 'clsx';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import CalendarTimesheetIcon from '../../../../packages/common/shared-ui/src/icons/CalendarTimesheetIcon';
import { store } from '../../app/redux';
import { useDispatch } from 'react-redux';
import {
  getResplanUsers,
  getResplanProjects,
  getResplanContracts,
  getResplanTrackers,
} from '../../../../packages/common/api';

const ResourcePlanTableCell = ({
  row,
  column,
  columnIndex,
  handleMonthPercentChange,
  handleMonthFieldUpdate,
  handlePercentFieldUpdate,
  handleChangeDate,
  addNewRowAbove,
  groupType,
  getProjectUserId,
  handleIssueBlur,
  canEdit,
  fetchedQuery,
  handleGetRedirectUrl,
  negativeColumns,
  idealColumns,
  handleIssueChange,
}) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [trackers, setTrackers] = useState([]);
  const apiRef = useGridApiRef();

  const formatDate = useCallback(dateStr => {
    if (dateStr) {
      return dayjs(dateStr).format('DD.MM.YYYY');
    }
    return dateStr;
  }, []);
  const fetchUserIssueData = async projectId => {
    try {
      const newUsers = await getResplanUsers(projectId);
      setUsers(newUsers);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchProjectIssueData = async userId => {
    try {
      const newProjects = await getResplanProjects(userId);
      setProjects(newProjects);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchContractIssueData = async projectId => {
    try {
      const newContracts = await getResplanContracts(projectId);
      setContracts(newContracts);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchTrackersIssueData = async () => {
    try {
      const newTrackers = await getResplanTrackers();
      setTrackers(newTrackers);
      console.log(newTrackers);
    } catch (e) {
      console.error(e);
    }
  };

  const renderCell = () => {
    const canEdit = row?.services?.canEdit;
    if (row.services.isNew === true) {
      if (column.fieldType === 'project' && groupType === false) {
        return (
          <Box key={column.position} className={classes.tableCell} align="left">
            <Tooltip title={row.columns[column.position]?.value} PopperProps={{ className: classes.commentTooltip }}>
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                {row.columns[column.position]?.value}
              </Typography>
            </Tooltip>
          </Box>
        );
      }
      if (column.fieldType === 'user' && groupType === true) {
        return (
          <Box key={column.position} className={classes.tableCell} align="left">
            <Tooltip title={row.columns[column.position]?.value} PopperProps={{ className: classes.commentTooltip }}>
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                {row.columns[column.position]?.value}
              </Typography>
            </Tooltip>
          </Box>
        );
      }
      if (
        column.fieldType === 'project' ||
        column.fieldType === 'user' ||
        column.fieldType === 'contract' ||
        column.fieldType === 'tracker'
      ) {
        let fetchFunction;
        let data = [];
        const projectUserId = useMemo(() => getProjectUserId(row.rowId), []);
        switch (column.fieldType) {
          case 'tracker':
            data = trackers;
            fetchFunction = fetchTrackersIssueData;
            break;
          case 'user':
            data = users;
            fetchFunction = () => fetchUserIssueData(projectUserId.projectId);
            break;
          case 'project':
            data = projects;
            fetchFunction = () => fetchProjectIssueData(projectUserId.userId);
            break;
          case 'contract':
            data = contracts;
            fetchFunction = () => fetchContractIssueData(projectUserId.projectId);
            break;
          default:
            break;
        }
        return (
          <Box key={column.position} className={classes.selectCell}>
            <Select
              IconComponent={() => (
                <Box className={classes.groupIcon}>
                  <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
                </Box>
              )}
              MenuProps={{
                className: classes.optionsGroupMenu,
              }}
              variant="standard"
              disableUnderline
              className={classes.createSelect}
              value={row.columns[column.position]?.valueId}
              onOpen={e => fetchFunction()}
              onChange={e => handleIssueBlur(row.rowId, column.fieldId, e.target.value)}
            >
              {data.length === 0 ? (
                <MenuItem value={row.columns[column.position]?.valueId}>
                  <Typography
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    variant="h5"
                    color="#212346"
                  >
                    {row.columns[column.position]?.value}
                  </Typography>
                </MenuItem>
              ) : (
                data.map(item => (
                  <MenuItem value={item.valueId} key={item.valueId}>
                    <Typography
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      variant="h5"
                      color="#212346"
                    >
                      {item.valueName}
                    </Typography>
                  </MenuItem>
                ))
              )}
            </Select>
          </Box>
        );
      }
      if (column.fieldType === 'monthly_distribution') {
        const isExceeded = row.columns[column.position]?.exceeded;
        return (
          <Box key={column.position} className={classes.dateCell} align="right">
            <Tooltip title={row.columns[column.position]?.value} PopperProps={{ className: classes.commentTooltip }}>
              <>
                <TextField
                  type="number"
                  value={row.columns[column.position]?.value}
                  className={clsx(
                    classes.dateCellField,
                    classes.dateCellCommentField,
                    { [classes.redText]: isExceeded }, // Apply redText class when isExceeded is true
                  )}
                  onChange={e => handleMonthPercentChange(row.rowId, column.position, e.target.value)}
                  onBlur={e => handleMonthFieldUpdate(row.groupId, row.rowId, column.field, e.target.value)}
                  align="right"
                  disabled={!canEdit}
                />
              </>
            </Tooltip>
          </Box>
        );
      }
      if (column.fieldType === 'loading_percent') {
        return (
          <Box key={column.position} className={classes.dateCell} align="right">
            <Tooltip title={row.columns[column.position]?.value} PopperProps={{ className: classes.commentTooltip }}>
              <>
                <TextField
                  type="number"
                  value={row.columns[column.position]?.value}
                  className={clsx(classes.dateCellField, classes.dateCellCommentField)}
                  onChange={e => handleMonthPercentChange(row.rowId, column.position, e.target.value)}
                  onBlur={e => {
                    // const loadingPercentColumn = row.columns.find(
                    //   column => column.fieldId === 'resourcePlan.loading_percent',
                    // );
                    // const loadingPercentValue = loadingPercentColumn ? loadingPercentColumn.value : null;
                    handlePercentFieldUpdate(
                      row.groupId,
                      row.rowId,
                      row.services?.startDate,
                      row.services?.endDate,
                      e.target.value,
                      column.fieldId,
                      e.target.value,
                    );
                  }}
                  disabled={!canEdit}
                  align="right"
                />
              </>
            </Tooltip>
          </Box>
        );
      }
      if (column.fieldType === 'date') {
        return (
          <Box key={column.position} className={classes.tableCell} align="left">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Tooltip
                title={formatDate(row.columns[column.position]?.value)}
                PopperProps={{ className: classes.commentTooltip }}
              >
                <>
                  <DatePicker
                    views={['day']}
                    // disableMaskedInput
                    className={classes.datePicker}
                    components={{
                      OpenPickerIcon: () => (
                        <CalendarTimesheetIcon
                          style={{ right: 10, width: 16, height: 16 }}
                          viewBox="0 0 16 16"
                          color={canEdit ? '#DCDFF4' : '#ffffff'} // Если canEdit истина, цвет серый, иначе дефолтный цвет
                        />
                      ),
                      LeftArrowIcon: () => <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />,
                      RightArrowIcon: () => (
                        <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />
                      ),
                      SwitchViewIcon: () => <ChevronIcon direction="down" viewBox="0 0 24 24" width={24} height={24} />,
                      SwitchViewButton: componentProps => (
                        <IconButton disableRipple {...componentProps}>
                          {componentProps.children}
                        </IconButton>
                      ),
                      RightArrowButton: componentProps => (
                        <IconButton disableRipple {...componentProps}>
                          {componentProps.children}
                        </IconButton>
                      ),
                      LeftArrowButton: componentProps => (
                        <IconButton disableRipple {...componentProps}>
                          {componentProps.children}
                        </IconButton>
                      ),
                    }}
                    renderInput={params => (
                      <Box display="flex" alignItems="center">
                        <TextField
                          {...params}
                          placeholder=""
                          inputProps={{
                            ...params.inputProps,
                            placeholder: ' ',
                          }}
                          helperText={null}
                          fullWidth
                        />
                      </Box>
                    )}
                    value={row.columns[column.position]?.value || ''}
                    onChange={date => {
                      const loadingPercentColumn = row.columns.find(
                        column => column.fieldId === 'resourcePlan.loading_percent',
                      );
                      const loadingPercentValue = loadingPercentColumn ? loadingPercentColumn.value : null;
                      handleChangeDate(
                        column.position,
                        date,
                        column.fieldId,
                        row.groupId,
                        row.rowId,
                        row.services?.startDate,
                        row.services?.endDate,
                        loadingPercentValue,
                      );
                    }}
                    disabled={!canEdit}
                  />
                </>
              </Tooltip>
            </LocalizationProvider>
          </Box>
        );
      }
      if (column.fieldType === 'issues') {
        return (
          <Box
            key={column.position}
            // className={clsx({
            //   [classes.redBorder]: !row.columns[column.position]?.value,
            //   [classes.tableCell]: row.columns[column.position]?.value,
            // })}
            className={clsx(classes.tableCell)}
            align="left"
          >
            <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
              <TextField
                value={row.columns[column.position]?.value}
                className={clsx(classes.tableCellField, classes.tableCellCommentField)}
                onBlur={e => {
                  handleIssueBlur(row.rowId, column.fieldId, e.target.value);
                }}
                onChange={e => handleIssueChange(row.rowId, column.position, e.target.value)}
                align="left"
              />
            </Tooltip>
          </Box>
        );
      }

      return (
        <Box key={column.position} className={classes.tableCell} align="left">
          <Tooltip title={row.columns[column.position]?.value} PopperProps={{ className: classes.commentTooltip }}>
            <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
              {row.columns[column.position]?.value}
            </Typography>
          </Tooltip>
        </Box>
      );
    }
    if (row.rowType === 'group' && !groupType) {
      if (column.position === 0) {
        return (
          <Box key={'addRow'}>
            <IconButton
              disableRipple
              className={classes.addRowButton}
              onClick={() => addNewRowAbove(row.rowId, row.groupId)}
            >
              <PlusIcon width={24} height={24} viewBox="0 0 24 24" />
            </IconButton>
          </Box>
        );
      }
      if (column.fieldType === 'loading_percent') {
        return (
          <Box key={'total'} align="right" style={{ paddingRight: '10px' }}>
            <Typography variant="h5" fontWeight={700}>
              <FormattedMessage id="resplan_table_total" defaultMessage="Total" />
            </Typography>
          </Box>
        );
      }
      if (column.fieldType === 'monthly_distribution') {
        return (
          <Box key={columnIndex} className={classes.dateCell} align="right">
            <Tooltip title={row.columns[column.field]?.group.value} PopperProps={{ className: classes.commentTooltip }}>
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                {row.columns[column.field]?.group.value}
              </Typography>
            </Tooltip>
          </Box>
        );
      }
      return (
        <Box key={column.position} className={classes.tableCell} align="left">
          <Tooltip title={row.columns[column.position]?.value} PopperProps={{ className: classes.commentTooltip }}>
            <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
              {row.columns[column.position]?.value}
            </Typography>
          </Tooltip>
        </Box>
      );
    }
    if (row.rowType === 'group' && groupType) {
      if (column.position === 0) {
        return (
          <Box key={'addRow'}>
            <IconButton
              disableRipple
              className={classes.addRowButton}
              onClick={() => addNewRowAbove(row.rowId, row.groupId)}
            >
              <PlusIcon width={24} height={24} viewBox="0 0 24 24" />
            </IconButton>
          </Box>
        );
      }
      if (column.fieldType === 'loading_percent') {
        return (
          <Box key={'total'} align="right" style={{ paddingRight: '10px' }}>
            <Typography variant="h5" fontWeight={700}>
              <FormattedMessage id="resplan_table_total" defaultMessage="Total" />
            </Typography>
            <Typography variant="h5" fontWeight={700}>
              <FormattedMessage id="resplan_table_perload" defaultMessage="Perload" />
            </Typography>
          </Box>
        );
      }
      if (column.fieldType === 'monthly_distribution') {
        return (
          <>
            <Box key={columnIndex} className={classes.dateCell} align="right">
              <Tooltip
                title={row.columns[column.field]?.group.value}
                PopperProps={{ className: classes.commentTooltip }}
              >
                <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                  {row.columns[column.field]?.group.value}
                </Typography>
              </Tooltip>
              {row.columns[column.field]?.perload && (
                <Tooltip
                  title={row.columns[column.field]?.perload.value}
                  PopperProps={{ className: classes.commentTooltip }}
                >
                  <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                    {row.columns[column.field]?.perload.value}
                  </Typography>
                </Tooltip>
              )}
            </Box>
          </>
        );
      }
      return (
        <Box key={column.position} className={classes.tableCell} align="left">
          <Tooltip title={row.columns[column.position]?.value} PopperProps={{ className: classes.commentTooltip }}>
            <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
              {row.columns[column.position]?.value}
            </Typography>
          </Tooltip>
        </Box>
      );
    }
    if (column.fieldType === 'monthly_distribution') {
      const isExceeded = row.columns[column.position]?.exceeded;
      return (
        <Box key={column.position} className={classes.dateCell} align="right">
          <Tooltip title={row.columns[column.position]?.value} PopperProps={{ className: classes.commentTooltip }}>
            <>
              <TextField
                type="number"
                value={row.columns[column.position]?.value}
                className={clsx(
                  classes.dateCellField,
                  classes.dateCellCommentField,
                  { [classes.redText]: isExceeded }, // Apply redText class when isExceeded is true
                )}
                onChange={e => handleMonthPercentChange(row.rowId, column.position, e.target.value)}
                onBlur={e => handleMonthFieldUpdate(row.groupId, row.rowId, column.field, e.target.value)}
                align="right"
                disabled={!canEdit}
              />
            </>
          </Tooltip>
        </Box>
      );
    }
    if (column.fieldType === 'loading_percent') {
      return (
        <Box key={column.position} className={classes.dateCell} align="right">
          <Tooltip title={row.columns[column.position]?.value} PopperProps={{ className: classes.commentTooltip }}>
            <>
              <TextField
                type="number"
                value={row.columns[column.position]?.value}
                className={clsx(classes.dateCellField, classes.dateCellCommentField)}
                onChange={e => handleMonthPercentChange(row.rowId, column.position, e.target.value)}
                onBlur={e => {
                  // const loadingPercentColumn = row.columns.find(
                  //   column => column.fieldId === 'resourcePlan.loading_percent',
                  // );
                  // const loadingPercentValue = loadingPercentColumn ? loadingPercentColumn.value : null;
                  handlePercentFieldUpdate(
                    row.groupId,
                    row.rowId,
                    row.services?.startDate,
                    row.services?.endDate,
                    e.target.value,
                    column.fieldId,
                    e.target.value,
                  );
                }}
                disabled={!canEdit}
                align="right"
              />
            </>
          </Tooltip>
        </Box>
      );
    }
    if (column.fieldType === 'issue') {
      return (
        <Box key={column.position} className={classes.tableCell} align="left">
          <Tooltip title={row.columns[column.position]?.value} PopperProps={{ className: classes.commentTooltip }}>
            <a
              href={handleGetRedirectUrl({
                id: row.services.issueId,
                columnType: 'issue',
              })}
              className={classes.tableCellLink}
              target="_blank"
            >
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                {row.columns[column.position]?.value}
              </Typography>
            </a>
          </Tooltip>
        </Box>
      );
    }
    if (column.fieldType === 'date') {
      return (
        <Box key={column.position} className={classes.tableCell} align="left">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Tooltip
              title={formatDate(row.columns[column.position]?.value)}
              PopperProps={{ className: classes.commentTooltip }}
            >
              <>
                <DatePicker
                  views={['day']}
                  // disableMaskedInput
                  className={classes.datePicker}
                  components={{
                    OpenPickerIcon: () => (
                      <CalendarTimesheetIcon
                        style={{ right: 10, width: 16, height: 16 }}
                        viewBox="0 0 16 16"
                        color={canEdit ? '#DCDFF4' : '#ffffff'} // Если canEdit истина, цвет серый, иначе дефолтный цвет
                      />
                    ),
                    LeftArrowIcon: () => <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />,
                    RightArrowIcon: () => <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />,
                    SwitchViewIcon: () => <ChevronIcon direction="down" viewBox="0 0 24 24" width={24} height={24} />,
                    SwitchViewButton: componentProps => (
                      <IconButton disableRipple {...componentProps}>
                        {componentProps.children}
                      </IconButton>
                    ),
                    RightArrowButton: componentProps => (
                      <IconButton disableRipple {...componentProps}>
                        {componentProps.children}
                      </IconButton>
                    ),
                    LeftArrowButton: componentProps => (
                      <IconButton disableRipple {...componentProps}>
                        {componentProps.children}
                      </IconButton>
                    ),
                  }}
                  renderInput={params => (
                    <Box display="flex" alignItems="center">
                      <TextField
                        {...params}
                        placeholder=""
                        inputProps={{
                          ...params.inputProps,
                          placeholder: ' ',
                        }}
                        helperText={null}
                        fullWidth
                      />
                    </Box>
                  )}
                  value={row.columns[column.position]?.value || ''}
                  onChange={date => {
                    const loadingPercentColumn = row.columns.find(
                      column => column.fieldId === 'resourcePlan.loading_percent',
                    );
                    const loadingPercentValue = loadingPercentColumn ? loadingPercentColumn.value : null;
                    handleChangeDate(
                      column.position,
                      date,
                      column.fieldId,
                      row.groupId,
                      row.rowId,
                      row.services?.startDate,
                      row.services?.endDate,
                      loadingPercentValue,
                    );
                  }}
                  disabled={!canEdit}
                />
              </>
            </Tooltip>
          </LocalizationProvider>
        </Box>
      );
    }

    return (
      <Box key={column.position} className={classes.tableCell} align="left">
        <Tooltip title={row.columns[column.position]?.value} PopperProps={{ className: classes.commentTooltip }}>
          <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
            {row.columns[column.position]?.value}
          </Typography>
        </Tooltip>
      </Box>
    );
  };
  return <>{renderCell()}</>;
};

export default memo(ResourcePlanTableCell);
