import { FormattedMessage, injectIntl } from 'react-intl';
import Page from '../../../../packages/common/shared-ui/src/components/Page';
import { Box, Button, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { getUsersInfo } from '../../../../packages/common/api';
import { useParams } from 'react-router-dom';
import userPageStyles from './UserPage.styles';
import UserPageCommonTab from './components/UserPageCommonTab/UserPageCommonTab';
import UserPageRatesTab from './components/UserPageRatesTab/UserPageRatesTab';
import PropTypes from 'prop-types';
import ProjectEditIcon from '../../../../packages/common/shared-ui/src/icons/ProjectEditIcon';

const UserPage = ({ intl }) => {
  const classes = userPageStyles();
  const tabsNames = { COMMON: 0, RATES: 1 };
  const pageTitle = intl.formatMessage({ id: 'menu_item_administration_users' });
  const params = useParams();

  const [activeTab, setActiveTab] = useState(tabsNames.COMMON);
  const [userInfo, setUserInfo] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  useEffect(() => {
    let ignore = false;
    async function getInfo() {
      const { data: userInfoValue } = await getUsersInfo(params.id);
      if (!ignore) {
        return userInfoValue;
      }
    }
    getInfo()
      .then(res => {
        setUserInfo(res);
      })
      .catch(error => console.error(error, 'ERROR IN GET USER INFO'));
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = useCallback(() => {
    setIsEdit(!isEdit);
  }, [isEdit]);

  return (
    <Page title={pageTitle} width="100%">
      <Typography m={0} p={0} variant="h1">
        {pageTitle}
      </Typography>
      <Typography m={0} p={0} sx={{ color: '#41424E', fontWeight: '700' }} variant="h3">
        {userInfo && userInfo.login}
      </Typography>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            className={classes.userTabs}
            TabIndicatorProps={{ sx: { display: 'none' } }}
          >
            <Tab label={intl.formatMessage({ id: 'users_page_common' })} />
            <Tab label={intl.formatMessage({ id: 'users_page_rates' })} />
          </Tabs>
        </Grid>
        {/*        <Grid item>
          {!isEdit ? (
            <IconButton onClick={handleEdit}>
              <ProjectEditIcon width={18} height={16} viewBox="0 0 18 16" />
            </IconButton>
          ) : (
            <Box className={classes.buttonsBlock}>
              <Button variant="cutBluePrimary" className={classes.saveReportButton}>
                <FormattedMessage id="save_text" defaultMessage="Save" />
              </Button>
              <Button onClick={handleEdit} variant="cutBluePrimary">
                <FormattedMessage id="cancel" defaultMessage="cancel" />
              </Button>
            </Box>
          )}
        </Grid>*/}
      </Grid>
      <CustomTabPanel value={activeTab} index={0}>
        <UserPageCommonTab userInfo={userInfo} isEdit={isEdit}></UserPageCommonTab>
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={1}>
        <UserPageRatesTab userId={userInfo?.redmineId}></UserPageRatesTab>
      </CustomTabPanel>
    </Page>
  );
};

export default injectIntl(UserPage);
