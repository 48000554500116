import React, { memo, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Form, withFormik } from 'formik';
import * as yup from 'yup';
import { useQueryParam } from 'use-query-params';
import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Page from 'Common/shared-ui/src/components/Page';
import CalendarHeader from '../CalendarHeader';
import CalendarBody from '../CalendarBody/CalendarBody';
import { withProductionCalendarRequests } from '../withProductionCalendarRequests';
import { useSelector } from 'react-redux';

const ProdCalendarContainer = ({
  intl,
  values,
  errors,
  setFieldValue,
  handleChange,
  handleSubmit,
  customDays,
  customExclusions,
  setCustomExclusions,
  workHours,
  offDays,
  getDatesExclusions,
  getCalendarDateExclusions,
  setCustomDates,
  setWorkHours,
  setOffDays,
  isLoading,
  setIsLoading,
  isCreateModalOpen,
  toggleCreateModalOpen,
  submitForm,
  handleUpdateRequests,
}) => {
  const [year, setYear] = useQueryParam('year');
  const appLang = useSelector(state => state.user.language);
  const pageTitle = intl.formatMessage({ id: 'page_title_production_calendar' });

  const removeException = useCallback(
    async e => {
      try {
        setIsLoading(true);
        const dayDate = e.currentTarget.attributes.value.nodeValue;
        // await deleteCustomExclusion(e.currentTarget.id);
        setCustomExclusions(
          Object.fromEntries(Object.entries(customExclusions).filter(date => date[1].date !== dayDate)),
        );
        setCustomDates(Object.fromEntries(Object.entries(customDays).filter(date => date[1].date !== dayDate)));
        setFieldValue(
          'newCustomDates',
          values.newCustomDates.filter(date => date[dayDate]?.date !== dayDate),
        );
        // getDatesExclusions.current(year);
        return setIsLoading(false);
      } catch (error) {
        throw new Error(error);
      }
    },
    [
      customDays,
      customExclusions,
      setCustomDates,
      setCustomExclusions,
      setFieldValue,
      setIsLoading,
      values.newCustomDates,
    ],
  );

  const handleBackYear = useCallback(() => {
    const prevYear = dayjs(year).subtract(1, 'year').get('year');
    setYear(prevYear);
  }, [setYear, year]);

  const handleForwardYear = useCallback(() => {
    const forwardYear = dayjs(year).add(1, 'year').get('year');
    setYear(forwardYear);
  }, [setYear, year]);

  const getNewCustomDay = useCallback(
    (prevState = []) => ({
      ...prevState,
      [values.date]: {
        date: values.date,
        hours: values.hours,
        isExclusion: values.hours !== 0,
        isDayOff: values.hours === 0,
        exclusionId: values.date,
      },
    }),
    [values.date, values.hours],
  );

  const getNewExclusionDay = useCallback(
    prevState => ({
      ...prevState,
      [values.date]: {
        date: values.date,
        hours: values.hours,
      },
    }),
    [values.date, values.hours],
  );

  const isDayEquallyOff = useCallback(
    day => {
      return values.offDays.map(offDay => day === offDay || day === 0);
    },
    [values.offDays],
  );

  const handleUpdateCalendars = useCallback(
    e => {
      setIsLoading(prevState => !prevState);
      const currentDayOfWeek = dayjs(values.date).day();
      if (isDayEquallyOff(currentDayOfWeek).includes(true)) {
        setFieldValue('newCustomDates', [getNewCustomDay(), ...values.newCustomDates]);
        setCustomExclusions(prevState => getNewExclusionDay(prevState));
        setCustomDates(prevState => getNewCustomDay(prevState));
        toggleCreateModalOpen(false);
        return setIsLoading(prevState => !prevState);
      }
      if (Number(values.workHours) === Number(values.hours)) {
        setCustomExclusions(
          Object.fromEntries(Object.entries(customExclusions).filter(date => date[0] !== values.date)),
        );
        setCustomDates(Object.fromEntries(Object.entries(customDays).filter(date => date[0] !== values.date)));
        setFieldValue('newCustomDates', values.newCustomDates.filter(date => date.date !== values.date) || []);
        toggleCreateModalOpen(false);
        return setIsLoading(prevState => !prevState);
      }
      setFieldValue('newCustomDates', [getNewCustomDay(), ...values.newCustomDates]);
      setCustomExclusions(prevState => getNewExclusionDay(prevState));
      setCustomDates(prevState => getNewCustomDay(prevState));
      toggleCreateModalOpen(false);
      return setIsLoading(prevState => !prevState);
    },
    [
      customDays,
      customExclusions,
      getNewCustomDay,
      getNewExclusionDay,
      isDayEquallyOff,
      setCustomDates,
      setCustomExclusions,
      setFieldValue,
      setIsLoading,
      toggleCreateModalOpen,
      values.date,
      values.hours,
      values.newCustomDates,
      values.workHours,
    ],
  );

  return (
    <Page title={pageTitle} display="flex" width="100%">
      <Grid container direction="column">
        <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <CalendarHeader
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            workHours={workHours}
            offDays={offDays}
            setWorkHours={setWorkHours}
            setOffDays={setOffDays}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={appLang.split('-')[0]}>
            <CalendarBody
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              customDays={customDays}
              customExclusions={customExclusions}
              isLoading={isLoading}
              handleBackYear={handleBackYear}
              handleForwardYear={handleForwardYear}
              year={year}
              getDatesExclusions={getDatesExclusions}
              getCalendarDateExclusions={getCalendarDateExclusions}
              removeException={removeException}
              isCreateModalOpen={isCreateModalOpen}
              toggleCreateModalOpen={toggleCreateModalOpen}
              setCustomDates={setCustomDates}
              workHours={workHours}
              setIsLoading={setIsLoading}
              handleUpdateCalendars={handleUpdateCalendars}
              submitForm={submitForm}
              handleUpdateRequests={handleUpdateRequests}
            />
          </LocalizationProvider>
        </Form>
      </Grid>
    </Page>
  );
};

export default injectIntl(
  withProductionCalendarRequests(
    withFormik({
      enableReinitialize: true,
      mapPropsToValues: ({ workHours, offDays }) => ({
        hours: Number(workHours),
        date: dayjs().format('YYYY-MM-DD'),
        newCustomDates: [],
        workHours,
        offDays,
      }),
      validationSchema: yup.object().shape({
        hours: yup.number().max(24, 'Too much').required('Required'),
        date: yup.string().min(10, 'Too Short!').required('Required'),
      }),
      handleSubmit(formValues, { props, resetForm, setSubmitting, setErrors }) {
        props.handleUpdateProductionCalendar({
          formValues,
          resetForm,
          setSubmitting,
          setErrors,
        });
      },
    })(memo(ProdCalendarContainer)),
  ),
);
