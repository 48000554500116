import React from 'react';
import { SvgIcon } from '@mui/material';

function FilterIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.14 12.94C19.18 12.64 19.2 12.33 19.2 12C19.2 11.68 19.18 11.36 19.13 11.06L21.16 9.48003C21.34 9.34003 21.39 9.07003 21.28 8.87003L19.36 5.55003C19.24 5.33003 18.99 5.26003 18.77 5.33002L16.38 6.29003C15.88 5.91003 15.35 5.59002 14.76 5.35002L14.4 2.81002C14.36 2.57002 14.16 2.40002 13.92 2.40002H10.08C9.83999 2.40002 9.64999 2.57002 9.60999 2.81002L9.24999 5.35002C8.65999 5.59002 8.11999 5.92003 7.62999 6.29003L5.23999 5.33002C5.01999 5.25003 4.76999 5.33003 4.64999 5.55003L2.73999 8.87003C2.61999 9.08003 2.65999 9.34003 2.85999 9.48003L4.88999 11.06C4.83999 11.36 4.79999 11.69 4.79999 12C4.79999 12.31 4.81999 12.64 4.86999 12.94L2.83999 14.52C2.65999 14.66 2.60999 14.93 2.71999 15.13L4.63999 18.45C4.75999 18.67 5.00999 18.74 5.22999 18.67L7.61999 17.71C8.11999 18.09 8.64999 18.41 9.23999 18.65L9.59999 21.19C9.64999 21.43 9.83999 21.6 10.08 21.6H13.92C14.16 21.6 14.36 21.43 14.39 21.19L14.75 18.65C15.34 18.41 15.88 18.09 16.37 17.71L18.76 18.67C18.98 18.75 19.23 18.67 19.35 18.45L21.27 15.13C21.39 14.91 21.34 14.66 21.15 14.52L19.14 12.94ZM12 15.6C10.02 15.6 8.39999 13.98 8.39999 12C8.39999 10.02 10.02 8.40003 12 8.40003C13.98 8.40003 15.6 10.02 15.6 12C15.6 13.98 13.98 15.6 12 15.6Z"
          fill="#7174AC"
        />
      </svg>
    </SvgIcon>
  );
}

export default FilterIcon;
