import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  AuthContainerRoot: {
    backgroundColor: '#DCDFF4',
    padding: theme.spacing(8),
    height: '100vh',
    [theme.breakpoints.maxWidth('sm')]: {
      padding: theme.spacing(4),
    },
  },
}));

export default useStyles;
