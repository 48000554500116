import React from 'react';
import { SvgIcon } from '@mui/material';

const UnblockIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_13531_13406)">
          <path
            d="M8.00033 11.3333C8.73366 11.3333 9.33366 10.7333 9.33366 10C9.33366 9.26666 8.73366 8.66666 8.00033 8.66666C7.26699 8.66666 6.66699 9.26666 6.66699 10C6.66699 10.7333 7.26699 11.3333 8.00033 11.3333ZM12.0003 5.33333H11.3337V4C11.3337 2.16 9.84032 0.666664 8.00033 0.666664C6.16033 0.666664 4.66699 2.16 4.66699 4H5.93366C5.93366 2.86 6.86033 1.93333 8.00033 1.93333C9.14033 1.93333 10.067 2.86 10.067 4V5.33333H4.00033C3.26699 5.33333 2.66699 5.93333 2.66699 6.66666V13.3333C2.66699 14.0667 3.26699 14.6667 4.00033 14.6667H12.0003C12.7337 14.6667 13.3337 14.0667 13.3337 13.3333V6.66666C13.3337 5.93333 12.7337 5.33333 12.0003 5.33333ZM12.0003 13.3333H4.00033V6.66666H12.0003V13.3333Z"
            fill="#DCDFF4"
          />
        </g>
        <defs>
          <clipPath id="clip0_13531_13406">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
export default UnblockIcon;
