import React, { useEffect, useReducer, useRef, useState } from 'react';
import Page from 'Common/shared-ui/src/components/Page';
import dayjs from 'dayjs';
import { useParams, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import clsx from 'clsx';
import useStyles from './ProjectCreate.styles';
import { generateRedirectUrl } from 'Common/utils/getRedirectUrl';
import DeleteIcon from 'Common/shared-ui/src/icons/DeleteIcon';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import { createProject, createProjectFromData, getVerifyProjectIdentifier } from '../../../../packages/common/api';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import {
  CircularProgress,
  Tabs,
  Box,
  Tab,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Autocomplete,
  TableBody,
  Button,
  Tooltip,
  Checkbox,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  MenuItem,
  Paper,
  IconButton,
  Chip,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import CustomField from 'Common/shared-ui/src/components/CustomField';
import ProjectOpenIcon from '../../../../packages/common/shared-ui/src/icons/ProjectOpenIcon';
import ProjectCloseIcon from '../../../../packages/common/shared-ui/src/icons/ProjectCloseIcon';
import ProjectUserGroupeIcon from '../../../../packages/common/shared-ui/src/icons/ProjectUserGroupeIcon';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
const ProjectCreate = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const classes = useStyles();
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [isLoading, setIsLoading] = useState(true);
  const [projectData, setProjectData] = useState([]);
  const [errors, setErrors] = useState([]);
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const [changes, setChanges] = useState(false);
  const handleChangeInput = (e, fieldName) => {
    setChanges(true);
    let tempProjectData = JSON.parse(JSON.stringify(projectData));
    if (fieldName == 'isPublic') {
      tempProjectData[fieldName] = event.target.checked;
    } else {
      tempProjectData[fieldName] = e.target.value;
    }
    console.log(tempProjectData[fieldName]);
    setProjectData(tempProjectData);
  };
  const handleDeleteTracker = (e, value, index) => {
    setChanges(true);
    console.log(index);
    setProjectData(prevState => {
      prevState.trackers[index].isChecked = false;
      return prevState;
    }, forceUpdate());
  };
  const handleChangeSelect = (event, type) => {
    setChanges(true);
    setProjectData(prevState => {
      console.log(prevState);
      if (type == 'parents') {
        prevState.parentId = event.target.value;
        prevState.parents.forEach(item => {
          if (item.projectId == event.target.value) {
            item.isChecked = item.isChecked === true ? false : true;
          } else {
            item.isChecked = false;
          }
        });
      } else if (type == 'calculation') {
        prevState.calculationTypeId = event.target.value;
        prevState.calculationTypes.forEach(item => {
          if (item.valueId == event.target.value) {
            item.isChecked = item.isChecked === true ? false : true;
          } else {
            item.isChecked = false;
          }
        });
      } else {
        prevState.trackers.forEach(item => {
          if (item.trackerId == event.target.value) {
            item.isChecked = item.isChecked === true ? false : true;
          }
        });
      }

      return prevState;
    }, forceUpdate());
  };
  const PaperComponent = props => {
    return (
      <Paper {...props}>
        <Box className={classes.menuGroupWrap}>{props.children}</Box>
      </Paper>
    );
  };
  const handleCancelSaveProject = project => {
    const projectUrl = '/projects/';
    navigate(projectUrl);
  };
  const handleSaveProject = async () => {
    console.log('SAVE');
    console.log(projectData);
    let tempErrors = [];
    const regexForIdentifier = /^[a-z0-9-_]*$/;
    const regexForUrl = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    if (regexForIdentifier.test(projectData.identifier)) {
      try {
        const response = await getVerifyProjectIdentifier(projectData.identifier);
        console.log('Check id');
        console.log(response);
        if (!response) {
          setChanges(false);
          setIsLoading(false);
          tempErrors = [...tempErrors, intl.formatMessage({ id: 'incorrect_id' })];
        }
      } catch (error) {
        return console.log(error);
      }
    } else {
      tempErrors = [...tempErrors, intl.formatMessage({ id: 'incorrect_id' })];
    }
    if (projectData.homepage.length > 0 && !regexForUrl.test(projectData.homepage)) {
      tempErrors = [...tempErrors, intl.formatMessage({ id: 'incorrect_homepage_url' })];
    }
    if (projectData.name.length < 1) {
      tempErrors = [...tempErrors, intl.formatMessage({ id: 'incorrect_name' })];
    }
    let tempProjectData = [...projectData.customs];
    console.log(tempProjectData);
    tempProjectData = tempProjectData.filter(field => field.fieldDefinition.isRequired && field.valueId === null);
    console.log(tempProjectData);
    tempProjectData.forEach(field => {
      let message = intl.formatMessage({ id: 'incorrect_field' });
      tempErrors = [...tempErrors, message + field.fieldName];
    });
    setErrors(tempErrors);
    console.log(tempErrors);
    if (tempErrors.length < 1) {
      console.log('Go save');
      let saveProjectObject = JSON.parse(JSON.stringify(projectData));
      console.log(saveProjectObject);
      delete saveProjectObject.parents;
      delete saveProjectObject.defaultAssignedTo;
      saveProjectObject.trackers = saveProjectObject.trackers
        .filter(filter => filter.isChecked)
        .map(item => item.trackerId);
      saveProjectObject.customs = saveProjectObject.customs.filter(item => item.valueId !== null);
      saveProjectObject.customs.map((field, index) => {
        if (field.fieldDefinition.fieldFormat == 'date' && field.valueId != '') {
          field.valueId = dayjs(field.valueId).format('YYYY-MM-DD');
        }
        if (
          field.fieldDefinition.fieldFormat == 'enumeration' ||
          field.fieldDefinition.fieldFormat == 'user' ||
          field.fieldDefinition.fieldFormat == 'list'
        ) {
          field.valueId = field.fieldDefinition.values.filter(field => field.isChecked);
        }
        delete field.fieldDefinition;
        delete field.fieldName;
        delete field.valueName;

        if (Array.isArray(field.valueId)) {
          field.valueId = field.valueId.map(item => item.valueId);
        } else {
          if (typeof field.valueId === 'object') {
            field.valueId = field.valueId;
          }
        }

        return field;
      });
      console.log(saveProjectObject);
      try {
        const response = await createProjectFromData(saveProjectObject);
        console.log(response);
        const projectUrl = '/projects/' + response.data.identifier;
        navigate(projectUrl);
      } catch (error) {
        return console.log(error);
      }
    }
  };
  useEffect(() => {
    async function createProjectData() {
      try {
        let response = await createProject();
        console.log(response);
        response.customs.forEach(field => {
          if (field.fieldDefinition.values != null) {
            field.fieldDefinition.selectedValues = field.fieldDefinition.values.filter(filed => filed.isChecked);
          }
        });
        setProjectData(response);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    }
    createProjectData();
  }, []);
  return (
    <>
      {!isLoading ? (
        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
        <Page title="Новый проект" width="100%" className={classes.ProjectsContainerRoot}>
          <Grid container position="relative" className={classes.filtersWrap}>
            <Grid
              container
              item
              container
              marginTop={1}
              spacing={5}
              alignItems="center"
              justifyContent="space-between"
              display="flex"
              position="relative"
              className={classes.projectsPageTtl}
            >
              {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
              <Typography variant="h1">Новый проект</Typography>
              <Box className={classes.actionsBlock}>
                <Button
                  disabled={!changes}
                  onClick={handleSaveProject}
                  variant="cutBluePrimary"
                  className={classes.saveReportButton}
                >
                  <FormattedMessage id="save_text" defaultMessage="Save" />
                </Button>
                <Button
                  onClick={handleCancelSaveProject}
                  variant="cutBluePrimary"
                  className={classes.cancelReportButton}
                >
                  <FormattedMessage id="cancel" defaultMessage="cancel" />
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.ProjectsTabsWrap}>
            <Grid container marginTop={5} gap={10} position="relative">
              <Grid item xs={5.5} className={classes.projectWrap}>
                <Box className={classes.projectErrorsWrap}>
                  {errors.map((error, index) => (
                    <Box className={classes.projectError} key={index}>
                      {error}
                    </Box>
                  ))}
                </Box>
                <Grid container spacing={5} position="relative" className={classes.projectFieldsWrap}>
                  <Box className={classes.projectFieldWrap}>
                    <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Box className={classes.projectFieldLeft}>
                        Имя проекта
                        <Box component="span" className={classes.red}>
                          *
                        </Box>
                      </Box>
                      <Box className={classes.projectFieldRight}>
                        <TextField
                          hiddenLabel
                          onChange={e => handleChangeInput(e, 'name')}
                          value={projectData.name}
                          placeholder={projectData.name.length > 0 ? '' : intl.formatMessage({ id: 'enter' })}
                          className={classes.nameInput}
                          fullWidth
                          variant="filled"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.projectFieldWrap}>
                    <Box
                      className={clsx(classes.projectFiledTtl, classes.projectFieldRow, classes.projectFieldDescRow)}
                    >
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Box className={classes.projectFieldLeft}>Описание</Box>
                      <Box className={classes.projectFieldRight}>
                        <TextField
                          hiddenLabel
                          rows={5}
                          multiline
                          onChange={e => handleChangeInput(e, 'description')}
                          value={projectData.description}
                          className={clsx(classes.nameInput, classes.textArea)}
                          fullWidth
                          variant="filled"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.projectFieldWrap}>
                    <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Box className={classes.projectFieldLeft}>
                        Уникальный идентификатор
                        <Box component="span" className={classes.red}>
                          *
                        </Box>
                      </Box>
                      <Box className={classes.projectFieldRight}>
                        <TextField
                          hiddenLabel
                          onChange={e => handleChangeInput(e, 'identifier')}
                          value={projectData.identifier}
                          placeholder={projectData.identifier.length > 0 ? '' : intl.formatMessage({ id: 'enter' })}
                          className={classes.nameInput}
                          fullWidth
                          variant="filled"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.projectFieldWrap}>
                    <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Box className={classes.projectFieldLeft}>Стартовая страница</Box>
                      <Box className={classes.projectFieldRight}>
                        <TextField
                          hiddenLabel
                          onChange={e => handleChangeInput(e, 'homepage')}
                          placeholder={projectData.homepage.length > 0 ? '' : intl.formatMessage({ id: 'enter' })}
                          value={projectData.homepage}
                          className={classes.nameInput}
                          fullWidth
                          variant="filled"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.projectFieldWrap}>
                    <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Box className={classes.projectFieldLeft}>Общедоступный</Box>
                      <Box className={classes.projectFieldRight}>
                        <Checkbox
                          onChange={e => handleChangeInput(e, 'isPublic')}
                          icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
                          checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                          checked={projectData.isPublic}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.projectFieldWrap}>
                    <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Box className={classes.projectFieldLeft}>Родительский проект:</Box>
                      <Box className={classes.projectFieldRight}>
                        <Autocomplete
                          options={projectData.parents}
                          value={projectData.parents}
                          popupIcon={<ChevronIcon direction="down" viewBox="0 0 24 24" />}
                          disableClearable
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          className={classes.autocompleteRolesList}
                          onChange={(e, val) => handleChangeSelect(e, 'parents')}
                          getOptionLabel={option => option.projectName || ''}
                          clearIcon={null}
                          ChipProps={{
                            className: classes.subItemChip,
                            deleteIcon: null,
                          }}
                          renderOption={(props, option, { selected }) => (
                            <MenuItem
                              {...props}
                              key={option.projectId}
                              value={option.projectId}
                              className={clsx(classes.poperItem, option.isChecked ? classes.itemSelected : '')}
                            >
                              {option.projectName}
                            </MenuItem>
                          )}
                          renderInput={params => {
                            let selectedFields = projectData.parents.filter(filter => filter.isChecked);
                            let selectedFieldsNames = selectedFields.map(project => project.projectName).join(',');
                            return (
                              <TextField
                                {...params}
                                value={selectedFieldsNames}
                                placeholder={
                                  selectedFieldsNames.length > 0
                                    ? selectedFieldsNames
                                    : intl.formatMessage({ id: 'choose' })
                                }
                              />
                            );
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.projectFieldWrap}>
                    <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Box className={classes.projectFieldLeft}>Тип расчета:</Box>
                      <Box className={classes.projectFieldRight}>
                        <Autocomplete
                          options={projectData.calculationTypes}
                          value={projectData.calculationTypes}
                          popupIcon={<ChevronIcon direction="down" viewBox="0 0 24 24" />}
                          disableClearable
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          className={classes.autocompleteRolesList}
                          onChange={(e, val) => handleChangeSelect(e, 'calculation')}
                          getOptionLabel={option => option.valueName || ''}
                          clearIcon={null}
                          ChipProps={{
                            className: classes.subItemChip,
                            deleteIcon: null,
                          }}
                          renderOption={(props, option, { selected }) => (
                            <MenuItem
                              {...props}
                              key={option.valueId}
                              value={option.valueId}
                              className={clsx(classes.poperItem, option.isChecked ? classes.itemSelected : '')}
                            >
                              {option.valueName}
                            </MenuItem>
                          )}
                          renderInput={params => {
                            let selectedFields = projectData.calculationTypes?.filter(filter => filter.isChecked);
                            let selectedFieldsNames = selectedFields?.map(project => project.valueName).join(',');
                            return (
                              <TextField
                                {...params}
                                value={selectedFieldsNames}
                                placeholder={
                                  selectedFieldsNames?.length > 0
                                    ? selectedFieldsNames
                                    : intl.formatMessage({ id: 'choose' })
                                }
                              />
                            );
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {projectData.customs.map((field, index) => (
                    <Box className={classes.projectFieldWrap} key={index}>
                      <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                        {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                        <Box className={classes.projectFieldLeft}>
                          {field.fieldName}
                          <Box component="span" className={classes.red}>
                            {field.fieldDefinition.isRequired ? '*' : ''}
                          </Box>
                        </Box>
                        <Box className={classes.projectFieldRight}>
                          <CustomField
                            intl={intl}
                            fieldData={field}
                            fieldIndex={index}
                            setProjectData={setProjectData}
                            setChanges={setChanges}
                          />
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={6} className={classes.projectWrap}>
                <Grid container spacing={5} position="relative" className={classes.projectFieldsWrap}>
                  {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                  <Typography className={classes.projectFieldsTtl} variant="h3">
                    Настройка задач
                  </Typography>
                  <Box className={classes.projectFieldWrap}>
                    <Box
                      className={clsx(classes.projectFiledTtl, classes.projectFieldRow, classes.projectTrackersWrap)}
                    >
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Box className={classes.projectFieldLeft}>Тип задачи:</Box>
                      <Box className={classes.projectFieldRight}>
                        <Autocomplete
                          multiple
                          options={projectData.trackers}
                          value={projectData.trackers}
                          disableCloseOnSelect
                          disableClearable
                          popupIcon={''}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          className={classes.autocompleteTrackersList}
                          PaperComponent={(e, val) => PaperComponent(e)}
                          onChange={(e, val) => handleChangeSelect(e, 'trackers')}
                          getOptionLabel={option => option.trackerName || ''}
                          clearIcon={null}
                          ChipProps={{
                            className: classes.subItemChip,
                            deleteIcon: null,
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                              if (option.isChecked) {
                                return (
                                  <Box className={classes.chips} key={option.trackerId}>
                                    <Chip
                                      key={option.trackerId}
                                      label={option.trackerName}
                                      clickable
                                      deleteIcon={<DeleteIcon width={10} height={10} viewBox="0 0 10 10" />}
                                      className={classes.chip}
                                      onDelete={e => handleDeleteTracker(e, value, index)}
                                    />
                                  </Box>
                                );
                              }
                            })
                          }
                          renderOption={(props, option) => (
                            <MenuItem
                              {...props}
                              className={classes.poperItem}
                              key={option.trackerId}
                              value={option.trackerId}
                            >
                              <Checkbox
                                icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                                checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                                style={{ marginRight: 8 }}
                                checked={option.isChecked}
                                value={option.trackerId}
                              />
                              {option.trackerName}
                            </MenuItem>
                          )}
                          renderInput={params => <TextField {...params} variant="standard" margin="normal" fullWidth />}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid container spacing={5} position="relative" className={classes.projectFieldsWrap}>
                  {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                  <Typography className={classes.projectFieldsTtl} variant="h3">
                    Участники
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Page>
      ) : (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </>
  );
};
export default injectIntl(ProjectCreate);
