import React from 'react';
import { SvgIcon } from '@mui/material';

const ProjectUserGroupeIcon = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_1234">
        <path
          d="M10.666 4.333a1.992 1.992 0 0 0 1.993-2 1.997 1.997 0 1 0-3.993 0c0 1.107.893 2 2 2Zm-5.333 0a1.992 1.992 0 0 0 1.993-2 1.997 1.997 0 1 0-3.993 0c0 1.107.893 2 2 2Zm0 1.334C3.779 5.667.666 6.447.666 8v1.667h9.333V8c0-1.553-3.113-2.333-4.666-2.333Zm5.333 0a7.79 7.79 0 0 0-.647.033c.774.56 1.314 1.313 1.314 2.3v1.667h4V8c0-1.553-3.114-2.333-4.667-2.333Z"
          fill="#7174AC"
        />
      </g>
      <defs>
        <clipPath id="clip0_1234">
          <rect width="16" height="10" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ProjectUserGroupeIcon;
