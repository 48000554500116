import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  filterWrap: {
    display: 'inline-flex !important',
    width: '177px',
    verticalAlign: 'top',
    '& .MuiAutocomplete-input': {
      position: 'relative',
    },
    '& .autoCompletePopper': {
      position: 'relative',
    },
    '& defaultFirstStepPaper': {
      position: 'relative',
    },
    '& .MuiFormControl-root': {
      margin: '0px',
    },
  },
  filterTopRow: {
    display: 'flex',
    width: '190px',
    verticalAlign: 'top',
    '& .MuiAutocomplete-input': {
      position: 'relative',
    },
    '& .autoCompletePopper': {
      position: 'relative',
    },
    '& defaultFirstStepPaper': {
      position: 'relative',
    },
    '& .MuiFormControl-root': {
      margin: '0px',
    },
  },
  filterWrapStatus: {
    width: 'auto !important',
  },
  filterWrapDisabledInput: {
    '& .MuiBox-root': {
      width: '100%',
    },
  },
  confirmButton: {
    '&.MuiButtonBase-root, &.MuiButton-root': {
      padding: '8px 8px',
      borderRadius: '12px',
      minWidth: '40px',
      height: '40px',
      marginLeft: '4px',
    },
  },
  commentTooltip: {
    '& .MuiTooltip-tooltip': {
      marginTop: '0 !important',
      marginBottom: '3px !important',
      backgroundColor: '#F1F2F8',
      borderRadius: theme.spacing(2),
      padding: theme.spacing(2),
      color: '#212346',
      boxShadow: '0px 5px 10px -8px',
      fontSize: '12px',
    },
  },
}));

export default useStyles;
