import React, { memo, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import clsx from 'clsx';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';

import PlusIcon from 'Common/shared-ui/src/icons/PlusIcon';
import { formatWeek } from '../formatWeek';
import useStyles from './SpreadsheetTable.styles';
import SpreadsheetWeek from '../SpreadsheetWeek';
import SpreadsheetMobileIssueButton from '../SpreadsheetMobileIssueButton/SpreadsheetMobileIssueButton';

const SpreadsheetTable = ({
  weekDataHeader,
  startOfWeek,
  endOfWeek,
  showOnlyFilledIn,
  tableFooterDaysSum,
  formattedIssues,
  editedFormattedIssues,
  getAnotherProjectsData,
  anotherProjects,
  formattedAnotherProjects,
  tableSum,
  setTableSum,
  timeType,
  timeTypes,
  intl,
  query,
  setQuery,
}) => {
  const [workDaysCount, setWorkDaysCount] = useState(0);
  const [workDayHours, setWorkDayHours] = useState(0);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('lg'));
  const classes = useStyles();

  useEffect(() => {
    setTableSum(0);
    tableFooterDaysSum.forEach(daySum => {
      setTableSum(
        prevState => prevState + daySum[timeType === timeTypes.MAIN_HOURS ? 'totalNormalHours' : 'totalOvertimeHours'],
      );
    });
    setWorkDaysCount(tableFooterDaysSum.filter(day => day.expectedHours !== 0).length);
    setWorkDayHours(
      tableFooterDaysSum
        .filter(day => day.totalNormalHours !== 0)
        .reduce(
          (acc, current) => acc + parseFloat(Math.abs(current.totalNormalHours - current.expectedHours).toFixed(1)),
          0,
        ),
    );
  }, [setTableSum, tableFooterDaysSum, timeType, timeTypes.MAIN_HOURS]);

  const handleHideWeek = useCallback(
    e => {
      timeType === timeTypes.MAIN_HOURS
        ? (formattedIssues[e.currentTarget.attributes.issueid.value].hiddenMainHour = true)
        : (formattedIssues[e.currentTarget.attributes.issueid.value].hiddenOvertimeHour = true);
      return forceUpdate();
    },
    [formattedIssues, timeType, timeTypes.MAIN_HOURS],
  );

  const getCurrentIssueWeek = useCallback(
    (issueId, type) => formattedIssues[issueId][type === timeTypes.MAIN_HOURS ? 'weekDays' : 'overTimeWeekDays'],
    [formattedIssues, timeTypes.MAIN_HOURS],
  );

  const getCurrentWeekDayIndex = useCallback(
    (currentWeek, date) =>
      currentWeek.reduce((acc, day, index) => {
        if (dayjs(day.date).format() === dayjs(date).format()) {
          return index;
        }
        return acc;
      }, 0),
    [],
  );

  const handleChangeDayHour = useCallback(
    (newValue, issueId, date, type) => {
      let regex = /^[0-9]{1,3}([,.][0-9]{0,2})*$/;
      const currentIssue = formattedIssues[issueId];
      const currentWeek = getCurrentIssueWeek(issueId, type);
      const currentWeekDayIndex = getCurrentWeekDayIndex(currentWeek, date);
      currentWeek[currentWeekDayIndex].isEdited = true;
      currentWeek[currentWeekDayIndex].hours = regex.test(newValue) ? newValue : 0;
      currentWeek[currentWeekDayIndex].issueId = currentIssue.issueId;
      currentWeek[currentWeekDayIndex].issueUniqueId = currentIssue.issueUniqueId;
      editedFormattedIssues[currentIssue.issueUniqueId] = currentIssue;
    },
    [editedFormattedIssues, formattedIssues, getCurrentIssueWeek, getCurrentWeekDayIndex],
  );

  const handleChangeDayComment = useCallback(
    (newValue, issueId, date, type) => {
      const currentIssue = formattedIssues[issueId];
      const currentWeek = getCurrentIssueWeek(issueId, type);
      const currentWeekDayIndex = getCurrentWeekDayIndex(currentWeek, date);
      currentWeek[currentWeekDayIndex].isEdited = true;
      currentWeek[currentWeekDayIndex].comments = newValue;
      if (currentWeek[currentWeekDayIndex].redmineId === 0 && newValue.length === 0) {
        currentWeek[currentWeekDayIndex].isEdited = false;
      }
      editedFormattedIssues[currentIssue.issueUniqueId] = currentIssue;
    },
    [editedFormattedIssues, formattedIssues, getCurrentIssueWeek, getCurrentWeekDayIndex],
  );

  const counter = useRef(0);

  const handleCreateNewProject = useCallback(async () => {
    if (anotherProjects.length === 0) {
      await getAnotherProjectsData({ start: startOfWeek, end: endOfWeek });
    }
    const issueTemplate = {
      issueId: `_${counter.current}`,
      issueUniqueId: `_${counter.current}`,
      issueName: `_${counter.current}`,
      projectName: `_${counter.current}`,
      weekDays: formatWeek({ startOfWeek }),
      overTimeWeekDays: formatWeek({ startOfWeek }),
      totalNormalHours: 0,
      totalOvertimeHours: 0,
    };
    formattedIssues[issueTemplate.issueUniqueId] = issueTemplate;
    counter.current = counter.current + 1;
    forceUpdate();
  }, [anotherProjects.length, startOfWeek, formattedIssues, getAnotherProjectsData, endOfWeek]);

  const handleSelectNewProject = useCallback(
    (projectId, issue) => {
      const currentProject = anotherProjects.find(project => Number(project.projectId) === Number(projectId));
      formattedIssues[issue.issueUniqueId].projectName = currentProject.projectName;
      formattedIssues[issue.issueUniqueId].projectId = currentProject.projectId;
      formattedIssues[issue.issueUniqueId].weekDays = formatWeek({
        startOfWeek,
        project: currentProject,
      });
    },
    [anotherProjects, formattedIssues, startOfWeek],
  );

  const handleSelectNewProjectTask = useCallback(
    (issueId, issue) => {
      if (formattedIssues[issueId]?.issueUniqueId === formattedAnotherProjects[issueId]?.issueUniqueId) {
        formattedIssues[`${issueId}_${counter.current}`] = {
          ...formattedAnotherProjects[issueId],
          issueUniqueId: `${issueId}_${counter.current}`,
          weekDays: formatWeek({ startOfWeek }),
          overTimeWeekDays: formatWeek({ startOfWeek }),
        };
        editedFormattedIssues[`${issueId}_${counter.current}`] = formattedAnotherProjects[issueId];
        counter.current = counter.current + 1;
      } else {
        formattedIssues[issueId] = {
          ...formattedAnotherProjects[issueId],
          weekDays: formattedIssues[issue.issueUniqueId].weekDays,
          overTimeWeekDays: formattedIssues[issue.issueUniqueId].overTimeWeekDays,
        };
        editedFormattedIssues[issueId] = formattedAnotherProjects[issueId];
      }
      delete formattedIssues[issue.issueUniqueId];
      forceUpdate();
    },
    [editedFormattedIssues, formattedAnotherProjects, formattedIssues, startOfWeek],
  );

  return (
    <>
      {isSmallScreen ? (
        <>
          <Grid container alignContent="center" spacing={2}>
            {Object.keys(formattedIssues).map(issueKey => {
              const projectIssue = formattedIssues[issueKey];
              return (
                projectIssue && (
                  <Grid key={projectIssue.issueUniqueId} item xs={6} sm={3} maxWidth="50%" flexBasis="50%">
                    <SpreadsheetMobileIssueButton
                      projectIssue={projectIssue}
                      timeTypes={timeTypes}
                      timeType={timeType}
                      setQuery={setQuery}
                      query={query}
                    />
                  </Grid>
                )
              );
            })}
          </Grid>
        </>
      ) : (
        <Table className={classes.SpreadsheetTableRoot}>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell align="left" className={classes.tableCell}>
                <Typography variant="h4" fontWeight={700}>
                  <FormattedMessage id="spreadsheet_table_project" defaultMessage="Project" />
                </Typography>
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                <Typography variant="h4" fontWeight={700}>
                  <FormattedMessage id="spreadsheet_table_issues" defaultMessage="Issues" />
                </Typography>
              </TableCell>
              {weekDataHeader.map(day => (
                <TableCell align="center" className={classes.tableCellMedium} key={day.date}>
                  <Typography variant="h5" fontWeight={700}>
                    {dayjs(day.date).format('DD.MM')}
                  </Typography>
                  <Typography variant="h5">{day.dayOfWeek}</Typography>
                </TableCell>
              ))}
              <TableCell align="center" className={classes.tableCellSmall}>
                <Typography variant="h4" fontWeight={700}>
                  <FormattedMessage id="spreadsheet_table_total" defaultMessage="Total" />
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(formattedIssues).map(issueKey => {
              const projectIssue = formattedIssues[issueKey];
              if (showOnlyFilledIn) {
                if (
                  timeType === timeTypes.MAIN_HOURS
                    ? projectIssue.totalNormalHours === 0
                    : projectIssue.totalOvertimeHours === 0
                ) {
                  return;
                }
              }
              if (timeType === timeTypes.MAIN_HOURS ? projectIssue.hiddenMainHour : projectIssue.hiddenOvertimeHour) {
                return;
              }
              return (
                projectIssue && (
                  <SpreadsheetWeek
                    key={projectIssue.issueUniqueId}
                    projectIssue={projectIssue}
                    formattedAnotherProjects={formattedAnotherProjects}
                    anotherProjects={anotherProjects}
                    handleHideWeek={handleHideWeek}
                    handleChangeDayHour={handleChangeDayHour}
                    handleChangeDayComment={handleChangeDayComment}
                    handleSelectNewProject={handleSelectNewProject}
                    handleSelectNewProjectTask={handleSelectNewProjectTask}
                    timeTypes={timeTypes}
                    timeType={timeType}
                    intl={intl}
                  />
                )
              );
            })}
            <TableRow className={clsx(classes.tableRow, classes.bottomRow)}>
              <TableCell align="left" className={classes.tableCell}>
                <IconButton disableRipple onClick={handleCreateNewProject} className={classes.addRowButton}>
                  <PlusIcon width={24} height={24} viewBox="0 0 24 24" />
                </IconButton>
              </TableCell>
              <TableCell align="right" className={classes.tableCell}>
                <Grid container direction="column" paddingRight={4}>
                  <Typography variant="h4" fontWeight={700}>
                    <FormattedMessage id="spreadsheet_table_total" defaultMessage="Total" />
                  </Typography>
                  {timeType === timeTypes.MAIN_HOURS && (
                    <Typography variant="h4" fontWeight={700}>
                      <FormattedMessage id="spreadsheet_overtime" />
                    </Typography>
                  )}
                </Grid>
              </TableCell>
              {tableFooterDaysSum.map(daySum => {
                const isColored = Number(daySum.totalNormalHours) !== daySum.expectedHours;
                return (
                  <TableCell align="right" className={classes.tableCellMedium} key={daySum.date}>
                    <Grid container direction="column" paddingRight={3}>
                      {(timeType === timeTypes.MAIN_HOURS ? daySum.totalNormalHours : daySum.totalOvertimeHours) ===
                      0 ? (
                        <Typography variant="h4" color="#7174AC">
                          0
                        </Typography>
                      ) : (
                        <>
                          <Typography variant="h4" color={isColored && '#3448FF'}>
                            {parseFloat(
                              Math.round(
                                timeType === timeTypes.MAIN_HOURS ? daySum.totalNormalHours : daySum.totalOvertimeHours,
                              ).toFixed(1),
                            )}
                          </Typography>
                          {timeType === timeTypes.MAIN_HOURS && (
                            <Typography variant="h4" color={isColored && '#3448FF'}>
                              {parseFloat(
                                Math.abs(
                                  (timeType === timeTypes.MAIN_HOURS
                                    ? daySum.totalNormalHours
                                    : daySum.totalOvertimeHours) - daySum.expectedHours,
                                ).toFixed(1),
                              )}
                            </Typography>
                          )}
                        </>
                      )}
                    </Grid>
                  </TableCell>
                );
              })}
              <TableCell align="center" className={classes.tableCellSmall}>
                <Grid container direction="column">
                  <Typography variant="h4" color={workDayHours !== tableSum && '#3448FF'}>
                    {parseFloat(Number(tableSum).toFixed(1))}
                  </Typography>
                  {timeType === timeTypes.MAIN_HOURS && (
                    <Typography variant="h4" color={workDayHours !== 0 && '#3448FF'}>
                      {workDayHours}
                    </Typography>
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default memo(SpreadsheetTable);
