import React from 'react';
import { SvgIcon } from '@mui/material';

const ProjectEditIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        fill="#C7CAE6"
        d="M15.41 1.8L14.2.59c-.78-.78-2.05-.78-2.83 0L8.69 3.27 0 11.96V16h4.04l8.74-8.74 2.63-2.63c.79-.78.79-2.05 0-2.83zM3.21 14H2v-1.21l8.66-8.66 1.21 1.21L3.21 14zM8 16l4-4h6v4H8z"
      ></path>
    </SvgIcon>
  );
};

export default ProjectEditIcon;
