import React, { memo, useCallback, useEffect, useState } from 'react';
import useStyles from './SpentTimeQueryModal.styles';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import ClearIcon from '../../../../packages/common/shared-ui/src/icons/ClearIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import { createNewSavedQuery, getQueryRoles } from '../../../../packages/common/api';
import UncheckedRadioIcon from '../../../../packages/common/shared-ui/src/icons/UncheckedRadioIcon';
import CheckedRadioIcon from '../../../../packages/common/shared-ui/src/icons/CheckedRadioIcon';
import SpentTimeQueryAutocomplete from '../SpentTimeQueryAutocomplete';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { store } from '../../app/redux';

const SpentTimeQueryModal = ({ locationState, query, isModalOpen, closeModal, title, isNewQuery }) => {
  const intl = useIntl();
  const classes = useStyles();
  const navigate = useNavigate();
  const currentUser = useSelector(state => state.user.info);
  const [queryModalError, setQueryModalError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [queryModalSettings, setQueryModalSettings] = useState({
    name: locationState?.name && !isNewQuery ? locationState.name : '',
    category: locationState?.categoryId && !isNewQuery ? locationState.categoryId : null,
    accessLevel: locationState?.accessLevel && !isNewQuery ? locationState.accessLevel : 0,
    projectsLists: [],
    categoriesLists: [],
    projectLevel: locationState?.projectLevel && !isNewQuery ? locationState.projectLevel : 2,
    // selectedHideProjects: [],
    // selectedShowProjects: [],
    availableRoles: [],
    selectedRoles: [],
  });
  const state = store.getState();
  useEffect(() => {
    let value = 2;
    isNewQuery && state.user.project !== undefined && (value = 0);
    locationState?.projectLevel && !isNewQuery && (value = locationState?.projectLevel);
    setQueryModalSettings(prevState => ({
      ...prevState,
      projectLevel: value,
    }));
  }, [isNewQuery, locationState?.projectLevel, state.user.project]);

  // const handleGetCategoryLists = useCallback(async () => {
  //   try {
  //     setIsLoading(true);
  //     const { data: categories } = await getQueryCategory();
  //     setQueryModalSettings(prevState => ({
  //       ...prevState,
  //       categoriesLists: categories,
  //     }));
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error('ERROR GET PROJECTS LIST FOR CREATE QUERY', error);
  //   }
  // }, []);

  // const handleGetProjectLists = useCallback(async () => {
  //   try {
  //     if (!queryModalSettings.isForAllProjects) {
  //       setIsSettingsLoading(true);
  //       const { data: projects } = await getQueryProjects();
  //       setQueryModalSettings(prevState => ({
  //         ...prevState,
  //         projectsLists: projects,
  //       }));
  //     }
  //     setIsSettingsLoading(false);
  //   } catch (error) {
  //     console.error('ERROR GET PROJECTS LIST FOR CREATE QUERY', error);
  //   } finally {
  //     setIsSettingsLoading(false);
  //   }
  // }, [queryModalSettings.isForAllProjects]);

  const handleGetQueryRoles = useCallback(async () => {
    try {
      const { data } = await getQueryRoles();
      const newSelectedRoles =
        data.filter(queryRole =>
          locationState?.roles.find(role => Number(queryRole.valueId) === Number(role.roleId)),
        ) || [];
      setQueryModalSettings(prevState => {
        return {
          ...prevState,
          availableRoles: data,
          selectedRoles: newSelectedRoles,
        };
      });
      setIsLoading(false);
    } catch (error) {
      console.error('ERROR GET ROLES', error);
    }
  }, [locationState]);

  useEffect(() => {
    if (locationState && !isNewQuery && locationState?.roles.length > 0) {
      handleGetQueryRoles();
    }
  }, [handleGetQueryRoles, isNewQuery, locationState]);

  const handleChangeQueryName = useCallback(e => {
    setQueryModalSettings(prevState => ({
      ...prevState,
      name: e.target.value,
    }));
  }, []);

  const handleChangeQueryProjectsStatus = useCallback(e => {
    setQueryModalSettings(prevState => ({
      ...prevState,
      projectLevel: e.target.checked ? 2 : 0,
      // selectedHideProjects: prevState.projectLevel !== 0 ? [] : prevState.selectedHideProjects,
      // selectedShowProjects: prevState.projectLevel !== 0 ? [] : prevState.selectedShowProjects,
    }));
  }, []);

  // const handleChangeQueryCategory = useCallback((e, value) => {
  //   setQueryModalSettings(prevState => ({
  //     ...prevState,
  //     category: value,
  //   }));
  // }, []);

  // const handleChangeHideProjects = useCallback((e, value) => {
  //   setQueryModalSettings(prevState => {
  //     prevState.projectLevel = value.length > 0 ? 1 : 2;
  //     prevState.selectedHideProjects = value;
  //     return prevState;
  //   });
  // }, []);

  // const handleChangeShowProjects = useCallback((e, value) => {
  //   setQueryModalSettings(prevState => {
  //     prevState.projectLevel = value.length > 0 ? 1 : 2;
  //     prevState.selectedShowProjects = value;
  //     return prevState;
  //   });
  // }, []);

  const handleChangeQueryAccess = useCallback(
    async e => {
      if (Number(e.target.value) === 1 && queryModalSettings.availableRoles.length === 0) {
        await handleGetQueryRoles();
      }
      setQueryModalSettings(prevState => ({
        ...prevState,
        accessLevel: Number(e.target.value),
      }));
    },
    [handleGetQueryRoles, queryModalSettings.availableRoles],
  );

  const handleChangeQueryRoles = useCallback((e, value) => {
    setQueryModalSettings(prevState => ({
      ...prevState,
      selectedRoles: value,
    }));
  }, []);

  const handleSaveNewQuery = useCallback(async () => {
    try {
      if (queryModalSettings.name.length === 0) {
        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
        setQueryModalError('Название запроса не может быть пустым');
        return setTimeout(() => setQueryModalError(null), 2500);
      }
      // if (!queryModalSettings.category) {
      //   // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
      //   setQueryModalError('Выберите категорию запроса');
      //   return setTimeout(() => setQueryModalError(null), 2500);
      // }
      const formattedFields =
        query.fields?.split('$$').reduce((acc, rec, index) => {
          const fieldValues = rec.split('.');
          return [
            ...acc,
            {
              instance: fieldValues[0],
              field: fieldValues[1],
              position: index,
            },
          ];
        }, []) ?? [];
      const formattedTotals =
        query.totals?.split('$$').reduce((acc, rec) => {
          const totalValues = rec.split('.');
          if (totalValues.includes('null')) {
            return acc;
          }
          return [
            ...acc,
            {
              instance: totalValues[0],
              field: totalValues[1],
            },
          ];
        }, []) ?? [];
      const formattedFilters =
        query.filters?.split('$$').reduce((acc, rec) => {
          let chosenItemsFromLocalStorage = JSON.parse(window.localStorage.getItem('storageChosenItems'));
          if (!rec) {
            return acc;
          }
          const filterValues = rec.split(';');
          const predefinedType = chosenItemsFromLocalStorage[filterValues[0]].notParsedType;
          return [
            ...acc,
            {
              instance: filterValues[0].split('.')[0],
              field: filterValues[0].split('.')[1],
              operation: predefinedType[0] === predefinedType[0].toUpperCase() ? 'eq' : predefinedType,
              values: chosenItemsFromLocalStorage[filterValues[0]].type === 'btw' ? null : filterValues[2],
              [predefinedType[0] === predefinedType[0].toUpperCase() && 'predefined']: {
                type: predefinedType,
              },
              [chosenItemsFromLocalStorage[filterValues[0]].type === 'btw' &&
              predefinedType[0] === predefinedType[0].toLowerCase() &&
              'min']: chosenItemsFromLocalStorage[filterValues[0]].subLabels[0]?.valueId,
              [chosenItemsFromLocalStorage[filterValues[0]].type === 'btw' &&
              predefinedType[0] === predefinedType[0].toLowerCase() &&
              'max']: chosenItemsFromLocalStorage[filterValues[0]].subLabels[1]?.valueId,
            },
          ];
        }, []) ?? [];
      const formattedSorts = query.sorts
        ? query.sorts?.split('$$').reduce((acc, rec) => {
            const sortsValues = rec.split(';');
            return [
              ...acc,
              {
                instance: sortsValues[0].split('.')[0],
                field: sortsValues[0].split('.')[1],
                direction: sortsValues[1],
              },
            ];
          }, [])
        : [];
      const formattedRoles = queryModalSettings.selectedRoles.map(role => Number(role.valueId));
      const { data: createdQuery } = await createNewSavedQuery(
        {
          type: 'timesheet',
          name: queryModalSettings.name,
          categoryId: queryModalSettings.category,
          query: {
            filters: formattedFilters,
            sorts: formattedSorts,
            fields: formattedFields,
            group: query.groups
              ? {
                  instance: query.groups.split('.')[0],
                  field: query.groups.split('.')[1],
                }
              : null,
            totals: formattedTotals,
          },
          roles: formattedRoles,
          accessLevel: queryModalSettings.accessLevel,
          projectLevel: queryModalSettings.projectLevel,
          projects: [],
        },
        query.savedQueryId && title !== intl.formatMessage({ id: 'spent_time_save' }) ? query.savedQueryId : null,
      );
      closeModal();
      setQueryModalSettings({
        name: '',
        category: null,
        accessLevel: 0,
        projectsLists: [],
        categoriesLists: [],
        projectLevel: 2,
        // selectedHideProjects: [],
        // selectedShowProjects: [],

        availableRoles: [],
        selectedRoles: [],
      });
      const link = state.user.project
        ? `/spentTime/query/${createdQuery.id}?project=${state.user.project.redmineId}`
        : `/spentTime/query/${createdQuery.id}`;
      navigate(link);
    } catch (error) {
      console.error('ERROR CREATE NEW SAVED QUERY', error);
    }
  }, [
    queryModalSettings.name,
    queryModalSettings.selectedRoles,
    queryModalSettings.category,
    queryModalSettings.accessLevel,
    queryModalSettings.projectLevel,
    query.fields,
    query.totals,
    query.filters,
    query.sorts,
    query.groups,
    query.savedQueryId,
    title,
    intl,
    closeModal,
    state.user.project,
    navigate,
  ]);

  return (
    <Modal
      open={isModalOpen}
      className={classes.modalRoot}
      onBackdropClick={closeModal}
      keepMounted
      disableRestoreFocus
    >
      <Grid container direction="column" wrap="nowrap" className={classes.modalPaper}>
        {title ? (
          <>
            <Grid
              container
              wrap="nowrap"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={queryModalError ? 0 : 5}
            >
              <Typography variant="h4" fontWeight={600} paddingLeft={4}>
                {title}
              </Typography>
              <IconButton disableRipple onClick={closeModal}>
                <Box width={24} height={24}>
                  <ClearIcon viewBox="0 0 24 24" />
                </Box>
              </IconButton>
            </Grid>
            {typeof queryModalError === 'string' && (
              <Typography paddingLeft={8} variant="h5" fontWeight={600} color="#E03737">
                {queryModalError}
              </Typography>
            )}
            <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" marginBottom={5}>
              <Grid item xs={5}>
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                <Typography variant="h5" paddingLeft={8}>
                  Название запроса
                </Typography>
              </Grid>
              <Grid item xs={7} paddingRight={4}>
                <OutlinedInput
                  fullWidth
                  onChange={handleChangeQueryName}
                  placeholder={`${intl.formatMessage({ id: 'enter' })}`}
                  value={queryModalSettings.name}
                />
              </Grid>
            </Grid>
            {currentUser.isAdmin && (
              <Grid container wrap="nowrap" justifyContent="space-between" marginBottom={5}>
                <Grid item xs={5} alignItems="flex-start">
                  {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                  <Typography variant="h5" paddingLeft={8} paddingTop={1.5}>
                    Видимое
                  </Typography>
                </Grid>
                <Grid item xs={7} paddingRight={4}>
                  <Grid container paddingLeft={4}>
                    <RadioGroup value={queryModalSettings.accessLevel}>
                      <FormControlLabel
                        value={0}
                        onChange={handleChangeQueryAccess}
                        control={
                          <Radio
                            disableRipple
                            icon={<UncheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                            checkedIcon={<CheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                          />
                        }
                        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
                        label="только мне"
                      />
                      <FormControlLabel
                        value={2}
                        onChange={handleChangeQueryAccess}
                        control={
                          <Radio
                            disableRipple
                            icon={<UncheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                            checkedIcon={<CheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                          />
                        }
                        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
                        label="всем пользователям"
                      />
                      <FormControlLabel
                        value={1}
                        onChange={handleChangeQueryAccess}
                        control={
                          <Radio
                            disableRipple
                            icon={<UncheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                            checkedIcon={<CheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                          />
                        }
                        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
                        label="только этим ролям"
                      />
                    </RadioGroup>
                  </Grid>
                  {Number(queryModalSettings.accessLevel) === 1 && (
                    <Grid container width="100%" marginTop={2} className={classes.forProjectsAutocomplete}>
                      <SpentTimeQueryAutocomplete
                        options={queryModalSettings.availableRoles}
                        value={queryModalSettings.selectedRoles}
                        onChange={handleChangeQueryRoles}
                        renderTags={() => null}
                        intl={intl}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" marginBottom={5}>
              <Grid item xs={5} />
              <Grid item xs={7}>
                <Grid container justifyContent="center" direction="column" height={36} padding="8px 16px">
                  <FormControlLabel
                    className={classes.booleanLabel}
                    /*                    value={
                          queryModalSettings.selectedHideProjects.length > 0 ||
                          queryModalSettings.selectedShowProjects.length > 0
                            ? 1
                            : 2
                        }*/

                    checked={queryModalSettings.projectLevel === 2}
                    //checked={true}
                    onChange={handleChangeQueryProjectsStatus}
                    control={
                      <Checkbox
                        disabled={state.user.project === undefined}
                        disableRipple
                        icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
                        checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                      />
                    }
                    label={
                      // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
                      <Typography variant="h5">Для всех проектов</Typography>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            {/*<Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" marginBottom={5}>*/}
            {/*  <Grid item xs={5}>*/}
            {/*    /!* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string *!/*/}
            {/*    <Typography variant="h5" paddingLeft={8}>*/}
            {/*      Опции*/}
            {/*    </Typography>*/}
            {/*  </Grid>*/}
            {/*  <Grid item xs={7} padding="8px 16px">*/}
            {/*    <FormControlLabel*/}
            {/*      className={classes.booleanLabel}*/}
            {/*      // value="true"*/}
            {/*      // checked={*/}
            {/*      //   filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]*/}
            {/*      //     ?.valueName === 'true'*/}
            {/*      // }*/}
            {/*      control={*/}
            {/*        <Checkbox*/}
            {/*          disableRipple*/}
            {/*          icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}*/}
            {/*          checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}*/}
            {/*        />*/}
            {/*      }*/}
            {/*      label={*/}
            {/*        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string*/}
            {/*        <Typography variant="h5">Столбцы по умолчанию</Typography>*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
            <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
              <Box marginRight={2}>
                <Button onClick={handleSaveNewQuery} variant="defaultBlue">
                  <FormattedMessage id="save_text" />
                </Button>
              </Box>
            </Grid>
          </>
        ) : (
          <Grid container alignItems="center" justifyContent="center" width="100%" height="100%" paddingY={6}>
            <CircularProgress color="secondary" />
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};

export default memo(SpentTimeQueryModal);
