/* eslint-disable no-cyrillic-string/no-cyrillic-string */
import React from 'react';
import { Grid, Typography, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import UncheckedRadioIcon from '../../../../packages/common/shared-ui/src/icons/UncheckedRadioIcon';
import CheckedRadioIcon from '../../../../packages/common/shared-ui/src/icons/CheckedRadioIcon';
import { FormattedMessage } from 'react-intl';

const ResourcePlanSaveOptions = ({ errorData, saveOption, setSaveOption, handleButtonClick }) => {
  return (
    <Grid container direction="column" marginBottom={2}>
      <Typography variant="h3" fontWeight={600} color="#E03737">
        Кто-то обновил задачу, пока вы ее редактировали.
      </Typography>
      <Grid container direction="column" marginLeft={2}>
        {errorData &&
          errorData?.map((item, index) => {
            const formattedText = [];

            if (
              item.updatedValues.assignedUser &&
              ('new' in item.updatedValues.assignedUser || 'old' in item.updatedValues.assignedUser)
            ) {
              const oldValue =
                item.updatedValues.assignedUser.old !== null ? item.updatedValues.assignedUser.old : '"отсутствует"';
              const newValue =
                item.updatedValues.assignedUser.new !== null ? item.updatedValues.assignedUser.new : '"отсутствует"';
              formattedText.push(`Назначено изменилось с ${oldValue} на ${newValue}`);
            }

            if (
              item.updatedValues.estimatedHours &&
              ('new' in item.updatedValues.estimatedHours || 'old' in item.updatedValues.estimatedHours)
            ) {
              const oldValue =
                item.updatedValues.estimatedHours.old !== null
                  ? item.updatedValues.estimatedHours.old
                  : '"отсутствует"';
              const newValue =
                item.updatedValues.estimatedHours.new !== null
                  ? item.updatedValues.estimatedHours.new
                  : '"отсутствует"';
              formattedText.push(`Оценка временных затрат изменилась с ${oldValue} ч на ${newValue} ч`);
            }

            if (
              item.updatedValues.startDate &&
              ('new' in item.updatedValues.startDate || 'old' in item.updatedValues.startDate)
            ) {
              const oldDateValue = item.updatedValues.startDate.old
                ? new Date(item.updatedValues.startDate.old).toLocaleDateString()
                : '"отсутствует"';
              const newDateValue = item.updatedValues.startDate.new
                ? new Date(item.updatedValues.startDate.new).toLocaleDateString()
                : '"отсутствует"';
              formattedText.push(`Дата начала изменилась с ${oldDateValue} на ${newDateValue}`);
            }

            if (
              item.updatedValues.endDate &&
              ('new' in item.updatedValues.endDate || 'old' in item.updatedValues.endDate)
            ) {
              const oldEndDateValue = item.updatedValues.endDate.old
                ? new Date(item.updatedValues.endDate.old).toLocaleDateString()
                : '"отсутствует"';
              const newEndDateValue = item.updatedValues.endDate.new
                ? new Date(item.updatedValues.endDate.new).toLocaleDateString()
                : '"отсутствует"';
              formattedText.push(`Дата завершения изменилась с ${oldEndDateValue} на ${newEndDateValue}`);
            }

            return (
              <Typography key={index} variant="h4" fontWeight={600}>
                {`По задаче ${item?.issueName}: ${formattedText.join(', ')}`}
              </Typography>
            );
          })}
      </Grid>
      <Grid container marginLeft={4} wrap="nowrap" direction="column" spacing={0}>
        <RadioGroup value={saveOption} onChange={e => setSaveOption(e.target.value)} defaultValue={0}>
          <FormControlLabel
            value="save_all"
            control={
              <Radio
                disableRipple
                icon={<UncheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                checkedIcon={<CheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
              />
            }
            label="Применить мои изменения"
          />
          <FormControlLabel
            value="save_all_expect_changes"
            control={
              <Radio
                disableRipple
                icon={<UncheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                checkedIcon={<CheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
              />
            }
            label="Применить мои изменения, кроме обновленных задач"
          />
          <FormControlLabel
            value="cancel_changes"
            control={
              <Radio
                disableRipple
                icon={<UncheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                checkedIcon={<CheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
              />
            }
            label="Отменить мои изменения"
          />
        </RadioGroup>
        <Grid>
          <Button variant="defaultBluePrimary" disableRipple onClick={handleButtonClick}>
            <Typography variant="h4" fontWeight={600}>
              <FormattedMessage id="resPlan_accept_saving" />
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResourcePlanSaveOptions;
