import React from 'react';
import { SvgIcon } from '@mui/material';

function DownloadFileIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_13370_74453)">
          <path
            d="M12.6673 6H10.0007V2H6.00065V6H3.33398L8.00065 10.6667L12.6673 6ZM3.33398 12V13.3333H12.6673V12H3.33398Z"
            fill="#7174AC"
          />
        </g>
        <defs>
          <clipPath id="clip0_13370_74453">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default DownloadFileIcon;
