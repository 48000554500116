import React, { useCallback, useEffect, useRef, useState } from 'react';
export const useDebouncedEffect = (effect, delay, deps) => {
  const [result, setResult] = useState();
  const callback = useCallback(effect, [effect, deps]);
  useEffect(() => {
    const handler = setTimeout(async () => {
      const res = await callback();
      setResult(res);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay, result]);
  return result;
};

export const useDebouncedFunction = function (func, delay, cleanUp = false) {
  const timeoutRef = useRef();

  // Очистка таймера
  function clearTimer() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }

  // Очищаем таймер при анмаунте компонента, если cleanUp выставлен в true
  // и тем самым отменяем последний запланированный вызов
  useEffect(() => (cleanUp ? clearTimer : undefined), [cleanUp]);

  return (...args) => {
    clearTimer();
    timeoutRef.current = setTimeout(() => func(...args), delay);
  };
};
