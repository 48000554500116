import React from 'react';
import { SvgIcon } from '@mui/material';

function DownloadButton(props) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_4611_978)">
        <path d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z" fill="#7174AC" />
      </g>
      <defs>
        <clipPath id="clip0_4611_978">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default DownloadButton;
