import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { deleteIssueFile, getIssueFiles, saveIssueFile, uploadFile } from '../../../../../packages/common/api';
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import PlusIcon from '../../../../../packages/common/shared-ui/src/icons/PlusIcon';
import issuePageStyles from '../../IssuePage.styles';
import DownloadFileIcon from '../../../../../packages/common/shared-ui/src/icons/DownloadFileIcon';
import DeleteFileIcon from '../../../../../packages/common/shared-ui/src/icons/DeleteFileIcon';
import { useSelector } from 'react-redux';
import { generateRedirectUrl } from '../../../../../packages/common/utils/getRedirectUrl';
import AttachIcon from '../../../../../packages/common/shared-ui/src/icons/AttachIcon';
const IssueFiles = ({ intl, permissions, obtainFiles, viewOnly = false }) => {
  const params = useParams();
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const issueCommonStyles = issuePageStyles();
  const [loading, setLoading] = useState(true);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    let ignore = false;
    async function getFiles() {
      const { data: files } = await getIssueFiles(params.id);
      if (!ignore) {
        return files;
      }
    }
    getFiles()
      .then(res => {
        setFiles(res);
        !viewOnly && obtainFiles(res);
        setLoading(false);
      })
      .catch(error => console.error(error, 'ERROR IN GET'));
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleFileUpload = useCallback(
    async event => {
      setLoadingUpload(true);
      const { data: fileData } = await uploadFile(event.target.files[0], event.target.files[0].name);
      if (fileData.upload.token) {
        const data = {
          token: fileData.upload.token,
          fileName: event.target.files[0].name,
          contentType: event.target.files[0].type,
        };
        try {
          const { data: response } = await saveIssueFile(params.id, data);
          if (response) {
            setFiles(response);
            setLoadingUpload(false);
          }
        } catch (error) {
          console.error('ERROR WITH SAVE', error);
        }
      }
    },
    [params.id],
  );

  const handleDeleteIssueFile = useCallback(
    async (id, index) => {
      try {
        const response = await deleteIssueFile(params.id, id);
        if (response) {
          const newArray = files.filter((file, i) => i !== index);
          setFiles(newArray);
        }
      } catch (error) {
        console.error('ERROR WITH DELETE', error);
      }
    },
    [files, params.id],
  );

  const handleDownloadFile = useCallback(
    (id, name) => {
      let hostUrl = '';
      hostUrl = generateRedirectUrl({ host: window.location.origin, path: id, redmineToken });
      const link = document.createElement('a');
      link.href = hostUrl;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    [redmineToken],
  );

  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          {!viewOnly && (
            <Grid container alignItems={'center'}>
              <Grid item>
                <Typography variant="h3" color={'#082253'} fontWeight={600}>
                  {intl.formatMessage({ id: 'issue_page_files_title' })}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton component="label" sx={{ padding: '6px 4px' }} className={issueCommonStyles.addButton}>
                  <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
                  <input hidden multiple type="file" onChange={event => handleFileUpload(event)} />
                </IconButton>
              </Grid>
              {loadingUpload && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  width="30px"
                  height="30px"
                  marginLeft={'10px'}
                >
                  <CircularProgress size={20} color="secondary" />
                </Grid>
              )}
            </Grid>
          )}
          {files.length > 0 && (
            <Grid container direction={'column'} marginLeft={viewOnly ? '200px' : 0}>
              {files.map((file, index) => (
                <Grid key={index} item sx={{ lineHeight: '20px' }} marginTop={'8px'}>
                  <Grid container>
                    <Grid item>
                      {!viewOnly ? (
                        <Box
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleDownloadFile(file.attachmentUrl, file.filename)}
                          component={'span'}
                          className={issueCommonStyles.issueLink}
                        >
                          <Typography variant="h4" color={'#7174AC'}>
                            {file.filename}
                          </Typography>
                        </Box>
                      ) : (
                        <Box component={'span'} display={'flex'} alignItems={'center'}>
                          <AttachIcon />
                          <Typography variant="h4" color={'#7174AC'} marginLeft={'4px'}>
                            {file.filename}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                    <Grid item sx={{ display: 'flex', marginLeft: '13px' }}>
                      <IconButton
                        className={issueCommonStyles.hoveredIcon}
                        sx={{ padding: '0', marginLeft: '4px' }}
                        onClick={() => handleDeleteIssueFile(file.attachmentId, index)}
                      >
                        <DeleteFileIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default injectIntl(IssueFiles);
