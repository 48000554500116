import React, { memo } from 'react';
import { Autocomplete, Box, Checkbox, Grid, TextField, Typography } from '@mui/material';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import { FormattedMessage } from 'react-intl';
import useStyles from './IssuesQueryAutocomplete.styles';

const IssuesQueryAutocomplete = ({ options, onChange, renderTags, value, intl }) => {
  const classes = useStyles();
  return (
    <Autocomplete
      open
      multiple
      fullWidth
      disableClearable
      disablePortal
      options={options}
      getOptionLabel={option => option.valueName}
      value={value}
      onChange={onChange}
      renderOption={(params, option, { selected }) => {
        return (
          <Box {...params} key={params['data-option-index']}>
            <Checkbox
              disableRipple
              checked={selected}
              onChange={() => null}
              icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
              checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
            />
            <Typography variant="h5" whiteSpace="nowrap" textOverflow="ellipsis">
              {option.valueName}
            </Typography>
          </Box>
        );
      }}
      renderInput={params => (
        <TextField
          variant="outlined"
          InputProps={{ style: { padding: 0 } }}
          {...params}
          className={classes.forProjectsInput}
          placeholder={intl.formatMessage({ id: 'enter' })}
        />
      )}
      PopperComponent={params => {
        return <Box className={classes.forProjectsPopper}>{params.children}</Box>;
      }}
      renderTags={renderTags}
      PaperComponent={params => {
        return (
          <Grid container direction="column" {...params} className={classes.forProjectsPaper}>
            {params.children}
          </Grid>
        );
      }}
      noOptionsText={
        <Typography variant="h5">
          <FormattedMessage id="no_results" />
        </Typography>
      }
    />
  );
};

export default memo(IssuesQueryAutocomplete);
