import { makeStyles } from '@mui/styles';

const issueRelatedStyles = makeStyles(theme => ({
  relatedRow: {
    '& .MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
      '& h5': {
        flexShrink: 0,
        display: 'inline-flex',
      },
      '& .MuiFormControl-root.MuiTextField-root.formTextField': {
        width: '156px',
        '& .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input.MuiOutlinedInput-input': {
          padding: '11px 16px',
        },
      },
    },
  },
  paperDropdown: {
    '&.MuiPaper-root.MuiPopover-paper.MuiMenu-paper': {
      maxHeight: '300px',
      borderRadius: '0 0 16px 16px',
      border: '1px solid #E4E4EF',
      boxShadow: '0px 5px 10px -8px #A0A1AA66',
      '&::-webkit-scrollbar': {
        width: '2px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#7174AC',
        borderRadius: '5px',
      },
      '& .MuiList-root.MuiMenu-list': {
        padding: '0',
        '& li': {
          fontSize: '14px',
          lineHeight: '20px',
          color: '#212346',
          '&.MuiButtonBase-root.MuiMenuItem-root': {
            '&.Mui-selected': {
              backgroundColor: '#DCDFF4',
            },
            '&:focus': {
              backgroundColor: 'transparent',
            },
            '&:hover': {
              backgroundColor: '#DCDFF4',
            },
          },
        },
      },
    },
  },
  rowSelect: {
    '&.MuiInputBase-root.MuiOutlinedInput-root': {
      fontSize: '14px',
      lineHeight: '20px',
      height: '100%',
      padding: '10px',
      '&::before': {
        borderBottom: 'none',
      },
      '&:hover': {
        '&::before': {
          borderBottom: 'none',
        },
      },
      '& .MuiSelect-select': {
        padding: 0,
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      '& .MuiSvgIcon-root.MuiSelect-icon': {
        width: '20px',
        height: '20px',
        position: 'static',
      },
      '& .MuiSelect-nativeInput': {
        padding: '0',
      },
    },
  },
  selectLabel: {
    '&.MuiFormLabel-root.MuiInputLabel-root': {
      color: '#7174AC',
      fontSize: '12px',
      lineHeight: '20px',
      marginTop: '-5px',
      transform: 'none',
      left: '10px',
      top: '15px',
      '&.Mui-focused': {
        transform: 'none',
        left: '10px',
        top: '15px',
      },
      '&.MuiFormLabel-filled': {
        display: 'none',
      },
    },
  },
  filterSelect: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      padding: '10px 65px 10px 10px',
      lineHeight: '20px',
      height: 'auto',
      '& .MuiAutocomplete-endAdornment': {
        '& .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator': {
          '& .MuiSvgIcon-root': {
            width: '20px',
            height: '20px',
          },
        },
        '& .MuiSvgIcon-root': {
          width: '24px',
          height: '24px',
          position: 'static',
        },
      },
      '& .MuiAutocomplete-input': {
        fontSize: '12px',
        lineHeight: '20px',
        color: '#212346',
        padding: '0',
        height: 'auto',
      },
      '&.Mui-disabled': {
        '& .MuiAutocomplete-input': {
          '&::placeholder': {
            color: '#E4E4EF',
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#E4E4EF',
        },
        '& .MuiAutocomplete-endAdornment': {
          '& .MuiSvgIcon-root path': {
            fill: '#E4E4EF',
          },
        },
      },
    },
    '&.MuiAutocomplete-hasClearIcon': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        borderColor: '#3448FF',
        '&:hover': {
          borderColor: '#3448FF',
        },
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      color: '#7174AC',
      fontSize: '14px',
      lineHeight: '20px',
      top: '-5px',
      zIndex: 99,
      '&.MuiFormLabel-filled': {
        display: 'none',
      },
    },
    '&.Mui-expanded': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        borderRadius: '16px 16px 0 0',
        '& .MuiOutlinedInput-notchedOutline': {
          padding: 0,
        },
      },
      '& .MuiFormLabel-root.MuiInputLabel-root': {
        display: 'none',
      },
    },
    '&.Mui-focused': {
      '& .MuiFormLabel-root.MuiInputLabel-root': {
        display: 'none',
      },
    },
    '& + .MuiAutocomplete-popper': {
      minWidth: 'auto!important',
      //width: '100%!important',
      maxWidth: '300px!important',
      '& .MuiPaper-root.MuiAutocomplete-paper': {
        borderRadius: '0 0 16px 16px',
        border: '1px solid #E4E4EF',
        boxShadow: '0px 5px 10px -8px #A0A1AA66',
        '& .MuiAutocomplete-noOptions': {
          padding: '8px 16px',
          color: '#212346',
          fontSize: '12px',
          lineHeight: '20px',
        },
        '& .MuiAutocomplete-loading': {
          padding: '8px 16px',
          color: '#212346',
          fontSize: '12px',
          lineHeight: '20px',
        },
        '& .MuiAutocomplete-listbox': {
          padding: '0',
          '& li': {
            whiteSpace: 'normal',
            fontSize: '12px',
            lineHeight: '20px',
            color: '#212346',
            '&.Mui-focused': {
              backgroundColor: 'transparent',
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: '#DCDFF4',
            },
          },
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#7174AC',
            borderRadius: '5px',
          },
        },
      },
    },
  },
}));

export default issueRelatedStyles;
