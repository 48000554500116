import React from 'react';
import PropTypes from 'prop-types';

import { theme } from 'Common/shared-ui/src/theme';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material';

const ThemeSetup = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StyledEngineProvider injectFirst />
      {children}
    </ThemeProvider>
  );
};

ThemeSetup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeSetup;
