import React, { memo, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Checkbox, ClickAwayListener, Grid, IconButton, Popover, Typography, useMediaQuery } from '@mui/material';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import {
  deleteIssue,
  deleteIssueBulk,
  patchIssues,
  getIssuesActionMenu,
  deleteSavedQuery,
  confirmationInitial,
} from '../../../../packages/common/api';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import SortIcon from '../../../../packages/common/shared-ui/src/icons/SortIcon';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import DownloadButton from '../../../../packages/common/shared-ui/src/icons/DownloadButton';
import { getTranslatedText } from '../../../../packages/common/utils/getTranslatedText';
import SpentTimeToolbar from '../IssuesToolbar';
import TableSecondStepPopover from '../TableSecondStepPopover';
import TreeArrowRight from 'Common/shared-ui/src/icons/TreeArrowRight';
import useStyles from './IssuesTable.styles';
import clsx from 'clsx';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import { Link } from 'react-router-dom';
import { store } from '../../app/redux';
import WarningModal from '../../../../packages/common/shared-ui/src/components/WarningModal';
import ParentIssueDropdown from '../../../../packages/common/shared-ui/src/icons/ParentIssueDropdown';
import dataGridTableStyles from '../../../../packages/common/shared-ui/src/styles/DataGridTable.styles';

const IssuesTable = ({
  query,
  setQuery,
  intl,
  originalTimesheetsData,
  formattedTimesheetsData,
  isLoading,
  setIsLoading,
  tableErrorMessage,
  contextMenuData,
  setContextMenuData,
  currentType,
  updateTable,
  issusesColumnsValue,
}) => {
  const issuesData = formattedTimesheetsData();
  const classes = useStyles();
  const dataGridTableStyle = dataGridTableStyles();
  const navigate = useNavigate();
  const state = store.getState();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('lg'));
  const [tableSelectedValues, setTableSelectedValues] = useState([]);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [sortModel, setSortModel] = useState(
    query.sorts ? [{ field: query.sorts.split(';')[0], sort: query.sorts.split(';')[1] }] : [],
  );
  const [, forceUpdate] = useReducer(x => x - 1, 0);
  const [showDeleteIssueWarning, setShowDeleteIssueWarning] = useState(false);

  const handleGetRedirectUrl = useCallback(
    ({ id, columnType }) => {
      const issueLink = state.user.project ? `/issues/${id}?project=${state.user.project.redmineId}` : `/issues/${id}`;
      switch (columnType) {
        case 'user':
          return generateRedirectUrl({ host: window.location.origin, path: `/users/${id}` });
        case 'project':
          return `${window.location.origin}/projects/${id}`;
        case 'issue':
        case 'issue.subject':
        case 'id':
          return issueLink;
        default:
          return '';
      }
    },
    [state.user.project],
  );

  const cellRenderer = useCallback(
    ({ props, column }) => {
      const linkFields = ['project', 'user', 'issue', 'issueId'];
      if (!props.value && props.row.groupName) {
        if (props.colDef.position === 0) {
          return (
            <Typography variant="h4" fontWeight={700}>
              {props.row.groupName}
            </Typography>
          );
        }
      } else {
        if (linkFields.includes(column.fieldType) || column.fieldId === 'issue.subject') {
          if (column.fieldType === 'issueId') {
            props.value = props.id + '$$' + props.id + '$$issue';
          }
          const splittedColumnName = props?.value?.split('$$');

          return (
            <Box
              sx={{
                wordBreak: 'break-word',
                display: 'flex',
                alignItems: 'center',
                padding:
                  column.fieldId === 'issue.subject' && props.row.level !== 0
                    ? `0 0 0 ${
                        props.row.hasSubtasks && props.row.level > 0
                          ? `${props.row.level * 16}px!important`
                          : !props.row.hasSubtasks && props.row.level > 0
                          ? `${props.row.level * 16 + 16}px!important`
                          : 0
                      }`
                    : 0,
              }}
            >
              {column.fieldId === 'issue.subject' && props.row.hasSubtasks && (
                <ParentIssueDropdown></ParentIssueDropdown>
              )}
              <Link to={handleGetRedirectUrl({ id: splittedColumnName[1], columnType: splittedColumnName[2] })}>
                {splittedColumnName[0]}
              </Link>
            </Box>
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleGetRedirectUrl],
  );
  const formattedIssuesColumns = useCallback(() => {
    const smallFields = ['date', 'hours', 'float', 'bool', 'issueId'];
    return issusesColumnsValue.map(column => {
      let columnWidth = 'auto';
      let columnFlex = 1;
      smallFields.includes(column.fieldType) && (columnWidth = 100);
      column.fieldType === 'text' && (columnWidth = 200);
      column.fieldType === 'project' && (columnWidth = 300);
      column.fieldType === 'user' && (columnWidth = 250);
      (smallFields.includes(column.fieldType) ||
        column.fieldType === 'project' ||
        column.fieldType === 'text' ||
        column.fieldType === 'user') &&
        (columnFlex = 0);
      return {
        field: column.fieldId,
        headerName: getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName),
        renderHeader: props => (
          <Typography variant="h4" fontWeight={700}>
            {props.colDef.headerName}
          </Typography>
        ),
        disableExport: false,
        position: column.position,
        //sortable: query.groups !== column.fieldId,
        flex: columnFlex,
        width: columnWidth,
        renderCell: props => cellRenderer({ props, column }),
        cellClassName: props => {
          let cellClasses = [];
          if (!props.row.groupName) {
            cellClasses.push('tableCell');
          }
          if (column.fieldId === 'issue.subject') {
            switch (props.row.level) {
              case 1:
                cellClasses.push('tableCelllevelOne');
                break;
              case 2:
                cellClasses.push('tableCelllevelTwo');
                break;
              case 3:
                cellClasses.push('tableCelllevelThree');
                break;
              default:
                break;
            }
          }
          return cellClasses;
        },
      };
    });
  }, [cellRenderer, intl, issusesColumnsValue]);
  const issuesColumns = formattedIssuesColumns();
  useEffect(() => {
    if (sortModel.length === 0 && query.sorts?.length > 0) {
      setQuery({ sorts: undefined });
      return setIsLoading(true);
    }
    if (sortModel.length > 0 && query.sorts !== Object.values(sortModel[0]).join(';')) {
      setQuery({ sorts: Object.values(sortModel[0]).join(';') });
      setIsLoading(true);
    }
  }, [isLoading, query.sorts, setIsLoading, setQuery, sortModel]);

  const contextPopperStylePosition = useRef({
    left: 0,
    top: 0,
    maxWidth: 180,
  });
  const handleCopyIssue = useCallback(() => {
    const trackerId = contextMenuData.currentRow.columns.find(item => item.fieldId === 'issue.tracker').valueId;
    let url = `/issues/${contextMenuData.currentRow.rowId}/copy?trackerId=${trackerId}&projectId=${contextMenuData.currentRow.services.projectId}&list`;
    if (contextMenuData.currentRows?.length > 1) {
      const issuesIds = contextMenuData.currentRows.join('&ids%5B%5D=');
      url = url + 'bulk_edit?copy=1&ids%5B%5D=' + issuesIds;
    }
    if (state.user.project) {
      url = `/issues/${contextMenuData.currentRow.rowId}/copy?projectId=${state.user.project.redmineId}&project=${state.user.project.redmineId}&trackerId=${trackerId}&list`;
    }
    //url = generateRedirectUrl({ host: window.location.origin, path: url });
    window.open(url, '_blank');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextMenuData]);
  const handleEditIssue = useCallback(() => {
    let url = '';
    url = `/issues/${contextMenuData.currentRow.rowId}/edit?list`;
    if (state.user.project) {
      url = `/issues/${contextMenuData.currentRow.rowId}/edit?project=${state.user.project.redmineId}&list`;
    }
    if (contextMenuData.currentRows?.length > 1) {
      const issuesIds = contextMenuData.currentRows.join(',');
      url = `/issues/bulk_edit?issues=${issuesIds}&list`;
      if (state.user.project) {
        url = `/issues/bulk_edit?issues=${issuesIds}&project=${state.user.project.redmineId}&list`;
      }
    }
    //url = generateRedirectUrl({ host: window.location.origin, path: url });
    window.open(url, '_blank');
  }, [contextMenuData.currentRow.rowId, contextMenuData.currentRows, state.user.project]);

  const handleDeleteIssue = useCallback(
    async (force = false) => {
      try {
        let response;
        let params = {};
        force && (params.force = true);
        if (contextMenuData.currentRows?.length > 1) {
          response = await deleteIssueBulk(
            contextMenuData.currentRows.map(item => Number(item)),
            params,
          );
        } else {
          response = await deleteIssue(contextMenuData.currentRow.rowId, params);
        }
        if (response) {
          updateTable({
            totals: typeof query.totals != 'undefined' ? query.totals : 'null',
            fields: typeof query.fields != 'undefined' ? query.fields : 'null',
            [typeof query.filters != 'undefined' ? 'filters' : null]: query.filters,
            [query.groups !== 0 ? 'groups' : null]: query.groups,
            [query.sorts !== 0 ? 'sorts' : null]: query.sorts,
          });
        }
      } catch (error) {
        error.response.status === 420 && setShowDeleteIssueWarning(true);
        console.error('ERROR WITH DELETE', error);
      }
    },
    [
      contextMenuData.currentRow.rowId,
      contextMenuData.currentRows,
      query.fields,
      query.filters,
      query.groups,
      query.sorts,
      query.totals,
      updateTable,
    ],
  );

  const handleInitConfirmation = useCallback(async () => {
    try {
      let rows = [];
      contextMenuData.currentRow && rows.push(Number(contextMenuData.currentRow.rowId));
      contextMenuData.currentRows.length > 0 && (rows = contextMenuData.currentRows.map(item => Number(item)));
      const { data: response } = await confirmationInitial(rows);
      if (response) {
        navigate(`/confirmations/${Number(response)}`);
      }
    } catch (error) {
      console.error('ERROR WITH POST CONFIRMATION INITIAL', error);
    }
  }, [contextMenuData.currentRow, contextMenuData.currentRows, navigate]);

  const handleReinitConfirmation = useCallback(async () => {
    let issuesIds = [];
    contextMenuData.currentRow && issuesIds.push(Number(contextMenuData.currentRow.rowId));
    contextMenuData.currentRows.length > 0 && (issuesIds = contextMenuData.currentRows.join(','));
    navigate(`/confirmation/reinit?issues=${issuesIds}`);
  }, [contextMenuData, navigate]);

  const handleChooseSubItemType = useCallback(
    (type, value, itemPosition) => {
      const itemsCount = tableSelectedValues.length === 1 ? 5 : 4;
      const valueLength = value.length;
      setContextMenuData(prevState => {
        return {
          ...prevState,
          isSecondStepVisible: !prevState.isSecondStepVisible,
          secondPopoverStepValue: value,
          secondStepType: type,
          positionInfo: {
            itemPosition: itemPosition,
            positionDelta: itemsCount - itemPosition - valueLength,
            totalCount: valueLength,
          },
        };
      });
    },
    [setContextMenuData, tableSelectedValues.length],
  );

  const handleChooseSubItem = useCallback(
    async (value, type) => {
      setIsContextMenuOpen(false);
      let updateIssuesQuery = {
        ids: tableSelectedValues.map(item => Number(item)),
        issue: {},
      };
      if (type === 'assigned') {
        setContextMenuData(prevState => {
          const newAssignedTo = prevState.data.assignedTo.map(item => {
            item.isChecked = false;
            if (item.userId === value) {
              item.isChecked = true;
            }
            return item;
          });
          prevState.data.assignedTo = newAssignedTo;
          return { ...prevState, isSecondStepVisible: false };
        });
        updateIssuesQuery.issue.assignedTo = value;
      } else if (type === 'status') {
        setContextMenuData(prevState => {
          const newStatuses = prevState.data.statuses.map(item => {
            item.isChecked = false;
            if (item.statusId === value) {
              item.isChecked = true;
            }
            return item;
          });
          prevState.data.statuses = newStatuses;
          return { ...prevState, isSecondStepVisible: false };
        });
        updateIssuesQuery.issue.statusId = value;
      }
      const { responce } = await patchIssues(updateIssuesQuery);
      updateTable({
        totals: typeof query.totals != 'undefined' ? query.totals : 'null',
        fields: typeof query.fields != 'undefined' ? query.fields : 'null',
        [typeof query.filters != 'undefined' ? 'filters' : null]: query.filters,
        [query.groups !== 0 ? 'groups' : null]: query.groups,
        [query.sorts !== 0 ? 'sorts' : null]: query.sorts,
      });
    },
    [setContextMenuData, tableSelectedValues, updateTable, query],
  );

  const handleUnselect = useCallback(() => {
    setIsContextMenuOpen(false);
    setContextMenuData(prevState => ({
      ...prevState,
      isSecondStepVisible: false,
      currentRow: {},
    }));
    setTableSelectedValues([]);
  }, [setContextMenuData]);

  const { current: dataGridComponents } = useRef({
    ColumnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
    ColumnSortedAscendingIcon: () => <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />,
    ColumnSortedDescendingIcon: () => <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />,
    ExportIcon: () => {
      return (
        <IconButton disableRipple disabled>
          <DownloadButton width="24" height="24" viewBox="0 0 24 24" />
        </IconButton>
      );
    },
    Toolbar: SpentTimeToolbar,
    BaseCheckbox: props => {
      return (
        <Checkbox
          {...props}
          disableRipple
          icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
          checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
          indeterminateIcon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
        />
      );
    },
  });

  const isDisabledMenuItems = {
    isProjectClosed: true,
    isVisibleApprove:
      !contextMenuData.currentRow.services?.canApprove || contextMenuData.currentRow.services?.isProjectClosed,
    isVisibleCanEditOrOvertimeItem:
      contextMenuData.currentRow.services?.isApproved ||
      !contextMenuData.currentRow.services?.canEdit ||
      contextMenuData.currentRow.services?.isProjectClosed,
  };

  const isSelectedRowsIncludesClosedProjects = originalTimesheetsData.filter((row, index, array) => {
    const selectedValue = tableSelectedValues.find(value => {
      return row.rowId === value;
    });
    if (selectedValue && row.services.isProjectClosed) {
      return array[index];
    }
  });

  const isSelectedRowsIncludesApproved = originalTimesheetsData.filter((row, index, array) => {
    const selectedValue = tableSelectedValues.find(value => {
      return row.rowId === value;
    });
    if (selectedValue && row.services.isApproved) {
      return array[index];
    }
  });
  const selectRow = useCallback(
    ({ rowId, clear = false }) => {
      setTableSelectedValues(prevState => {
        if (clear) {
          return [rowId];
        }
        if (prevState.includes(rowId)) {
          return prevState.filter(id => id !== rowId);
        }
        return [...prevState, rowId];
      });
      forceUpdate();
    },
    [setTableSelectedValues],
  );

  const handleRowContextMenu = useCallback(
    async e => {
      e.preventDefault();
      if (!e.currentTarget) {
        return;
      }
      const groupRow = e.currentTarget.className.includes('groupRow');
      const rowId = e.currentTarget.getAttribute('data-id');
      const selectedRows = tableSelectedValues.length > 0 ? tableSelectedValues.join('||') : rowId;
      const contexIssueMenuData = await getIssuesActionMenu(selectedRows);

      if (!isContextMenuOpen && !groupRow) {
        const currentRow = originalTimesheetsData.find(row => row.rowId === rowId);
        setContextMenuData(prevState => {
          prevState.currentRow = currentRow;
          prevState.currentRows = tableSelectedValues;
          prevState.isSecondStepVisible = false;
          prevState.secondStepType = '';
          prevState.data = contexIssueMenuData.data;
          return prevState;
        });
        contextPopperStylePosition.current.left = e.clientX;
        contextPopperStylePosition.current.top = e.clientY;
        if (!tableSelectedValues.includes(rowId)) {
          selectRow({ rowId: rowId });
        }
        setIsContextMenuOpen(true);
        forceUpdate();
      }
    },
    [
      isContextMenuOpen,
      originalTimesheetsData,
      setContextMenuData,
      contextPopperStylePosition,
      tableSelectedValues,
      setIsContextMenuOpen,
      selectRow,
    ],
  );

  const handleModalAction = useCallback(
    async e => {
      switch (e.currentTarget.attributes.value.value) {
        case 'save':
          await handleDeleteIssue(true);
          break;
        case 'delete':
          setShowDeleteIssueWarning(false);
          break;
      }
    },
    [handleDeleteIssue],
  );

  return (
    <>
      {isSmallScreen ? (
        <></>
      ) : (
        <>
          <WarningModal
            issue
            simple
            isWarningModalOpen={showDeleteIssueWarning}
            handleToggle={handleModalAction}
            closeWarningModal={() => setShowDeleteIssueWarning(false)}
            title={
              /* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */
              `Вы уверены, что хотите удалить выбранные задачи? Так же будут удалены подзадачи и таймшиты. Удалить?`
            }
          />
          {isContextMenuOpen && (
            <ClickAwayListener onClickAway={handleUnselect}>
              <Popover
                keepMounted
                open={isContextMenuOpen}
                style={contextPopperStylePosition.current}
                anchorPosition={contextPopperStylePosition.current}
                className={classes.contextPopover}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                anchorReference="none"
              >
                <TableSecondStepPopover
                  value={contextMenuData.secondPopoverStepValue}
                  type={contextMenuData.secondStepType}
                  data={contextMenuData}
                  handleChooseSubItem={handleChooseSubItem}
                  isSecondStepVisible={contextMenuData.isSecondStepVisible}
                  contextPopperStylePosition={contextPopperStylePosition}
                  positionInfo={contextMenuData.positionInfo}
                />
                <Grid container direction="column">
                  <Grid
                    item
                    xs={12}
                    padding="6px 20px"
                    className={clsx(classes.contextMenuItem, {
                      [classes.contextMenuItemDisabled]: !contextMenuData.data.canEdit,
                    })}
                    onClick={handleEditIssue}
                  >
                    {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                    <Typography variant="h5">Редактировать</Typography>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    padding="6px 0 6px 20px"
                    className={clsx(classes.contextMenuItem, {
                      [classes.contextMenuItemDisabled]: !contextMenuData.data.canEdit,
                    })}
                    wrap="nowrap"
                    alignItems="center"
                    onClick={() => handleChooseSubItemType('status', contextMenuData.data.statuses, 1)}
                  >
                    {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                    <Typography variant="h5">Статус</Typography>
                    <Box width={24} height={24}>
                      <ChevronIcon direction="right" color="rgba(228, 228, 239, 1)" viewBox="0 0 24 24" />
                    </Box>
                  </Grid>
                  {contextMenuData.data.canInitConfirmation && (
                    <Grid
                      item
                      xs={12}
                      padding="6px 20px"
                      className={classes.contextMenuItem}
                      onClick={handleInitConfirmation}
                    >
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography variant="h5">Согласовать документ</Typography>
                    </Grid>
                  )}
                  {contextMenuData.data.canReinitConfirmation && (
                    <Grid
                      item
                      xs={12}
                      padding="6px 20px"
                      className={classes.contextMenuItem}
                      onClick={handleReinitConfirmation}
                    >
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography variant="h5">Согласовать изменения</Typography>
                    </Grid>
                  )}
                  <Grid
                    container
                    justifyContent="space-between"
                    padding="6px 0 6px 20px"
                    className={clsx(classes.contextMenuItem, {
                      [classes.contextMenuItemDisabled]: !contextMenuData.data.canEdit,
                    })}
                    wrap="nowrap"
                    alignItems="center"
                    onClick={() => handleChooseSubItemType('assigned', contextMenuData.data.assignedTo, 2)}
                  >
                    {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                    <Typography variant="h5">Назначена</Typography>
                    <Box width={24} height={24}>
                      <ChevronIcon direction="right" color="rgba(228, 228, 239, 1)" viewBox="0 0 24 24" />
                    </Box>
                  </Grid>
                  {tableSelectedValues.length === 1 && (
                    <Grid
                      item
                      xs={12}
                      padding="6px 20px"
                      className={clsx(classes.contextMenuItem, {
                        [classes.contextMenuItemDisabled]: !contextMenuData.data.canCopy,
                      })}
                      onClick={() => handleCopyIssue()}
                    >
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography variant="h5">Копировать</Typography>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    padding="6px 20px"
                    className={clsx(classes.contextMenuItem, {
                      [classes.contextMenuItemDisabled]: !contextMenuData.data.canDelete,
                    })}
                    onClick={() => handleDeleteIssue()}
                  >
                    {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                    <Typography variant="h5">Удалить</Typography>
                  </Grid>
                </Grid>
              </Popover>
            </ClickAwayListener>
          )}
          <Grid container flexDirection={'column'} marginTop={5} flex={1} height={'100%'} overflow={'hidden'}>
            <Grid container justifyContent={tableErrorMessage ? 'space-between' : 'flex-end'}>
              {typeof tableErrorMessage === 'string' && (
                <Typography variant="h5" fontWeight={600} color="#E03737">
                  {tableErrorMessage}
                </Typography>
              )}
              <Box className={classes.totalsWrap}>
                {originalTimesheetsData[0].columns.map(column => {
                  return (
                    <Box key={column.fieldId} display="flex" flexWrap="nowrap" alignItems="center" marginLeft={2}>
                      <Typography variant="h5" marginRight={2}>
                        {getTranslatedText(intl, 'filter', column.fieldId, column.fieldName)}:
                      </Typography>
                      <Typography variant="h5" fontWeight={700}>
                        {column.value ? column.value : 0}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Grid>
            <DataGridPro
              //autoHeight
              hideFooter
              {...{ ['checkboxSelection']: currentType !== 1 }}
              disableColumnFilter
              disableRowSelectionOnClick
              disableColumnMenu
              columns={issuesColumns}
              rows={issuesData}
              className={dataGridTableStyle.DataGridTableRoot}
              rowSelectionModel={tableSelectedValues}
              onRowSelectionModelChange={newRowSelectionModel => {
                setTableSelectedValues(newRowSelectionModel);
              }}
              getRowId={item => item.id}
              sortModel={sortModel ? sortModel : []}
              onSortModelChange={model => setSortModel(model?.length > 0 ? model : sortModel)}
              sortingMode="server"
              sortingOrder={['asc', 'desc', null]}
              slotProps={{
                row: {
                  onContextMenu: e => handleRowContextMenu(e),
                  style: { cursor: 'context-menu' },
                },
                baseCheckbox: {
                  icon: <CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />,
                  checkedIcon: <CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />,
                },
              }}
              slots={{
                columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
                columnSortedAscendingIcon: () => <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />,
                columnSortedDescendingIcon: () => (
                  <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />
                ),
              }}
              getRowClassName={params => params.row.groupName && 'groupRow'}
              isRowSelectable={params => !params.row.groupName}
              getRowHeight={() => 'auto'}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default memo(IssuesTable);
