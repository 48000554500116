import useStyles from './ResourcePlanTableRow.styles';
import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ResourcePlanTableCell from '../ResourcePlanTableCell/ResourcePlanTableCell';
import dayjs from 'dayjs';
import {
  Grid,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Select,
  MenuItem,
  Box,
  TextField,
  Tooltip,
} from '@mui/material';
import useResPlanWebsockets from '../useResplanWebSockets';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import PlusIcon from 'Common/shared-ui/src/icons/PlusIcon';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import clsx from 'clsx';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import CalendarTimesheetIcon from '../../../../packages/common/shared-ui/src/icons/CalendarTimesheetIcon';
import { store } from '../../app/redux';
import { useDispatch } from 'react-redux';

const ResourcePlanTableRow = ({
  intl,
  fetchedQuery,
  groupType,
  row,
  hasIssues,
  rowIndex,
  canEdit,
  resplanColumns,
  resplanFields,
}) => {
  const classes = useStyles();
  const addNewRowAbove = rowIndex => {
    const newRows = [...resplanFields.rows];
    const newRow = {
      rowId: `${Date.now()}`,
      rowName: 'new row',
      rowType: 'new',
      columns: resplanFields?.rows[0].columns,
    };
    newRows.splice(rowIndex, 0, newRow);
    setResplanFields(prev => ({ ...prev, rows: newRows }));
  };
  const indexOfFirstMonthlyDistrib = useMemo(() => {
    return resplanColumns.findIndex(item => item.fieldType === 'monthly_distribution');
  }, [resplanColumns]);
  const indexOfLastMonthlyDistrib = useMemo(() => {
    return resplanColumns
      .map((item, index) => (item.fieldType === 'monthly_distribution' ? index : -1))
      .reduce((acc, curr) => (curr > acc ? curr : acc), -1);
  }, [resplanColumns]);
  const startGroupAdditionalCells = useMemo(() => {
    let cells = [];
    for (let i = 0; i < indexOfFirstMonthlyDistrib - 2; i++) {
      cells.push(<TableCell></TableCell>);
    }
    return cells;
  }, [indexOfFirstMonthlyDistrib]);

  const endGroupAdditionalCells = useMemo(() => {
    let cells = [];
    for (let i = 0; i < resplanColumns.length - 1 - indexOfLastMonthlyDistrib; i++) {
      cells.push(<TableCell></TableCell>);
    }

    return cells;
  }, [resplanColumns, indexOfLastMonthlyDistrib]);

  const startAdditionalCells = useMemo(() => {
    let cells = [];
    for (let i = 0; i < indexOfFirstMonthlyDistrib - 2; i++) {
      cells.push(<TableCell rowSpan={2}></TableCell>);
    }
    return cells;
  }, [indexOfFirstMonthlyDistrib]);

  const endAdditionalCells = useMemo(() => {
    let cells = [];
    for (let i = 0; i < resplanColumns.length - 1 - indexOfLastMonthlyDistrib; i++) {
      cells.push(<TableCell rowSpan={2}></TableCell>);
    }
    return cells;
  }, [resplanColumns, indexOfLastMonthlyDistrib]);

  const getMonthName = useCallback(monthStr => {
    if (monthStr && monthStr.includes('-')) {
      const date = dayjs(monthStr);
      const formattedMonth = date.format('MMM');
      return `${formattedMonth.charAt(0).toUpperCase()}${formattedMonth.slice(1)} ${date.format('YYYY')}`;
    }
    return monthStr;
  }, []);
  const formatDate = useCallback(dateStr => {
    if (dateStr) {
      return dayjs(dateStr).format('DD.MM.YYYY');
    }
    return dateStr;
  }, []);

  const handleMonthFieldChange = (rowGroupId, rowId, columnFieldId, targetValue) => {
    const mountValue = {
      fieldId: columnFieldId,
      value: targetValue,
    };
    updateResPlanByMount(rowGroupId, rowId, mountValue);
  };
  const handlePercentFieldChange = (rowGroupId, rowId, startDate, endDate, targetValue, columnFieldId, percentLoad) => {
    let percentValue;
    if (columnFieldId.includes('date_from')) {
      percentValue = {
        startDate: targetValue,
        endDate: endDate,
        percent: percentLoad,
      };
    }
    if (columnFieldId.includes('date_to')) {
      percentValue = {
        startDate: startDate,
        endDate: targetValue,
        percent: percentLoad,
      };
    }
    if (columnFieldId.includes('loading_percent')) {
      percentValue = {
        startDate: startDate,
        endDate: endDate,
        percent: targetValue,
      };
    }
    updateResPlanByPercent(rowGroupId, rowId, percentValue);
  };
  const handleGetRedirectUrl = useCallback(({ id, columnType }) => {
    switch (columnType) {
      case 'issue':
        return generateRedirectUrl({ host: window.location.origin, path: `/issues/${id}` });
      // return `${window.location.origin}/issues/${id}`;

      default:
        return '';
    }
  }, []);
  const handleMonthlyDistributionChange = (rowIndex, columnIndex, newValue) => {
    const regex = /^\d{0,3}(\.\d{0,2})?$/;

    if (regex.test(newValue) || newValue === '') {
      const updatedFields = [...resplanFields.rows];
      updatedFields[rowIndex].columns[columnIndex].value = newValue;
      setResplanFields(prev => ({ ...prev, rows: updatedFields }));
    }
  };
  const handlePercentChange = (rowIndex, columnIndex, newValue) => {
    const regex = /^\d{0,3}(\.\d{0,2})?$/;

    if (regex.test(newValue) || newValue === '') {
      const updatedFields = [...resplanFields.rows];
      updatedFields[rowIndex].columns[columnIndex].value = newValue;
      setResplanFields(prev => ({ ...prev, rows: updatedFields }));
    }
  };
  const handleChangeDate = (
    rowIndex,
    columnIndex,
    date,
    columnFieldId,
    rowGroupId,
    rowId,
    startDate,
    endDate,
    percentLoad,
  ) => {
    console.log(rowIndex);
    console.log(columnIndex);
    const newValue = date.format('YYYY-MM-DD');
    const dateValue = date.toISOString();
    const updatedFields = [...resplanFields.rows];
    updatedFields[rowIndex].columns[columnIndex].value = newValue;
    console.log(updatedFields);
    setResplanFields(prev => ({ ...prev, rows: updatedFields }));
    handlePercentFieldChange(rowGroupId, rowId, startDate, endDate, dateValue, columnFieldId, percentLoad);
  };
  const renderRow = () => {
    if (!groupType) {
      if (row.rowType === 'group') {
        const canAdd = resplanFields.rows[rowIndex - 1].services?.canEdit;
        return (
          <TableRow key={rowIndex}>
            <TableCell key={'addRow'}>
              {canAdd && hasIssues && (
                <IconButton disableRipple className={classes.addRowButton} onClick={() => addNewRowAbove(rowIndex - 1)}>
                  <PlusIcon width={24} height={24} viewBox="0 0 24 24" />
                </IconButton>
              )}
            </TableCell>
            {startGroupAdditionalCells}
            <TableCell key={'total'} align="right" style={{ borderRight: '1px solid #E4E4EF', paddingRight: '10px' }}>
              <Typography variant="h5" fontWeight={700}>
                <FormattedMessage id="resplan_table_total" defaultMessage="Total" />
              </Typography>
            </TableCell>
            {row.columns.map((column, columnIndex) => (
              <TableCell key={columnIndex} className={classes.dateCell} align="right">
                <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                  <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                    {column.value}
                  </Typography>
                </Tooltip>
              </TableCell>
            ))}
            {endGroupAdditionalCells}
          </TableRow>
        );
      }
    }
    if (groupType) {
      if (row.rowType === 'group') {
        return null;
      }
      if (row.rowType === 'perload') {
        const canAdd = resplanFields.rows[rowIndex - 2].services.canEdit;
        const groupRow = resplanFields.rows[rowIndex - 1];
        let negativeColumns = row.columns.map(column => column.value < 0);
        let idealColumns = row.columns.map(column => column.value == 0);
        return (
          <>
            <TableRow key={rowIndex}>
              <TableCell key={'addRow'} rowSpan={2}>
                <TableCell key={'addRow'} rowSpan={2}>
                  {canAdd && hasIssues && (
                    <IconButton
                      disableRipple
                      className={classes.addRowButton}
                      onClick={() => addNewRowAbove(rowIndex - 1)}
                    >
                      <PlusIcon width={24} height={24} viewBox="0 0 24 24" />
                    </IconButton>
                  )}
                </TableCell>
              </TableCell>
              {startAdditionalCells}
              <TableCell
                key={'Perload'}
                rowSpan={2}
                align="right"
                style={{ borderRight: '1px solid #E4E4EF', paddingRight: '10px' }}
              >
                <Typography variant="h5" fontWeight={700}>
                  <FormattedMessage id="resplan_table_total" defaultMessage="Total" />
                </Typography>
                <Typography variant="h5" fontWeight={700}>
                  <FormattedMessage id="resplan_table_perload" defaultMessage="Perload" />
                </Typography>
              </TableCell>
              {groupRow.columns.map((column, columnIndex) => (
                <TableCell
                  key={columnIndex}
                  className={clsx(
                    classes.perloadDateCell,
                    classes.noBottom,
                    {
                      [classes.redText]: negativeColumns[columnIndex],
                    },
                    {
                      [classes.blackText]: idealColumns[columnIndex],
                    },
                  )}
                  align="right"
                >
                  <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                    <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                      {column.value}
                    </Typography>
                  </Tooltip>
                </TableCell>
              ))}
              {endAdditionalCells}
            </TableRow>
            <TableRow key={rowIndex} style={{ border: '0 !important' }}>
              {row.columns.map((column, columnIndex) => (
                <TableCell
                  key={columnIndex}
                  className={clsx(
                    classes.perloadDateCell,
                    { [classes.redText]: column.value < 0 },
                    { [classes.blackText]: column.value == 0 },
                  )}
                  align="right"
                >
                  <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                    <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                      {column.value}
                    </Typography>
                  </Tooltip>
                </TableCell>
              ))}
              {/* {endAdditionalCells} */}
            </TableRow>
          </>
        );
      }
    }
    if (row.services.isNew) {
      let projectId;
      let userId;
      row.columns.map((column, index) => {
        if (resplanColumns[columnIndex].fieldType === 'project') {
          projectId = column.valueId;
        }
        if (resplanColumns[columnIndex].fieldType === 'user') {
          userId = column.valueId;
        }
      });
      return (
        <TableRow key={rowIndex}>
          {row.columns.map((column, columnIndex) => {
            if (column.fieldName === 'Contract') {
              return (
                <TableCell key={columnIndex} className={classes.perloadCell}>
                  <Select
                    // fullWidth
                    IconComponent={() => (
                      <Box className={classes.groupIcon}>
                        <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
                      </Box>
                    )}
                    MenuProps={{
                      className: classes.optionsGroupMenu,
                    }}
                    variant="standard"
                    disableUnderline
                    className={classes.createSelect}
                    value={column.valueId}
                    style={{ width: '220px !important' }}
                    onOpen={e => fetchContractIssueData(projectId)}
                    onChange={e => handleIssueChange(rowIndex, column.fieldId, e.target.value)}
                  >
                    {contracts.length === 0 ? (
                      <MenuItem value={column.valueId}>
                        <Typography
                          textOverflow="ellipsis"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          variant="h5"
                          color="#212346"
                        >
                          {column.value}
                        </Typography>
                      </MenuItem>
                    ) : (
                      contracts.map(contract => (
                        <MenuItem value={contract.valueId} key={contract.valueId}>
                          <Typography
                            textOverflow="ellipsis"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            variant="h5"
                            color="#212346"
                          >
                            {contract.valueName}
                          </Typography>
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </TableCell>
              );
            }
            if (column.fieldName === 'Project') {
              if (!groupType) {
                return (
                  <TableCell key={columnIndex} className={classes.tableCell} align="left">
                    <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                      <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                        {column.value}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                );
              }
              return (
                <TableCell key={columnIndex} className={classes.perloadCell}>
                  <Select
                    // fullWidth
                    IconComponent={() => (
                      <Box className={classes.groupIcon}>
                        <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
                      </Box>
                    )}
                    MenuProps={{
                      className: classes.optionsGroupMenu,
                    }}
                    variant="standard"
                    disableUnderline
                    className={classes.createSelect}
                    value={column.valueId}
                    style={{ width: '220px !important' }}
                    onOpen={e => fetchProjectIssueData(userId)}
                    onChange={e => handleIssueChange(rowIndex, column.fieldId, e.target.value)}
                  >
                    {projects.length === 0 ? (
                      <MenuItem value={column.valueId}>
                        <Typography
                          textOverflow="ellipsis"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          variant="h5"
                          color="#212346"
                        >
                          {column.value}
                        </Typography>
                      </MenuItem>
                    ) : (
                      projects.map(project => (
                        <MenuItem value={project.valueId} key={project.valueId}>
                          <Typography
                            textOverflow="ellipsis"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            variant="h5"
                            color="#212346"
                          >
                            {project.valueName}
                          </Typography>
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </TableCell>
              );
            }
            if (column.fieldName === 'User') {
              if (groupType) {
                return (
                  <TableCell key={columnIndex} className={classes.tableCell} align="left">
                    <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                      <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                        {column.value}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                );
              }
              return (
                <TableCell key={columnIndex} className={classes.perloadCell}>
                  <Select
                    // fullWidth
                    IconComponent={() => (
                      <Box className={classes.groupIcon}>
                        <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
                      </Box>
                    )}
                    MenuProps={{
                      className: classes.optionsGroupMenu,
                    }}
                    variant="standard"
                    disableUnderline
                    className={classes.createSelect}
                    value={column.valueId}
                    style={{ width: '220px !important' }}
                    onOpen={e => fetchUserIssueData(projectId)}
                    onChange={e => handleIssueChange(rowIndex, column.fieldId, e.target.value)}
                  >
                    {users.length === 0 ? (
                      <MenuItem value={column.valueId}>
                        <Typography
                          textOverflow="ellipsis"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          variant="h5"
                          color="#212346"
                        >
                          {column.value}
                        </Typography>
                      </MenuItem>
                    ) : (
                      users.map(user => (
                        <MenuItem value={user.valueId} key={user.valueId}>
                          <Typography
                            textOverflow="ellipsis"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            variant="h5"
                            color="#212346"
                          >
                            {user.valueName}
                          </Typography>
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </TableCell>
              );
            }
            if (column.fieldName === 'Tracker') {
              return (
                <TableCell key={columnIndex} className={classes.perloadCell}>
                  <Select
                    // fullWidth
                    IconComponent={() => (
                      <Box className={classes.groupIcon}>
                        <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
                      </Box>
                    )}
                    MenuProps={{
                      className: classes.optionsGroupMenu,
                    }}
                    variant="standard"
                    disableUnderline
                    className={classes.createSelect}
                    value={column.valueId}
                    style={{ width: '220px !important' }}
                    onOpen={e => fetchTrackersIssueData()}
                    onChange={e => handleIssueChange(rowIndex, column.fieldId, e.target.value)}
                  >
                    {trackers.length === 0 ? (
                      <MenuItem value={column.valueId}>
                        <Typography
                          textOverflow="ellipsis"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          variant="h5"
                          color="#212346"
                        >
                          {column.value}
                        </Typography>
                      </MenuItem>
                    ) : (
                      trackers.map(tracker => (
                        <MenuItem value={tracker.valueId} key={tracker.valueId}>
                          <Typography
                            textOverflow="ellipsis"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            variant="h5"
                            color="#212346"
                          >
                            {tracker.valueName}
                          </Typography>
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </TableCell>
              );
            }
            if (resplanColumns[columnIndex].fieldType === 'monthly_distribution') {
              const isExceeded = column.exceeded;
              return (
                <TableCell key={columnIndex} className={classes.dateCell} align="right">
                  <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                    <TextField
                      type="number"
                      value={column.value}
                      className={clsx(classes.dateCellField, classes.dateCellCommentField, {
                        [classes.redText]: isExceeded,
                      })}
                      onChange={e => handleMonthlyDistributionChange(rowIndex, columnIndex, e.target.value)}
                      onBlur={e => handleMonthFieldChange(row.groupId, row.rowId, column.fieldId, e.target.value)}
                      align="right"
                      disabled={!canEdit}
                    />
                  </Tooltip>
                </TableCell>
              );
            }
            if (resplanColumns[columnIndex].fieldType === 'loading_percent') {
              return (
                <TableCell key={columnIndex} className={classes.dateCell} align="right">
                  <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                    <TextField
                      type="number"
                      value={column.value}
                      className={clsx(classes.dateCellField, classes.dateCellCommentField)}
                      onChange={e => handlePercentChange(rowIndex, columnIndex, e.target.value)}
                      onBlur={e => {
                        const loadingPercentColumn = row.columns.find(
                          column => column.fieldId === 'resourcePlan.loading_percent',
                        );
                        const loadingPercentValue = loadingPercentColumn ? loadingPercentColumn.value : null;
                        handlePercentFieldChange(
                          row.groupId,
                          row.rowId,
                          row.services?.startDate,
                          row.services?.endDate,
                          e.target.value,
                          column.fieldId,
                          loadingPercentValue,
                        );
                      }}
                      disabled={!canEdit}
                      align="right"
                    />
                  </Tooltip>
                </TableCell>
              );
            }
            if (resplanColumns[columnIndex].fieldType === 'date_to') {
              return (
                <TableCell key={columnIndex} className={classes.tableCell} align="left">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Tooltip title={formatDate(column.value)} PopperProps={{ className: classes.commentTooltip }}>
                      <DatePicker
                        views={['day']}
                        // disableMaskedInput
                        className={classes.datePicker}
                        components={{
                          OpenPickerIcon: () => (
                            <CalendarTimesheetIcon
                              style={{ right: 10, width: 16, height: 16 }}
                              viewBox="0 0 16 16"
                              color={canEdit ? '#DCDFF4' : '#ffffff'} // Если canEdit истина, цвет серый, иначе дефолтный цвет
                            />
                          ),
                          LeftArrowIcon: () => (
                            <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />
                          ),
                          RightArrowIcon: () => (
                            <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />
                          ),
                          SwitchViewIcon: () => (
                            <ChevronIcon direction="down" viewBox="0 0 24 24" width={24} height={24} />
                          ),
                          SwitchViewButton: componentProps => (
                            <IconButton disableRipple {...componentProps}>
                              {componentProps.children}
                            </IconButton>
                          ),
                          RightArrowButton: componentProps => (
                            <IconButton disableRipple {...componentProps}>
                              {componentProps.children}
                            </IconButton>
                          ),
                          LeftArrowButton: componentProps => (
                            <IconButton disableRipple {...componentProps}>
                              {componentProps.children}
                            </IconButton>
                          ),
                        }}
                        renderInput={params => (
                          <Box display="flex" alignItems="center">
                            <TextField
                              {...params}
                              placeholder=""
                              inputProps={{
                                ...params.inputProps,
                                placeholder: ' ',
                              }}
                              helperText={null}
                              fullWidth
                            />
                          </Box>
                        )}
                        value={column.value || ''}
                        onChange={date => {
                          const loadingPercentColumn = row.columns.find(
                            column => column.fieldId === 'resourcePlan.loading_percent',
                          );
                          const loadingPercentValue = loadingPercentColumn ? loadingPercentColumn.value : null;
                          handleChangeDate(
                            rowIndex,
                            columnIndex,
                            date,
                            column.fieldId,
                            row.groupId,
                            row.rowId,
                            row.services?.startDate,
                            row.services?.endDate,
                            loadingPercentValue,
                          );
                        }}
                        disabled={!canEdit}
                      />
                    </Tooltip>
                  </LocalizationProvider>
                </TableCell>
              );
            }
            if (resplanColumns[columnIndex].fieldType === 'issue') {
              return (
                <TableCell key={columnIndex} className={classes.tableCell} align="left">
                  <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                    <TextField
                      value={column.value}
                      className={clsx(classes.tableCellField, classes.tableCellCommentField)}
                      onBlur={e => {
                        handleIssueChange(rowIndex, column.fieldId, e.target.value);
                      }}
                      align="left"
                    />
                  </Tooltip>
                </TableCell>
              );
            }
            return (
              <TableCell key={columnIndex} className={classes.tableCell} align="left">
                <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                  <>
                    <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                      {column.value}
                    </Typography>
                  </>
                </Tooltip>
              </TableCell>
            );
          })}
        </TableRow>
      );
    }
    return (
      <TableRow key={rowIndex}>
        {row.columns.map((column, columnIndex) => {
          return (
            <TableCell key={columnIndex} className={classes.tableCell} align="left">
              <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                  {column.value}
                </Typography>
              </Tooltip>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };
  return <>{renderRow()}</>;
};

export default memo(ResourcePlanTableRow);
