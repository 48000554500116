import React from 'react';
import { SvgIcon } from '@mui/material';

function ClearSelectChipIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_14138_24126)">
          <path
            d="M12.6663 4.27331L11.7263 3.33331L7.99967 7.05998L4.27301 3.33331L3.33301 4.27331L7.05967 7.99998L3.33301 11.7266L4.27301 12.6666L7.99967 8.93998L11.7263 12.6666L12.6663 11.7266L8.93967 7.99998L12.6663 4.27331Z"
            fill="#7174AC"
          />
        </g>
        <defs>
          <clipPath id="clip0_14138_24126">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default ClearSelectChipIcon;
