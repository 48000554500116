import React, { memo } from 'react';
import { Box, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import ChevronIcon from 'Common/shared-ui/src/icons/ChevronIcon';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';

const useStyles = makeStyles(theme => ({
  DateSwitcherRoot: {
    height: 40,
    backgroundColor: '#DCDFF4',
    borderRadius: theme.spacing(4),
  },
}));

const DateSwitcher = ({ dateFrom, dateTo, handleToggle }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('lg'));
  const isSmallestScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('sm'));
  return (
    <Grid container className={classes.DateSwitcherRoot} wrap="nowrap">
      <IconButton disableRipple onClick={handleToggle} direction="left">
        <ChevronIcon
          direction="left"
          width={isSmallScreen ? 20 : 24}
          height={isSmallScreen ? 20 : 24}
          viewBox="0 0 24 24"
        />
      </IconButton>
      <Box display="flex" alignItems="center" flexDirection={isSmallestScreen ? 'column' : 'row'}>
        <Typography variant={isSmallScreen ? (isSmallestScreen ? 'h6' : 'h5') : 'h4'} fontWeight={600}>
          {dayjs(dateFrom).format('DD MMMM YYYY')}
        </Typography>
        <Box>
          <Typography
            variant={isSmallScreen ? 'h6' : 'h4'}
            fontWeight={600}
            marginRight={2}
            marginLeft={2}
            display={isSmallestScreen ? 'none' : 'block'}
          >
            —
          </Typography>
        </Box>
        <Typography variant={isSmallScreen ? (isSmallestScreen ? 'h6' : 'h5') : 'h4'} fontWeight={600}>
          {dayjs(dateTo).format('DD MMMM YYYY')}
        </Typography>
      </Box>
      <IconButton disableRipple onClick={handleToggle} direction="right">
        <ChevronIcon
          direction="right"
          width={isSmallScreen ? 20 : 24}
          height={isSmallScreen ? 20 : 24}
          viewBox="0 0 24 24"
        />
      </IconButton>
    </Grid>
  );
};

export default memo(DateSwitcher);
