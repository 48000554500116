import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  marginTop: `${theme.spacing(1)} !important`,
  zIndex: 2000,
  maxWidth: 280,
  backgroundColor: '#fff',
  borderRadius: theme.spacing(2.5),
  width: '100%',
  // overflowX: 'hidden',
  // overflowY: 'auto',
  border: '1px solid #E4E4EF',
  '& .MuiAutocomplete-listbox': {
    padding: 0,
    '& .MuiAutocomplete-option': {
      maxHeight: 28,
    },
    '& .MuiAutocomplete-option[aria-selected="true"], & .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
      backgroundColor: '#DCDFF4',
    },
    '& .MuiAutocomplete-option[aria-selected="true"]:hover': {
      backgroundColor: 'rgba(220, 223, 244, .6)',
    },
  },
  treeArrowRight: {
    width: '5px',
    height: '10px',
    position: 'absolute',
    top: '0px',
    left: '-10px',
  },
  SpentTimeTableRoot: {
    maxHeight: '80vh !important',
    overflowY: 'auto',
    '&.MuiDataGrid-root': {
      backgroundColor: '#ffffff',
      borderRadius: theme.spacing(4),
      marginBottom: theme.spacing(4),
      border: 0,
      // overflow: 'hidden',
      '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor': {
        borderColor: 'transparent',
      },
      '& .MuiDataGrid-columnHeaders': {
        position: 'sticky !important',
        top: '0 !important',
        zIndex: 1000,
        backgroundColor: '#ffffff',
        '& .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
          '&:not(.MuiDataGrid-columnHeaderCheckbox)': {
            '& + .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
              borderLeft: '1px solid #E4E4EF',
            },
          },
          borderRight: 0,
          border: 'none',
        },
        '& .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor.MuiDataGrid-columnHeaderCheckbox': {
          minWidth: '36px!important',
          maxWidth: '36px!important',
          '& + .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
            paddingLeft: 0,
          },
        },
      },
      '& .MuiDataGrid-row': {
        width: '100%',
        borderTop: '1px solid #E4E4EF',
        '& .MuiDataGrid-cell': {
          '&.tableCell': {
            '&:not(.MuiDataGrid-columnHeaderCheckbox)': {
              '& + .MuiDataGrid-cell.tableCell': {
                borderLeft: '1px solid #E4E4EF',
              },
            },
          },
        },
        '& .MuiDataGrid-cell.MuiDataGrid-withBorderColor.MuiDataGrid-cellCheckbox': {
          minWidth: '36px!important',
          maxWidth: '36px!important',
          '& + .MuiDataGrid-cell': {
            paddingLeft: 0,
          },
        },
      },
      '& .MuiDataGrid-row.groupRow': {
        '& .MuiDataGrid-cellCheckbox': {
          boxShadow: 'none',
          '& .MuiCheckbox-root': {
            visibility: 'hidden',
          },
        },
      },
      '& .MuiDataGrid-row .MuiDataGrid-cell:last-child': {
        borderRight: 0,
      },
      '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: '#DCDFF4',
      },
      '& .MuiDataGrid-row.Mui-selected.Mui-hovered': {
        backgroundColor: '#DCDFF4',
      },
      '& .MuiDataGrid-virtualScrollerRenderZone': {
        position: 'relative',
      },
      '& .MuiDataGrid-virtualScrollerContent': {
        height: 'unset !important',
      },
      '& .MuiDataGrid-main': {
        overflow: 'unset !important',
      },
      '& .MuiDataGrid-main .MuiDataGrid-iconButtonContainer .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      },
      '& .MuiCheckbox-root': {
        '& .MuiSvgIcon-root': {
          width: '12px',
        },
      },
      '& .MuiDataGrid-columnSeparator': {
        '& .MuiSvgIcon-root': {
          width: '24px',
        },
      },
      '& .MuiDataGrid-cell': {
        '&.tableCell': {
          borderColor: 'transparent',
          position: 'relative',
          '& a': {
            position: 'relative',
          },
          '&:last-child': {
            boxShadow: 0,
          },
          '& .MuiCheckbox-root': {
            padding: 0,
          },
        },
        '& a': {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
      '& .MuiDataGrid-cellCheckbox': {
        borderColor: 'transparent',
        //boxShadow: '1px 1px #E4E4EF',
      },
    },
  },
  tableRow: {
    height: 56,
    '&.MuiTableRow-head th:last-child': {
      boxShadow: '0px 1px #e4e4ef',
    },
  },
  tableCellMedium: {
    '&.MuiTableCell-root': {
      boxShadow: '1px 1px #E4E4EF',
    },
  },
  tableCellSmall: {
    '&.MuiTableCell-root': {
      boxShadow: '1px 1px #E4E4EF',
      '&:not(.MuiTableCell-head)': {
        padding: theme.spacing(4.5, 4),
      },
    },
  },
  tableField: {
    display: 'flex',
    flexDirection: 'column',
    '&.MuiTableCell-root': {
      padding: 0,
    },
  },
  tableFieldBox: {
    padding: theme.spacing(2, 0, 2, 2),
  },
  tableCellField: {
    position: 'relative',
    maxHeight: 20,
    '& .MuiInputBase-input, & .MuiOutlinedInput-notchedOutline': {
      width: '100%',
      height: 20,
      border: '0 !important',
      padding: 0,
      marginRight: theme.spacing(8),
    },
    '& .MuiInputBase-root.Mui-disabled input': {
      '-webkit-text-fill-color': 'unset',
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      appearance: 'none',
      '-webkit-appearance': 'none',
    },
    '& input::placeholder': {
      color: '#7174AC',
    },
  },
  tableCellCommentField: {
    '&:before': {
      content: '""',
      width: 15,
      height: '100%',
      position: 'absolute',
      right: 1,
      top: 0,
      zIndex: 100,
      background: 'linear-gradient(to right, transparent -15px, white)',
    },
    '& .MuiInputBase-input, & .MuiOutlinedInput-notchedOutline': {
      // ...theme.typography.h6,
      marginRight: 0,
    },
  },
  tableCellError: {
    outline: '1px solid #E03737',
  },
  rowTooltip: {
    '& .MuiTooltip-tooltip': {
      marginTop: '0 !important',
      backgroundColor: '#F1F2F8',
      borderRadius: theme.spacing(4),
      padding: theme.spacing(2),
      color: '#212346',
    },
  },
  menuSubItemsPaper: {
    '&.MuiPaper-root': {
      maxWidth: 305,
      width: '100%',
      borderRadius: theme.spacing(0, 2, 2, 0),
      boxShadow: '0px 5px 10px -10px #212346',
    },
  },
  exportItems: {
    zIndex: 2000,
    width: 90,
    backgroundColor: '#ffffff',
    borderRadius: theme.spacing(4),
    boxShadow: '0px 5px 10px -8px #212346',
  },
  exportItem: {
    height: 36,
    cursor: 'pointer',
    padding: theme.spacing(2, 4),
  },
  contextPopover: {
    transform: 'unset !important',
    height: 'fit-content',
    borderRadius: theme.spacing(4),
    '& .MuiPaper-root': {
      overflowY: 'hidden',
      maxWidth: 'unset',
      maxHeight: 'unset',
      width: '100%',
      borderRadius: theme.spacing(4),
      boxShadow: '0px 5px 10px -8px rgba(33, 35, 70, 1)',
    },
    '& .MuiBackdrop-root': {
      display: 'none',
      position: 'unset',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F1F2F8',
      marginRight: '2px',
    },
    '&::-webkit-scrollbar': {
      width: '4px',
      backgroundColor: 'white !important',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7174AC',
      borderRadius: '5px',
    },
  },
  contextMenuItem: {
    cursor: 'pointer',
    // transition: 'all .2s',
    height: '32px',
    '&:hover': {
      backgroundColor: 'rgba(220, 223, 244, 1)',
      '& svg path': {
        fill: '#212346',
      },
    },
  },
  contextMenuItemDisabled: {
    cursor: 'not-allowed',
    pointerEvents: 'none',
    opacity: 0.5,
  },
}));

export default useStyles;
