import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { PickersDay } from '@mui/x-date-pickers-pro';
import useStyles from './CustomPickersDay.styles';

const CustomPickersDay = ({ date, currentDayObj, ...props }) => {
  const classes = useStyles();
  return (
    <PickersDay
      className={classes.pickersDay}
      outsideCurrentMonth={false}
      day={date}
      disableRipple
      isdayoff={`${currentDayObj.isDayOff}`}
      {...props}
    />
  );
};

CustomPickersDay.propTypes = {
  date: PropTypes.string.isRequired,
  currentDayObj: PropTypes.shape({}),
};

export default memo(CustomPickersDay);
