import React, { useEffect } from 'react';
import { generateRedirectUrl } from '../../../packages/common/utils/getRedirectUrl';
import { useNavigate } from 'react-router';

const RedirectToRedmine = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      window.location.href = generateRedirectUrl({ host: window.location.origin });
    } else {
      navigate('/projects');
    }
  }, [navigate]);
  return null;
};

export default RedirectToRedmine;
