import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  createCustomExclusions,
  deleteCustomExclusion,
  getAdminSettings,
  getCalendarExclusions,
  getTableExclusions,
  updateCalendarSettings,
  updateCustomExclusion,
} from '../../../../packages/common/api';
import dayjs from 'dayjs';
import { useQueryParam } from 'use-query-params';

export const withProductionCalendarRequests =
  Component =>
  ({ ...props }) => {
    const [year, setYear] = useQueryParam('year');
    const [customDays, setCustomDates] = useState({});
    const [notFormattedCustomExclusions, setNotFormattedCustomExclusions] = useState([]);
    const [customExclusions, setCustomExclusions] = useState({});
    const [workHours, setWorkHours] = useState('');
    const [offDays, setOffDays] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCreateModalOpen, toggleCreateModalOpen] = useState(false);

    const getAdminCalendarSettings = useRef(async () => {
      try {
        const { data } = await getAdminSettings();
        const offDaysSetting = data.find(({ setting }) => setting === 'non_work_days');
        setOffDays(JSON.parse(offDaysSetting.value));
        const workHoursSetting = data.find(({ setting }) => setting === 'hours_per_day');
        setWorkHours(workHoursSetting.value);
      } catch (error) {
        throw new Error(error);
      }
    });

    const getDatesExclusions = useRef(async year => {
      try {
        const { data: datesExclusions } = await getTableExclusions(year);
        const formatExclusions = datesExclusions.reduce((acc, rec) => {
          return { ...acc, [rec.date]: rec };
        }, {});
        setCustomExclusions(formatExclusions);
        setNotFormattedCustomExclusions(datesExclusions);
      } catch (err) {
        setCustomExclusions(null);
        setNotFormattedCustomExclusions(null);
      }
    });

    const getCalendarDateExclusions = useRef(async year => {
      try {
        const { data: calendarExclusions } = await getCalendarExclusions(year);
        const formatExclusions = calendarExclusions.reduce((acc, rec) => {
          return { ...acc, [rec.date]: rec };
        }, {});
        setCustomDates(formatExclusions);
        setIsLoading(false);
      } catch (err) {
        setCustomDates(null);
        setIsLoading(false);
      }
    });

    const updateProductionCalendarSettings = useCallback(
      async ({ offDays, workHours, setErrors }) => {
        try {
          setIsLoading(true);
          await updateCalendarSettings([
            { setting: 'non_work_days', value: offDays },
            { setting: 'hours_per_day', value: workHours },
          ]);
        } catch (error) {
          throw new Error(error);
        }
      },
      [setIsLoading],
    );

    const handleUpdateRequests = useCallback(async () => {
      await getDatesExclusions.current(year);
      return getCalendarDateExclusions.current(year);
    }, [year]);

    const createExclusions = useCallback(
      async newDates => {
        const formattedDates = newDates.map(date => {
          return {
            date: date[1].date,
            hours: date[1].hours,
          };
        });
        await createCustomExclusions(year, formattedDates);
      },
      [year],
    );

    const handleUpdateProductionCalendar = useCallback(
      async ({ formValues, resetForm, hours, date }) => {
        await updateProductionCalendarSettings({ offDays: formValues.offDays, workHours: formValues.workHours });
        await createExclusions(Object.entries(customExclusions));
        resetForm();
        setIsLoading(false);
      },
      [createExclusions, customExclusions, updateProductionCalendarSettings],
    );

    useEffect(() => {
      if (!year) {
        return setYear(dayjs().get('year'));
      }
      setIsLoading(true);
      getAdminCalendarSettings.current();
      handleUpdateRequests();
    }, [handleUpdateRequests, setYear, year]);

    return (
      <>
        <Component
          customDays={customDays}
          customExclusions={customExclusions}
          workHours={Number(workHours)}
          offDays={offDays}
          getAdminCalendarSettings={getAdminCalendarSettings}
          getDatesExclusions={getDatesExclusions}
          getCalendarDateExclusions={getCalendarDateExclusions}
          setCustomDates={setCustomDates}
          setCustomExclusions={setCustomExclusions}
          setWorkHours={setWorkHours}
          setOffDays={setOffDays}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleUpdateProductionCalendar={handleUpdateProductionCalendar}
          isCreateModalOpen={isCreateModalOpen}
          toggleCreateModalOpen={toggleCreateModalOpen}
          handleUpdateRequests={handleUpdateRequests}
          {...props}
        />
      </>
    );
  };
