import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  projectMemberTtl: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  ProjectTabContentWrap: {
    '& .MuiBox-root': {
      padding: '0px !important',
    },
  },
  ProjectsTabsWrap: {
    marginTop: '30px',
  },
  ProjectTabContentGridContainer: {
    '& .MuiBox-root': {
      padding: '0px !important',
    },
  },
  Url: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  projectFieldsWrap: {
    margin: '0 0 20px 0 !important',
    alignItems: 'baseline',
    backgroundColor: '#FFF',
    borderRadius: '16px',
    padding: '19px 64px 17px 32px !important',
  },
  projectFieldWrap: {
    width: '100% !important',
    marginBottom: '20px !important',
  },
  projectFieldRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& $projectFieldLeft': {
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      fontSize: '12px !important',
      lineHeight: '20px !important',
    },
    '& $projectFieldRight': {
      fontSize: '12px',
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        padding: '0 9px !important',
      },
      '& .MuiInputBase-root': {
        fontSize: '12px',
      },
      '& .MuiAutocomplete-endAdornment': {
        top: 'calc(50% - 12px)',
      },
      '& .MuiIconButton-root.MuiAutocomplete-popupIndicator': {
        width: '24px',
        height: '24px',
      },
    },
  },
  autocompleteRolesList: {
    width: '75%',
    MenuItem: {
      width: '400px',
    },
  },
  poperItem: {
    fontSize: '12px !important',
    width: '100%',
    overflow: 'hidden',
  },
  red: {
    color: '#E03737',
  },
  itemSelected: {
    backgroundColor: '#DCDFF4 !important',
  },
  nameInput: {
    '& .MuiInputBase-root': {
      background: '#FFFFFF !important',
      border: '1px solid #E4E4EF !important',
    },
  },
  TextField: {
    fontSize: '12px',
  },
  textArea: {
    '& .MuiInputBase-root': {
      height: 'auto !important',
    },
  },
  projectTrackersWrap: {
    alignItems: 'flex-start !important',
    '& $projectFieldLeft': {
      width: '20% !important',
    },
    '& $projectFieldRight': {
      width: '80% !important',
      '& .MuiTextField-root': {
        marginTop: '0 !important',
      },
      '& .MuiInputBase-root': {
        margin: 2,
        backgroundColor: '#FFF',
        border: '1px solid #E4E4EF',
        borderRadius: '14px',
        '&:before': {
          display: 'none',
        },
      },
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        padding: '0 9px !important',
      },
    },
  },
  projectFieldDescRow: {
    alignItems: 'flex-start !important',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#212346; !important',
    gap: '5px',
    '& .MuiChip-root': {
      margin: 2,
      backgroundColor: '#FFF',
      border: '1px solid #E4E4EF',
      borderRadius: '14px',
    },
    '& .MuiChip-deleteIcon': {
      height: '10px !important',
    },
  },
  projectCloseMessage: {
    margin: '30px 0 0 0 !important',
    padding: '19px 17px !important',
    alignItems: 'baseline',
    borderRadius: '16px',
    display: 'inline-flex',
    alignContent: 'center',
    backgroundColor: '#FFF',
    '& .MuiSvgIcon-root': {
      marginRight: '5px',
    },
  },
  ProjectsViewChangeButton: {
    border: '0px !important',
    padding: '0px !important',
  },
  projectFieldLeft: {
    fontWeight: 700,
    width: '40%',
  },
  projectsPageTtl: {
    marginLeft: '0px !important',
  },
  projectFieldRight: {
    width: '60%',
    overflowWrap: 'break-word',
  },
  projectFieldMemberWrap: {
    width: '100% !important',
    display: 'flex',
    marginBottom: '10px !important',
    position: 'relative',
    '& .MuiSvgIcon-root': {
      width: '16px',
      left: '-20px',
      top: '7px',
      position: 'absolute',
    },
    '& .MuiTypography-h5': {
      width: '50%',
    },
  },
  projectFieldMemberRolesWrap: {
    width: '50%',
    '& .MuiTypography-h5': {
      fontSize: '12px !important',
    },
  },
  projectFieldsTtl: {
    fontWeight: '600 !important',
    fontSize: '16px !important',
    lineHeight: '20px !important',
    color: '#082253 !important',
    marginBottom: '18px !important',
  },
  projectFiledTtl: {
    color: '#41424E !important',
    fontSize: '12px!important',
    lineHeight: '20px!important',
  },
  projectFiledValue: {
    color: '#41424E !important',
    fontSize: '14px!important',
    lineHeight: '20px!important',
  },
  ProjectsTabs: {
    minHeight: '36px!important',
    '& .MuiTabs-indicator': { display: 'none' },
    '& .MuiTab-root': {
      background: '#DCDFF4',
      color: '#656985',
      fontSize: '14px',
      textTransform: 'none',
      padding: '8px 16px',
      minHeight: '36px',
      '&:first-child': {
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '16px',
      },
      '&:last-child': {
        borderTopRightRadius: '16px',
        borderBottomRightRadius: '16px',
      },
    },
    '& .Mui-selected': { background: '#6270D0', color: '#FFFFFF' },
    '& .MuiTabs-flexContainer': { gap: '2px' },
  },
  ProjectsListViewTableRootWrap: {
    border: '1px solid #E4E4EF',
    borderRadius: '16px',
    '& .MuiTableCell-root': {
      border: '1px solid #E4E4EF',
      padding: '10px',
      color: '#212346',
      fontWeight: 700,
      '&:first-child': {
        borderLeft: 'none',
      },
      '&:last-child': {
        borderRight: 'none',
      },
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        padding: '18px 10px',
        color: '#082253',
      },
    },
  },
  saveSettingButton: {
    borderRadius: '16px !important',
  },
  autoCompletePopper: {
    // marginTop: `${theme.spacing(1)} !important`,
    zIndex: 2000,
    minWidth: 280,
    maxWidth: 592,
    backgroundColor: '#fff',
    borderRadius: theme.spacing(2.5),
    width: 'fit-content',
    // overflowX: 'hidden',
    // overflowY: 'auto',
    border: '1px solid #E4E4EF',
    '& .MuiAutocomplete-listbox': {
      padding: 0,
      '& .MuiAutocomplete-option': {
        maxHeight: 28,
      },
      '& .MuiAutocomplete-option[aria-selected="true"], & .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
        backgroundColor: '#DCDFF4 !important',
      },
      '& .MuiAutocomplete-option[aria-selected="true"]:hover': {
        backgroundColor: 'rgba(220, 223, 244, .6)',
      },
    },
  },
  uploadButton: {
    fontSize: '12px !important',
    color: '#656985 !important',
    background: '#DCDFF4 !important',
    padding: '6px 16px !important',
  },
  '.MuiButtonBase-root.MuiButton-root': {
    fontSize: '12px !important',
    color: '#656985 !important',
    background: '#DCDFF4 !important',
    padding: '6px 16px !important',
  },
  projectError: {
    color: '#E03737',
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '14px',
  },
  cancelReportButton: {
    marginRight: '10px !important',
    borderRadius: '16px !important',
  },
  '.Mui-disabled': {
    background: '#DCDFF4  !important',
  },
  saveReportButton: {
    marginRight: '10px !important',
    borderRadius: '16px !important',
    '& .MuiInputBase-root': {
      '&.Mui-disabled': {
        backgroundColor: '#DCDFF4 !important',
      },
    },
    '&.Mui-disabled': {
      backgroundColor: '#DCDFF4 !important',
      color: '#656985 !important',
    },
  },
}));

export default useStyles;
