import React, { Suspense, useCallback, useEffect } from 'react';
import { Outlet } from 'react-router';
import { connect } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { useQueryParams } from 'use-query-params';
import MainDrawer from './MainDrawer';
import { getXlanceAuthToken } from 'Common/api';
import cookies from 'Common/utils/cookies';
import { getProject, getProjectStatistic } from '../../../packages/common/api';
import { setProject } from '../app/redux/reducers/userSlice';
import { store } from '../app/redux';

const DashboardLayout = ({ userInfo, isUserLoading, projectSelected }) => {
  const state = store.getState();
  const [query, setQuery] = useQueryParams({
    back_url: '',
    redmine_token: '',
    project: '',
  });

  const getXlanceToken = useCallback(async (back_url, redmine_token) => {
    const accessToken = cookies.getCookie('access_token');
    try {
      if (!accessToken) {
        const { data: access_token } = await getXlanceAuthToken({ redmine_token });
        cookies.setCookie({
          key: 'access_token',
          value: access_token,
          host: `${window.location.protocol}//${window.location.hostname}`,
        });
        return (window.location.href = back_url);
      }
    } catch (error) {
      console.log('CANNOT SET PASSTHROWGH TOKEN', error);
    }
  }, []);

  /*  useEffect(() => {
    if (query.redmine_token && query.back_url) {
      getXlanceToken(query.back_url, query.redmine_token);
    }
  }, [getXlanceToken, query.back_url, query.redmine_token, query, state]);*/

  return (
    <>
      <MainDrawer {...{ userInfo, isUserLoading, projectSelected }}>
        <Suspense fallback={<CircularProgress />}>
          <Outlet />
        </Suspense>
      </MainDrawer>
    </>
  );
};

const mapStateToProps = state => {
  return {
    userInfo: state.user.info,
    isUserLoading: state.user.isLoading,
    projectSelected: state.user.project,
  };
};

export default connect(mapStateToProps)(DashboardLayout);
