import useStyles from './AgileSettingsModal.styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Typography,
  FormControl,
  Checkbox,
  ListItemText,
  CircularProgress,
  InputLabel,
} from '@mui/material';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import DragDropLists from 'Common/shared-ui/src/components/DragDropLists/DragDropLists';
import DeleteIcon from 'Common/shared-ui/src/icons/DeleteIcon';
import { useDispatch } from 'react-redux';
import { setAgileFiltersToDelete } from '../../app/redux/reducers/userSlice';

const AgileSettingsModal = ({ intl, open, handleClose, agileFilters, setQuery, setAgileFilters, query }) => {
  const classes = useStyles();
  const [filters, setFilters] = React.useState([]);
  const [fields, setFields] = React.useState([]);
  const [statuses, setStatuses] = React.useState([]);
  const [isModalLoading, setIsModalLoading] = useState(true);
  const [modalSessionParams, setModalSessionParams] = useState({
    availableFields: [],
    selectedFields: [],
  });
  const dispatch = useDispatch();

  const fetchSettingsData = useCallback(async () => {
    try {
      const queryFilters = query?.filters?.split('$$').map(filter => filter.split(';')[0]);
      const queryFields = query?.fields?.split('$$');
      const queryStatuses = query?.statuses?.split('||');
      const updatedFilters = agileFilters?.filters?.map(filter => {
        if (queryFilters?.includes(filter.fieldId)) {
          return { ...filter, isChecked: true };
        }
        return filter;
      });
      const updatedFields = agileFilters?.fields?.map(field => {
        if (queryFields?.includes(field.fieldId)) {
          return { ...field, isChecked: true };
        }
        if (!queryFields?.includes(field.fieldId)) {
          return { ...field, isChecked: false };
        }
        return field;
      });
      const updatedStatuses = agileFilters?.statuses?.values.map(status => {
        if (query.statuses === 'statuses=' || !query.statuses || queryStatuses?.includes(status.valueId)) {
          return { ...status, isChecked: true };
        } else {
          return { ...status, isChecked: false };
        }
      });
      setFilters(updatedFilters);
      setFields(updatedFields);
      setStatuses(updatedStatuses);
      setIsModalLoading(false);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agileFilters, handleClearSettings, query]);

  useEffect(() => {
    fetchSettingsData();
  }, [fetchSettingsData]);

  const handleSelectChange = event => {
    const selectedFieldIds = event.target.value;
    setFilters(prevFilters =>
      prevFilters.map(filter =>
        selectedFieldIds.includes(filter.fieldId) ? { ...filter, isChecked: true } : { ...filter, isChecked: false },
      ),
    );
  };

  const handleRemoveFilter = useCallback(fieldId => {
    setFilters(prevFilters => {
      return prevFilters.map(filter => (filter.fieldId === fieldId ? { ...filter, isChecked: false } : filter));
    });
  }, []);

  const handleStatusChange = event => {
    const selectedFieldIds = event.target.value;
    setStatuses(prevStatuses =>
      prevStatuses.map(status =>
        selectedFieldIds.includes(status.valueId) ? { ...status, isChecked: true } : { ...status, isChecked: false },
      ),
    );
  };

  const handleRemoveStatus = useCallback(valueId => {
    setStatuses(prevStatuses => {
      return prevStatuses.map(status => (status.valueId === valueId ? { ...status, isChecked: false } : status));
    });
  }, []);
  useEffect(() => {
    if (fields.length > 0) {
      setModalSessionParams({
        availableFields: fields.filter(field => !field.isChecked),
        selectedFields: fields.filter(field => field.isChecked),
      });
    }
  }, [fields]);

  const handleClearSettings = async () => {
    fetchSettingsData();
  };
  const handleSaveSettings = async () => {
    const updatedSelectedFields = modalSessionParams.selectedFields.map(field => ({
      ...field,
      isChecked: true,
    }));
    const updatedAvailableFields = modalSessionParams.availableFields.map(field => ({
      ...field,
      isChecked: false,
    }));

    const queryFiltersArray = query.filters.split('$$');
    const newQueryArray = {};
    queryFiltersArray.forEach(filter => {
      const filterKey = filter.split(';')[0];
      newQueryArray[filterKey] = filter;
    });

    const queryFiltersIds = query?.filters?.split('$$').map(filter => filter.split(';')[0]);
    const queryFiltersToDelete = [];
    filters?.forEach(filter => {
      if (!filter.isChecked && queryFiltersIds?.includes(filter.fieldId)) {
        queryFiltersToDelete[filter.fieldId] = filter;
        delete newQueryArray[filter.fieldId];
      }
    });
    dispatch(setAgileFiltersToDelete(queryFiltersToDelete));
    const queryFiltersToUpdate = Object.values(newQueryArray).join('$$');
    statuses.sort((a, b) => a.position - b.position);
    const payload = {
      statuses: statuses
        .filter(status => status.isChecked)
        .map(status => status.valueId)
        .join('||'),
      fields: updatedSelectedFields.map(item => item.fieldId).join('$$'),
    };

    try {
      console.log('SAVE');
      console.log('savedData', payload);
      setQuery({
        ...query,
        fields: updatedSelectedFields.map(item => item.fieldId).join('$$'),
        filters: queryFiltersToUpdate,
        statuses: statuses
          .filter(status => status.isChecked)
          .map(status => status.valueId)
          .join('||'),
      });
      setAgileFilters({
        ...agileFilters,
        fields: [...updatedSelectedFields, ...updatedAvailableFields],
        statuses: { ...agileFilters.statuses, values: statuses },
        filters: filters,
      });
      handleClose();
    } catch (e) {
      console.error('Error', e);
    }
  };
  const sortAlphabetically = (a, b) => {
    let newA, newB;
    if (a.fieldId && b.fieldId) {
      newA = getTranslatedText(intl, 'filter', a.fieldId, a.fieldName);
      newB = getTranslatedText(intl, 'filter', b.fieldId, b.fieldName);
    } else if (a.valueId && b.valueId) {
      newA = getTranslatedText(intl, '', a.valueName, a.valueName);
      newB = getTranslatedText(intl, '', b.valueName, b.valueName);
    } else {
      newA = '';
      newB = '';
    }

    return (
      newA.localeCompare(newB, 'ru', { numeric: true, sensitivity: 'base' }) ||
      newA.localeCompare(newB, 'en', { numeric: true, sensitivity: 'base' })
    );
  };
  return (
    <>
      {!isModalLoading ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalRoot}
        >
          <Grid container direction="column" wrap="nowrap" className={classes.modalPaper}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              className={classes.modalHeader}
            >
              <Typography variant="h1">
                <FormattedMessage id="parameters_text" />
              </Typography>
              <IconButton disableRipple onClick={handleClose}>
                <Box width={24} height={24}>
                  <ClearIcon width={24} height={24} viewBox="0 0 24 24" />
                </Box>
              </IconButton>
            </Grid>
            <Grid container className={classes.modalContent}>
              <Grid container spacing={5} marginBottom={5} position="relative" className={classes.filtersWrap}>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="filter" />
                  </Typography>
                </Grid>
                <Grid item xs={9} className={classes.formControlWrap}>
                  <FormControl className={classes.formControl}>
                    <InputLabel className={classes.selectLabel} id="select-label-filter">
                      {intl.formatMessage({ id: 'choose' })}
                    </InputLabel>
                    <Select
                      id="filters"
                      multiple
                      value={filters.filter(filter => filter.isChecked).map(filter => filter.fieldId)}
                      MenuProps={{ className: classes.optionsGroupMenu }}
                      onChange={handleSelectChange}
                      labelId="select-label-filter"
                      label={intl.formatMessage({ id: 'choose' })}
                      renderValue={selected => {
                        return (
                          <div className={classes.chips}>
                            {selected.map(value => {
                              const filter = filters.find(filter => filter.fieldId === value);
                              return (
                                <Chip
                                  key={filter.field}
                                  label={getTranslatedText(intl, 'filter', filter.fieldId, filter.fieldName)}
                                  clickable
                                  deleteIcon={
                                    <DeleteIcon
                                      width={10}
                                      height={10}
                                      viewBox="0 0 10 10"
                                      onMouseDown={event => event.stopPropagation()}
                                    />
                                  }
                                  className={classes.chip}
                                  onDelete={() => handleRemoveFilter(filter.fieldId)}
                                />
                              );
                            })}
                          </div>
                        );
                      }}
                    >
                      {filters
                        .sort(sortAlphabetically)
                        .sort((a, b) => b.isChecked - a.isChecked)
                        .map(filter => (
                          <MenuItem key={filter.field} value={filter.fieldId} className={classes.menuItem}>
                            <Checkbox className={classes.menuItemCheckbox} checked={filter.isChecked} />
                            <Typography variant="h5" fontWeight={400} marginLeft="12px">
                              {getTranslatedText(intl, 'filter', filter.fieldId, filter.fieldName)}
                            </Typography>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={5} position="relative" className={classes.filtersWrap}>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="agile_tasks_status" />
                  </Typography>
                </Grid>
                <Grid item xs={9} className={classes.formControlWrap}>
                  <FormControl className={classes.formControl}>
                    <InputLabel className={classes.selectLabel} id="select-label-statuses">
                      {intl.formatMessage({ id: 'choose' })}
                    </InputLabel>
                    <Select
                      id="statuses"
                      multiple
                      value={statuses.filter(status => status.isChecked).map(status => status.valueId)}
                      MenuProps={{ className: classes.optionsGroupMenu }}
                      onChange={handleStatusChange}
                      labelId="select-label-statuses"
                      label={intl.formatMessage({ id: 'choose' })}
                      renderValue={selected => {
                        return (
                          <div className={classes.chips}>
                            {selected.map(value => {
                              const status = statuses.find(status => status.valueId === value);
                              return (
                                <Chip
                                  key={status.valueId}
                                  label={getTranslatedText(intl, '', status.valueName, status.valueName)}
                                  clickable
                                  deleteIcon={
                                    <DeleteIcon
                                      width={10}
                                      height={10}
                                      viewBox="0 0 10 10"
                                      onMouseDown={event => event.stopPropagation()}
                                    />
                                  }
                                  className={classes.chip}
                                  onDelete={() => handleRemoveStatus(status.valueId)}
                                />
                              );
                            })}
                          </div>
                        );
                      }}
                    >
                      {statuses
                        .sort(sortAlphabetically)
                        .sort((a, b) => b.isChecked - a.isChecked)
                        .map(status => (
                          <MenuItem key={status.valueId} value={status.valueId} className={classes.menuItem}>
                            <Checkbox className={classes.menuItemCheckbox} checked={status.isChecked} />
                            <Typography variant="h5" fontWeight={400} marginLeft="12px">
                              {getTranslatedText(intl, 'filter', status.valueName, status.valueName)}
                            </Typography>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                marginTop={0}
                marginLeft={0}
                justifyContent="space-between"
                display="flex"
                position="relative"
                spacing={5}
                className={classes.dragWrap}
              >
                <DragDropLists
                  intl={intl}
                  firstListOptions={modalSessionParams.availableFields}
                  secondListOptions={modalSessionParams.selectedFields}
                  setParentState={setModalSessionParams}
                  getOptionLabel={option =>
                    `${option.fieldId};${getTranslatedText(intl, 'filter', option.fieldId, option.fieldName)}`
                  }
                  componentParent="agile"
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" marginTop={5} className={classes.modalFooter}>
              <Button disableRipple variant="cutGreyPrimaryBig" onClick={handleClearSettings}>
                <Typography variant="h5" fontWeight={700}>
                  <FormattedMessage id="spreadsheet_table_clear" defaultMessage="Clear" />
                </Typography>
              </Button>
              <Box marginLeft={1}>
                <Button disableRipple variant="cutBlueSecondary" onClick={handleSaveSettings}>
                  <Typography variant="h5" fontWeight={700}>
                    <FormattedMessage id="save_text" defaultMessage="Save" />
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Modal>
      ) : (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </>
  );
};

export default injectIntl(AgileSettingsModal);
