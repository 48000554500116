import useStyles from './ResourcePlanTable.styles';
import React, { useEffect, useState, useCallback, useMemo, useDeferredValue } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import dayjs from 'dayjs';
import {
  Grid,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Select,
  MenuItem,
  Box,
  TextField,
  Tooltip,
} from '@mui/material';
import useResPlanWebsockets from '../useResplanWebSockets';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import PlusIcon from 'Common/shared-ui/src/icons/PlusIcon';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import clsx from 'clsx';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import CalendarTimesheetIcon from '../../../../packages/common/shared-ui/src/icons/CalendarTimesheetIcon';
import { store } from '../../app/redux';
import { useDispatch } from 'react-redux';
import ResourcePlanTableRow from '../ResourcePlanTableRow/ResourcePlanTableRow';
import {
  getResplanUsers,
  getResplanProjects,
  getResplanContracts,
  getResplanTrackers,
} from '../../../../packages/common/api';

const ResourcePlanTable = ({
  intl,
  fetchedQuery,
  isTableLoading,
  setResplanFields,
  updateResPlanByMount,
  updateResPlanByPercent,
  addOrUpdateResPlanNewIssue,
}) => {
  const classes = useStyles();
  const resplanFields = useSelector(state => state.user?.resplanRows);
  const resplanColumns = useSelector(state => state.user?.resplanColumns);
  const state = store.getState();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [trackers, setTrackers] = useState([]);
  const [hasIssues, setHasIssues] = useState(false);

  const fetchUserIssueData = async projectId => {
    try {
      const newUsers = await getResplanUsers(projectId);
      setUsers(newUsers);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchProjectIssueData = async userId => {
    try {
      const newProjects = await getResplanProjects(userId);
      setProjects(newProjects);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchContractIssueData = async projectId => {
    try {
      const newContracts = await getResplanContracts(projectId);
      setContracts(newContracts);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchTrackersIssueData = async () => {
    try {
      const newTrackers = await getResplanTrackers();
      setTrackers(newTrackers);
    } catch (e) {
      console.error(e);
    }
  };
  // useEffect(() => {
  //   const projectId = '268';
  //   fetchNewRowData(projectId);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // const {
  //   isTableLoading,
  //   resplanFields,
  //   resplanColumns,
  //   setResplanFields,
  //   updateResPlanByMount,
  //   updateResPlanByPercent,
  // } = useResPlanWebsockets(fetchedQuery);
  useEffect(() => {
    resplanColumns.map(column => {
      if (column.field === 'issue') {
        setHasIssues(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resplanColumns]);
  const addNewRowAbove = rowIndex => {
    const prevRow = resplanFields?.rows[rowIndex - 1];
    const groupId = prevRow.groupId;
    const uniqueTime = new Date().toISOString();
    const rowId = `${groupId}-${uniqueTime}`;
    let projectId;
    let userId;
    prevRow.columns.map((column, index) => {
      if (column.fieldId.includes('resourcePlan.project')) {
        projectId = column.valueId;
      }
      if (column.fieldId.includes('resourcePlan.user')) {
        userId = column.valueId;
      }
    });
    addOrUpdateResPlanNewIssue(groupId, rowId, projectId, userId);
  };
  const handleIssueChange = (rowIndex, fieldId, targetValue) => {
    const issueRow = resplanFields?.rows[rowIndex];
    if (!issueRow) {
      return;
    }

    const { rowId, groupId, columns } = issueRow;

    const mapping = {
      'resourcePlan.user': 'userId',
      'resourcePlan.project': 'projectId',
      'resourcePlan.issue': 'name',
      'resourcePlan.contract': 'contractId',
      'resourcePlan.tracker': 'trackerId',
    };

    const result = {};

    columns.forEach(column => {
      const key = mapping[column.fieldId];
      if (key) {
        result[key] = column.valueId;
      }
    });

    const targetKey = mapping[fieldId];
    if (targetKey) {
      result[targetKey] = targetValue;
    }

    const { userId, projectId, name, trackerId, contractId } = result;

    addOrUpdateResPlanNewIssue(groupId, rowId, projectId, userId, name, trackerId, contractId);
  };

  const indexOfFirstMonthlyDistrib = useMemo(() => {
    return resplanColumns.findIndex(item => item.fieldType === 'monthly_distribution');
  }, [resplanColumns]);

  const indexOfLastMonthlyDistrib = useMemo(() => {
    return resplanColumns
      .map((item, index) => (item.fieldType === 'monthly_distribution' ? index : -1))
      .reduce((acc, curr) => (curr > acc ? curr : acc), -1);
  }, [resplanColumns]);

  const startGroupAdditionalCells = useMemo(() => {
    let cells = [];
    for (let i = 0; i < indexOfFirstMonthlyDistrib - 2; i++) {
      cells.push(<TableCell></TableCell>);
    }
    return cells;
  }, [indexOfFirstMonthlyDistrib]);

  const endGroupAdditionalCells = useMemo(() => {
    let cells = [];
    for (let i = 0; i < resplanColumns.length - 1 - indexOfLastMonthlyDistrib; i++) {
      cells.push(<TableCell></TableCell>);
    }
    return cells;
  }, [resplanColumns, indexOfLastMonthlyDistrib]);

  const startAdditionalCells = useMemo(() => {
    let cells = [];
    for (let i = 0; i < indexOfFirstMonthlyDistrib - 2; i++) {
      cells.push(<TableCell rowSpan={2}></TableCell>);
    }
    return cells;
  }, [indexOfFirstMonthlyDistrib]);

  const endAdditionalCells = useMemo(() => {
    let cells = [];
    for (let i = 0; i < resplanColumns.length - 1 - indexOfLastMonthlyDistrib; i++) {
      cells.push(<TableCell rowSpan={2}></TableCell>);
    }
    return cells;
  }, [resplanColumns, indexOfLastMonthlyDistrib]);

  const getMonthName = useCallback(monthStr => {
    if (monthStr && monthStr.includes('-')) {
      const date = dayjs(monthStr);
      const formattedMonth = date.format('MMM');
      return `${formattedMonth.charAt(0).toUpperCase()}${formattedMonth.slice(1)} ${date.format('YYYY')}`;
    }
    return monthStr;
  }, []);

  return (
    <>
      {!isTableLoading ? (
        <TableContainer style={{ height: '80vh', overflow: 'auto', marginBottom: '30px', marginTop: '30px' }}>
          <Table style={{ borderCollapse: 'separate' }} className={classes.ResplanTableWrap}>
            <TableHead style={{ position: 'sticky', top: 0 }} className={classes.tableHead} align="center">
              <TableRow className={classes.tableRow}>
                {resplanColumns.map((column, index) => {
                  if (column.fieldId.includes('monthly_distribution')) {
                    return (
                      <TableCell rowSpan={2} className={classes.tableCellHead} key={index} align="center">
                        <Typography variant="h4" fontWeight={700} style={{ marginBottom: '-6px' }}>
                          {getMonthName(getTranslatedText(intl, '', `resPlan_${column.field}`, column.fieldName))}
                        </Typography>
                        <Typography variant="h5" fontWeight={400} style={{ marginTop: '-6px' }}>
                          {column.hours}
                        </Typography>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell className={classes.tableCellHead} key={index} align="center">
                      <Typography variant="h4" fontWeight={700}>
                        {getTranslatedText(intl, '', `resPlan_${column.field}`, column.fieldName)}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {!resplanFields.rows || resplanFields.rows.length === 0 ? (
                <TableRow>
                  <TableCell key={'addButton'}>
                    <IconButton disableRipple className={classes.addRowButton}>
                      <PlusIcon width={24} height={24} viewBox="0 0 24 24" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ) : (
                resplanFields.rows.map((row, rowIndex) => {
                  const canEdit = row?.services?.canEdit;
                  if (fetchedQuery.includes('resourcePlan.group_by;eq;0')) {
                    if (row.rowType === 'group') {
                      return (
                        <TableRow key={rowIndex}>
                          <TableCell key={'addRow'}>
                            <IconButton
                              disableRipple
                              className={classes.addRowButton}
                              onClick={() => addNewRowAbove(rowIndex)}
                            >
                              <PlusIcon width={24} height={24} viewBox="0 0 24 24" />
                            </IconButton>
                          </TableCell>
                          {startGroupAdditionalCells}
                          <TableCell
                            key={'total'}
                            align="right"
                            style={{ borderRight: '1px solid #E4E4EF', paddingRight: '10px' }}
                          >
                            <Typography variant="h5" fontWeight={700}>
                              <FormattedMessage id="resplan_table_total" defaultMessage="Total" />
                            </Typography>
                          </TableCell>
                          {row.columns.map((column, columnIndex) => (
                            <TableCell key={columnIndex} className={classes.dateCell} align="right">
                              <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                                <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                                  {column.value}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                          ))}
                          {endGroupAdditionalCells}
                        </TableRow>
                      );
                    }
                  }
                  if (fetchedQuery.includes('resourcePlan.group_by;eq;1')) {
                    if (row.rowType === 'group') {
                      return null;
                    }
                    if (row.rowType === 'perload') {
                      const groupRow = resplanFields.rows[rowIndex - 1];
                      let negativeColumns = row.columns.map(column => column.value < 0);
                      let idealColumns = row.columns.map(column => column.value == 0);
                      return (
                        <>
                          <TableRow key={rowIndex}>
                            <TableCell key={'addRow'} rowSpan={2}>
                              <IconButton
                                disableRipple
                                className={classes.addRowButton}
                                onClick={() => addNewRowAbove(rowIndex - 1)}
                              >
                                <PlusIcon width={24} height={24} viewBox="0 0 24 24" />
                              </IconButton>
                            </TableCell>
                            {startAdditionalCells}
                            <TableCell
                              key={'Perload'}
                              rowSpan={2}
                              align="right"
                              style={{ borderRight: '1px solid #E4E4EF', paddingRight: '10px' }}
                            >
                              <Typography variant="h5" fontWeight={700}>
                                <FormattedMessage id="resplan_table_total" defaultMessage="Total" />
                              </Typography>
                              <Typography variant="h5" fontWeight={700}>
                                <FormattedMessage id="resplan_table_perload" defaultMessage="Perload" />
                              </Typography>
                            </TableCell>
                            {groupRow.columns.map((column, columnIndex) => (
                              <TableCell
                                key={columnIndex}
                                className={clsx(
                                  classes.perloadDateCell,
                                  classes.noBottom,
                                  {
                                    [classes.redText]: negativeColumns[columnIndex],
                                  },
                                  {
                                    [classes.blackText]: idealColumns[columnIndex],
                                  },
                                )}
                                align="right"
                              >
                                <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                                  <Typography
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                    variant="h5"
                                  >
                                    {column.value}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            ))}
                            {endAdditionalCells}
                          </TableRow>
                          <TableRow key={rowIndex} style={{ border: '0 !important' }}>
                            {row.columns.map((column, columnIndex) => (
                              <TableCell
                                key={columnIndex}
                                className={clsx(
                                  classes.perloadDateCell,
                                  { [classes.redText]: column.value < 0 },
                                  { [classes.blackText]: column.value == 0 },
                                )}
                                align="right"
                              >
                                <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                                  <Typography
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                    variant="h5"
                                  >
                                    {column.value}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            ))}
                            {/* {endAdditionalCells} */}
                          </TableRow>
                        </>
                      );
                    }
                  }
                  if (row.rowType === 'new') {
                    return (
                      <TableRow key={rowIndex}>
                        {row.columns.map((column, columnIndex) => {
                          if (
                            column.fieldName === 'User' ||
                            column.fieldName === 'Project' ||
                            column.fieldName === 'Contract'
                          ) {
                            return (
                              <TableCell key={columnIndex} className={classes.perloadCell}>
                                <Select
                                  // fullWidth
                                  IconComponent={() => (
                                    <Box className={classes.groupIcon}>
                                      <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
                                    </Box>
                                  )}
                                  MenuProps={{
                                    className: classes.optionsGroupMenu,
                                  }}
                                  variant="standard"
                                  disableUnderline
                                  className={classes.createSelect}
                                  value={'choose'}
                                  style={{ width: '220px !important' }}
                                  // onChange={handleChange}
                                >
                                  <MenuItem value="choose">
                                    <Typography
                                      textOverflow="ellipsis"
                                      overflow="hidden"
                                      whiteSpace="nowrap"
                                      variant="h5"
                                      color="#212346"
                                    >
                                      {column.value}
                                    </Typography>
                                  </MenuItem>
                                </Select>
                              </TableCell>
                            );
                          }
                          if (
                            column.fieldId.includes('monthly_distribution') ||
                            column.fieldId.includes('loading_percent')
                          ) {
                            return (
                              <TableCell key={columnIndex} className={classes.dateCell} align="right">
                                <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                                  <>
                                    <TextField
                                      value={column.value}
                                      className={clsx(classes.dateCellField, classes.dateCellCommentField)}
                                      // onChange={handleChangeComment}
                                      align="right"
                                    />
                                  </>
                                </Tooltip>
                              </TableCell>
                            );
                          }
                          if (column.fieldId.includes('date_from') || column.fieldId.includes('date_to')) {
                            return (
                              <TableCell key={columnIndex} className={classes.tableCell} align="left">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <Tooltip
                                    title={formatDate(column.value)}
                                    PopperProps={{ className: classes.commentTooltip }}
                                  >
                                    <>
                                      <DatePicker
                                        views={['day']}
                                        // disableMaskedInput
                                        className={classes.datePicker}
                                        components={{
                                          OpenPickerIcon: () => (
                                            <CalendarTimesheetIcon
                                              style={{ right: 10, width: 16, height: 16 }}
                                              viewBox="0 0 16 16"
                                            />
                                          ),
                                          LeftArrowIcon: () => (
                                            <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />
                                          ),
                                          RightArrowIcon: () => (
                                            <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />
                                          ),
                                          SwitchViewIcon: () => (
                                            <ChevronIcon direction="down" viewBox="0 0 24 24" width={24} height={24} />
                                          ),
                                          SwitchViewButton: componentProps => (
                                            <IconButton disableRipple {...componentProps}>
                                              {componentProps.children}
                                            </IconButton>
                                          ),
                                          RightArrowButton: componentProps => (
                                            <IconButton disableRipple {...componentProps}>
                                              {componentProps.children}
                                            </IconButton>
                                          ),
                                          LeftArrowButton: componentProps => (
                                            <IconButton disableRipple {...componentProps}>
                                              {componentProps.children}
                                            </IconButton>
                                          ),
                                        }}
                                        renderInput={params => (
                                          <Box display="flex" alignItems="center">
                                            <TextField
                                              {...params}
                                              placeholder=""
                                              inputProps={{ ...params.inputProps, placeholder: ' ' }}
                                              helperText={null}
                                              fullWidth
                                            />
                                          </Box>
                                        )}
                                        value={column.value || ''}
                                        onChange={handleChangeDate}
                                      />
                                    </>
                                  </Tooltip>
                                </LocalizationProvider>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={columnIndex} className={classes.tableCell} align="left">
                              <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                                <>
                                  <TextField
                                    value={column.value}
                                    className={clsx(classes.tableCellField, classes.tableCellCommentField)}
                                    // onChange={handleChangeComment}
                                  />
                                </>
                              </Tooltip>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  }
                  return (
                    <TableRow key={rowIndex}>
                      {row.columns.map((column, columnIndex) => {
                        if (column.fieldId.includes('monthly_distribution')) {
                          const isExceeded = column.exceeded;
                          return (
                            <TableCell key={columnIndex} className={classes.dateCell} align="right">
                              <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                                <>
                                  <TextField
                                    type="number"
                                    value={column.value}
                                    className={clsx(
                                      classes.dateCellField,
                                      classes.dateCellCommentField,
                                      { [classes.redText]: isExceeded }, // Apply redText class when isExceeded is true
                                    )}
                                    onChange={e =>
                                      handleMonthlyDistributionChange(rowIndex, columnIndex, e.target.value)
                                    }
                                    onBlur={e =>
                                      handleMonthFieldChange(row.groupId, row.rowId, column.fieldId, e.target.value)
                                    }
                                    align="right"
                                    disabled={!canEdit}
                                  />
                                </>
                              </Tooltip>
                            </TableCell>
                          );
                        }
                        if (column.fieldId.includes('loading_percent')) {
                          return (
                            <TableCell key={columnIndex} className={classes.dateCell} align="right">
                              <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                                <>
                                  <TextField
                                    type="number"
                                    value={column.value}
                                    className={clsx(classes.dateCellField, classes.dateCellCommentField)}
                                    onChange={e => handlePercentChange(rowIndex, columnIndex, e.target.value)}
                                    onBlur={e => {
                                      const loadingPercentColumn = row.columns.find(
                                        column => column.fieldId === 'resourcePlan.loading_percent',
                                      );
                                      const loadingPercentValue = loadingPercentColumn
                                        ? loadingPercentColumn.value
                                        : null;
                                      handlePercentFieldChange(
                                        row.groupId,
                                        row.rowId,
                                        row.services?.startDate,
                                        row.services?.endDate,
                                        e.target.value,
                                        column.fieldId,
                                        loadingPercentValue,
                                      );
                                    }}
                                    disabled={!canEdit}
                                    align="right"
                                  />
                                </>
                              </Tooltip>
                            </TableCell>
                          );
                        }
                        if (column.fieldId.includes('issue')) {
                          return (
                            <TableCell key={columnIndex} className={classes.tableCell} align="left">
                              <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                                <a
                                  href={handleGetRedirectUrl({
                                    id: row.services.issueId,
                                    columnType: 'issue',
                                  })}
                                  className={classes.tableCellLink}
                                  target="_blank"
                                >
                                  <Typography
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                    variant="h5"
                                  >
                                    {column.value}
                                  </Typography>
                                </a>
                              </Tooltip>
                            </TableCell>
                          );
                        }
                        if (column.fieldId.includes('date_from') || column.fieldId.includes('date_to')) {
                          return (
                            <TableCell key={columnIndex} className={classes.tableCell} align="left">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Tooltip
                                  title={formatDate(column.value)}
                                  PopperProps={{ className: classes.commentTooltip }}
                                >
                                  <>
                                    <DatePicker
                                      views={['day']}
                                      // disableMaskedInput
                                      className={classes.datePicker}
                                      components={{
                                        OpenPickerIcon: () => (
                                          <CalendarTimesheetIcon
                                            style={{ right: 10, width: 16, height: 16 }}
                                            viewBox="0 0 16 16"
                                            color={canEdit ? '#DCDFF4' : '#ffffff'} // Если canEdit истина, цвет серый, иначе дефолтный цвет
                                          />
                                        ),
                                        LeftArrowIcon: () => (
                                          <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />
                                        ),
                                        RightArrowIcon: () => (
                                          <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />
                                        ),
                                        SwitchViewIcon: () => (
                                          <ChevronIcon direction="down" viewBox="0 0 24 24" width={24} height={24} />
                                        ),
                                        SwitchViewButton: componentProps => (
                                          <IconButton disableRipple {...componentProps}>
                                            {componentProps.children}
                                          </IconButton>
                                        ),
                                        RightArrowButton: componentProps => (
                                          <IconButton disableRipple {...componentProps}>
                                            {componentProps.children}
                                          </IconButton>
                                        ),
                                        LeftArrowButton: componentProps => (
                                          <IconButton disableRipple {...componentProps}>
                                            {componentProps.children}
                                          </IconButton>
                                        ),
                                      }}
                                      renderInput={params => (
                                        <Box display="flex" alignItems="center">
                                          <TextField
                                            {...params}
                                            placeholder=""
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: ' ',
                                            }}
                                            helperText={null}
                                            fullWidth
                                          />
                                        </Box>
                                      )}
                                      value={column.value || ''}
                                      onChange={date => {
                                        const loadingPercentColumn = row.columns.find(
                                          column => column.fieldId === 'resourcePlan.loading_percent',
                                        );
                                        const loadingPercentValue = loadingPercentColumn
                                          ? loadingPercentColumn.value
                                          : null;
                                        handleChangeDate(
                                          rowIndex,
                                          columnIndex,
                                          date,
                                          column.fieldId,
                                          row.groupId,
                                          row.rowId,
                                          row.services?.startDate,
                                          row.services?.endDate,
                                          loadingPercentValue,
                                        );
                                      }}
                                      disabled={!canEdit}
                                    />
                                  </>
                                </Tooltip>
                              </LocalizationProvider>
                            </TableCell>
                          );
                        }

                        return (
                          <TableCell key={columnIndex} className={classes.tableCell} align="left">
                            <Tooltip title={column.value} PopperProps={{ className: classes.commentTooltip }}>
                              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                                {column.value}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </>
  );
};

export default injectIntl(ResourcePlanTable);
