import React from 'react';
import { SvgIcon } from '@mui/material';

function SearchIcon(props) {
  return (
    <SvgIcon {...props} style={props.direction === 'up' ? { transform: 'rotate(180deg)' } : {}}>
      <path
        d="M15.8536 12.4989L14.1672 14.1847L14.1784 8.28572C14.1784 7.96989 13.9228 7.71429 13.6069 7.71429C13.2922 7.71429 13.0355 7.96989 13.0355 8.28572L13.0244 14.1445L11.3793 12.4989C11.156 12.2762 10.7944 12.2762 10.5712 12.4989C10.348 12.7221 10.348 13.0843 10.5712 13.3075L12.9228 15.659C13.079 15.8661 13.3279 16 13.6069 16C13.8246 16 14.0221 15.9197 14.1728 15.7869C14.1951 15.7701 14.2163 15.7517 14.2364 15.7316L16.6616 13.3075C16.8848 13.0843 16.8848 12.7221 16.6616 12.4989C16.4384 12.2762 16.0768 12.2762 15.8536 12.4989Z"
        fill="#7174AC"
      />
      <path
        d="M3.14307 13.8287C3.14307 14.0809 3.34731 14.2857 3.60066 14.2857H8.11404C8.22341 14.2857 8.32387 14.2478 8.40198 14.1842C8.50581 14.1005 8.57164 13.9721 8.57164 13.8287V13.5999C8.57164 13.3477 8.36741 13.1429 8.11404 13.1429H3.60066C3.46784 13.1429 3.34843 13.1987 3.26472 13.2885C3.21784 13.3382 3.18324 13.3985 3.16316 13.4654C3.14976 13.5078 3.14307 13.553 3.14307 13.5999V13.8287Z"
        fill="#7174AC"
      />
      <path
        d="M3.43213 9.68194C3.3886 9.66463 3.34843 9.6412 3.31271 9.61217C3.20891 9.52845 3.14307 9.40068 3.14307 9.25725V9.02845C3.14307 8.77623 3.34731 8.57143 3.60066 8.57143H10.6855C10.9388 8.57143 11.1431 8.77623 11.1431 9.02845V9.25725C11.1431 9.50948 10.9388 9.71428 10.6855 9.71428H3.60066C3.54151 9.71428 3.48459 9.70257 3.43213 9.68194Z"
        fill="#7174AC"
      />
      <path
        d="M3.18548 4.87723C3.15869 4.81919 3.14307 4.75446 3.14307 4.68583V4.45703C3.14307 4.31138 3.21115 4.18136 3.31717 4.09766C3.3953 4.03627 3.49351 4 3.60065 4H15.8283C16.0817 4 16.2859 4.2048 16.2859 4.45703V4.68583C16.2859 4.93806 16.0817 5.14286 15.8283 5.14286H3.60065C3.41651 5.14286 3.25802 5.03404 3.18548 4.87723Z"
        fill="#7174AC"
      />
    </SvgIcon>
  );
}

export default SearchIcon;
