import React, { useCallback, useReducer, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { withTimesheetsRequests } from '../withTimesheetsRequests';
import { formatWeek } from '../formatWeek';

import SpreadsheetTable from '../SpreadsheetTable';
import SpreadsheetHeader from '../SpreadsheetHeader';
import SpreadsheetMobileCreate from '../SpreadsheetMobileCreate';
import SpreadsheetMobileDetail from '../SpreadsheetMobileDetail';
import SpreadsheetMobileEditIssue from '../SpreadsheetMobileEditIssue';

import Page from 'Common/shared-ui/src/components/Page';
import PlusIcon from 'Common/shared-ui/src/icons/PlusIcon';

import useStyles from './SpreadsheetContainer.styles';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';

const SpreadsheetContainer = ({
  handleUpdateSpreadsheet,
  handleSaveSpreadsheet,
  formattedIssues,
  setFormattedIssues,
  editedFormattedIssues,
  setEditedFormattedIssues,
  readOnlyProjectsData,
  query,
  setQuery,
  setWeekForward,
  setWeekAgo,
  timeTypes,
  tableFooterDaysSum,
  isLoading,
  intl,
  getAnotherProjects,
  formatIssues,
  timeType,
  setTimeType,
  setIsLoading,
  isError,
  setIsError,
}) => {
  const classes = useStyles();
  const pageTitle = intl.formatMessage({ id: 'page_title_spreadsheet' });
  const hideCheckboxLabel = intl.formatMessage({ id: 'spreadsheet_tooltip_hide_checkbox' });
  const startOfWeek = query.start;
  const endOfWeek = query.end;
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('lg'));
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const [anotherProjects, setAnotherProjects] = useState([]);
  const [formattedAnotherProjects, setFormattedAnotherProjects] = useState([]);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [warningModalType, setWarningModalType] = useState('');
  const [actionDirection, setActionDirection] = useState('');
  const [tableSum, setTableSum] = useState(0);
  const [showOnlyFilledIn, setShowOnlyFilledIn] = useState(false);

  const handleShowOnlyFilledIn = useCallback(() => {
    const hoursMap = new Map([
      ['MAIN_HOURS', 'hiddenMainHour'],
      ['OVER_HOURS', 'hiddenOvertimeHour'],
    ]);
    const totalHoursMap = new Map([
      ['MAIN_HOURS', 'totalNormalHours'],
      ['OVER_HOURS', 'totalOvertimeHours'],
    ]);
    Object.values(formattedIssues).map(issue => {
      const id = issue.issueUniqueId;
      formattedIssues[id][hoursMap.get(timeType)] =
        issue[totalHoursMap.get(timeType)] === 0 && !issue[hoursMap.get(timeType)];
      setShowOnlyFilledIn(!showOnlyFilledIn);
      forceUpdate();
    });
  }, [formattedIssues, showOnlyFilledIn, timeType]);

  const { current: openWarningModal } = useRef((direction = '') => {
    setActionDirection(direction);
    setIsWarningModalOpen(true);
  });

  const { current: closeWarningModal } = useRef(() => {
    setIsWarningModalOpen(false);
  });

  const getReadOnlyIssues = useCallback(() => {
    setIsLoading(true);
    const formattedNewIssues = formatIssues(readOnlyProjectsData.projects);
    setFormattedIssues(formattedNewIssues);
    setEditedFormattedIssues({});
    setIsLoading(false);
  }, [formatIssues, readOnlyProjectsData.projects, setEditedFormattedIssues, setFormattedIssues, setIsLoading]);

  const handleChangeTimeType = useCallback(
    async e => {
      setAnotherProjects([]);
      setIsError(false);
      setShowOnlyFilledIn(false);
      const anotherTimeType = timeType === timeTypes.MAIN_HOURS ? timeTypes.OVER_HOURS : timeTypes.MAIN_HOURS;
      const isHasEditedDays = Object.keys(editedFormattedIssues).map(editedIssueKey => {
        const issue = editedFormattedIssues[editedIssueKey];
        const editedDays = issue[timeType === timeTypes.MAIN_HOURS ? 'weekDays' : 'overTimeWeekDays'].filter(
          day => day.isEdited,
        );
        return editedDays?.length > 0;
      });
      if (isHasEditedDays.includes(true) && !isWarningModalOpen) {
        setWarningModalType('time');
        return openWarningModal();
      }
      if (timeTypes[e.currentTarget.value]) {
        if (timeTypes[e.currentTarget.value] === timeType && isSmallScreen) {
          getReadOnlyIssues();
          return setTimeType(anotherTimeType);
        }
        getReadOnlyIssues();
        return setTimeType(e.currentTarget.value);
      }
      if (e.currentTarget.value === 'cancel') {
        return closeWarningModal();
      }
      if (e.currentTarget.value === 'delete') {
        getReadOnlyIssues();
        setTimeType(anotherTimeType);
      }
      if (e.currentTarget.value === 'save') {
        closeWarningModal();
        const saveResult = await handleSaveSpreadsheet();
        saveResult && setTimeType(anotherTimeType);
      }
      return closeWarningModal();
    },
    [
      setIsError,
      timeType,
      timeTypes,
      editedFormattedIssues,
      isSmallScreen,
      isWarningModalOpen,
      closeWarningModal,
      getReadOnlyIssues,
      setTimeType,
      openWarningModal,
      handleSaveSpreadsheet,
    ],
  );

  const handleToggleWeek = useCallback(
    async e => {
      setIsError(false);
      const isHasEditedDays = Object.keys(editedFormattedIssues).map(issueKey => {
        const issue = editedFormattedIssues[issueKey];
        const editedDays = issue.weekDays.filter(day => day.isEdited);
        const editedOvertimeDays = issue.overTimeWeekDays.filter(day => day.isEdited);
        return editedDays?.length > 0 || editedOvertimeDays?.length > 0;
      });
      if (e.currentTarget.value === 'cancel') {
        setActionDirection('');
        return closeWarningModal();
      }
      if (isHasEditedDays.includes(true) && !isWarningModalOpen) {
        setWarningModalType('week');
        return openWarningModal(e.currentTarget.attributes.direction.nodeValue);
      }
      if (e.currentTarget.value === 'delete') {
        setEditedFormattedIssues({});
      }
      if (e.currentTarget.value === 'save') {
        closeWarningModal();
        const saveResult = await handleSaveSpreadsheet();
        // setEditedFormattedIssues({});
        if (!saveResult) {
          return;
        }
      }
      if (actionDirection) {
        if (actionDirection === 'left') {
          closeWarningModal();
          setActionDirection('');
          setWeekAgo();
        }
        if (actionDirection === 'right') {
          closeWarningModal();
          setActionDirection('');
          setWeekForward();
        }
        return;
      }
      if (!e.currentTarget.attributes.direction.nodeValue) {
        return;
      }
      if (e.currentTarget.attributes.direction.nodeValue === 'left') {
        return setWeekAgo();
      }
      if (e.currentTarget.attributes.direction.nodeValue === 'right') {
        return setWeekForward();
      }
    },
    [
      setIsError,
      editedFormattedIssues,
      isWarningModalOpen,
      actionDirection,
      closeWarningModal,
      openWarningModal,
      setEditedFormattedIssues,
      setWeekAgo,
      setWeekForward,
      handleSaveSpreadsheet,
    ],
  );

  const handleToggleWarningModal = useCallback(
    e => {
      if (warningModalType === 'time') {
        return handleChangeTimeType(e);
      }
      if (warningModalType === 'week') {
        return handleToggleWeek(e);
      }
    },
    [handleChangeTimeType, handleToggleWeek, warningModalType],
  );

  const getAnotherProjectsData = useCallback(
    async ({ start, end }) => {
      const anotherProjectsData = await getAnotherProjects({ start, end });
      const formattedIssues = formatIssues(anotherProjectsData);
      setAnotherProjects(anotherProjectsData);
      setFormattedAnotherProjects(formattedIssues);
    },
    [formatIssues, getAnotherProjects],
  );

  const handleCreateMobileRow = useCallback(async () => {
    setQuery({ create: true });
    await getAnotherProjectsData({ start: startOfWeek, end: endOfWeek });
  }, [endOfWeek, getAnotherProjectsData, setQuery, startOfWeek]);

  return (
    <Page title={pageTitle} className={classes.SpreadsheetContainerRoot}>
      {isSmallScreen && Boolean(query.create) && (
        <SpreadsheetMobileCreate
          pageTitle={pageTitle}
          anotherProjects={anotherProjects}
          formattedAnotherProjects={formattedAnotherProjects}
          formatWeek={formatWeek}
          getAnotherProjectsData={getAnotherProjectsData}
          setQuery={setQuery}
          query={query}
          intl={intl}
          setEditedFormattedIssues={setEditedFormattedIssues}
          handleSaveSpreadsheet={handleSaveSpreadsheet}
          timeType={timeType}
          timeTypes={timeTypes}
        />
      )}
      {isSmallScreen && Boolean(query.issue) && (
        <SpreadsheetMobileDetail
          query={query}
          setQuery={setQuery}
          formattedIssues={formattedIssues}
          timeType={timeType}
          timeTypes={timeTypes}
          intl={intl}
        />
      )}
      {isSmallScreen && Boolean(query.editIssue) && (
        <SpreadsheetMobileEditIssue
          query={query}
          setQuery={setQuery}
          formattedIssues={formattedIssues}
          timeType={timeType}
          timeTypes={timeTypes}
          intl={intl}
          setEditedFormattedIssues={setEditedFormattedIssues}
          handleSaveSpreadsheet={handleSaveSpreadsheet}
        />
      )}
      {!Boolean(query.create) && !Boolean(query.issue) && !Boolean(query.editIssue) && (
        <>
          <SpreadsheetHeader
            pageTitle={pageTitle}
            isWarningModalOpen={isWarningModalOpen}
            handleToggleWarningModal={handleToggleWarningModal}
            closeWarningModal={closeWarningModal}
            warningModalType={warningModalType}
            timeType={timeType}
            timeTypes={timeTypes}
            handleChangeTimeType={handleChangeTimeType}
            startOfWeek={startOfWeek}
            endOfWeek={endOfWeek}
            handleToggleWeek={handleToggleWeek}
            handleUpdateSpreadsheet={handleUpdateSpreadsheet}
            getReadOnlyIssues={getReadOnlyIssues}
            tableSum={tableSum}
          />
          {isError && (
            <Box marginBottom={3.5}>
              <Typography variant="h3" fontWeight={600} color="#E03737">
                <FormattedMessage id="table_error" />
              </Typography>
            </Box>
          )}
          {isLoading ? (
            <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
              <CircularProgress color="secondary" />
            </Grid>
          ) : (
            <>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" color="#656985" />}
                      checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                    />
                  }
                  label={hideCheckboxLabel}
                  labelPlacement="start"
                  className={classes.hideEmptyRows}
                  value={showOnlyFilledIn}
                  checked={showOnlyFilledIn}
                  onChange={handleShowOnlyFilledIn}
                />
              </Box>
              <SpreadsheetTable
                weekDataHeader={formatWeek({ startOfWeek, intl })}
                startOfWeek={startOfWeek}
                endOfWeek={endOfWeek}
                showOnlyFilledIn={showOnlyFilledIn}
                tableFooterDaysSum={tableFooterDaysSum}
                formattedIssues={formattedIssues}
                editedFormattedIssues={editedFormattedIssues}
                getAnotherProjectsData={getAnotherProjectsData}
                anotherProjects={anotherProjects}
                formattedAnotherProjects={formattedAnotherProjects}
                tableSum={tableSum}
                setTableSum={setTableSum}
                timeType={timeType}
                timeTypes={timeTypes}
                setQuery={setQuery}
                query={query}
                intl={intl}
              />
            </>
          )}
          {isSmallScreen && (
            <IconButton disableRipple className={classes.mobileCreate} onClick={handleCreateMobileRow}>
              <PlusIcon viewBox="0 0 24 24" width={24} height={24} color="#fff" />
            </IconButton>
          )}
        </>
      )}
    </Page>
  );
};

export default injectIntl(withTimesheetsRequests(SpreadsheetContainer));
