import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  buttonSwitcher: {
    backgroundColor: '#DCDFF4',
    borderRadius: theme.spacing(4),
    position: 'relative',
  },
  firstButton: {
    [theme.breakpoints.maxWidth('sm')]: {
      '&.MuiButton-defaultSecondary': {
        position: 'absolute',
        left: '-8px',
        backgroundColor: '#DCDFF4 !important',
        zIndex: 0,
      },
    },
  },
  secondButton: {
    [theme.breakpoints.maxWidth('sm')]: {
      '&.MuiButton-defaultSecondary': {
        position: 'absolute',
        right: '-8px',
        backgroundColor: '#DCDFF4 !important',
        zIndex: 0,
      },
    },
  },
}));

const ButtonSwitcher = ({ handleChangeFunc, values, selectedButtonIndex }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('lg'));
  // const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);

  const handleChangeButton = useCallback(
    e => {
      // setSelectedButtonIndex(prevState => (prevState === 0 ? 1 : 0));
      handleChangeFunc(e);
    },
    [handleChangeFunc],
  );

  return (
    <Box className={classes.buttonSwitcher} marginLeft={isSmallScreen && selectedButtonIndex === 1 && 2}>
      <Button
        className={clsx({ [classes.firstButton]: !!isSmallScreen })}
        onClick={handleChangeButton}
        data-type={0}
        variant="default"
        color={selectedButtonIndex === 0 ? 'primary' : 'secondary'}
        value={values.firstButton}
        disableRipple
      >
        <Typography variant={isSmallScreen ? 'h5' : 'h4'} fontWeight={600}>
          {values.firstButtonText()}
        </Typography>
      </Button>
      {values.secondButtonText && (
        <Button
          className={clsx({ [classes.secondButton]: !!isSmallScreen })}
          onClick={handleChangeButton}
          data-type={1}
          variant="default"
          color={selectedButtonIndex === 1 ? 'primary' : 'secondary'}
          value={values.secondButton}
          disableRipple
        >
          <Typography variant={isSmallScreen ? 'h5' : 'h4'} fontWeight={600}>
            {values.secondButtonText()}
          </Typography>
        </Button>
      )}
    </Box>
  );
};

ButtonSwitcher.propTypes = {
  values: PropTypes.shape({
    firstButton: PropTypes.string,
    firstButtonText: PropTypes.func,
    secondButton: PropTypes.string,
    secondButtonText: PropTypes.func,
  }).isRequired,
  handleChangeFunc: PropTypes.func.isRequired,
};

export default ButtonSwitcher;
