import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  gridCell: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  projectsListTableRoot: {
    maxHeight: '80vh !important',
    overflowY: 'auto',
    '&.MuiDataGrid-root': {
      backgroundColor: '#ffffff',
      borderRadius: theme.spacing(4),
      marginBottom: theme.spacing(4),
      border: 0,
      // overflow: 'hidden',
      '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor': {
        borderColor: 'transparent',
      },
      '& .MuiDataGrid-columnHeaders': {
        position: 'sticky !important',
        top: '0 !important',
        zIndex: 1000,
        backgroundColor: '#ffffff',
        '& .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
          '&:not(.MuiDataGrid-columnHeaderCheckbox)': {
            '& + .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
              borderLeft: '1px solid #E4E4EF',
            },
          },
          borderRight: 0,
          border: 'none',
        },
        '& .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor.MuiDataGrid-columnHeaderCheckbox': {
          minWidth: '36px!important',
          maxWidth: '36px!important',
          '& + .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
            paddingLeft: 0,
          },
        },
      },
      '& .MuiDataGrid-row': {
        width: '100%',
        borderTop: '1px solid #E4E4EF',
        '& .MuiDataGrid-cell': {
          borderLeft: '1px solid #E4E4EF',
        },
      },
      '& .MuiDataGrid-row .MuiDataGrid-cell:last-child': {
        borderRight: 0,
      },
      '& .MuiDataGrid-row .MuiDataGrid-cell:first-child': {
        borderLeft: 0,
      },
      '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: '#DCDFF4',
      },
      '& .MuiDataGrid-row.Mui-selected.Mui-hovered': {
        backgroundColor: '#DCDFF4',
      },
      '& .MuiDataGrid-virtualScrollerRenderZone': {
        position: 'relative',
      },
      '& .MuiDataGrid-virtualScrollerContent': {
        height: 'unset !important',
      },
      '& .MuiDataGrid-main': {
        overflow: 'unset !important',
      },
      '& .MuiDataGrid-main .MuiDataGrid-iconButtonContainer .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      },
      '& .MuiDataGrid-columnSeparator': {
        '& .MuiSvgIcon-root': {
          width: '24px',
        },
      },
      '& .MuiDataGrid-cell': {
        '&.tableCell': {
          borderColor: 'transparent',
          '&:last-child': {
            boxShadow: 0,
          },
        },
        '& a': {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
  },
  tableCell: {
    borderRight: '1px solid #E4E4EF',
    textAlign: 'center !important',
    padding: '8px !important',
    width: 220,
  },
  ProjectStatus: {
    color: '#37C670',
  },
  ProjectClosed: {
    color: '#E03737',
  },
  ProjectOpened: {
    color: '#37C670',
  },
}));

export default useStyles;
