import React, { memo } from 'react';
import { SvgIcon } from '@mui/material';

const ChevronIcon = props => {
  switch (props.direction) {
    case 'up': {
      return (
        <SvgIcon
          {...props}
          style={{ transform: 'rotate(90deg)', [props.right ? 'right' : '']: props.right ? props.right : '' }}
        >
          <path
            d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z"
            fill={props.color ? props.color : '#7174AC'}
          />
        </SvgIcon>
      );
    }
    case 'down': {
      return (
        <SvgIcon
          {...props}
          style={{ transform: 'rotate(-90deg)', [props.right ? 'right' : '']: props.right ? props.right : '' }}
        >
          <path
            d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z"
            fill={props.color ? props.color : '#7174AC'}
          />
        </SvgIcon>
      );
    }
    case 'right': {
      return (
        <SvgIcon
          {...props}
          style={{ transform: 'rotate(180deg)', [props.right ? 'right' : '']: props.right ? props.right : '' }}
        >
          <path
            d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z"
            fill={props.color ? props.color : '#7174AC'}
          />
        </SvgIcon>
      );
    }
    default: {
      return (
        <SvgIcon {...props}>
          <path
            d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z"
            fill={props.color ? props.color : '#7174AC'}
          />
        </SvgIcon>
      );
    }
  }
};

export default memo(ChevronIcon);
