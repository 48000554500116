import React, { useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { injectIntl } from 'react-intl';
import { getIssueHistory } from '../../../../../packages/common/api';
import { Link, useParams } from 'react-router-dom';
import issuePageStyles from '../../IssuePage.styles';
import AddPlusIcon from '../../../../../packages/common/shared-ui/src/icons/addPlusIcon';
import { generateRedirectUrl } from '../../../../../packages/common/utils/getRedirectUrl';
import { useSelector } from 'react-redux';

const IssueHistory = ({ intl }) => {
  const params = useParams();
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const issueCommonStyles = issuePageStyles();

  useEffect(() => {
    let ignore = false;
    async function getHistory() {
      const { data: history } = await getIssueHistory(params.id);
      if (!ignore) {
        return history;
      }
    }
    getHistory()
      .then(res => {
        setHistory(res);
        setLoading(false);
      })
      .catch(error => console.error(error, 'ERROR IN GET'));
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHistoryLink = useCallback(
    (rowId, valueId) => {
      let url;
      rowId.includes('project_id') && (url = `/projects/${valueId}`);
      rowId.includes('relates') && (url = `/issues/${valueId}`);
      (rowId.includes('assigned_to_id') || rowId.includes('total')) &&
        (url = generateRedirectUrl({
          host: window.location.origin,
          path: `/users/${valueId}`,
          redmineToken,
        }));
      return url;
    },
    [redmineToken],
  );

  const itemIsLink = useCallback((rowId, fieldId) => {
    const rows = ['relates', 'total', 'project_id', 'assigned_to_id'];
    const fields = ['history.author', 'history.valueFrom', 'history.valueTo'];
    return rows.some(item => rowId.includes(item)) && fields.some(item => fieldId.includes(item));
  }, []);

  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          <Grid container alignItems={'center'}>
            <Grid item>
              <Typography variant="h3" color={'#082253'} fontWeight={600}>
                {intl.formatMessage({ id: 'issue_page_history_title' })}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => setShowHistory(!showHistory)}
                sx={{ padding: '6px 4px' }}
                className={issueCommonStyles.addButton}
              >
                <AddPlusIcon
                  sx={{ transform: showHistory && 'rotate(180deg)' }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                />
              </IconButton>
            </Grid>
          </Grid>
          {history.length > 0 && (
            <>
              {showHistory && (
                <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                  {history.map((historyItem, index) => {
                    const boolRemoved = historyItem.columns.filter(item => item.fieldType !== 'bool');
                    return (
                      <Grid
                        key={'common-row-' + index}
                        item
                        marginTop={index > 0 && historyItem.rowType === 'total' ? '16px' : 0}
                        marginLeft={historyItem.rowType !== 'total' ? '16px' : 0}
                      >
                        <Typography variant="h4" sx={{ color: '#212346' }}>
                          {historyItem.rowType === 'total' && `${intl.formatMessage({ id: 'issue_page_updated' })} `}
                          {boolRemoved.map((item, index) => {
                            const stringExists = item.value
                              ? !!intl.messages[`issue_page_${item.value.toLowerCase().split(' ').join('_').trim()}`]
                              : false;
                            const stringValue = item.value ? item.value : '';
                            if (
                              itemIsLink(
                                historyItem.rowType === 'total' ? historyItem.rowType : historyItem.rowId,
                                item.fieldId,
                              )
                            ) {
                              return (
                                <Link
                                  key={'common-item-' + index}
                                  target={'_blank'}
                                  className={issueCommonStyles.issueLink}
                                  to={handleHistoryLink(
                                    historyItem.rowType === 'total' ? historyItem.rowType : historyItem.rowId,
                                    item.valueId,
                                  )}
                                  style={{
                                    fontWeight:
                                      item.fieldId === '2' ||
                                      item.fieldId === 'history.valueTo' ||
                                      item.fieldId === 'history.valueFrom'
                                        ? '600'
                                        : '400',
                                  }}
                                >
                                  {item.value}
                                </Link>
                              );
                            }
                            return (
                              <Box
                                key={'common-item-' + index}
                                component={'span'}
                                sx={{
                                  color: '#41424E',
                                  fontWeight:
                                    item.fieldId === '2' ||
                                    item.fieldId === 'history.valueTo' ||
                                    item.fieldId === 'history.valueFrom'
                                      ? '600'
                                      : '400',
                                  margin: '0 3px',
                                }}
                              >
                                {stringExists
                                  ? intl.formatMessage({
                                      id: `issue_page_${item.value.toLowerCase().split(' ').join('_').trim()}`,
                                    })
                                  : stringValue}
                              </Box>
                            );
                          })}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default injectIntl(IssueHistory);
