import React, { memo } from 'react';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import DateSwitcher from '../DateSwitcher';
import ButtonSwitcher from 'Common/shared-ui/src/components/ButtonSwitcher';
import WarningModal from 'Common/shared-ui/src/components/WarningModal';

const SpreadsheetHeader = ({
  pageTitle,
  isWarningModalOpen,
  handleToggleWarningModal,
  closeWarningModal,
  warningModalType,
  timeType,
  timeTypes,
  handleChangeTimeType,
  startOfWeek,
  endOfWeek,
  handleToggleWeek,
  handleUpdateSpreadsheet,
  getReadOnlyIssues,
  tableSum,
}) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('lg'));
  const intl = useIntl();
  return (
    <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap" marginBottom={5}>
      <Grid container alignItems="center">
        <WarningModal
          isWarningModalOpen={isWarningModalOpen}
          handleToggle={handleToggleWarningModal}
          closeWarningModal={closeWarningModal}
          type={warningModalType}
          title={intl.formatMessage({ id: 'save_changes' })}
        />
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
          direction={isSmallScreen ? 'column' : 'row'}
        >
          {isSmallScreen ? (
            <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
              <Typography variant="h1">{pageTitle}</Typography>
              {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
              <Typography variant="h4">
                <FormattedMessage id="spreadsheet_week_sum" /> {parseFloat(Number(tableSum).toFixed(1))}{' '}
                <FormattedMessage id="hour" />
              </Typography>
            </Grid>
          ) : (
            <Typography variant="h1">{pageTitle}</Typography>
          )}
          <Grid container justifyContent={isSmallScreen && 'space-between'} marginTop={isSmallScreen && 3}>
            <Box marginLeft={!isSmallScreen && 8}>
              <ButtonSwitcher
                selectedButtonIndex={timeType === timeTypes.MAIN_HOURS ? 0 : 1}
                handleChangeFunc={handleChangeTimeType}
                values={{
                  firstButton: timeTypes.MAIN_HOURS,
                  firstButtonText: () => <FormattedMessage id="spreadsheet_time_main" />,
                  secondButton: timeTypes.OVER_HOURS,
                  secondButtonText: () => <FormattedMessage id="spreadsheet_time_over" />,
                }}
              />
            </Box>
            <Box marginLeft={!isSmallScreen && 5}>
              <DateSwitcher dateFrom={startOfWeek} dateTo={endOfWeek} handleToggle={handleToggleWeek} />
            </Box>
          </Grid>
          {!isSmallScreen && (
            <Box display="flex" alignItems="center">
              <Box marginRight={0.5}>
                <Button disableRipple onClick={handleUpdateSpreadsheet} variant="cutBluePrimary">
                  <FormattedMessage id="save_text" defaultMessage="Save" />
                </Button>
              </Box>
              <Button disableRipple variant="cutGreySecondary" onClick={getReadOnlyIssues}>
                <Typography variant="h5">
                  <FormattedMessage id="spreadsheet_table_clear" defaultMessage="Clear" />
                </Typography>
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(SpreadsheetHeader);
