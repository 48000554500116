import React, { memo, useCallback, useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { LOCALES } from 'Common/shared-ui/src/i18n/locales';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { setAppLang } from '../../app/redux/reducers/userSlice';
import LogoIconBlack from 'Common/shared-ui/src/icons/LogoIconBlack';
import GlobusIcon from 'Common/shared-ui/src/icons/GlobusIcon';
import LanguageArrowIcon from 'Common/shared-ui/src/icons/LanguageArrowIcon';
import CustomSelect from 'Common/shared-ui/src/components/CustomSelect';
import useStyles from './RightContainer.styles';
import LogoIconBlackDoc from 'Common/shared-ui/public/images/logo_DocHub_b.png';
const RightContainer = ({ intl }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const appLang = useSelector(state => state.user.language);
  const selectLabel = intl.formatMessage({ id: 'select_language_label_text' });
  const menuItemRuName = intl.formatMessage({ id: 'auth_page_language_ru_text' });
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('md'));

  const langItems = useCallback(
    () =>
      Object.values({
        [LOCALES.RUSSIAN]: {
          id: LOCALES.RUSSIAN,
          name: menuItemRuName,
        },
        [LOCALES.ENGLISH]: {
          id: LOCALES.ENGLISH,
          name: 'English',
        },
      }),
    [menuItemRuName],
  );

  const { current: changeLang } = useRef(e => {
    dispatch(setAppLang(e.target.value));
  });

  return (
    <Grid container direction="column" className={classes.RightContainerRoot}>
      <Grid container alignItems="center" justifyContent="space-between" maxWidth={140} marginLeft="auto">
        <CustomSelect
          className={classes.selectMethod}
          value={appLang}
          items={langItems()}
          handleSelect={changeLang}
          IconComponent={LanguageArrowIcon}
          label={selectLabel}
        >
          <Box width={24} height={24}>
            <GlobusIcon viewBox="0 0 24 24" />
          </Box>
        </CustomSelect>
      </Grid>
      <Box margin="auto" maxWidth={242} display={isSmallScreen && 'none'}>
        <Box marginBottom={14}>
          <img width="242" src={LogoIconBlackDoc} />
        </Box>
        <Typography variant="h3">
          <FormattedMessage id="auth_page_info_text" />
        </Typography>
      </Box>
    </Grid>
  );
};

export default injectIntl(memo(RightContainer));
