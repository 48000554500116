import React from 'react';
import { SvgIcon } from '@mui/material';

function RadioButton(props) {
  return (
    <SvgIcon {...props}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#E4E4EF"
          d="M7 .333A6.67 6.67 0 00.333 7 6.67 6.67 0 007 13.667 6.67 6.67 0 0013.666 7 6.669 6.669 0 007 .333zm0 12A5.332 5.332 0 011.666 7 5.332 5.332 0 017 1.667 5.332 5.332 0 0112.333 7 5.332 5.332 0 017 12.333z"
        ></path>
      </svg>
    </SvgIcon>
  );
}

export default RadioButton;
