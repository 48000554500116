import React from 'react';
import { SvgIcon } from '@mui/material';

function LockIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_13476_5734)">
          <path
            d="M12 5.33334H11.3333V4.00001C11.3333 2.16001 9.84 0.666672 8 0.666672C6.16 0.666672 4.66667 2.16001 4.66667 4.00001V5.33334H4C3.26667 5.33334 2.66667 5.93334 2.66667 6.66667V13.3333C2.66667 14.0667 3.26667 14.6667 4 14.6667H12C12.7333 14.6667 13.3333 14.0667 13.3333 13.3333V6.66667C13.3333 5.93334 12.7333 5.33334 12 5.33334ZM8 11.3333C7.26667 11.3333 6.66667 10.7333 6.66667 10C6.66667 9.26667 7.26667 8.66667 8 8.66667C8.73333 8.66667 9.33333 9.26667 9.33333 10C9.33333 10.7333 8.73333 11.3333 8 11.3333ZM10.0667 5.33334H5.93333V4.00001C5.93333 2.86001 6.86 1.93334 8 1.93334C9.14 1.93334 10.0667 2.86001 10.0667 4.00001V5.33334Z"
            fill="#DCDFF4"
          />
        </g>
        <defs>
          <clipPath id="clip0_13476_5734">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
export default LockIcon;
