import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  mobileCreateField: {
    height: '100%',
    '& .MuiInputBase-root': {
      backgroundColor: '#fff',
      height: '100%',
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&.Mui-focused': {
        backgroundColor: '#fff',
      },
      '&.Mui-disabled': {
        backgroundColor: '#fff',
      },
      '& input': {
        fontSize: 14,
        textAlign: 'center',
        color: '#212346',
      },
    },
  },
  mobileCommentField: {
    '& .MuiInputBase-root': {
      '& input': {
        textAlign: 'left',
      },
    },
  },
  mobileCommentLabel: {
    '&.MuiInputLabel-root': {
      ...theme.typography.h4,
      top: -2,
      color: '#7174AC',
      '&[data-shrink="true"]': {
        ...theme.typography.h5,
        color: '#111322',
        top: -24,
        transform: 'unset',
      },
    },
  },
  clearButton: {
    '& .MuiSvgIcon-root': {
      zIndex: -1,
    },
  },
}));

export default useStyles;
