import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { injectIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { getIssueChildren } from '../../../../../packages/common/api';
import PlusIcon from '../../../../../packages/common/shared-ui/src/icons/PlusIcon';
import issuePageStyles from '../../IssuePage.styles';
import dayjs from 'dayjs';
import { store } from '../../../app/redux';
import ChevronIcon from '../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import ParentIssueDropdown from '../../../../../packages/common/shared-ui/src/icons/ParentIssueDropdown';

const IssueSubTasks = ({ intl, projectId, trackerId, permissions }) => {
  const params = useParams();
  const issueCommonStyles = issuePageStyles();
  const [subTasks, setSubTasks] = useState(null);
  const state = store.getState();

  useEffect(() => {
    let ignore = false;
    async function getSubTasks() {
      const { data: children } = await getIssueChildren(params.id);
      if (!ignore) {
        return children;
      }
    }
    getSubTasks()
      .then(res => {
        setSubTasks(res);
      })
      .catch(error => console.error(error, 'ERROR IN GET ISSUE'));
    return () => {
      ignore = true;
    };
  }, [params.id]);

  const RowCellComponent = useCallback(
    ({ type, rowId, value }) => {
      if (!value.valueName) {
        return '';
      }
      if (type === 'issue.assigned' || type === 'issue.subject') {
        const link =
          type === 'issue.assigned'
            ? `${window.location.origin}/administration/users/${value.valueId}`
            : `${window.location.origin}/issues/${rowId}`;
        return (
          <Link to={link} className={issueCommonStyles.rowLink}>
            {value.valueName}
          </Link>
        );
      }
      return (
        <Typography component="span" color={'#212346'} fontSize={'14px'} lineHeight={'20px'}>
          {type === 'issue.start' || type === 'issue.end'
            ? dayjs(value.valueName).format('DD.MM.YYYY')
            : value.valueName}
        </Typography>
      );
    },
    [issueCommonStyles.rowLink],
  );

  const goCreateTask = useCallback(() => {
    const url = state.user.project
      ? `/issues/${params.id}/create?project=${state.user.project.redmineId}&projectId=${projectId}`
      : `/issues/${params.id}/create?projectId=${projectId}`;
    window.open(url, '_blank');
  }, [params.id, projectId, state.user.project]);

  return (
    <>
      <Grid container alignItems={'center'}>
        <Grid item>
          <Typography variant="h3" color={'#082253'} fontWeight={600}>
            {intl.formatMessage({ id: 'issue_page_children_title' })}
          </Typography>
        </Grid>
        {permissions.canManageSubtasks && (
          <Grid item>
            <IconButton
              sx={{ padding: '6px 4px' }}
              className={issueCommonStyles.addButton}
              onClick={() => goCreateTask()}
            >
              <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {subTasks && subTasks.rows.length > 0 && (
        <TableContainer sx={{ marginTop: '10px', marginLeft: '-16px', paddingLeft: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                {subTasks.fields.map((item, index) => (
                  <TableCell
                    key={index}
                    sx={{ padding: index > 0 ? '0 16px 4px 16px' : '0 16px 4px 0', border: 'none' }}
                  >
                    <Typography variant="h4" color={'#212346'} fontWeight={600}>
                      {intl.formatMessage({
                        id: `issue_page_children_${item.field}`,
                      })}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {subTasks.rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.columns.map((column, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        position: 'relative',
                        padding:
                          index === 0
                            ? `4px 16px 4px ${row.services.level > 0 ? `${row.services.level * 16}px!important` : 0}`
                            : '4px 16px',
                        border: 'none',
                      }}
                    >
                      {index === 0 && row.services.hasSubtasks && (
                        <Box
                          component={'span'}
                          sx={{
                            position: 'absolute',
                            left: `${
                              row.services.level === 0
                                ? '-8px'
                                : row.services.level === 1
                                ? 0
                                : `${(row.services.level - 1) * 16}px`
                            }`,
                          }}
                        >
                          <ParentIssueDropdown></ParentIssueDropdown>
                        </Box>
                      )}
                      <RowCellComponent
                        type={column.fieldId}
                        rowId={row.rowId}
                        value={{ valueName: column.value, valueId: column.valueId }}
                      ></RowCellComponent>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default injectIntl(IssueSubTasks);
