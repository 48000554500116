import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  ResplanTableWrap: {
    backgroundColor: '#ffffff',
    borderRadius: '16px !important',
    border: 'none !important',
    maxHeight: '80vh !important',
    overflowY: 'auto',
    marginBottom: '50px',
    '& [class*=tableCell]:last-child': {
      borderRight: 0,
    },
    '& .MuiTableRow-root': {
      borderRadius: theme.spacing(4),
    },
    '& .MuiDataGrid-cell': {
      borderBottom: '1px solid #e4e4ef',
      borderRight: '1px solid #e4e4ef',
      borderTop: 0,
      padding: 0,
    },
    '& .MuiTableCell-root': {
      borderBottom: 0,
      borderTop: 0,
      padding: 0,
    },
    '& .MuiDataGrid-columnHeader': {
      borderRight: '1px solid #E4E4EF !important',
    },
    '& .MuiDataGrid-columnHeader:last-child': {
      borderRight: '0px solid #E4E4EF !important',
    },
    '& .MuiDataGrid-main': {
      overflow: 'unset !important',
      borderRadius: theme.spacing(4),
    },
    '& .MuiDataGrid-columnHeaders': {
      position: 'sticky !important',
      top: '0 !important',
      zIndex: 1000,
      backgroundColor: '#ffffff',
    },
    '& .MuiDataGrid-columnSeparator': {
      '& .MuiSvgIcon-root': {
        width: '24px',
      },
    },
  },
  contextMenuWrapper: {
    transform: 'unset !important',
    '& .MuiPaper-root': {
      maxWidth: '180px',
      maxHeight: 'auto',
      width: '100%',
      borderRadius: theme.spacing(4),
      padding: 0,
      // boxShadow: '0px 5px 10px -8px rgba(33, 35, 70, 1)',
    },
    '& .MuiBackdrop-root': {
      display: 'none',
      position: 'unset',
    },
  },
  contextMenuItem: {
    cursor: 'pointer',
    // transition: 'all .2s',
    paddingBottom: '0 !important',
    paddingTop: '0 !important',
    '&:hover': {
      backgroundColor: 'rgba(220, 223, 244, 1)',
      '& svg path': {
        fill: '#212346',
      },
    },
  },
  tableSettings: {
    backgroundColor: '#ffffff',
    '& .MuiTableCell-root': {
      // borderBottom: '1px solid #e4e4ef',
      borderTop: 0,
      padding: 0,
    },
    '& .MuiTableBody-root td:first-child': {
      borderRight: '1px solid #e4e4ef',
      paddingLeft: theme.spacing(3),
    },
    '& .MuiTableBody-root td:last-child': {
      borderLeft: '1px solid #e4e4ef',
    },
    '& .MuiTableBody-root': {
      // borderBottom: '1px solid #e4e4ef',
      borderTop: '1px solid #e4e4ef',
    },
  },
  tableHead: {
    backgroundColor: '#ffffff',
    borderRadius: theme.spacing(4),
    zIndex: 1000,
    position: 'sticky !important',
    top: '0 !important',
  },
  tableCellHead: {},
  tableRow: {
    height: 52,
    '& [class*=tableCell]:last-child': {
      borderRight: 0,
    },
  },
  tableCell: {
    // borderRight: '1px solid #E4E4EF',
    // width: 220,
    // paddingRight: '10px !important',
    // '& .MuiTypography-root': {
    //   width: 200,
    // },
    // minWidth: '150px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  dateCell: {
    // borderRight: '1px solid #E4E4EF',
    // minWidth: 100,
    // padding: '10px !important',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  perloadDateCell: {
    borderRight: '1px solid #E4E4EF',
    paddingTop: '2px !important',
    paddingBottom: '2px !important',
    paddingRight: '10px !important',
    paddingLeft: '10px !important',
    '& .MuiTypography-root': {
      width: 100,
      color: 'blue !important',
    },
    '& .MuiTableCell-root': {
      color: 'blue !important',
    },
    // borderBottom: '0 !important',
  },
  noBottom: {
    // borderBottom: '0 !important',
  },
  totalCell: {
    borderRight: '1px solid #E4E4EF',
    textAlign: 'center !important',
    padding: '8px !important',
    width: 220,
  },
  perloadCell: {
    // borderRight: '1px solid #E4E4EF',
    // width: 220,
    // '& .MuiTableCell-root': {
    //   padding: '0 !important',
    // },
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  groupIcon: {
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
      right: 10,
    },
  },
  optionsGroupMenu: {
    '&.MuiPopover-root': {
      zIndex: 12000,
    },
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(4),
      maxHeight: '40vh',
      overflow: 'auto',
      '& ul': {
        padding: 0,
      },
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  createSelect: {
    '&.MuiInputBase-root.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0 !important',
      },
      opacity: '70%',
    },
    border: '0px',
    paddingLeft: '10px',
    paddingRight: '5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '220px !important',
  },
  tableCellField: {
    position: 'relative',
    maxHeight: 20,
    '& .MuiInputBase-input, & .MuiOutlinedInput-notchedOutline': {
      width: '100%',
      height: 20,
      border: '0 !important',
      textAlign: 'left',
      padding: '0',
      marginLeft: '10px',
    },
    '& .MuiInputBase-root.Mui-disabled input': {
      '-webkit-text-fill-color': 'unset',
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      appearance: 'none',
      '-webkit-appearance': 'none',
    },
    '& input::placeholder': {
      color: '#7174AC',
    },
    '& .MuiOutlinedInput-input': {
      '&.Mui-disabled': {
        color: '#7174AC !important',
      },
    },
  },
  dateCellField: {
    position: 'relative',
    maxHeight: 20,
    '& .MuiInputBase-input, & .MuiOutlinedInput-notchedOutline': {
      width: '100%',
      height: 20,
      border: '0 !important',
      textAlign: 'right',
      padding: '0 10px',
      // marginRight: '10px',
    },
    '& .MuiInputBase-root.Mui-disabled input': {
      '-webkit-text-fill-color': 'unset',
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      appearance: 'none',
      '-webkit-appearance': 'none',
    },
    '& input::placeholder': {
      color: '#7174AC',
    },
    '& .MuiOutlinedInput-input': {
      '&.Mui-disabled': {
        color: '#7174AC !important',
      },
    },
  },
  commentTooltip: {
    '& .MuiTooltip-tooltip': {
      // marginTop: '0 !important',
      // marginLeft: '0 !important',
      // marginBottom: '0 !important',
      backgroundColor: '#F1F2F8',
      borderRadius: theme.spacing(2),
      padding: theme.spacing(2),
      display: 'flex',
      color: '#212346',
      boxShadow: '0px 5px 10px -8px',
      fontSize: '12px',
      left: '0 !important', // Изменено
    },
    '.MuiTooltip-popper': {
      marginTop: '0 !important',
      marginLeft: '0 !important',
      marginBottom: '0 !important',
      left: '0 !important', // Изменено
    },
    // Остальные стили...
  },

  tableCellCommentField: {
    '&:before': {
      content: '""',
      // width: 15,
      height: '100%',
      position: 'absolute',
      right: 1,
      top: 0,
      zIndex: 100,
      background: 'linear-gradient(to right, transparent -15px, white)',
    },
    '& .MuiInputBase-input, & .MuiOutlinedInput-notchedOutline': {
      ...theme.typography.h5,
      marginRight: 0,
      textAlign: 'left',
    },
  },
  dateCellCommentField: {
    '&:before': {
      content: '""',
      // width: 15,
      height: '100%',
      position: 'absolute',
      right: 1,
      top: 0,
      zIndex: 100,
      background: 'linear-gradient(to right, transparent -15px, white)',
    },
    '& .MuiInputBase-input, & .MuiOutlinedInput-notchedOutline': {
      ...theme.typography.h5,
      marginRight: 0,
      textAlign: 'right',
    },
  },
  commentTooltip: {
    '& .MuiTooltip-tooltip': {
      marginTop: '0 !important',
      backgroundColor: '#F1F2F8',
      borderRadius: theme.spacing(4),
      padding: theme.spacing(2),
      color: '#212346',
    },
  },
  datePickerPopper: {
    zIndex: '1990 !important',
  },
  datePicker: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },
    '& .MuiOutlinedInput-input': {
      '&.Mui-disabled': {
        color: '#7174AC !important',
        '-webkit-text-fill-color': 'unset',
      },
    },
  },
  tableCellLink: {
    '&:hover': {
      backgroundColor: '#DCDFF4',
      textDecoration: 'underline',
    },
  },
  redText: {
    color: 'red !important',
    '& .MuiOutlinedInput-root': {
      color: 'red !important',
    },
    '& .MuiTableCell-root': {
      color: 'red !important',
    },
    '& .MuiTypography-root': {
      color: 'red !important',
    },
  },
  blackText: {
    '& .MuiTableCell-root': {
      color: 'black !important',
    },
    '& .MuiTypography-root': {
      color: 'black !important',
    },
  },
  blueText: {
    '& .MuiTableCell-root': {
      color: 'blue !important',
    },
    '& .MuiTypography-root': {
      color: 'blue !important',
    },
  },
  blockedText: {
    '& .MuiTypography-root': {
      color: '#7174AC !important',
    },
  },
  redBorder: {
    borderRight: '1px solid red !important',
    borderLeft: '1px solid red !important',
    borderTop: '1px solid red !important',
    borderBottom: '1px solid red !important',
    padding: '10px !important',
  },
  addRowButton: {
    padding: '0 !important',
  },
}));

export default useStyles;
