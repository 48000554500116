import { TextField } from '@mui/material';
import React, { memo, useCallback } from 'react';
import { injectIntl } from 'react-intl';

const TextFieldMemo = memo(function TextFieldMemo({ intl, label, value, disabled, onChange }) {
  const handleChange = useCallback(
    e => {
      onChange(e.target.value);
    },
    [onChange],
  );
  return (
    <TextField
      onChange={handleChange}
      disabled={disabled}
      value={value}
      label={label}
      placeholder={intl.formatMessage({ id: 'enter' })}
      fullWidth
      variant="outlined"
      required
      InputLabelProps={{ shrink: false }}
      className={'formTextField labelLeft'}
    />
  );
});
export default injectIntl(TextFieldMemo);
