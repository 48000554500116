import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  filterWrap: {
    display: 'inline-flex !important',
    width: '177px !important',
    margin: '0 8px !important',
    verticalAlign: 'top',
    marginBottom: '10px !important',
    '& .MuiAutocomplete-input': {
      position: 'relative !important',
    },
    '& .autoCompletePopper': {
      position: 'relative !important',
    },
    '& defaultFirstStepPaper': {
      position: 'relative !important',
    },
    '& .MuiFormControl-root': {
      margin: '0px !important',
    },
  },
  filterWrapStatus: {
    width: 'auto !important',
  },
  filterWrapDisabledInput: {
    '& .MuiBox-root': {
      // width: '100%',
    },
  },
  defaultFirstStepPaper: {
    maxWidth: 'unset',
    position: 'absolute',
    top: 410,
    left: 0,
  },
  CustomFilter: {
    display: 'inline-flex !important',
    width: '177px !important',
    margin: '0 8px !important',
    verticalAlign: 'top',
    marginBottom: '10px !important',
    '& .MuiFormControl-root': {
      margin: '0px !important',
    },
  },
  FilterItem: {
    backgroundColor: '#FFFFFF !important',
    borderRadius: '16px !important',
    display: 'flex !important',
    alignItems: 'center',
    margin: '0px !important',
    color: '#7174AC !important',
    fontWeight: 'normal !important',
    width: '100%',
    justifyContent: 'left !important',
    boxShadow: 'none !important',

    '& .MuiSelect-icon': {
      position: 'relative',
      width: '24px',
    },
    '& .MuiSelect-select': {
      padding: '0',
      fontSize: '12px',
      borderRadius: '16px !important',
      backgroundColor: '#FFFFFF !important',
    },
    '& .MuiToggleButtonGroup-root': {
      fontSize: '12px',
      '& .Mui-selected': {
        background: '#FFFFFF',
        fontSize: '12px',
        border: '1px solid #E4E4EF',
        borderRadius: '16px',
      },
    },
  },
  FilterItemActive: {
    border: '1px solid #3448FF',
    color: '#212346 !important',
    '&.MuiButtonBase-root.MuiButton-root': {
      border: '1px solid #3448FF',
      color: '#212346 !important',
    },
  },
  menuItem: {
    fontSize: '12px !important',
    '&.Mui-selected': {
      fontSize: '12px',
      backgroundColor: '#DCDFF4 !important',
    },
  },
  FilterItemPopupTtlWrp: {
    borderBottom: '1px solid #E4E4EF',
  },
  FilterItemPopupTtl: {
    fontSize: '12px !important',
  },
  FilterItemTextField: {
    color: '#7174AC !important',
    padding: '6px 16px!important',
  },
  FilterItemExtra: {
    fontSize: '12px !important',
    display: 'flex',
    background: '#FFFFFF!important',
    border: '1px solid #E4E4EF!important',
    borderRadius: '16px!important',
    padding: '2px 16px !important',
    height: 'auto !important',
    '& .MuiPopover-paper': {
      borderRadius: '16px !important',
    },
    '& .MuiButtonBase-root': {
      fontSize: '12px !important',
      '& .Mui-selected': {
        fontSize: '12px',
        backgroundColor: '#DCDFF4 !important',
      },
    },
    '& .MuiSelect-icon': {
      position: 'relative',
      width: '24px',
      top: '0',
    },
    '& .MuiSelect-select': {
      padding: '0',
      fontSize: '12px',
      backgroundColor: '#FFFFFF !important',
    },
    '& .MuiToggleButtonGroup-root': {
      '& .Mui-selected': {
        background: '#FFFFFF',
        fontSize: '12px',
        border: '1px solid #E4E4EF',
        borderRadius: '16px',
      },
    },
  },
  StatusFilterItem: {
    backgroundColor: '#ffffff !important',
    border: '0px !important',
    height: '40px',
    borderRadius: '16px !important',
    maxWidth: '100%',
    '& .Mui-selected': {
      backgroundColor: '#dcdff4 !important',
      border: '1px solid #E4E4EF',
      padding: '10px  !important',
      borderRadius: '16px !important',
    },
    '& .MuiToggleButtonGroup-grouped': {
      background: '#ffffff',
      color: '#7174AC',
      borderRadius: '16px',
      border: '0px !important',
      padding: '10px 7px',
    },
  },

  FormControl: {
    background: '#FFFFFF',
    borderRadius: '16px',
    display: 'flex',
    padding: '10px',
    alignItems: 'center',
  },
  FilterItemPopup: {
    padding: '0px',
    position: 'relative',
    background: '#FFFFFF',
    border: '1px solid #E4E4EF',
    boxShadow: '0px 5px 10px -8px #212346',
  },
  exclusionMenu: {
    width: '276px !important',
    height: 246,
    padding: theme.spacing(5),
    borderRadius: theme.spacing(4),
    backgroundColor: '#F1F2F8',
  },
  popper: {
    zIndex: '1999 !important',
  },
  FullWidth: {
    width: '100%',
  },
  popperRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    transform: 'unset',
    backgroundColor: 'rgba(31, 33, 72, 0.15)',
    zIndex: '1999 !important',
  },
  newExceptionField: {
    backgroundColor: '#ffffff',
    borderRadius: theme.spacing(4),
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      appearance: 'none',
      '-webkit-appearance': 'none',
    },
    '& input': {
      ...theme.typography.h5,
    },
  },
  newExceptionDatePicker: {
    borderRadius: theme.spacing(4),

    backgroundColor: '#ffffff',
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
    },
    '& .MuiInputAdornment-root': {
      marginLeft: 0,
    },
    '& fieldset': {
      border: '0 !important',
    },
    '& input': {
      padding: '0 0 0 10px !important',
      ...theme.typography.h5,
    },
  },
  paperRoot: {
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
}));

export default useStyles;
