/* eslint-disable no-cyrillic-string/no-cyrillic-string */
import React from 'react';
import { Grid, Typography, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import UncheckedRadioIcon from '../../../../packages/common/shared-ui/src/icons/UncheckedRadioIcon';
import CheckedRadioIcon from '../../../../packages/common/shared-ui/src/icons/CheckedRadioIcon';
import { FormattedMessage } from 'react-intl';

const ResourcePlanUndefinedError = ({ setIsClearing, setIsSaving, setShowUndefinedError }) => {
  const [optionValue, setOptionValue] = React.useState('save_all');
  const handleClick = () => {
    if (optionValue === 'save_all') {
      setIsSaving(true);
    } else if (optionValue === 'cancel_changes') {
      setIsClearing(true);
    }
    setShowUndefinedError(false);
  };
  return (
    <Grid container direction="column" marginBottom={2}>
      <Typography variant="h3" fontWeight={600} color="#E03737">
        Произошла ошибка
      </Typography>
      <Grid container marginLeft={4} wrap="nowrap" direction="column" spacing={0}>
        <RadioGroup value={optionValue} onChange={e => setOptionValue(e.target.value)} defaultValue={0}>
          <FormControlLabel
            value="save_all"
            control={
              <Radio
                disableRipple
                icon={<UncheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                checkedIcon={<CheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
              />
            }
            label="Сохранить мои изменения"
          />
          <FormControlLabel
            value="cancel_changes"
            control={
              <Radio
                disableRipple
                icon={<UncheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                checkedIcon={<CheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
              />
            }
            label="Отменить мои изменения"
          />
        </RadioGroup>
        <Grid>
          <Button variant="defaultBluePrimary" disableRipple onClick={handleClick}>
            <Typography variant="h4" fontWeight={600}>
              <FormattedMessage id="resPlan_accept_saving" />
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResourcePlanUndefinedError;
