import { makeStyles } from '@mui/styles';

const userPageRatesTabStyles = makeStyles(theme => ({
  rowHeader: {
    padding: '18px 16px',
    fontWeight: 'bold',
    flex: 1,
    '& + .MuiGrid-root': {
      borderLeft: '1px solid #E4E4EF',
    },
  },
  rowItem: {
    borderTop: '1px solid #E4E4EF',
  },
  rowCell: {
    padding: '10px',
    fontSize: '14px',
    flex: 1,
    '& + .MuiGrid-root': {
      borderLeft: '1px solid #E4E4EF',
    },
    '& .MuiFormControl-root': {
      height: '100%',
    },
  },
  paperDropdown: {
    '&.MuiPaper-root.MuiPopover-paper.MuiMenu-paper': {
      maxHeight: '300px',
      borderRadius: '0 0 16px 16px',
      borderTop: '1px solid #E4E4EF',
      boxShadow: '0px 5px 10px -8px #A0A1AA66',
      '&::-webkit-scrollbar': {
        width: '2px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#7174AC',
        borderRadius: '5px',
      },
      '& .MuiList-root.MuiMenu-list': {
        padding: '0',
        '& li': {
          fontSize: '14px',
          lineHeight: '20px',
          color: '#212346',
          '&.MuiButtonBase-root.MuiMenuItem-root': {
            '&:hover': {
              backgroundColor: '#DCDFF4',
            },
          },
        },
      },
    },
  },
  rowSelect: {
    '&.MuiInputBase-root.MuiInput-root': {
      marginTop: 0,
      width: '100%',
      fontSize: '14px',
      lineHeight: '20px',
      height: '100%',
      padding: '10px',
      '&::before': {
        borderBottom: 'none',
      },
      '&:hover': {
        '&::before': {
          borderBottom: 'none',
        },
      },
      '& .MuiSelect-select': {
        padding: 0,
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      '& .MuiSvgIcon-root.MuiSelect-icon': {
        width: '20px',
        height: '20px',
        position: 'static',
      },
      '& .MuiSelect-nativeInput': {
        padding: '0',
      },
    },
  },
  selectLabel: {
    '&.MuiFormLabel-root.MuiInputLabel-root': {
      color: '#7174AC',
      fontSize: '14px',
      lineHeight: '20px',
      marginTop: '-3px',
      transform: 'none',
      left: '10px',
      top: '15px',
      '&.Mui-focused': {
        transform: 'none',
        left: '10px',
        top: '15px',
      },
      '&.MuiFormLabel-filled': {
        display: 'none',
      },
    },
  },
  datePicker: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      height: '100%',
      '& .MuiInputBase-input.MuiOutlinedInput-input': {
        padding: 0,
        color: '#7174AC',
        fontSize: '14px',
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  rowInput: {
    '& .MuiInputBase-root.MuiInput-root': {
      fontSize: '14px',
      lineHeight: '20px',
      '&::before': {
        borderBottom: 'none',
      },
      '&:hover': {
        '&::before': {
          borderBottom: 'none',
        },
      },
      '& .MuiInputBase-input.MuiInput-input': {
        color: '#7174AC',
        padding: 0,
        '&::placeholder': {
          opacity: 1,
        },
      },
    },
  },
  rowButton: {
    '&.MuiButtonBase-root.MuiIconButton-root': {
      borderLeft: '1px solid #E4E4EF',
      height: '100%',
      borderRadius: 0,
      minWidth: '32px',
    },
    '& .MuiSvgIcon-root': {
      height: 'auto',
    },
  },
}));

export default userPageRatesTabStyles;
