import { injectIntl } from 'react-intl';
import React, { useCallback, useEffect, useState } from 'react';
import { getUsersData, getUsersFilters } from '../../../../packages/common/api';
import SortIcon from '../../../../packages/common/shared-ui/src/icons/SortIcon';
import {
  DataGridPro,
  gridPageCountSelector,
  GridPagination,
  useGridSelector,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import Page from '../../../../packages/common/shared-ui/src/components/Page';
import { Autocomplete, Box, CircularProgress, Grid, MenuItem, Popper, TextField, Typography } from '@mui/material';
import { getTranslatedText } from '../../../../packages/common/utils/getTranslatedText';
import useStyles from './UsersList.styles';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import SubmitIcon from '../../../../packages/common/shared-ui/src/icons/SubmitIcon';
import { Link } from 'react-router-dom';
import ClearIcon from '../../../../packages/common/shared-ui/src/icons/ClearIcon';
import MuiPagination from '@mui/material/Pagination';
import { PaginationItem } from '@mui/material';
import PaginationNextButtonIcon from '../../../../packages/common/shared-ui/src/icons/PaginationNextButtonIcon';
import PaginationPreviousButtonIcon from '../../../../packages/common/shared-ui/src/icons/PaginationPreviousButtonIcon';

const UsersList = ({ intl }) => {
  const classes = useStyles();
  const pageTitle = intl.formatMessage({ id: 'menu_item_administration_users' });
  const [usersColumns, setUsersColumns] = useState([]);
  const [usersRows, setUsersRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [allFilters, setAllFilters] = useState([]);
  useEffect(() => {
    let ignore = false;
    async function getFilters() {
      const { data: filters } = await getUsersFilters();
      const { filters: data } = filters;
      const updatedArray = {
        ...data[1],
        values: data[1].values.map(item => {
          return { ...item, valueName: intl.formatMessage({ id: `users_filter_${item.valueName}` }) };
        }),
      };
      if (!ignore) {
        setAllFilters([...data.slice(0, 1), updatedArray, ...data.slice(2)]);
      }
    }
    getFilters().then(() => {});
    return () => {
      ignore = true;
    };
  }, [intl]);
  useEffect(() => {
    setLoading(true);
    const filters = selectedFilter ? { filters: Object.values(selectedFilter).join('$$') } : null;
    try {
      getUsersData(filters).then(response => {
        setUsersRows(response.data.rows.filter(item => item.rowId !== 'total'));
        setUsersColumns(response.data.fields);
        setLoading(false);
      });
    } catch (error) {
      console.error(error, 'ERROR IN GET USERS');
    }
  }, [selectedFilter]);

  const cellRenderer = useCallback(({ props, column }) => {
    const linkFields = ['user', 'email'];
    if (linkFields.includes(column.field)) {
      return <Link to={`${window.location.origin}/administration/users/${props.row.id}`}>{props.formattedValue}</Link>;
    }
    if (column.field === 'admin') {
      // TODO: добавить boolean на бэке
      // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
      return props.value === 'Да' && <SubmitIcon></SubmitIcon>;
    }
  }, []);

  const formattedUsersColumns = useCallback(() => {
    const smallFields = ['date', 'bool'];
    return usersColumns.map(column => {
      return {
        field: column.fieldId,
        headerName: getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName),
        renderHeader: props => (
          <Typography variant="h4" fontWeight={700}>
            {props.colDef.headerName}
          </Typography>
        ),
        disableExport: false,
        position: column.position,
        flex: smallFields.includes(column.fieldType) ? 0 : 1,
        width: smallFields.includes(column.fieldType) && 150,
        renderCell: props => cellRenderer({ props, column }),
        cellClassName: props => (props.field === 'users.admin' ? 'tableCell textCenter' : 'tableCell'),
      };
    });
  }, [cellRenderer, intl, usersColumns]);

  const usersColumnsValue = formattedUsersColumns();

  const formattedUsersRows = useCallback(
    () =>
      usersRows.reduce((acc, row) => {
        const newCols = row.columns.reduce((columnAcc, _, i) => {
          return {
            ...columnAcc,
            [row.columns[i].fieldId]: row.columns[i].value,
          };
        }, {});
        return [...acc, { ...newCols, id: row.rowId, active: row.services.active }];
      }, []),
    [usersRows],
  );

  const usersRowsValue = formattedUsersRows();

  const handleSelect = useCallback((event, value, type) => {
    if (!value) {
      setSelectedFilter(prev => {
        const state = { ...prev };
        delete state[type];
        if (Object.values(state).length === 0) {
          return null;
        }
        return state;
      });
      return;
    }
    const text = `${type};eq;${value.valueId}`;
    setSelectedFilter(prev => ({ ...prev, [type]: text }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FilterSelect = useCallback(
    ({ type, label, items }) => {
      return (
        <Autocomplete
          onChange={(event, newValue) => handleSelect(event, newValue, type)}
          className={classes.filterSelect}
          disablePortal
          options={items}
          sx={{ width: 180 }}
          clearIcon={<ClearIcon></ClearIcon>}
          renderOption={(props, option) => {
            return (
              <MenuItem {...props} key={option.valueId} value={option.valueId}>
                {option.valueName}
              </MenuItem>
            );
          }}
          getOptionLabel={option => option.valueName}
          PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
          renderInput={params => (
            <TextField {...params} label={label} placeholder={intl.formatMessage({ id: 'enter' })} />
          )}
          popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
        />
      );
    },
    [classes.filterSelect, handleSelect, intl],
  );
  function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        siblingCount={1}
        boundaryCount={0}
        page={page + 1}
        renderItem={item => {
          if (item.type === 'next') {
            return (
              !item.disabled && (
                <Box>
                  {intl.formatMessage({ id: 'pagination_next' })}
                  <PaginationItem
                    {...item}
                    slots={{
                      next: PaginationNextButtonIcon,
                    }}
                  ></PaginationItem>
                </Box>
              )
            );
          } else if (item.type === 'previous') {
            return (
              !item.disabled && (
                <Box>
                  <PaginationItem
                    {...item}
                    slots={{
                      previous: PaginationPreviousButtonIcon,
                    }}
                  ></PaginationItem>
                  {intl.formatMessage({ id: 'pagination_previous' })}
                </Box>
              )
            );
          } else {
            return <PaginationItem {...item}></PaginationItem>;
          }
        }}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
        }}
      />
    );
  }
  function CustomPagination(props) {
    return (
      <GridPagination
        rowsPerPage={20}
        labelDisplayedRows={() => ''}
        rowsPerPageOptions={[]}
        ActionsComponent={Pagination}
        {...props}
      />
    );
  }
  return (
    <Page title={pageTitle} width="100%">
      <Grid container spacing={5} sx={{ mb: '30px' }}>
        <Grid item>
          <Typography m={0} padding={'10px 0'} variant="h1">
            {pageTitle}
          </Typography>
        </Grid>
        {allFilters &&
          allFilters.map(
            (filter, index) =>
              filter.field !== 'group' && (
                <Grid item key={index}>
                  <FilterSelect
                    items={filter.values}
                    type={`${filter.instance}.${filter.field}`}
                    label={getTranslatedText(intl, 'filter', filter.fieldType, filter.fieldName)}
                  ></FilterSelect>
                </Grid>
              ),
          )}
      </Grid>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <DataGridPro
          pagination
          autoHeight
          disableRowSelectionOnClick
          disableColumnMenu
          columns={usersColumnsValue}
          rows={usersRowsValue}
          getRowId={item => item.id}
          getRowClassName={params => !params.row.active && 'disabled'}
          isRowSelectable={params => !params.row.groupName}
          className={classes.SpentTimeTableRoot}
          pageSize={10}
          slots={{
            pagination: CustomPagination,
            columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
            columnSortedAscendingIcon: () => <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />,
            columnSortedDescendingIcon: () => <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />,
          }}
          rowHeight={40}
        />
      )}
    </Page>
  );
};

export default injectIntl(UsersList);
