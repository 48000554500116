import React, { useCallback, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Box, Button, CircularProgress, Grid, IconButton, Pagination, Typography } from '@mui/material';
import ButtonSwitcher from 'Common/shared-ui/src/components/ButtonSwitcher';
import Page from 'Common/shared-ui/src/components/Page';
import SpentTimeTable from '../SpentTimeTable';
import SpentTimeModal from '../SpentTimeModal';
import SpentTimeQueryModal from '../SpentTimeQueryModal';
import { withSpentTimeRequests } from '../withSpentTimeRequests';
import FilterIcon from 'Common/shared-ui/src/icons/FilterIcon';
import ChevronIcon from 'Common/shared-ui/src/icons/ChevronIcon';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import useStyles from './SpentTimeContainer.styles';
import { useLocation, useNavigate } from 'react-router';
import WarningModal from '../../../../packages/common/shared-ui/src/components/WarningModal';
import { getTimesheetCsv, deleteSavedQuery, getIssuesCsv } from '../../../../packages/common/api';
import { useSelector } from 'react-redux';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import DownloadButton from '../../../../packages/common/shared-ui/src/icons/DownloadButton';

const SpentTimeContainer = ({
  isAddButtonDisplayed,
  isLoading,
  isOptionsFetching,
  allFilters,
  timesheetsData,
  timesheetsColumns,
  tableErrorMessage,
  approveOrRejectTimesheets,
  handleChangeSpentTimeType,
  approveOrRejectOvertime,
  handleDeleteTimesheet,
  tableSelectedValues,
  setTableSelectedValues,
  updateTable,
  setIsLoading,
  currentType,
  setQuery,
  query,
  intl,
}) => {
  const classes = useStyles();
  const pageTitle = intl.formatMessage({ id: 'page_title_spentTime' });
  const { state: locationState = null, search } = useLocation();
  const navigate = useNavigate();
  const currentUser = useSelector(state => state.user.info);

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isQueryOpen, setIsQueryOpen] = useState(false);
  const [optionsParams, setOptionsParams] = useState({});
  const [queryModalTitle, setQueryModalTitle] = useState('');
  const [isNewQuery, setIsNewQuery] = useState(true);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [downloadLoading, setDownloadLoading] = useState(false);

  const handleOpenWarningModal = () => setIsDeleteConfirmOpen(true);
  const closeWarningModal = () => setIsDeleteConfirmOpen(false);

  const handleToggleDelete = useCallback(
    async e => {
      switch (e.currentTarget.attributes.value.value) {
        case 'save':
          await deleteSavedQuery({ id: query.savedQueryId });
          navigate('/spentTime');
          closeWarningModal();
          break;
        case 'delete':
          closeWarningModal();
          break;
      }
    },
    [query.savedQueryId, navigate],
  );

  const handleOpenOptions = useCallback(() => setIsOptionsOpen(true), []);
  const handleCloseOptions = useCallback(() => setIsOptionsOpen(false), []);

  const handleOpenQuery = useCallback(({ title, isClear }) => {
    setQueryModalTitle(title);
    setIsNewQuery(isClear);
    setIsQueryOpen(true);
  }, []);
  const handleCloseQuery = useCallback(() => setIsQueryOpen(false), []);

  const handlePaginate = useCallback(
    (e, page) => {
      setIsLoading(true);
      setQuery({ page: page });
    },
    [setIsLoading, setQuery],
  );

  const formattedTimesheetsData = useCallback(
    () =>
      timesheetsData.reduce((acc, timesheet) => {
        if (timesheet.rowType === 'normal') {
          const newCols = timesheet.columns.reduce((columnAcc, _, i) => {
            switch (timesheet.columns[i].fieldId) {
              case 'timesheet.project':
              case 'timesheet.issue':
              case 'timesheet.user':
                return {
                  ...columnAcc,
                  [timesheet.columns[i]
                    .fieldId]: `${timesheet.columns[i].value}$$${timesheet.columns[i].valueId}$$${timesheet.columns[i].fieldType}`,
                };
              default:
                return {
                  ...columnAcc,
                  [timesheet.columns[i].fieldId]: timesheet.columns[i].value,
                };
            }
          }, {});
          return [...acc, { ...newCols, id: timesheet.rowId }];
        }
        if (timesheet.rowType === 'group') {
          const newCols =
            timesheet.columns.length > 0
              ? timesheet.columns.reduce((columnAcc, _, i) => {
                  return {
                    ...columnAcc,
                    [timesheet.columns[i].fieldId]: timesheet.columns[i].value,
                    issueId: `${timesheet.columns[i].value}`,
                    groupName: timesheet.rowName,
                  };
                }, {})
              : {
                  groupName: timesheet.rowName,
                };
          return [...acc, { ...newCols, id: timesheet.rowId, renderCell: props => console.log(props) }];
        }
        return [];
      }, []),
    [timesheetsData],
  );

  const handleGetRedirectUrl = useCallback(({ id, columnType }) => {
    switch (columnType) {
      case 'user':
        return generateRedirectUrl({ host: window.location.origin, path: `/users/${id}` });
      case 'project':
        return `${window.location.origin}/projects/${id}`;
      case 'issue':
        return generateRedirectUrl({ host: window.location.origin, path: `/issues/${id}` });
      default:
        return '';
    }
  }, []);

  const [contextMenuData, setContextMenuData] = useState({
    isSecondStepVisible: false,
    secondPopoverStepValue: null,
    currentRow: {
      services: {
        isApproved: '',
        isOvertime: false,
        canApprove: false,
        canEdit: false,
        isProjectClosed: false,
      },
    },
  });
  const downloadCsv = useCallback(
    e => {
      async function downloadTimesheetCsv() {
        try {
          setDownloadLoading(true);
          let query = searchParams.toString();
          query = query.replaceAll(/ThisWeek|LastWeek|LastTwoWeeks|ThisMonth|LastMonth|ThisYear/gi, 'btw');
          query = query.replaceAll('Today', 'eq');
          if (query?.length > 0) {
            const { data } = await getTimesheetCsv(query);
            const url = window.URL.createObjectURL(new Blob(['\ufeff', data], { type: 'text/csv;charset=utf-8;' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `spentTime-${dayjs().format('YYYY-MM-DD')}`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
          }
        } catch (e) {
          console.error(e);
        } finally {
          setDownloadLoading(false);
        }
      }
      downloadTimesheetCsv();
    },
    [searchParams, setDownloadLoading],
  );

  const handleAddTimeSheet = useCallback(() => {
    const url = `/spentTime/create${search}`;
    window.open(url, '_blank');
  }, [search]);

  return (
    <Page title={pageTitle} width="100%">
      <WarningModal
        simple
        isWarningModalOpen={isDeleteConfirmOpen}
        handleToggle={handleToggleDelete}
        closeWarningModal={closeWarningModal}
        title={`${intl.formatMessage({ id: 'spent_time_delete' })}?`}
      />
      {queryModalTitle && isQueryOpen && (
        <SpentTimeQueryModal
          locationState={locationState}
          isNewQuery={isNewQuery}
          query={query}
          isModalOpen={isQueryOpen}
          closeModal={handleCloseQuery}
          title={queryModalTitle}
        />
      )}
      {allFilters.hasOwnProperty('groups') && (
        <SpentTimeModal
          currentType={currentType}
          timesheetsColumns={timesheetsColumns}
          intl={intl}
          isModalOpen={isOptionsOpen}
          closeModal={handleCloseOptions}
          allFilters={allFilters}
          optionsParams={optionsParams}
          setOptionsParams={setOptionsParams}
          updateTable={updateTable}
          setIsLoading={setIsLoading}
          setQuery={setQuery}
          query={query}
        />
      )}
      <Grid container alignItems="center" marginBottom={5}>
        <Box display="flex" flexWrap="nowrap" alignItems="center" marginRight={6}>
          <Typography variant="h1" marginRight={locationState?.name ? 2.5 : 0}>
            {pageTitle}
          </Typography>
          {query.savedQueryId && (
            <Typography variant="h2" fontWeight={600}>
              {locationState?.name}
            </Typography>
          )}
        </Box>
        <ButtonSwitcher
          selectedButtonIndex={currentType}
          handleChangeFunc={handleChangeSpentTimeType}
          values={{
            firstButton: '0',
            firstButtonText: () => <FormattedMessage id="spent_time_type_details" />,
            secondButton: '1',
            secondButtonText: null,
          }}
        />
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
        <Grid container alignItems="center">
          <Box marginRight={2.5}>
            <Button
              variant="defaultGreyPrimary"
              disableRipple
              onClick={() => handleOpenQuery({ title: intl.formatMessage({ id: 'spent_time_save' }), isClear: true })}
            >
              <Typography variant="h4" fontWeight={600}>
                <FormattedMessage id="spent_time_save" />
              </Typography>
            </Button>
          </Box>
          {((query.savedQueryId && currentUser.isAdmin) || locationState?.userId === currentUser.id) && (
            <>
              <Box marginRight={2.5}>
                <Button
                  variant="defaultGreyPrimary"
                  disableRipple
                  onClick={() =>
                    handleOpenQuery({ title: intl.formatMessage({ id: 'spent_time_edit' }), isClear: false })
                  }
                >
                  <Typography variant="h4" fontWeight={600}>
                    <FormattedMessage id="spent_time_edit" />
                  </Typography>
                </Button>
              </Box>
              <Box marginRight={2.5}>
                <Button variant="defaultGreyPrimary" disableRipple onClick={handleAddTimeSheet}>
                  <Typography variant="h4" fontWeight={600}>
                    <FormattedMessage id="spent_time_delete" />
                  </Typography>
                </Button>
              </Box>
            </>
          )}
          {isAddButtonDisplayed && (
            <Box marginRight={2.5}>
              <Button variant="defaultBlueSecondary" disableRipple onClick={handleAddTimeSheet}>
                <Typography variant="h4" fontWeight={600}>
                  <FormattedMessage id="spent_time_add" />
                </Typography>
              </Button>
            </Box>
          )}
          <Box>
            <Button variant="defaultGreyPrimaryWithIcon" disableRipple onClick={handleOpenOptions}>
              <Box display="flex" alignItems="center" marginRight={'4px'}>
                <FilterIcon width="20" height="20" viewBox="0 0 20 20" />
              </Box>
              <Typography variant="h4" fontWeight={600}>
                <FormattedMessage id="parameters_text" />
              </Typography>
            </Button>
          </Box>
          <Box marginLeft={'auto'}>
            {!downloadLoading ? (
              <>
                <IconButton onClick={e => downloadCsv(e)} disableRipple>
                  <DownloadButton width="24" height="24" viewBox="0 0 24 24" />
                </IconButton>
              </>
            ) : (
              <>
                <CircularProgress size={18} color="secondary" />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      {timesheetsData.length === 0 ? (
        isOptionsFetching ? (
          <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
            <Typography variant="h2" fontWeight={700}>
              <FormattedMessage id="not_found" />
            </Typography>
          </Grid>
        )
      ) : isLoading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Grid marginBottom={8}>
          <SpentTimeTable
            intl={intl}
            query={query}
            setQuery={setQuery}
            tableErrorMessage={tableErrorMessage}
            originalTimesheetsData={timesheetsData}
            formattedTimesheetsData={formattedTimesheetsData}
            handleDeleteTimesheet={handleDeleteTimesheet}
            approveOrRejectTimesheets={approveOrRejectTimesheets}
            approveOrRejectOvertime={approveOrRejectOvertime}
            contextMenuData={contextMenuData}
            setContextMenuData={setContextMenuData}
            currentType={currentType}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            timesheetsColumnsValue={timesheetsColumns}
            tableSelectedValues={tableSelectedValues}
            setTableSelectedValues={setTableSelectedValues}
          />
          <Grid container marginY={10}>
            <Pagination
              onChange={handlePaginate}
              count={Math.ceil(Number(timesheetsData[0].services.count) / 100)}
              page={Number(query.page ?? 0)}
              shape="rounded"
              renderItem={item => {
                if (
                  (item.page === Math.ceil(Number(timesheetsData[0].services.count) / 100) || item.page === 1) &&
                  item.type === 'page'
                ) {
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      className={clsx(classes.paginateButton, { [classes.paginateButtonSelected]: item.selected })}
                      minWidth={28}
                      height={28}
                      marginX={0.5}
                      onClick={item.onClick}
                    >
                      <Typography variant="h3" paddingX={1}>
                        {item.page}
                      </Typography>
                    </Box>
                  );
                }
                if (item.type === 'start-ellipsis') {
                  return (
                    <Typography variant="h3" className={classes.paginateGoToPage} marginX={1.5}>
                      ...
                    </Typography>
                  );
                }
                if (item.type === 'end-ellipsis') {
                  return (
                    <Typography variant="h3" className={classes.paginateGoToPage} marginX={1.5}>
                      ...
                    </Typography>
                  );
                }
                if (item.type === 'previous') {
                  return Number(query.page) !== 1 ? (
                    <Box
                      display="flex"
                      flexWrap="nowrap"
                      alignItems="center"
                      onClick={item.onClick}
                      className={classes.paginateButton}
                      marginRight={2}
                      height={28}
                      paddingRight={2}
                      key={item.page}
                    >
                      <ChevronIcon width={20} height={20} direction="left" viewBox="0 0 24 24" color="#41424E" />
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography variant="h3" align="center" color="#41424E">
                        Предыдущая
                      </Typography>
                    </Box>
                  ) : null;
                }
                if (item.type === 'next') {
                  return Number(query.page) !==
                    Math.ceil(Number(timesheetsData[0]?.services.count) / Number(query.limit)) ? (
                    <Box
                      display="flex"
                      flexWrap="nowrap"
                      alignItems="center"
                      onClick={item.onClick}
                      className={classes.paginateButton}
                      height={28}
                      paddingLeft={2}
                      key={item.page}
                    >
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography variant="h3" align="center" color="#41424E">
                        Следующая
                      </Typography>
                      <ChevronIcon width={20} height={20} direction="right" viewBox="0 0 24 24" color="#41424E" />
                    </Box>
                  ) : null;
                }
                if (
                  item.type === 'page' &&
                  item.page === Math.ceil(Number(timesheetsData[0]?.services.count) / Number(query.limit))
                ) {
                  return Number(query.page) !== item.page ? (
                    <Typography variant="h3" className={classes.paginateGoToPage}>
                      ...
                    </Typography>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      className={clsx(classes.paginateButton, { [classes.paginateButtonSelected]: item.selected })}
                      minWidth={28}
                      height={28}
                      marginX={0.5}
                      onClick={item.onClick}
                    >
                      <Typography variant="h3" paddingX={1}>
                        {item.page}
                      </Typography>
                    </Box>
                  );
                }
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={clsx(classes.paginateButton, { [classes.paginateButtonSelected]: item.selected })}
                    minWidth={28}
                    height={28}
                    marginX={0.5}
                    onClick={item.onClick}
                    key={item.page}
                  >
                    <Typography variant="h3" paddingX={1}>
                      {item.page}
                    </Typography>
                  </Box>
                );
              }}
            />
          </Grid>
        </Grid>
      )}
    </Page>
  );
};

export default injectIntl(withSpentTimeRequests(SpentTimeContainer));
