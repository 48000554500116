import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import useStyles from './CalendarHeader.styles';
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';
import dayjs from 'dayjs';
import { Form, withFormik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import * as PropTypes from 'prop-types';

const CalendarHeader = ({ values, errors, setOffDays, setFieldValue, offDays }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('lg'));
  const isSmallestScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('sm'));

  const { current: dayOfWeeks } = useRef(
    new Array(7).fill(null).map((day, i) => {
      return {
        name: dayjs()
          .set('day', i + 1 || 0)
          .format('dddd'),
        dayOfWeek: i,
      };
    }),
  );

  const handleChangeWorkHours = useCallback(
    e => {
      const regex = /^([01]?[0-9]|2[0-4])$/;
      if (regex.test(e.currentTarget.value) || e.currentTarget.value === '') {
        setFieldValue('workHours', Number(e.currentTarget.value));
      }
    },
    [setFieldValue],
  );

  const handleRemoveOffDay = useCallback(
    e => {
      const filteredOffDays = offDays.filter(day => {
        return day !== Number(e.currentTarget.attributes.value.nodeValue);
      });
      return setOffDays(filteredOffDays);
    },
    [offDays, setOffDays],
  );

  const handleChange = event => {
    const {
      target: { value },
    } = event;
    setOffDays(typeof value === 'string' ? value.split(',') : value);
    setFieldValue('offDays', value);
  };

  const { current: menuProps } = useRef({
    className: classes.dayOfItems,
    day: 1,
  });

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid
        container
        wrap={isSmallScreen ? 'wrap' : 'nowrap'}
        justifyContent="space-between"
        alignItems="center"
        marginBottom={isSmallScreen ? 2.5 : 7.5}
        marginTop={2.5}
      >
        <Typography variant="h1">
          <FormattedMessage id="prod_calendar_title" defaultMessage="Production Calendar" />
        </Typography>
        <Box marginTop={isSmallestScreen ? 7.5 : 0} marginLeft={isSmallScreen ? 'auto' : '0'}>
          <Button type="submit" disableRipple variant="defaultGreyPrimary">
            <FormattedMessage id="save_text" defaultMessage="Save" />
          </Button>
        </Box>
      </Grid>
      <Grid
        container
        direction={isSmallScreen ? 'column-reverse' : 'row'}
        className={classes.calendarHeaderRoot}
        marginBottom={2.5}
      >
        <Grid item display="flex" alignItems="center" flex={1} width="100%" marginTop={isSmallScreen ? 3 : 0}>
          <Typography variant="h5" marginRight={isSmallScreen ? 0 : 2.5} flex={isSmallScreen ? 0.5 : 0.3}>
            <FormattedMessage id="prod_calendar_hours_default" />
          </Typography>
          <Box marginRight={isSmallScreen ? 0 : 12} width="100%" flex={isSmallScreen ? 0.5 : 0.7}>
            <TextField
              onChange={handleChangeWorkHours}
              value={values.workHours}
              type="number"
              className={classes.workHoursField}
              error={!!errors.workHours}
            />
          </Box>
        </Grid>
        <Grid item display="flex" alignItems="center" flex={1} width="100%">
          <Typography
            variant="h5"
            marginRight={isSmallScreen ? 0 : 25}
            whiteSpace="nowrap"
            flex={isSmallScreen ? 0.5 : 0.3}
          >
            <FormattedMessage id="prod_calendar_days_off" defaultMessage="Days off" />
          </Typography>
          <Box marginRight={isSmallScreen ? 0 : 12} width="100%" flex={isSmallScreen ? 0.5 : 0.7}>
            <Select
              className={classes.offDaysSelect}
              multiple
              value={values.offDays}
              onChange={handleChange}
              input={<OutlinedInput onClick={e => e.stopPropagation()} />}
              error={!!errors.offDays}
              renderValue={selected => (
                <Box display="flex" flexWrap="nowrap" alignItems="center">
                  {selected.map(value => {
                    return (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        wrap="nowrap"
                        className={classes.chosenOffDay}
                        key={value}
                      >
                        <Chip label={dayOfWeeks[value - 1]?.name} className={classes.offDayChip} />
                        <IconButton
                          value={value}
                          disableRipple
                          className={classes.offDayRemove}
                          onClick={handleRemoveOffDay}
                        >
                          <Box width={16} height={16} display="flex" alignItems="center" justifyContent="center">
                            <ClearIcon viewBox="0 0 24 24" color="primary" />
                          </Box>
                        </IconButton>
                      </Box>
                    );
                  })}
                </Box>
              )}
              MenuProps={menuProps}
            >
              {dayOfWeeks.map((day, i, array) => {
                return (
                  <MenuItem key={day.dayOfWeek} value={array[i].dayOfWeek + 1} className={classes.menuItem}>
                    <Typography variant="h5" textTransform="capitalize">
                      {array[i].name}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

CalendarHeader.propTypes = {
  values: PropTypes.shape({
    date: PropTypes.string.isRequired,
    hours: PropTypes.number.isRequired,
    newCustomDays: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})).isRequired, PropTypes.any]),
    offDays: PropTypes.arrayOf(PropTypes.number).isRequired,
    workHours: PropTypes.number.isRequired,
  }).isRequired,
  errors: PropTypes.shape({}).isRequired,
  offDays: PropTypes.arrayOf(PropTypes.number).isRequired,
  setOffDays: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default memo(CalendarHeader);
