import React, { useCallback, useReducer, useState } from 'react';
import { Box, IconButton, MenuItem, Select, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import SpreadsheetWeekValue from './SpreadsheetWeekValue';
import ChevronIcon from '../../../packages/common/shared-ui/src/icons/ChevronIcon';
import useStyles from './SpreadsheetTable/SpreadsheetTable.styles';
import EyeIcon from '../../../packages/common/shared-ui/src/icons/EyeIcon';
import { Link } from 'react-router-dom';
import { store } from '../app/redux';
import issuePageStyles from '../issuePage/IssuePage.styles';

const SpreadsheetWeek = ({
  projectIssue,
  formattedAnotherProjects,
  anotherProjects,
  handleHideWeek,
  handleChangeDayHour,
  handleChangeDayComment,
  handleSelectNewProject,
  handleSelectNewProjectTask,
  intl,
  timeType,
  timeTypes,
}) => {
  const classes = useStyles();
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const tooltipText = intl.formatMessage({ id: 'spreadsheet_tooltip_hide_text' });

  const [isWeekEdited, setIsWeekEdited] = useState(false);
  const state = store.getState();
  const issueCommonStyles = issuePageStyles();

  const weekHours = (timeType === timeTypes.MAIN_HOURS ? projectIssue.weekDays : projectIssue.overTimeWeekDays).reduce(
    (acc, day) => {
      return day.hours + acc;
    },
    0,
  );

  const handleUpdateDayHourWithWeekUpdate = useCallback(
    ({ currentTarget: target }) => {
      setIsWeekEdited(true);
      handleChangeDayHour(
        target.value === ''
          ? ''
          : target.value[0] === '.' || target.value[0] === ','
          ? `0${target.value}`
          : target.value,
        projectIssue.issueUniqueId,
        target.dataset.date,
        target.name,
      );
      forceUpdate();
    },
    [handleChangeDayHour, projectIssue.issueUniqueId],
  );

  const handleChangeProject = useCallback(
    e => {
      handleSelectNewProject(e.target.value, projectIssue);
      forceUpdate();
    },
    [handleSelectNewProject, projectIssue],
  );

  const handleChangeProjectTask = useCallback(
    e => {
      handleSelectNewProjectTask(e.target.value, projectIssue);
      forceUpdate();
    },
    [handleSelectNewProjectTask, projectIssue],
  );

  return (
    <TableRow key={projectIssue.issueUniqueId}>
      {`${projectIssue.projectName}`.split('')[0] === '_' ? (
        <TableCell padding="none" className={classes.tableCell}>
          <Select
            variant="standard"
            onChange={handleChangeProject}
            className={classes.projectSelect}
            IconComponent={() => <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />}
            disableUnderline
            defaultValue={0}
          >
            <MenuItem disabled value={0}>
              <Typography variant="h4">
                <FormattedMessage id="spreadsheet_choose_project" />
              </Typography>
            </MenuItem>
            {anotherProjects.map(project => (
              <MenuItem key={project.projectId} value={project.projectId}>
                <Typography variant="h4">{project.projectName}</Typography>
              </MenuItem>
            ))}
          </Select>
        </TableCell>
      ) : (
        <TableCell className={classes.tableCell}>
          <Typography variant="h4">{projectIssue.projectName}</Typography>
        </TableCell>
      )}
      {`${projectIssue.issueName}`.split('')[0] === '_' ? (
        <TableCell padding="none" className={classes.tableCell}>
          <Select
            variant="standard"
            className={classes.projectSelect}
            IconComponent={() => <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />}
            onChange={handleChangeProjectTask}
            defaultValue={0}
            disabled={`${projectIssue.projectName}`.split('')[0] === '_'}
            disableUnderline
          >
            <MenuItem disabled value={0}>
              <Typography variant="h4">
                <FormattedMessage id="spreadsheet_choose_task" />
              </Typography>
            </MenuItem>
            {Object.keys(formattedAnotherProjects).map(issueKey => {
              const issue = formattedAnotherProjects[issueKey];
              return (
                projectIssue.projectId === issue.projectId && (
                  <MenuItem key={issue.issueUniqueId} value={issue.issueUniqueId}>
                    <Typography variant="h4">{issue.issueName}</Typography>
                  </MenuItem>
                )
              );
            })}
          </Select>
        </TableCell>
      ) : (
        <TableCell className={classes.tableCell}>
          <Link
            className={issueCommonStyles.issueLink}
            target={'_blank'}
            to={
              state.user.project
                ? `/issues/${projectIssue.issueId}?project=${state.user.project.redmineId}`
                : `/issues/${projectIssue.issueId}`
            }
          >
            <Typography variant="h4">{projectIssue.issueName}</Typography>
          </Link>
        </TableCell>
      )}
      {(timeType === timeTypes.MAIN_HOURS ? projectIssue.weekDays : projectIssue.overTimeWeekDays).map(day => (
        <SpreadsheetWeekValue
          key={day.date}
          day={day}
          timeType={timeType}
          projectIssue={projectIssue}
          handleUpdateDayHourWithWeekUpdate={handleUpdateDayHourWithWeekUpdate}
          handleChangeDayHour={handleChangeDayHour}
          handleChangeDayComment={handleChangeDayComment}
          intl={intl}
        />
      ))}
      <TableCell align="center" className={classes.tableCellSmall} padding="none">
        <Box className={classes.totalCell}>
          {parseFloat(
            Number(
              timeType === timeTypes.MAIN_HOURS ? projectIssue.totalNormalHours : projectIssue.totalOvertimeHours,
            ).toFixed(1),
          )}
          <IconButton
            disableRipple
            className={classes.hideButton}
            disabled={isWeekEdited ? true : Boolean(weekHours)}
            onClick={handleHideWeek}
            issueid={projectIssue.issueUniqueId}
          >
            <Tooltip title={tooltipText} PopperProps={{ className: classes.commentTooltip }}>
              <Box>
                <EyeIcon
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  color={isWeekEdited ? '#FAFAFC' : Boolean(weekHours) ? '#FAFAFC' : '#DCDFF4'}
                />
              </Box>
            </Tooltip>
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default SpreadsheetWeek;
