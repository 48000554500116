import React, { useEffect, useReducer, useRef } from 'react';
import Header from './Header';
import LeftDrawer from './LeftDrawer/LeftDrawer';
import { menuItems } from './menuItems';
import { menuItemsProject } from './menuItemsProject';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../app/redux/index';
import { useQueryParams } from 'use-query-params';
import { getProject, getProjectReport, getProjectStatistic } from '../../../packages/common/api';
import { setProject } from '../app/redux/reducers/userSlice';
import { useParams } from 'react-router-dom';

const MainDrawer = ({ children, redmineId, userInfo, isUserLoading }) => {
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const state = store.getState();
  const dispatch = useDispatch();
  const [query, setQuery] = useQueryParams({
    project: '',
  });
  const pageParams = useParams();
  const projectParams = useRef(pageParams);
  useEffect(() => {
    async function fetchProject(slug) {
      try {
        let response = await getProject(slug);
        response.children = response.children.sort((a, b) => (a.name > b.name ? 1 : -1));
        let projectStatistic = await getProjectStatistic(response.redmineId);
        projectStatistic.rows = projectStatistic.rows.map(item => {
          item.columns[0].link = `/issues?project=${response.redmineId}&filters=issue.tracker%3Beq%3B${item.rowId}`;
          return item;
        });
        try {
          response.reports = await getProjectReport(response.redmineId);
        } catch (error) {
          console.error('ERROR WITH FETCH PROJECT REPORTS', error);
        }
        return response;
      } catch (e) {
        console.error(e);
      }
    }
    const project =
      query.project?.length > 0
        ? query.project
        : projectParams.current.projectId
        ? projectParams.current.projectId
        : null;
    if (project && state.user.project == undefined) {
      fetchProject(project)
        .then(async res => {
          dispatch(setProject(res));
        })
        .catch(e => console.error(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    userInfo &&
    !isUserLoading && (
      <>
        <Header />
        <LeftDrawer
          menuItems={state.user.project?.redmineId ? menuItemsProject(redmineToken) : menuItems(redmineToken)}
        >
          {children}
        </LeftDrawer>
      </>
    )
  );
};

export default MainDrawer;
