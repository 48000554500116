import React from 'react';
import { SvgIcon } from '@mui/material';

const ProjectCloseIcon = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_1234">
        <path
          d="M12 6.667h-.833V5C11.167 2.7 9.3.833 7 .833A4.168 4.168 0 0 0 2.833 5v1.667H2c-.917 0-1.667.75-1.667 1.666v8.334c0 .916.75 1.666 1.667 1.666h10c.917 0 1.667-.75 1.667-1.666V8.333c0-.916-.75-1.666-1.667-1.666Zm-5 7.5c-.917 0-1.667-.75-1.667-1.667s.75-1.667 1.667-1.667 1.667.75 1.667 1.667-.75 1.667-1.667 1.667Zm2.583-7.5H4.417V5A2.586 2.586 0 0 1 7 2.417 2.586 2.586 0 0 1 9.583 5v1.667Z"
          fill="#7174AC"
        />
      </g>
      <defs>
        <clipPath id="clip0_1234">
          <rect width="14" height="19" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ProjectCloseIcon;
