import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  tableSettings: {
    backgroundColor: '#ffffff',
    '& .MuiTableCell-root': {
      borderBottom: '1px solid #e4e4ef',
      borderTop: 0,
      padding: 0,
    },
    '& .MuiTableBody-root td:first-child': {
      borderRight: '1px solid #e4e4ef',
      paddingLeft: theme.spacing(3),
    },
    '& .MuiTableBody-root td:last-child': {
      borderLeft: '1px solid #e4e4ef',
    },
    '& .MuiTableBody-root': {
      borderBottom: '1px solid #e4e4ef',
      borderTop: '1px solid #e4e4ef',
    },
  },
  optionsGroupMenu: {
    '&.MuiPopover-root': {
      zIndex: 12000,
    },
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(4),
      maxHeight: '40vh',
      overflow: 'auto',
      '& ul': {
        padding: 0,
      },
    },
  },
  createSelect: {
    '&.MuiInputBase-root.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E4E4EF',
      },
      opacity: '70%',
    },
    border: '16px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  selectTracker: {
    '&.MuiTableRow-root td:first-child': {
      paddingLeft: 0,
    },
  },
  tableRow: {
    '&.MuiTableRow-root': {
      height: '40px',
    },
  },
  groupIcon: {
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
      right: 10,
    },
  },
  menuItem: {
    '&.Mui-selected': {
      background: '#DCDFF4 !important',
    },
    '& .MuiTypography-root': {
      textTransform: 'unset',
      color: '#212346',
    },
  },
  iconButton: {
    '&.MuiButtonBase-root.MuiIconButton-root': {
      minWidth: '32px',
      padding: '0px',
    },
  },
}));

export default useStyles;
