import React, { useState, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import {
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  Box,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import useStyles from './ResplanSettingsTracker.styles';
import ReportDeleteIcon from '../../../../packages/common/shared-ui/src/icons/ReportDeleteIcon';
import PlusIcon from '../../../../packages/common/shared-ui/src/icons/PlusIcon';
import DoneIcon from '../../../../packages/common/shared-ui/src/icons/DoneIcon';
import ClearIcon from '../../../../packages/common/shared-ui/src/icons/ClearIcon';

const ResplanSettingsTracker = ({ intl, settings, setSettings }) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [trackers, setTrackers] = useState(settings.trackers.map(t => ({ ...t, isNew: false })));
  const [selectedTracker, setSelectedTracker] = useState('');
  const trackerHeader = intl.formatMessage({ id: 'resplan_settings_tracker_header' });
  const [shouldOpenSelect, setShouldOpenSelect] = useState(false);
  const selectLabel = intl.formatMessage({ id: 'resplan_settings_choose' });

  const handleOpen = useCallback(() => {
    setIsOpen(prev => !prev);
    setShouldOpenSelect(true);
  }, []);

  const handleClose = useCallback(() => {
    setShouldOpenSelect(false);
  }, []);

  const handleSelectOpen = useCallback(() => {
    if (isOpen) {
      setShouldOpenSelect(true);
    }
  }, [isOpen]);

  const handleChange = event => {
    const newTrackers = trackers.map(tracker =>
      tracker.valueId === event.target.value ? { ...tracker, isNew: true } : tracker,
    );
    setTrackers(newTrackers);
    setSelectedTracker(event.target.value);
  };
  const handleDone = () => {
    const newTrackers = trackers
      .map(tracker => (tracker.valueId == selectedTracker ? { ...tracker, isChecked: true, isNew: false } : tracker))
      .sort((a, b) => (a.isChecked === b.isChecked ? 0 : a.isChecked ? 1 : -1));
    setTrackers(newTrackers);
    setSettings(prevSettings => ({
      ...prevSettings,
      trackers: prevSettings.trackers.map(tracker =>
        tracker.valueId === selectedTracker ? { ...tracker, isChecked: true } : tracker,
      ),
    }));
    handleOpen();
    setSelectedTracker('');
  };

  const handleDelete = valueId => {
    const newTrackers = trackers.map(tracker =>
      tracker.valueId == valueId ? { ...tracker, isChecked: false } : tracker,
    );
    setTrackers(newTrackers);
    setSettings(prevSettings => ({
      ...prevSettings,
      trackers: prevSettings.trackers.map(tracker =>
        tracker.valueId === valueId ? { ...tracker, isChecked: false } : tracker,
      ),
    }));
    setSelectedTracker('');
  };

  const handleCancel = () => {
    const newTrackers = trackers.map(tracker => (tracker.isNew == true ? { ...tracker, isNew: false } : tracker));
    setTrackers(newTrackers);
    handleOpen();
    setSelectedTracker('');
  };
  return (
    <>
      <Typography marginLeft={3} variant="h4" fontWeight={700} color="#082253">
        {trackerHeader}
      </Typography>
      <Grid marginTop={4}>
        <TableContainer>
          <Table className={classes.tableSettings}>
            <TableBody>
              {trackers
                .filter(tracker => tracker.isChecked)
                .sort((a, b) => (a.isChecked === b.isChecked ? 0 : a.isChecked ? 1 : -1))
                .map(tracker => (
                  <TableRow key={tracker.valueId} className={classes.tableRow}>
                    {!isOpen ? (
                      <>
                        <TableCell width="90%" align="left">
                          {tracker.valueName}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            disableRipple
                            className={classes.iconButton}
                            onClick={() => handleDelete(tracker.valueId)}
                          >
                            <ReportDeleteIcon width="24" height="24" viewBox="0 0 24 24" />
                          </IconButton>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell width="80%" colSpan={2} align="left">
                          {tracker.valueName}
                        </TableCell>
                        <TableCell colSpan={1} align="center">
                          <IconButton
                            className={classes.iconButton}
                            disableRipple
                            onClick={() => handleDelete(tracker.valueId)}
                          >
                            <ReportDeleteIcon width="24" height="24" viewBox="0 0 24 24" />
                          </IconButton>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              {isOpen && (
                <TableRow className={classes.selectTracker}>
                  <TableCell width="80%" align="left">
                    <Select
                      fullWidth
                      open={isOpen && shouldOpenSelect}
                      onOpen={handleSelectOpen}
                      onClose={handleClose}
                      IconComponent={() => (
                        <Box className={classes.groupIcon}>
                          <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
                        </Box>
                      )}
                      MenuProps={{
                        className: classes.optionsGroupMenu,
                      }}
                      variant="standard"
                      disableUnderline
                      className={classes.createSelect}
                      value={selectedTracker || 'choose'}
                      onChange={handleChange}
                    >
                      <MenuItem disabled value="choose">
                        <Typography variant="h4" color="#212346">
                          {selectLabel}
                        </Typography>
                      </MenuItem>
                      {trackers
                        .filter(tracker => !tracker.isChecked)
                        .sort((a, b) => b.isNew - a.isNew)
                        .map(tracker => (
                          <MenuItem key={tracker.valueId} value={tracker.valueId} className={classes.menuItem}>
                            <Typography variant="h4" color="#212346">
                              {tracker.valueName}
                            </Typography>
                          </MenuItem>
                        ))}
                    </Select>
                  </TableCell>
                  <TableCell align="center" width="10%">
                    <IconButton disableRipple onClick={handleDone} className={classes.iconButton}>
                      <DoneIcon color="#37C670" width="24" height="24" viewBox="0 0 24 24" />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center" width="10%">
                    <IconButton disableRipple onClick={handleCancel} className={classes.iconButton}>
                      <ReportDeleteIcon width="24" height="24" viewBox="0 0 24 24" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box marginLeft={1} marginTop={2}>
          {!isOpen ? (
            <IconButton disableRipple onClick={handleOpen}>
              <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
            </IconButton>
          ) : (
            <IconButton disabled disableRipple>
              <PlusIcon color="#DCDFF4" width="24" height="24" viewBox="0 0 24 24" />
            </IconButton>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default injectIntl(ResplanSettingsTracker);
