import React, { memo, useCallback, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useQueryParams } from 'use-query-params';
import { useLocation, useNavigate, useRoutes } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import dayjsRu from 'dayjs/locale/ru';
import dayjsEn from 'dayjs/locale/en';

import { getUser } from '../auth/actions';
import routes from '../app/routes';
import devRoutes from '../app/devRoutes';
import { LOCALES } from 'Common/shared-ui/src/i18n/locales';
import { messages } from 'Common/shared-ui/src/i18n/messages';
import { getXlanceAuthToken } from '../../../packages/common/api';
import cookies from '../../../packages/common/utils/cookies';
import { useSearchParams } from 'react-router-dom';

function AppContainer() {
  const element = useRoutes(process.env.NODE_ENV === 'development' ? devRoutes : routes);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appLang = useSelector(state => state.user.language);
  const userInfo = useSelector(state => state.user.info);
  const isUserLoadingError = useSelector(state => state.user.error);
  const projectSelected = useSelector(state => state.user.project);
  const [searchParams, setSearchParams] = useSearchParams();
  const accessToken = cookies.getCookie('access_token');
  const [query, setQuery] = useQueryParams({
    back_url: '',
    redmine_token: '',
    project: '',
    redmineOrigin: '',
  });
  const location = useLocation();
  useEffect(() => {
    if (location.key === 'default' && !location.pathname.includes('login')) {
      window.localStorage.setItem('back_url', location.pathname);
    }
  }, [location]);
  const getXlanceToken = useCallback(async (back_url, redmine_token) => {
    try {
      const { data } = await getXlanceAuthToken({ redmine_token: redmine_token.trim() });
      cookies.setCookie({
        key: 'access_token',
        value: data.access_token,
        host: `${window.location.protocol}//${window.location.hostname}`,
      });
      return (window.location.href = `${back_url}${back_url.split('').includes('?') ? '&' : '?'}incoming_url=${
        window.location.origin
      }`);
    } catch (error) {
      console.error('CANNOT SET PASSTHROWGH TOKEN', error);
    }
  }, []);

  useEffect(() => {
    dayjs.locale(appLang === LOCALES.RUSSIAN ? dayjsRu : dayjsEn);
    if (!accessToken && query.redmine_token && query.back_url) {
      getXlanceToken(query.back_url, query.redmine_token);
      return;
    }
    if (!!isUserLoadingError.length) {
      return navigate('/login');
    }
    if (!userInfo) {
      dispatch(getUser());
    }
  }, [
    appLang,
    dispatch,
    isUserLoadingError,
    navigate,
    searchParams,
    userInfo,
    query.redmineOrigin,
    getXlanceToken,
    query.back_url,
    query.redmine_token,
    accessToken,
  ]);

  return (
    <IntlProvider messages={messages[appLang]} locale={appLang} defaultLocale={LOCALES.RUSSIAN}>
      {element}
    </IntlProvider>
  );
}

export default memo(AppContainer);
