import React from 'react';
import dayjs from 'dayjs';

import { generateRedirectUrl } from 'Common/utils/getRedirectUrl';

export const menuItems = redmineToken => {
  return [
    {
      lang_key: 'menu_item_projects',
      rowIcon: null,
      path: '/projects',
      isVisible: true,
    },
    {
      lang_key: 'menu_item_tasks',
      rowIcon: null,
      path: '/issues',
      isVisible: true,
      isDropdown: false,
      isDropdownVisible: true,
      dropdown: [],
    },
    {
      lang_key: 'menu_item_confirmations',
      rowIcon: null,
      path: '/confirmations',
      isVisible: true,
      isDropdown: false,
      isDropdownVisible: true,
      //dropdown: [],
    },
    {
      lang_key: 'menu_item_work_costs',
      rowIcon: null,
      path: '/spentTime',
      isDropdown: false,
      isDropdownVisible: true,
      isVisible: true,
      dropdown: [],
    },
    {
      lang_key: 'menu_item_art',
      rowIcon: null,
      path:
        process.env.NODE_ENV === 'development'
          ? '/finances'
          : generateRedirectUrl({ host: window.location.origin, path: '/art_table', redmineToken }),
      isVisible: true,
    },
    {
      lang_key: 'menu_item_spreadsheet',
      rowIcon: null,
      path: '/spreadsheet',
      isVisible: true,
    },
    {
      lang_key: 'menu_item_resource_plan',
      rowIcon: null,
      path: '/resourcePlan',
      isVisible: true,
    },

    {
      lang_key: 'menu_item_agile',
      rowIcon: null,
      path: generateRedirectUrl({ host: window.location.origin, path: '/agile/board', redmineToken }),
      isVisible: true,
    },
    {
      lang_key: 'menu_item_administration',
      rowIcon: null,
      path:
        process.env.NODE_ENV === 'development'
          ? '/administration'
          : generateRedirectUrl({ host: window.location.origin, path: '/admin', redmineToken }),
      isDropdown: false,
      isVisible: false,
      dropdown: [
        {
          name: 'menu_item_administration_projects',
          path:
            process.env.NODE_ENV === 'development'
              ? '/administration/projects'
              : generateRedirectUrl({ host: window.location.origin, path: '/admin/projects', redmineToken }),
        },
        {
          name: 'menu_item_administration_users',
          path: '/administration/users',
          isItemVisible: true,
        },
        {
          name: 'menu_item_administration_groups',
          path:
            process.env.NODE_ENV === 'development'
              ? '/administration/groups'
              : generateRedirectUrl({ host: window.location.origin, path: '/groups', redmineToken }),
        },
        {
          name: 'menu_item_administration_permissions',
          path:
            process.env.NODE_ENV === 'development'
              ? '/administration/permissions'
              : generateRedirectUrl({ host: window.location.origin, path: '/roles', redmineToken }),
        },
        {
          name: 'menu_item_administration_trackers',
          path:
            process.env.NODE_ENV === 'development'
              ? '/administration/trackers'
              : generateRedirectUrl({ host: window.location.origin, path: '/trackers', redmineToken }),
        },
        {
          name: 'menu_item_administration_task_statuses',
          path:
            process.env.NODE_ENV === 'development'
              ? '/administration/issue-statuses'
              : generateRedirectUrl({ host: window.location.origin, path: '/issue_statuses', redmineToken }),
        },
        {
          name: 'menu_item_administration_workflows',
          path:
            process.env.NODE_ENV === 'development'
              ? '/administration/workflows'
              : generateRedirectUrl({ host: window.location.origin, path: '/workflows/edit', redmineToken }),
        },
        {
          name: 'menu_item_administration_custom_fields',
          path:
            process.env.NODE_ENV === 'development'
              ? '/administration/custom-fields'
              : generateRedirectUrl({ host: window.location.origin, path: '/custom_fields', redmineToken }),
        },
        {
          name: 'menu_item_administration_enumerations',
          path:
            process.env.NODE_ENV === 'development'
              ? '/administration/enumerations'
              : generateRedirectUrl({ host: window.location.origin, path: '/enumerations', redmineToken }),
        },
        {
          name: 'menu_item_administration_project_reports',
          path: `/administration/project-reports`,
          isItemVisible: true,
        },
        {
          name: 'menu_item_administration_calendar',
          path: `/administration/production-calendar?year=${dayjs().format('YYYY')}`,
          isItemVisible: true,
        },
        {
          name: 'menu_item_administration_settings',
          path:
            process.env.NODE_ENV === 'development'
              ? '/administration/settings'
              : generateRedirectUrl({ host: window.location.origin, path: '/settings', redmineToken }),
        },
        {
          name: 'menu_item_administration_resourcePlan',
          path: `/administration/resourcePlan-settings`,
          isItemVisible: true,
        },
        {
          name: 'menu_item_administration_confirmation_settings',
          path: `/administration/confirmation-settings`,
          isItemVisible: true,
        },
        {
          name: 'menu_item_administration_calculationTypes',
          path: `/administration/calculation-types`,
          isItemVisible: true,
        },
        {
          name: 'menu_item_administration_contractStages',
          path: `/administration/contract-stages`,
          isItemVisible: true,
        },
      ],
    },
  ];
};
