import React from 'react';
import { SvgIcon } from '@mui/material';

const MenuIcon = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_215_22881)">
        <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill="#DCDFF4" />
      </g>
      <defs>
        <clipPath id="clip0_215_22881">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default MenuIcon;
