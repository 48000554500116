import React from 'react';
import { SvgIcon } from '@mui/material';

const AddPlusIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_14472_41519)">
          <path
            d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
            fill="#7174AC"
          />
        </g>
        <defs>
          <clipPath id="clip0_14472_41519">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default AddPlusIcon;
