import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  SpreadsheetTableRoot: {
    backgroundColor: '#ffffff',
    borderRadius: theme.spacing(4),
    '& .MuiTableCell-root': {
      borderBottom: 0,
    },
  },
  tableCell: {
    boxShadow: '1px 1px #E4E4EF',
    width: 220,
  },
  tableCellMedium: {
    boxShadow: '1px 1px #E4E4EF',
    width: 95,
  },
  tableCellSmall: {
    width: 100,
    boxShadow: '0 1px #E4E4EF',
    '&.MuiTableCell-root.MuiTableCell-paddingNone': {
      padding: '0 !important',
    },
    '&.MuiTableCell-root:not(.MuiTableCell-head)': {
      padding: theme.spacing(4.5, 7),
    },
  },
  tableField: {
    display: 'flex',
    flexDirection: 'column',
    '&.MuiTableCell-root': {
      padding: 0,
    },
  },
  tableFieldBox: {
    padding: theme.spacing(2, 0, 2, 2),
  },
  tableCellField: {
    position: 'relative',
    maxHeight: 20,
    '& .MuiInputBase-input, & .MuiOutlinedInput-notchedOutline': {
      width: '100%',
      height: 20,
      border: '0 !important',
      textAlign: 'right',
      padding: 0,
      marginRight: theme.spacing(8),
    },
    '& .MuiInputBase-root.Mui-disabled input': {
      '-webkit-text-fill-color': 'unset',
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      appearance: 'none',
      '-webkit-appearance': 'none',
    },
    '& input::placeholder': {
      color: '#7174AC',
    },
  },
  tableCellCommentField: {
    '&:before': {
      content: '""',
      width: 15,
      height: '100%',
      position: 'absolute',
      right: 1,
      top: 0,
      zIndex: 100,
      background: 'linear-gradient(to right, transparent -15px, white)',
    },
    '& .MuiInputBase-input, & .MuiOutlinedInput-notchedOutline': {
      ...theme.typography.h6,
      marginRight: 0,
      textAlign: 'left',
    },
  },
  dayComment: {
    cursor: 'pointer',
  },
  addRowButton: {
    '&.MuiButtonBase-root': {
      padding: 0,
    },
  },
  bottomRow: {
    '& .MuiTableCell-root': {
      borderBottom: 0,
      boxShadow: '1px 0px #E4E4EF',
    },
    '& .MuiTableCell-root:last-child': {
      boxShadow: 'unset',
    },
  },
  projectSelect: {
    width: '100%',
    height: 56,
    padding: theme.spacing(4, 2, 4, 4),
    '& .MuiSelect-select': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: '0 !important',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      backgroundColor: 'transparent',
      zIndex: 1,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      right: 8,
      zIndex: 0,
    },
  },
  commentsPaper: {
    '&.MuiPaper-root': {
      minWidth: 200,
      padding: theme.spacing(3),
    },
  },
  commentTooltip: {
    '& .MuiTooltip-tooltip': {
      marginTop: '0 !important',
      backgroundColor: '#F1F2F8',
      borderRadius: theme.spacing(4),
      padding: theme.spacing(2),
      color: '#212346',
    },
  },
  tableCellError: {
    outline: '1px solid #E03737',
  },
  totalCell: {
    position: 'relative',
    padding: theme.spacing(4.5, 7),
  },
  hideButton: {
    '&.MuiButtonBase-root': {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  disabledHideButton: {
    opacity: '30%',
  },
}));

export default useStyles;
