import React from 'react';
import { Grid } from '@mui/material';

const AppLayout = ({ children, ...rest }) => {
  return (
    <Grid container {...rest}>
      {children}
    </Grid>
  );
};

export default AppLayout;
