import React from 'react';
import SearchIcon from 'Common/shared-ui/src/icons/SearchIcon';
import { Box, IconButton, TextField, useMediaQuery } from '@mui/material';
import { getRedirectUrlNoParams } from 'Common/utils/getRedirectUrlNoParams';
import useStyles from './SearchInput.styles';
import { useSelector } from 'react-redux';

const SearchInput = event => {
  const classes = useStyles();
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('sm'));
  const [searchValue, setSearchValue] = React.useState('');
  const handleSearch = event => {
    console.log(event.target.value);
    setSearchValue(event.target.value);
  };
  const goSearchPage = () => {
    const searchPageUrl = '/search?utf8=%E2%9C%93&scope=&q=' + searchValue;
    let hostUrl = getRedirectUrlNoParams({ host: window.location.origin, path: searchPageUrl, redmineToken });
    window.open(hostUrl, '_blank');
  };
  const handleEnter = event => {
    if (event.key === 'Enter') {
      goSearchPage();
    }
  };
  return (
    <Box className={classes.SearchInputRoot}>
      <IconButton disableRipple className={classes.inputIcon} onClick={goSearchPage}>
        <SearchIcon viewBox="0 0 24 24" width={24} height={24} />
      </IconButton>
      {!isSmallScreen && (
        <TextField
          variant="filled"
          onKeyDown={handleEnter}
          value={searchValue}
          onChange={handleSearch}
          className={classes.searchInput}
        />
      )}
    </Box>
  );
};

export default SearchInput;
