import nunitoRegularEOT from 'Common/shared-ui/src/fonts/nunito-regular.eot';
import nunitoRegularWOFF2 from 'Common/shared-ui/src/fonts/nunito-regular.woff2';
import nunitoRegularWOFF from 'Common/shared-ui/src/fonts/nunito-regular.woff';
import nunitoRegularTTF from 'Common/shared-ui/src/fonts/nunito-regular.ttf';
import nunitoRegularSVG from 'Common/shared-ui/src/fonts/nunito-regular.svg';

import nunito600EOT from 'Common/shared-ui/src/fonts/nunito-600.eot';
import nunito600WOFF2 from 'Common/shared-ui/src/fonts/nunito-600.woff2';
import nunito600WOFF from 'Common/shared-ui/src/fonts/nunito-600.woff';
import nunito600TTF from 'Common/shared-ui/src/fonts/nunito-600.ttf';
import nunito600SVG from 'Common/shared-ui/src/fonts/nunito-600.svg';

import nunito700EOT from 'Common/shared-ui/src/fonts/nunito-700.eot';
import nunito700WOFF2 from 'Common/shared-ui/src/fonts/nunito-700.woff2';
import nunito700WOFF from 'Common/shared-ui/src/fonts/nunito-700.woff';
import nunito700TTF from 'Common/shared-ui/src/fonts/nunito-700.ttf';
import nunito700SVG from 'Common/shared-ui/src/fonts/nunito-700.svg';
import { theme } from '../index';

export default {
  styleOverrides: themeParam => `
        /* nunito-regular - latin_cyrillic */
        @font-face {
          font-family: 'Nunito';
          font-style: normal;
          font-display: 'fallback';
          font-weight: 400;
          src: url(${nunitoRegularEOT}); /* IE9 Compat Modes */
          src: local(''),
               url('${nunitoRegularEOT}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
               url(${nunitoRegularWOFF2}) format('woff2'), /* Super Modern Browsers */
               url(${nunitoRegularWOFF}) format('woff'), /* Modern Browsers */
               url(${nunitoRegularTTF}) format('truetype'), /* Safari, Android, iOS */
               url('${nunitoRegularSVG}#Nunito') format('svg'); /* Legacy iOS */
        }
        /* nunito-600 - latin_cyrillic */
        @font-face {
          font-family: 'Nunito';
          font-style: normal;
          font-display: 'fallback';
          font-weight: 600;
          src: url(${nunito600EOT}); /* IE9 Compat Modes */
          src: local(''),
               url('${nunito600EOT}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
               url(${nunito600WOFF2}) format('woff2'), /* Super Modern Browsers */
               url(${nunito600WOFF}) format('woff'), /* Modern Browsers */
               url(${nunito600TTF}) format('truetype'), /* Safari, Android, iOS */
               url('${nunito600SVG}#Nunito') format('svg'); /* Legacy iOS */
        }
        /* nunito-700 - latin_cyrillic */
        @font-face {
          font-family: 'Nunito';
          font-style: normal;
          font-display: 'fallback';
          font-weight: 700;
          src: url(${nunito700EOT}); /* IE9 Compat Modes */
          src: local(''),
               url('${nunito700EOT}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
               url(${nunito700WOFF2}) format('woff2'), /* Super Modern Browsers */
               url(${nunito700WOFF}) format('woff'), /* Modern Browsers */
               url(${nunito700TTF}) format('truetype'), /* Safari, Android, iOS */
               url('${nunito700SVG}#Nunito') format('svg'); /* Legacy iOS */
        }
        body {
          background-color: ${themeParam.palette.common.lightGrey};
          position: relative;
          font-size: 14px;
          line-height: 20px;

        }
        a {
          text-decoration: none;
          color: ${theme.palette.common.blue};
        }
        h1 {
          margin: 0;
        }
      `,
};
