import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  yearSwitcher: {
    border: '1px solid #E4E4EF',
    borderRadius: theme.spacing(4),
    maxWidth: 126,
    height: 40,
  },
  yearSwitcherButton: {
    '&[direction="left"]': {
      padding: theme.spacing(0, 4, 0, 2),
    },
    '&[direction="right"]': {
      padding: theme.spacing(0, 2, 0, 4),
    },
  },
}));

export default useStyles;
