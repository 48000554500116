import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      backgroundColor: palette.common.lightGrey,
      borderRadius: 16,
      height: 40,
      color: '#7174AC',
      padding: '8px 12px',
      '&.Mui-focused': {
        backgroundColor: palette.common.lightGrey,
      },
      '&:hover': {
        backgroundColor: palette.common.lightGrey,
      },
      '& input': {
        padding: 0,
      },
      '&::after, &::before': {
        display: 'none',
      },
    },
  },
};
