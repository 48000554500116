import React from 'react';
import { SvgIcon } from '@mui/material';

function ReportDeleteIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="9.33" height="12" viewBox="0 0 9.33 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#DCDFF4"
          d="M1 10.667C1 11.4 1.6 12 2.333 12h5.333C8.4 12 9 11.4 9 10.667v-8H1v8zm8.666-10H7.333L6.666 0H3.333l-.667.667H.333V2h9.333V.667z"
        ></path>
      </svg>
    </SvgIcon>
  );
}

export default ReportDeleteIcon;
