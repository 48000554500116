import React, { useEffect, useState } from 'react';
import Page from 'Common/shared-ui/src/components/Page';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Box, Grid, Typography, CircularProgress, Button } from '@mui/material';
import useStyles from './ResplanSettingsContainer.styles';
import { getResplanGlobalSettings, updateResplanGlobalSettings } from '../../../../packages/common/api';
import ResplanSettingsTracker from '../ResplanSettingsTracker/ResplanSettingsTracker';
import ResplanSettingsSelects from '../ResplanSettingsSelects/ResplanSettingsSelects';
import ResplanSettingsChecks from '../ResplanSettingsChecks/ResplanSettingsChecks';

const ResplanSettingsContainer = ({ intl }) => {
  const [settings, setSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const pageTitle = intl.formatMessage({ id: 'resource_plan_settings' });
  const classes = useStyles();

  const fetchResplanSettings = async () => {
    try {
      const response = await getResplanGlobalSettings();
      setSettings(response);
      setIsLoading(false);
      console.log('GET');
      console.log(response);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    fetchResplanSettings();
  }, []);

  console.log('Changes monitoring:', settings);

  const handleSaveSettings = async () => {
    setIsLoading(true);
    let tempSettings = JSON.parse(JSON.stringify(settings));

    tempSettings = {
      ...tempSettings,
      trackers: tempSettings.trackers.filter(item => item.isChecked).map(item => item.valueId),
      contractTracker: tempSettings.contractTracker.find(item => item.isChecked)?.valueId || '',
      businessUnit: tempSettings.businessUnit.find(item => item.isChecked)?.fieldId || '',
      department: tempSettings.department.find(item => item.isChecked)?.fieldId || '',
      dismissalsDate: tempSettings.dismissalsDate.find(item => item.isChecked)?.fieldId || '',
      roleResource: tempSettings.roleResource.find(item => item.isChecked)?.fieldId || '',
      userAvailability: tempSettings.userAvailability.find(item => item.isChecked)?.fieldId || '',
      accessRoles: tempSettings.accessRoles.filter(item => item.isChecked).map(item => item.valueId),
      employmentCheck: tempSettings.employmentCheck,
      // overEmploymentProhibition: tempSettings.overEmploymentProhibition,
    };
    console.log('SAVE');
    console.log(tempSettings);
    try {
      const response = await updateResplanGlobalSettings(tempSettings);
      console.log(response);
      setSettings(response.data.settings);
      // fetchResplanSettings();
      if (location.key == 'default') {
        navigate('/projects');
      } else {
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeclineSettings = async () => {
    setIsLoading(true);
    try {
      fetchResplanSettings();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {!isLoading ? (
        <Page title={pageTitle} width="100%">
          <Grid
            container
            marginTop={1}
            marginBottom={8}
            spacing={5}
            alignItems="center"
            justifyContent="space-between"
            display="flex"
            position="relative"
          >
            <Typography variant="h1">{pageTitle}</Typography>
            <Box display="flex" alignItems="center">
              <Box marginRight={1}>
                <Button onClick={handleSaveSettings} variant="cutBluePrimary" className={classes.saveSettingButton}>
                  <FormattedMessage id="save_text" defaultMessage="Save" />
                </Button>
              </Box>
              <Button
                onClick={handleDeclineSettings}
                variant="defaultGreyPrimary"
                className={classes.saveSettingButton}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
            </Box>
          </Grid>
          <Grid
            container
            marginTop={1}
            spacing={5}
            justifyContent="space-between"
            position="relative"
            direction="row"
            wrap="nowrap"
            alignItems="flex-start"
          >
            <Grid
              item
              container
              marginTop={1}
              padding={5}
              spacing={5}
              alignItems="flex-start"
              display="flex"
              position="relative"
              direction="column"
              className={classes.block}
            >
              <ResplanSettingsChecks settings={settings} setSettings={setSettings} />
            </Grid>
            <Grid
              container
              marginTop={1}
              marginLeft={5}
              padding={5}
              spacing={5}
              display="flex"
              position="relative"
              direction="column"
              className={classes.block}
            >
              <ResplanSettingsSelects settings={settings} setSettings={setSettings} />
            </Grid>
            <Grid
              container
              marginTop={1}
              marginLeft={5}
              paddingTop={4}
              paddingBottom={2}
              spacing={5}
              display="flex"
              position="relative"
              direction="column"
              className={classes.block}
            >
              <ResplanSettingsTracker settings={settings} setSettings={setSettings} />
            </Grid>
          </Grid>
        </Page>
      ) : (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </>
  );
};

export default injectIntl(ResplanSettingsContainer);
