import React, { useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';

import useStyles from './UserAvatar.styles';

const UserAvatar = ({ avatar, userFirstName, userLastName, userFullNameArray, handleOpenAvatarMenu }) => {
  const classes = useStyles();
  const userInitials = `${userFullNameArray[0][0]}${userFullNameArray[userFullNameArray.length - 1][0]}`;

  return (
    <Box className={classes.UserAvatarRoot} onClick={handleOpenAvatarMenu}>
      <Box className={classes.avatar}>
        {avatar ? (
          <img src={avatar} alt="user avatar" />
        ) : (
          <Typography variant="h4" color="primary" fontWeight={600}>
            {userInitials}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column" marginLeft={2}>
        <Typography variant="h5" color="primary" fontWeight={600} lineHeight="16px">
          {userLastName}
        </Typography>
        <Typography variant="h6" color="primary" lineHeight="14px">
          {userFirstName}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserAvatar;
