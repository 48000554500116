import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  modalRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.MuiModal-root': {
      zIndex: 2000,
    },
  },
  modalPaper: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(4, 2),
    borderRadius: theme.spacing(4),
    maxWidth: 550,
    maxHeight: '95%',
    overflowY: 'auto',
  },
  categoryInput: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      padding: 0,
      height: 36,
      '& .MuiAutocomplete-endAdornment': {
        height: '100%',
        '& svg': {
          position: 'unset',
        },
      },
      '& input.MuiAutocomplete-input': {
        padding: theme.spacing(2.5, 4),
      },
    },
  },
  forProjectsAutocomplete: {
    // border: '1px solid #E4E4EF',
    borderRadius: theme.spacing(4),
    overflow: 'hidden',
    backgroundColor: '#ffffff',
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  forProjectsPaper: {
    backgroundColor: '#fff',
    borderRadius: theme.spacing(0, 0, 4, 4),
    overflow: 'hidden',
    border: '1px solid #E4E4EF',
    '& .MuiAutocomplete-listbox': {
      padding: 0,
    },
  },
  forProjectsInputOpened: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: theme.spacing(4, 4, 0, 0),
      },
    },
  },
}));

export default useStyles;
