import { makeStyles } from '@mui/styles';

const gridQueryModalStyles = makeStyles(theme => ({
  modalRoot: {
    '& .MuiPaper-root.MuiDialog-paper': {
      overflow: 'visible',
      padding: '16px',
      maxWidth: '448px',
      backgroundColor: '#fff',
      borderRadius: '16px',
    },
    modalPaper: {
      padding: theme.spacing(2.5, 5),
      overflowY: 'auto',
    },
  },
}));

export default gridQueryModalStyles;
