import React from 'react';
import { SvgIcon } from '@mui/material';

function ParentIssueDropdown(props) {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.66663 11.3332L9.99996 7.99984L6.66663 4.6665V11.3332Z" fill="#7174AC" />
      </svg>
    </SvgIcon>
  );
}

export default ParentIssueDropdown;
