import React, { useCallback, useEffect, useState, useReducer } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Page from 'Common/shared-ui/src/components/Page';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import {
  Autocomplete,
  ClickAwayListener,
  Box,
  Grid,
  Checkbox,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Typography,
  FormControl,
  CircularProgress,
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material';
import useStyles from './ProjectReports.styles';
import ReportDeleteIcon from 'Common/shared-ui/src/icons/ReportDeleteIcon';
import _without from 'lodash/without';
import _unionBy from 'lodash/unionBy';
import PlusIcon from 'Common/shared-ui/src/icons/PlusIcon';
import RadioButtonSelected from 'Common/shared-ui/src/icons/RadioButtonSelected';
import RadioButton from 'Common/shared-ui/src/icons/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { generateRedirectUrl } from 'Common/utils/getRedirectUrl';
import { useNavigate } from 'react-router';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import { getProjectReports, updateProjectReports } from '../../../../packages/common/api';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';

const ProjectReports = ({ intl }) => {
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [changes, setChanges] = useState(false);
  const [reports, setReports] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const pageTitle = intl.formatMessage({ id: 'page_title_project_reports' });
  const isUrlValid = url => url.length < 2 || !url.includes('.') || !url.startsWith('http');
  const addReport = () => {
    setChanges(true);
    console.log(reports);
    const newReport = {
      name: '',
      url: '',
      isForAll: false,
      roles: JSON.parse(JSON.stringify(roles)),
    };
    let tempReports = [...reports];
    setReports(current => [...current, newReport]);
  };
  const deleteReport = (report, index) => {
    let tempReports = JSON.parse(JSON.stringify(reports));
    tempReports[index].isDeleted = true;
    setChanges(true);
    setReports(tempReports);
  };
  const handleCancelReports = async () => {
    try {
      const response = await getProjectReports();
      setReports(response.reports);
      setRoles(response.roles);
      setIsLoading(false);
      setChanges(false);
    } catch (e) {
      console.error(e);
    }
  };
  const PaperComponent = (props, index) => {
    // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
    let radioButtonAll = 'всем пользователям';
    // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
    let radioButtonCustom = 'только этим ролям:';
    return (
      <Paper {...props}>
        <Box className={classes.radioButtonsGroupWrap}>
          <Box className={classes.radioButtonWrap}>
            <IconButton
              disableRipple
              className={classes.radioButton}
              onMouseDown={() => {
                let tempReports = [...reports];
                tempReports[index].isForAll = true;
                setReports(tempReports);
                setChanges(true);
              }}
            >
              {reports[index].isForAll ? (
                <RadioButtonSelected width={14} height={14} viewBox="0 0 14 14" />
              ) : (
                <RadioButton width={14} height={14} viewBox="0 0 14 14" />
              )}
              <Box className={classes.radioButtonTxt}>{radioButtonAll}</Box>
            </IconButton>
          </Box>
          <Box className={classes.radioButtonWrap}>
            <IconButton
              disableRipple
              className={classes.radioButton}
              onMouseDown={() => {
                let tempReports = [...reports];
                tempReports[index].isForAll = false;
                setReports(tempReports);
                setChanges(true);
              }}
            >
              {!reports[index].isForAll ? (
                <RadioButtonSelected width={14} height={14} viewBox="0 0 14 14" />
              ) : (
                <RadioButton width={14} height={14} viewBox="0 0 14 14" />
              )}
              <Box className={classes.radioButtonTxt}>{radioButtonCustom}</Box>
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.menuGroupWrap}>{!reports[index].isForAll ? <>{props.children}</> : <></>}</Box>
      </Paper>
    );
  };
  const handleChangeInput = (event, type, index) => {
    setChanges(true);
    let tempReports = [...reports];
    tempReports[index][type] = event.target.value;
    setReports(tempReports);
  };
  const handleChangeSelect = (event, role, index) => {
    setChanges(true);
    let tempReports = [...reports];
    let reportIndex = tempReports[index].roles.findIndex(obj => obj.roleId == event.target.value);
    tempReports[index].roles[reportIndex].isChecked =
      tempReports[index].roles[reportIndex].isChecked === true ? false : true;
    setReports(tempReports);
  };
  const handleSaveReports = async () => {
    setIsLoading(true);
    let tempReports = JSON.parse(JSON.stringify(reports));
    tempReports = tempReports.map((report, index) => {
      report.roles = report.roles.filter(filter => filter.isChecked);
      report.roles = report.roles.map((role, index) => {
        return role.roleId;
      });
      return report;
    });
    let data = {
      reports: tempReports,
    };
    try {
      const response = await updateProjectReports(data);
      setReports(response.data.reports);
      setIsLoading(false);
    } catch (error) {
      return console.log(error);
    }
  };
  const closePoper = async (e, index) => {
    console.log('test');
    console.log(e.target.value);
  };
  useEffect(() => {
    async function fetchProjectReports() {
      try {
        const response = await getProjectReports();
        setReports(response.reports);
        setRoles(response.roles);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    }
    fetchProjectReports();
  }, [intl]);
  return (
    <Page title={pageTitle} width="100%" className={classes.ProjectsContainerRoot}>
      <Grid
        container
        marginTop={1}
        spacing={5}
        alignItems="center"
        justifyContent="space-between"
        display="flex"
        position="relative"
      >
        <Typography variant="h1">{pageTitle}</Typography>
        <Box className={classes.actionsBlock}>
          <Button
            disabled={!changes}
            onClick={handleSaveReports}
            variant="cutBluePrimary"
            className={classes.saveReportButton}
          >
            <FormattedMessage id="save_text" defaultMessage="Save" />
          </Button>
          <Button
            disabled={!changes}
            onClick={handleCancelReports}
            variant="cutBluePrimary"
            className={classes.cancelReportButton}
          >
            <FormattedMessage id="cancel" defaultMessage="cancel" />
          </Button>
        </Box>
      </Grid>
      <Grid container marginTop={5} spacing={5} position="relative">
        {!isLoading ? (
          <Grid item xs={11} className={classes.settingsWrap}>
            <TableContainer className={classes.ProjectsListViewTableRootWrap}>
              <Table className={classes.ProjectsListViewTableRoot}>
                <TableHead align="left">
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCellHead}>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography variant="h4" fontWeight={700}>
                        Название
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCellHead}>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography variant="h4" fontWeight={700}>
                        Ссылка
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCellHead}>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography variant="h4" fontWeight={700}>
                        Видимое
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCellHead}></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {reports.map((report, index) => (
                    <TableRow key={index}>
                      {!report.isDeleted ? (
                        <>
                          <TableCell className={classes.tableCell} width="15%">
                            <TextField
                              hiddenLabel
                              placeholder={intl.formatMessage({ id: 'enter' })}
                              className={classes.nameInput}
                              onChange={(e, val) => handleChangeInput(e, 'name', index)}
                              value={report.name}
                              variant="filled"
                            />
                          </TableCell>
                          <TableCell className={classes.tableCell} width="60%">
                            <TextField
                              hiddenLabel
                              value={report.url}
                              placeholder={intl.formatMessage({ id: 'enter' })}
                              error={isUrlValid(report.url)}
                              helperText={isUrlValid(report.url) ? intl.formatMessage({ id: 'incorrect_url' }) : ''}
                              onChange={(e, val) => handleChangeInput(e, 'url', index)}
                              className={classes.nameInput}
                              fullWidth
                              variant="filled"
                            />
                          </TableCell>
                          <TableCell className={classes.tableCell} width="23%">
                            <Autocomplete
                              multiple
                              options={report.roles}
                              value={report.roles}
                              disableCloseOnSelect
                              disableClearable
                              className={classes.autocompleteRolesList}
                              PaperComponent={(e, val) => PaperComponent(e, index)}
                              PopperComponent={props => (
                                <ClickAwayListener onClickAway={e => closePoper(e, index)}>
                                  <Popper {...props} className={classes.customPopper} placement="bottom"></Popper>
                                </ClickAwayListener>
                              )}
                              onChange={(e, val) => handleChangeSelect(e, val, index)}
                              clearIcon={null}
                              getOptionLabel={option => option.roleName}
                              ChipProps={{
                                className: classes.subItemChip,
                                deleteIcon: null,
                              }}
                              renderOption={(props, option, { selected }) => (
                                <MenuItem {...props} key={option.roleId} value={option.roleId}>
                                  <Checkbox
                                    icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                                    checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                                    style={{ marginRight: 8 }}
                                    value={option.roleId}
                                    checked={option.isChecked}
                                  />
                                  {option.roleName}
                                </MenuItem>
                              )}
                              style={{ width: 500 }}
                              renderInput={params => {
                                let selectedReports = report.roles.filter(filter => filter.isChecked);
                                let selectedReportsNames = selectedReports.map(role => role.roleName).join(', ');
                                if (report.isForAll) {
                                  selectedReportsNames = '';
                                }
                                params.InputProps.startAdornment = '';
                                return (
                                  <Box className={classes.flexBox}>
                                    {report.isForAll ? (
                                      <Box className={classes.allBox}>{intl.formatMessage({ id: 'all_users' })}</Box>
                                    ) : (
                                      <Box className={classes.allBox}>{selectedReportsNames}</Box>
                                    )}
                                    <TextField
                                      placeholder={intl.formatMessage({ id: 'choose' })}
                                      className={classes.txtField}
                                      {...params}
                                      value={selectedReportsNames}
                                    />
                                  </Box>
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell className={classes.tableCell} width="2%">
                            <IconButton
                              disableRipple
                              className={classes.addRowButton}
                              onClick={e => deleteReport(report, index)}
                            >
                              <ReportDeleteIcon width={9.33} height={12} viewBox="0 0 9.33 12" />
                            </IconButton>
                          </TableCell>
                        </>
                      ) : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <IconButton disableRipple className={classes.addRowButton} onClick={addReport}>
              <PlusIcon width={24} height={24} viewBox="0 0 24 24" />
            </IconButton>
          </Grid>
        ) : (
          <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
            <CircularProgress color="secondary" />
          </Grid>
        )}
      </Grid>
    </Page>
  );
};
export default injectIntl(ProjectReports);
