import { Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import DeleteFileIcon from '../../../../packages/common/shared-ui/src/icons/DeleteFileIcon';
import React, { useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import { uploadFile } from '../../../../packages/common/api';
import issueFormFieldStyles from './IssueFormField.styles';

const IssueFileUpload = ({ intl, handleUpload }) => {
  const issueFormClasses = issueFormFieldStyles();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileUpload = useCallback(
    async event => {
      setLoading(true);
      const { data: fileData } = await uploadFile(event.target.files[0], event.target.files[0].name);
      if (fileData.upload.token) {
        const data = {
          token: fileData.upload.token,
          fileName: event.target.files[0].name,
          contentType: event.target.files[0].type,
        };
        setFile(data);
        handleUpload(data);
        setLoading(false);
      }
    },
    [handleUpload],
  );

  const handleDeleteIssueFile = useCallback(() => {
    setFile(null);
    handleUpload(null);
  }, [handleUpload]);

  return (
    <>
      {file ? (
        <Grid container sx={{ height: '36px' }} alignItems={'center'}>
          <Grid item>
            <Typography variant="h4" color={'#7174AC'}>
              {file.fileName}
            </Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', marginLeft: '13px' }}>
            <IconButton
              className={issueFormClasses.hoveredIcon}
              sx={{ padding: '0', marginLeft: '4px' }}
              onClick={() => handleDeleteIssueFile()}
            >
              <DeleteFileIcon />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems={'center'}>
          <Button variant="defaultGreyPrimary" component="label" sx={{ width: '115px', padding: '6px 16px' }}>
            <Typography variant="h5">{intl.formatMessage({ id: 'choose_file' })}</Typography>
            <input hidden multiple type="file" onChange={event => handleFileUpload(event)} />
          </Button>
          {loading && (
            <Grid container alignItems="center" justifyContent="center" width="30px" height="30px" marginLeft={'10px'}>
              <CircularProgress size={20} color="secondary" />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default injectIntl(IssueFileUpload);
