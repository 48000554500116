import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { injectIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { getIssueConfirmationById } from '../../../../../packages/common/api';
import issuePageStyles from '../../IssuePage.styles';
import dayjs from 'dayjs';
import { generateRedirectUrl } from '../../../../../packages/common/utils/getRedirectUrl';

const IssueConfirmations = ({ intl, projectId }) => {
  const params = useParams();
  const issueCommonStyles = issuePageStyles();
  const [confirmations, setConfirmations] = useState(null);

  useEffect(() => {
    let ignore = false;
    async function getConfirmations() {
      const { data: children } = await getIssueConfirmationById(params.id);
      if (!ignore) {
        return children;
      }
    }
    getConfirmations()
      .then(res => {
        setConfirmations(res);
      })
      .catch(error => console.error(error, 'ERROR IN GET ISSUE'));
    return () => {
      ignore = true;
    };
  }, [params.id]);
  const handleUrl = useCallback((columnType, value) => {
    switch (columnType) {
      case 'confirmation.createdBy':
        return generateRedirectUrl({ host: window.location.origin, path: `/users/${value.valueId}` });
      case 'confirmation.confirmation':
        return `${window.location.origin}/confirmations/${value.valueId}`;
      default:
        return '';
    }
  }, []);
  const RowCellComponent = useCallback(
    ({ type, value }) => {
      if (!value.valueName) {
        return '';
      }
      if (type === 'confirmation.confirmation' || type === 'confirmation.createdBy') {
        return (
          <Link to={handleUrl(type, value)} className={issueCommonStyles.rowLink} target={'_blank'}>
            {value.valueName}
          </Link>
        );
      }
      return (
        <Typography component="span" color={'#212346'} fontSize={'14px'} lineHeight={'20px'}>
          {type === 'issue.start' || type === 'issue.end'
            ? dayjs(value.valueName).format('DD.MM.YYYY')
            : value.valueName}
        </Typography>
      );
    },
    [handleUrl, issueCommonStyles.rowLink],
  );

  return (
    <>
      <Grid container alignItems={'center'}>
        <Grid item>
          <Typography variant="h3" color={'#082253'} fontWeight={600}>
            {intl.formatMessage({ id: 'issue_page_confirmations_title' })}
          </Typography>
        </Grid>
      </Grid>
      {confirmations && confirmations.rows.length > 0 && (
        <TableContainer sx={{ marginTop: '10px' }}>
          <Table>
            <TableHead>
              <TableRow>
                {confirmations.fields.map((item, index) => (
                  <TableCell key={index} sx={{ border: 'none', padding: '0 4px 4px 0!important' }}>
                    <Typography variant="h4" color={'#212346'} fontWeight={600}>
                      {intl.formatMessage({
                        id: `issue_page_confirmations_${item.field}`,
                      })}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {confirmations.rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.columns.map((column, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        border: 'none',
                        padding: '4px 4px 4px 0!important',
                      }}
                    >
                      <RowCellComponent
                        type={column.fieldId}
                        value={{ valueName: column.value, valueId: column.valueId }}
                      ></RowCellComponent>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default injectIntl(IssueConfirmations);
