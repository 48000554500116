import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  MenuItemRoot: {
    position: 'relative',
    minHeight: 32,
    paddingLeft: theme.spacing(15),
    whiteSpace: 'nowrap',
    width: '100%',
    height: '100%',
    '& a': {
      color: '#212346',
    },
    '&:hover': {
      backgroundColor: '#DCDFF4',
    },
    '& .MuiTypography-root': {
      maxWidth: 210,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    [theme.breakpoints.maxWidth('sm')]: {
      color: '#E4E4EF',
      paddingLeft: theme.spacing(12.5),
      whiteSpace: 'unset',
      '&:hover': {
        color: '#212346',
      },
      '& .MuiTypography-root': {
        maxWidth: 170,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  commentTooltip: {
    '& .MuiTooltip-tooltip': {
      marginTop: '0 !important',
      backgroundColor: '#F1F2F8',
      borderRadius: theme.spacing(4),
      padding: theme.spacing(2),
      color: '#212346',
    },
    '&.MuiTooltip-popper': {
      zIndex: 6000,
    },
  },
  menuSubItems: {
    '&.MuiPopover-root': {
      zIndex: 2000,
      width: 300,
    },
  },
  menuSubItem: {
    color: '#082253',
    paddingLeft: theme.spacing(4.5),
    '& .MuiTypography-root': {
      maxWidth: 305,
    },
    '&:hover': {
      backgroundColor: '#DCDFF4',
      textDecoration: 'underline',
    },
    [theme.breakpoints.maxWidth('sm')]: {
      paddingLeft: theme.spacing(15),
      height: 'unset',
    },
  },
  menuSubItemsPaper: {
    '&.MuiPaper-root': {
      maxWidth: 305,
      width: '100%',
      minHeight: 32,
      borderRadius: theme.spacing(0, 2, 2, 0),
      boxShadow: '0px 5px 10px -10px #212346',
    },
  },
  menuItemIcon: {
    position: 'absolute',
    left: 20,
    top: 0,
    bottom: 0,
    margin: 'auto 0',
    width: 32,
    height: 32,
    padding: theme.spacing(1),
    cursor: 'pointer',
    '& svg': {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.maxWidth('sm')]: {
      color: '#7174AC',
      left: 10,
    },
  },
  dropdownIcon: {
    right: 0,
    left: 'unset',
    [theme.breakpoints.maxWidth('sm')]: {
      right: 'unset',
      left: 10,
    },
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  dropdownMenu: {
    width: '100%',
    cursor: 'pointer',
    userSelect: 'none',
  },
  menuItemLink: {
    width: '100%',
  },
  anotherUrlLink: {
    cursor: 'pointer',
  },
  dropdownItem: {
    backgroundColor: '#DCDFF4',
    color: '#082253',
  },
  projectItem: {
    backgroundColor: '#F0F1F8',
    margin: '16px 0 8px 0!important',
    padding: '6px 10px !important',
    whiteSpace: 'normal',
  },
  itemLink: {
    userSelect: 'none',
    pointerEvents: 'none',
  },
}));

export default useStyles;
