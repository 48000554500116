import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Box, useMediaQuery } from '@mui/material';
import NotificationIcon from 'Common/shared-ui/src/icons/NotificationIcon';
import FaqIcon from 'Common/shared-ui/src/icons/FaqIcon';

import useStyles from './HeaderNavIcons.styles';

const HeaderNavIcons = () => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('sm'));
  return (
    <Box display="flex" flexWrap="nowrap" alignItems="center" className={classes.HeaderIconsRoot}>
      <Link to="/" className={clsx(classes.icon, classes.notificationIcon)}>
        <NotificationIcon viewBox="0 0 24 24" />
      </Link>
      {!isSmallScreen && (
        <Link to="/faq" className={classes.icon}>
          <FaqIcon className={classes.faqIcon} viewBox="0 0 24 24" />
        </Link>
      )}
    </Box>
  );
};

export default HeaderNavIcons;
