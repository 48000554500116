import { createSlice, current } from '@reduxjs/toolkit';
import { getUser, signInWithLoginOrEmail } from '../../../auth/actions';
import { LOCALES } from 'Common/shared-ui/src/i18n/locales';

const initialState = {
  isLoading: true,
  info: null,
  redmine_access_token: '',
  error: '',
  language: LOCALES.RUSSIAN,
  isDrawerOpen: typeof window === 'object' ? window.innerWidth >= 576 : null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAppLang: (state, action) => {
      state.language = action.payload;
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },
    setResplanTableColumns: (state, action) => {
      state.resplanColumns = action.payload;
    },
    setResplanTableRows: (state, action) => {
      let last = current(state);
      let userId;
      let projectId;
      let trackerId;
      let contractId;
      let groupRow = [];
      let newCols;
      const rows = action.payload.rows;
      if (rows?.length > 0) {
        const perloadRowIndex = rows.findIndex(item => item.rowType === 'perload');
        if (perloadRowIndex !== -1) {
          groupRow = [rows[perloadRowIndex - 1]];
          groupRow = groupRow?.reduce((acc, row) => {
            newCols = groupRow[0].columns.reduce(
              (columnAcc, column) => {
                const perloadId = `resourcePlan.${column.fieldId}`;
                columnAcc[perloadId] = column.value;
                return columnAcc;
              },
              { columns: row.columns },
            );
            return [
              ...acc,
              {
                ...newCols,
              },
            ];
          }, []);
          rows.splice(perloadRowIndex - 1, 1);
        }
        action.payload.rows = action.payload.rows.reduce((acc, row) => {
          if (row.rowType === 'perload') {
            newCols = row.columns.reduce(
              (columnAcc, column) => {
                const perloadId = column.fieldId.startsWith('monthly_distribution')
                  ? `resourcePlan.${column.fieldId}`
                  : column.fieldId;
                columnAcc[perloadId] = column.value;
                const groupId = `resourcePlan.${column.fieldId}.group`;
                columnAcc[groupId] = groupRow[0][perloadId];
                return columnAcc;
              },
              { columns: row.columns },
            );
          } else {
            newCols = row.columns.reduce(
              (columnAcc, column) => {
                if (column.fieldId === 'resourcePlan.user') {
                  userId = column.valueId;
                }
                if (column.fieldId === 'resourcePlan.project') {
                  projectId = column.valueId;
                }
                if (column.fieldId === 'resourcePlan.tracker') {
                  trackerId = column.valueId;
                }
                if (column.fieldId === 'resourcePlan.contract') {
                  contractId = column.valueId;
                }
                const fieldId = column.fieldId.startsWith('monthly_distribution')
                  ? `resourcePlan.${column.fieldId}`
                  : column.fieldId;
                columnAcc[fieldId] = column.value;

                return columnAcc;
              },
              { columns: row.columns },
            );
          }
          return [
            ...acc,
            {
              id: row.rowId + row.rowType,
              rowId: row.rowId,
              rowType: row.rowType,
              groupId: row.groupId,
              ...newCols,
              services: row.services,
              userId: userId,
              projectId: projectId,
              trackerId: trackerId,
              contractId: contractId,
            },
          ];
        }, []);
      }
      if (last.resplanRows !== undefined) {
        let newValue = { fields: action.payload.fields, rows: [...last.resplanRows.rows, ...action.payload.rows] };
        state.resplanRows = newValue;
      } else {
        state.resplanRows = action.payload;
      }
    },
    clearResplanTableRows: (state, action) => {
      state.resplanRows = { fields: [], rows: [] };
    },
    setResplanRowsToUpdate: (state, action) => {
      let last = current(state);
      let userId;
      let projectId;
      let trackerId;
      let contractId;
      let groupRow = [];
      let newCols;
      const rows = action.payload;
      if (rows?.length > 0) {
        const perloadRowIndex = rows.findIndex(item => item.rowType === 'perload');
        if (perloadRowIndex !== -1) {
          groupRow = [rows[perloadRowIndex - 1]];
          groupRow = groupRow?.reduce((acc, row) => {
            newCols = groupRow[0].columns.reduce(
              (columnAcc, column) => {
                const perloadId = `resourcePlan.${column.fieldId}`;
                columnAcc[perloadId] = column.value;
                return columnAcc;
              },
              { columns: row.columns },
            );
            return [
              ...acc,
              {
                ...newCols,
              },
            ];
          }, []);
          rows.splice(perloadRowIndex - 1, 1);
        }
        action.payload = action.payload.reduce((acc, row) => {
          if (row.rowType === 'perload') {
            newCols = row.columns.reduce(
              (columnAcc, column) => {
                const perloadId = column.fieldId.startsWith('monthly_distribution')
                  ? `resourcePlan.${column.fieldId}`
                  : column.fieldId;
                columnAcc[perloadId] = column.value;
                const groupId = `resourcePlan.${column.fieldId}.group`;
                columnAcc[groupId] = groupRow[0][perloadId];
                return columnAcc;
              },
              { columns: row.columns },
            );
          } else {
            newCols = row.columns.reduce(
              (columnAcc, column) => {
                if (column.fieldId === 'resourcePlan.user') {
                  userId = column.valueId;
                }
                if (column.fieldId === 'resourcePlan.project') {
                  projectId = column.valueId;
                }
                if (column.fieldId === 'resourcePlan.tracker') {
                  trackerId = column.valueId;
                }
                if (column.fieldId === 'resourcePlan.contract') {
                  contractId = column.valueId;
                }
                const fieldId = column.fieldId.startsWith('monthly_distribution')
                  ? `resourcePlan.${column.fieldId}`
                  : column.fieldId;
                columnAcc[fieldId] = column.value;

                return columnAcc;
              },
              { columns: row.columns },
            );
          }
          return [
            ...acc,
            {
              id: row.rowId + row.rowType,
              rowId: row.rowId,
              rowType: row.rowType,
              groupId: row.groupId,
              ...newCols,
              services: row.services,
              userId: userId,
              projectId: projectId,
              trackerId: trackerId,
              contractId: contractId,
            },
          ];
        }, []);
      }
      state.resplanRowsToUpdate = action.payload;
    },

    // setResplanRowsToUpdate: (state, action) => {
    //   const rows = action.payload;
    //   if (rows?.length > 0) {
    //     const groupRowIndex = rows.findIndex(item => item.rowType === 'group');
    //     const perloadRowIndex = rows.findIndex(item => item.rowType === 'perload');

    //     let monthlyDistribution = {};

    //     if (groupRowIndex !== -1) {
    //       rows[groupRowIndex].columns.forEach(column => {
    //         monthlyDistribution[column.fieldId] = { group: { ...column } };
    //       });

    //       if (perloadRowIndex !== -1) {
    //         rows[perloadRowIndex].columns.forEach(column => {
    //           if (monthlyDistribution[column.fieldId]) {
    //             monthlyDistribution[column.fieldId].perload = { ...column };
    //           } else {
    //             monthlyDistribution[column.fieldId] = { perload: { ...column } };
    //           }
    //         });

    //         rows.splice(perloadRowIndex, 1);
    //       }

    //       rows[groupRowIndex].columns = monthlyDistribution;
    //     }
    //     action.payload = rows;
    //   }
    //   state.resplanRowsToUpdate = action.payload;
    // },

    addResplanNewIssue: (state, action) => {
      let last = current(state);
      let userId;
      let projectId;
      let trackerId;
      let contractId;
      let groupRow = [];
      let newCols;
      const rows = action.payload;
      if (rows?.length > 0) {
        const perloadRowIndex = rows.findIndex(item => item.rowType === 'perload');
        if (perloadRowIndex !== -1) {
          groupRow = [rows[perloadRowIndex - 1]];
          groupRow = groupRow?.reduce((acc, row) => {
            newCols = groupRow[0].columns.reduce(
              (columnAcc, column) => {
                const perloadId = `resourcePlan.${column.fieldId}`;
                columnAcc[perloadId] = column.value;
                return columnAcc;
              },
              { columns: row.columns },
            );
            return [
              ...acc,
              {
                ...newCols,
              },
            ];
          }, []);
          rows.splice(perloadRowIndex - 1, 1);
        }
        action.payload = action.payload.reduce((acc, row) => {
          if (row.rowType === 'perload') {
            newCols = row.columns.reduce(
              (columnAcc, column) => {
                const perloadId = column.fieldId.startsWith('monthly_distribution')
                  ? `resourcePlan.${column.fieldId}`
                  : column.fieldId;
                columnAcc[perloadId] = column.value;
                const groupId = `resourcePlan.${column.fieldId}.group`;
                columnAcc[groupId] = groupRow[0][perloadId];
                return columnAcc;
              },
              { columns: row.columns },
            );
          } else {
            newCols = row.columns.reduce(
              (columnAcc, column) => {
                if (column.fieldId === 'resourcePlan.user') {
                  userId = column.valueId;
                }
                if (column.fieldId === 'resourcePlan.project') {
                  projectId = column.valueId;
                }
                if (column.fieldId === 'resourcePlan.tracker') {
                  trackerId = column.valueId;
                }
                if (column.fieldId === 'resourcePlan.contract') {
                  contractId = column.valueId;
                }
                const fieldId = column.fieldId.startsWith('monthly_distribution')
                  ? `resourcePlan.${column.fieldId}`
                  : column.fieldId;
                columnAcc[fieldId] = column.value;

                return columnAcc;
              },
              { columns: row.columns },
            );
          }
          return [
            ...acc,
            {
              id: row.rowId + row.rowType,
              rowId: row.rowId,
              rowType: row.rowType,
              groupId: row.groupId,
              ...newCols,
              services: row.services,
              userId: userId,
              projectId: projectId,
              trackerId: trackerId,
              contractId: contractId,
            },
          ];
        }, []);
      }
      state.resplanNewIssue = action.payload;
    },
    setStopLoadingRespanTableBySocket: (state, action) => {
      state.resplanStopLoading = action.payload;
    },
    setHasResplanRows: (state, action) => {
      state.hasResplanRows = action.payload;
    },
    setHasResplanUnsavedCash: (state, action) => {
      state.hasResplanUnsavedCash = action.payload;
    },
    setStorageChosenItems: (state, action) => {
      state.storageChosenItems = action.payload;
    },
    setLastIssuesSearch: (state, action) => {
      state.lastIssuesSearch = action.payload;
    },
    toggleDrawer: (state, action) => {
      state.isDrawerOpen = action.payload;
    },
    setAgileFiltersToDelete: (state, action) => {
      state.agileFiltersToDelete = action.payload;
    },
  },
  extraReducers: {
    // SIGN IN
    [signInWithLoginOrEmail.pending.type]: state => {
      state.isLoading = true;
    },
    [signInWithLoginOrEmail.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.info = action.payload.current_user;
      state.language = action.payload.language || LOCALES.RUSSIAN;
      state.redmine_access_token = action.payload.redmine_access_token;
    },
    [signInWithLoginOrEmail.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET USER DATA
    [getUser.pending.type]: state => {
      state.isLoading = true;
    },
    [getUser.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.info = action.payload;
      // state.language = action.payload.language ?? LOCALES.RUSSIAN;
    },
    [getUser.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setAppLang,
  toggleDrawer,
  setProject,
  setResplanTableColumns,
  setResplanTableRows,
  clearResplanTableRows,
  setStopLoadingRespanTableBySocket,
  setResplanRowsToUpdate,
  addResplanNewIssue,
  setHasResplanRows,
  setHasResplanUnsavedCash,
  setAgileFiltersToDelete,
} = userSlice.actions;

export default userSlice.reducer;
