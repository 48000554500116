import React, { useCallback, useReducer } from 'react';
import { Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import clsx from 'clsx';
import useStyles from './SpreadsheetMobileCreateRow.styles';
import ClearIcon from '../../../../packages/common/shared-ui/src/icons/ClearIcon';

const SpreadsheetMobileCreateRow = ({
  day,
  intl,
  currentWeek,
  currentIssue,
  disabled,
  setWeekSum,
  timeType,
  timeTypes,
  setEditedFormattedIssues,
  type,
}) => {
  const classes = useStyles();
  const comment = intl.formatMessage({ id: 'comment' });
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const handleChangeDayHour = useCallback(
    e => {
      const regex = /^([01]?[0-9]|2[0-4])([,.][0-9]{1,2})?$/;
      const currentDay = currentWeek.find(day => day.date === e.target.name);
      if (type !== 'edit') {
        currentWeek[currentWeek.indexOf(currentDay)].redmineId = currentDay.redmineId;
        currentWeek[currentWeek.indexOf(currentDay)].issueId = currentIssue.issueId;
      }
      currentWeek[currentWeek.indexOf(currentDay)].isEdited =
        type === 'edit'
          ? true
          : !(Number(e.target.value) === 0 || e.target.value.length === 0 || !regex.test(e.target.value));
      currentWeek[currentWeek.indexOf(currentDay)].hours =
        regex.test(e.target.value) || e.target.value === '' ? Number(e.target.value) : '';
      setEditedFormattedIssues(prevState => ({
        ...prevState,
        [currentIssue.issueUniqueId]: {
          ...currentIssue,
          [timeType === timeTypes.MAIN_HOURS ? 'weekDays' : 'overTimeWeekDays']: currentWeek,
        },
      }));
      setWeekSum(currentWeek.reduce((acc, day) => acc + Number(day.hours), 0));
      forceUpdate();
    },
    [currentIssue, currentWeek, setEditedFormattedIssues, setWeekSum, timeType, timeTypes.MAIN_HOURS, type],
  );

  const handleChangeDayComment = useCallback(
    e => {
      const currentDay = currentWeek.find(day => day.date === e.target.name);
      currentWeek[currentWeek.indexOf(currentDay)].comments = e.target.value;
      setEditedFormattedIssues(prevState => ({
        ...prevState,
        [currentIssue.issueUniqueId]: {
          ...currentIssue,
          [timeType === timeTypes.MAIN_HOURS ? 'weekDays' : 'overTimeWeekDays']: currentWeek,
        },
      }));
      forceUpdate();
    },
    [currentIssue, currentWeek, setEditedFormattedIssues, timeType, timeTypes.MAIN_HOURS],
  );

  const handleClearDay = useCallback(
    e => {
      const currentDay = currentWeek.find(day => day.date === e.target.value);
      const currentDayIndex = currentWeek.indexOf(currentDay);
      if (currentDay.hours !== 0 || currentDay.comments.length !== 0) {
        currentWeek[currentWeek.indexOf(currentDay)].isEdited = true;
        currentWeek[currentDayIndex].hours = 0;
        currentWeek[currentDayIndex].comments = '';
        setWeekSum(currentWeek.reduce((acc, day) => acc + Number(day.hours), 0));
        setEditedFormattedIssues(prevState => ({
          ...prevState,
          [currentIssue.issueUniqueId]: {
            ...currentIssue,
            [timeType === timeTypes.MAIN_HOURS ? 'weekDays' : 'overTimeWeekDays']: currentWeek,
          },
        }));
      }
      forceUpdate();
    },
    [currentIssue, currentWeek, setEditedFormattedIssues, setWeekSum, timeType, timeTypes.MAIN_HOURS],
  );

  return (
    <Grid container direction="column" marginTop={2.5}>
      <Box marginBottom={1}>
        <Typography variant="h5">
          {day.dayOfWeek}, {dayjs(day.date).format('DD.MM')}
        </Typography>
      </Box>
      <Grid container wrap="nowrap">
        <Box marginRight={2.5} maxWidth={65} height={48}>
          <TextField
            variant="filled"
            className={classes.mobileCreateField}
            onChange={handleChangeDayHour}
            value={day.hours}
            name={day.date}
            color={day.hours === 0 ? '#7174AC' : '#212346'}
            disabled={disabled}
          />
        </Box>
        <Box height={48} width="100%">
          <TextField
            variant="filled"
            className={clsx(classes.mobileCreateField, classes.mobileCommentField)}
            InputLabelProps={{ className: classes.mobileCommentLabel }}
            label={comment}
            onChange={handleChangeDayComment}
            value={day.comments}
            name={day.date}
            disabled={disabled || day.hours === 0}
          />
        </Box>
        {type === 'edit' && (
          <Box display="flex" alignItems="center" justifyContent="center" maxWidth={40}>
            <IconButton disableRipple onClick={handleClearDay} value={day.date} className={classes.clearButton}>
              <ClearIcon width={24} height={24} viewBox="0 0 24 24" value={day.date} />
            </IconButton>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default SpreadsheetMobileCreateRow;
