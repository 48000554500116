import React from 'react';
import { Box, Grid, Typography, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { ProjectsData, TasksData } from '../SettingsData';
import { FormattedMessage, injectIntl } from 'react-intl';
import useStyles from './SettingsProjectTasks.styles';
import SettingsTableCells from '../SettingsTableCells/SettingsTableCells';

const SettingsProjectTasks = ({ intl }) => {
  const classes = useStyles();
  const taskName = intl.formatMessage({ id: 'settings_task_name' });
  const taskOpened = intl.formatMessage({ id: 'settings_task_opened' });
  const taskClosed = intl.formatMessage({ id: 'settings_task_closed' });
  const projectName = intl.formatMessage({ id: 'settings_project_name' });
  const projectRole = intl.formatMessage({ id: 'settings_project_role' });
  const projectRegdate = intl.formatMessage({ id: 'settings_project_reg_date' });

  return (
    <>
      <Grid
        item
        container
        xs={6}
        marginTop={5}
        gap={7}
        position="relative"
        justifyContent="space-between"
        padding={5}
        className={classes.settingsTableWrap}
      >
        <Box position="relative" className={classes.boxWrap}>
          <Typography variant="h3" marginBottom={5} fontWeight={600}>
            <FormattedMessage id="settings_task_header" defaultMessage="Tasks" />
          </Typography>
          <Table className={classes.spentTimeTableRoot}>
            <TableHead>
              <TableRow>
                <SettingsTableCells content={taskName} weight="700" style={{ width: '70%' }} />
                <SettingsTableCells content={taskOpened} weight="700" style={{ width: '15%' }} />
                <SettingsTableCells content={taskClosed} weight="700" style={{ width: '15%' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {TasksData.map(task => (
                <TableRow key={task.id}>
                  <SettingsTableCells content={task.name} />
                  <SettingsTableCells content={task.open} />
                  <SettingsTableCells content={task.close} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Grid>
      <Grid
        item
        container
        xs={6}
        marginTop={5}
        gap={7}
        position="relative"
        padding={5}
        className={classes.settingsTableWrap}
      >
        <Box position="relative" className={classes.boxWrap}>
          <Typography variant="h3" marginBottom={5} fontWeight={600}>
            <FormattedMessage id="settings_project_header" defaultMessage="Projects" />
          </Typography>
          <Table className={classes.spentTimeTableRoot}>
            <TableHead>
              <TableRow>
                <SettingsTableCells content={projectName} weight="700" style={{ width: '30%' }} />
                <SettingsTableCells content={projectRole} weight="700" style={{ width: '60%' }} />
                <SettingsTableCells content={projectRegdate} weight="700" style={{ width: '30%' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {ProjectsData.map(project => (
                <TableRow key={project.id}>
                  <SettingsTableCells content={project.name} />
                  <SettingsTableCells content={project.role} />
                  <SettingsTableCells content={project.reg} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Grid>
    </>
  );
};

export default injectIntl(SettingsProjectTasks);
