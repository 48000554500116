import React from 'react';
import { SvgIcon } from '@mui/material';

const AttachIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_16676_39624)">
          <path
            d="M10.9993 4.00033V11.667C10.9993 13.1403 9.80602 14.3337 8.33268 14.3337C6.85935 14.3337 5.66602 13.1403 5.66602 11.667V3.33366C5.66602 2.41366 6.41268 1.66699 7.33268 1.66699C8.25268 1.66699 8.99935 2.41366 8.99935 3.33366V10.3337C8.99935 10.7003 8.69935 11.0003 8.33268 11.0003C7.96602 11.0003 7.66602 10.7003 7.66602 10.3337V4.00033H6.66602V10.3337C6.66602 11.2537 7.41268 12.0003 8.33268 12.0003C9.25268 12.0003 9.99935 11.2537 9.99935 10.3337V3.33366C9.99935 1.86033 8.80602 0.666992 7.33268 0.666992C5.85935 0.666992 4.66602 1.86033 4.66602 3.33366V11.667C4.66602 13.6937 6.30602 15.3337 8.33268 15.3337C10.3593 15.3337 11.9993 13.6937 11.9993 11.667V4.00033H10.9993Z"
            fill="#7174AC"
          />
        </g>
        <defs>
          <clipPath id="clip0_16676_39624">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default AttachIcon;
