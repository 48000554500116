import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Outlet } from 'react-router';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
  },
  content: {
    flexGrow: 1,
    maxWidth: '100%',
  },
}));

function BaseRouteLayout() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Outlet />
      </div>
    </div>
  );
}

BaseRouteLayout.propTypes = {
  route: PropTypes.object,
};

export default BaseRouteLayout;
