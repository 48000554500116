import React from 'react';
import { SvgIcon } from '@mui/material';

const DoneIcon = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_4427_8650)">
        <path
          d="M9.00002 16.2001L4.80002 12.0001L3.40002 13.4001L9.00002 19.0001L21 7.0001L19.6 5.6001L9.00002 16.2001Z"
          fill={props.color ? props.color : 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_4427_8650">
          <rect width="24" height="24" fill={props.color ? props.color : 'white'} />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default DoneIcon;
