// ResourcePlanModal.jsx
import useStyles from './ResourcePlanCashModal.styles';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Box, Button, Grid, IconButton, Modal, Typography } from '@mui/material';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';

const ResourcePlanCashModal = ({ isCashOpen, intl, setIsClearing, setIsSaving, handleCashClose }) => {
  const classes = useStyles();
  const handleClick = closeOption => {
    if (closeOption === 'save_all') {
      setIsSaving(true);
      handleCashClose();
    } else if (closeOption === 'cancel_changes') {
      setIsClearing(true);
      handleCashClose();
    }
  };
  return (
    <>
      <Modal
        open={isCashOpen}
        // onClose={handleCashClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalRoot}
      >
        <Grid container direction="column" wrap="nowrap" className={classes.modalPaper}>
          <Grid container justifyContent="right" alignItems="right" wrap="nowrap" marginBottom={10} marginTop={5}>
            {/* <IconButton disableRipple onClick={handleCashClose}>
              <Box width={24} height={24}>
                <ClearIcon width={24} height={24} viewBox="0 0 24 24" />
              </Box>
            </IconButton> */}
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            wrap="nowrap"
            marginBottom={10}
          >
            <Typography variant="h3" fontWeight={700}>
              <FormattedMessage id="resPlan_cash_save_message" defaultMessage="Unsaved editigs" />
            </Typography>
            <Typography variant="h3" fontWeight={700}>
              <FormattedMessage id="resPlan_cash_save_question" defaultMessage="Save?" />
            </Typography>
          </Grid>
          <Grid container justifyContent="center" marginBottom={5}>
            <Button disableRipple variant="defaultBlueSecondary" onClick={() => handleClick('save_all')}>
              <Typography variant="h5" fontWeight={700}>
                <FormattedMessage id="yes" defaultMessage="Yes" />
              </Typography>
            </Button>
            <Box marginLeft={1}>
              <Button disableRipple variant="defaultGreyPrimary" onClick={() => handleClick('cancel_changes')}>
                <Typography variant="h5" fontWeight={700}>
                  <FormattedMessage id="no" defaultMessage="No" />
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default injectIntl(ResourcePlanCashModal);
