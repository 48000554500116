import React, { useCallback, useEffect, useState } from 'react';
import Page from 'Common/shared-ui/src/components/Page';
import { injectIntl } from 'react-intl';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import useStyles from './SettingsContainer.styles';
import SettingsTasksTypes from './SettingsTasksTypes/SettingsTasksTypes';
import SettingsProjectTasks from './SettingsProjectTasks/SettingsProjectTasks';
import { redmineGet } from 'Common/api';
import SettingsUpperLine from './SettingsUpperLine/SettingsUpperLine';

const SettingsContainer = ({ intl }) => {
  const classes = useStyles();
  const [info, setInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const pageTitle = intl.formatMessage({ id: 'page_title_user_settings' });

  const userInfo = useCallback(async () => {
    const { data } = await redmineGet('my/account.json');
    setInfo(data);
    setIsLoading(false);
    console.log(data);
  }, []);
  useEffect(() => {
    userInfo();
  }, [userInfo]);
  return (
    <>
      {!isLoading ? (
        <Page title={pageTitle} width="100%">
          <Box sx={{ width: '100%' }} className={classes.PageWrap}>
            <Grid container>
              <Grid container item display="flex" alignItems="center" spacing={1}>
                <Typography variant="h1">
                  {info.user.lastname} {info.user.firstname}
                </Typography>
              </Grid>
            </Grid>
            <Grid container marginTop={5} position="relative" padding={5} wrap="wrap" className={classes.settingsWrap}>
              {info.user.custom_fields.map(inf => (
                <SettingsUpperLine key={inf.id} header={inf.name} content={inf.value} />
              ))}
            </Grid>
            <Grid container wrap="nowrap" className={classes.settingsMiddleContainer} position="relative" gap={5}>
              <SettingsProjectTasks />
            </Grid>
            <Grid
              item
              container
              direction="column"
              justifyContent="space-between"
              xs={12}
              marginTop={5}
              marginBottom={5}
              gap={7}
              className={classes.settingsLowerContainer}
            >
              <SettingsTasksTypes />
            </Grid>
          </Box>
        </Page>
      ) : (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </>
  );
};

export default injectIntl(SettingsContainer);
