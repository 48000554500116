export default (e, backUpStr) => {
  let message;
  if (e.response && e.response.data) {
    if (typeof e.response.data === 'object' && e.response.data !== null) {
      message = e.response.data.message;
    } else {
      message = e.response.data;
    }
  }
  return message || backUpStr;
};
