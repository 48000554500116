import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  tableRowGroup: {
    height: 36,
    boxShadow: '1px 1px #E4E4EF',
    padding: theme.spacing(2.5),
  },
  selectedRow: {
    backgroundColor: '#DCDFF4',
  },
  tableCell: {
    boxShadow: '1px 1px #E4E4EF',
    width: '100%',
    padding: theme.spacing(2.5),
    minHeight: 40,
    '&:last-child': {
      boxShadow: 0,
    },
    '& .MuiCheckbox-root': {
      padding: 0,
    },
  },
  tableCellLink: {
    '&:hover': {
      backgroundColor: '#DCDFF4',
      textDecoration: 'underline',
    },
  },
  commentTooltip: {
    '& .MuiTooltip-tooltip': {
      marginTop: '0 !important',
      backgroundColor: '#F1F2F8',
      borderRadius: theme.spacing(4),
      padding: theme.spacing(2),
      color: '#212346',
    },
  },
}));

export default useStyles;
