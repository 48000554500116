import React from 'react';
import { SvgIcon } from '@mui/material';

function LogoIconWhite(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M0 27.456L6.01271 19.8067L0.133616 12.3078H4.87698L8.32872 16.949L11.7805 12.3078H16.5461L10.667 19.8067L16.6574 27.456H11.8918L8.32872 22.686L4.76563 27.456H0Z"
        fill="white"
      />
      <path d="M17.6178 21.5015V18.5148H24.744V21.5015H17.6178Z" fill="white" />
      <path d="M27.5569 27.456V12.3078H31.6544V24.1686H38.647V27.456H27.5569Z" fill="white" />
      <path
        d="M44.1243 27.6924C43.2781 27.6924 42.5284 27.542 41.8751 27.2412C41.2368 26.9404 40.732 26.5321 40.3608 26.0164C40.0045 25.4864 39.8264 24.8848 39.8264 24.2115C39.8264 23.4523 40.0342 22.8507 40.4499 22.4066C40.8656 21.9482 41.5337 21.6259 42.4541 21.4397C43.3746 21.2392 44.5994 21.1389 46.1286 21.1389H46.9748V20.8381C46.9748 20.3224 46.8338 19.9571 46.5517 19.7423C46.2845 19.5274 45.8168 19.4199 45.1487 19.4199C44.5846 19.4199 43.961 19.5059 43.2781 19.6778C42.5952 19.8497 41.9271 20.1147 41.2739 20.4728L40.2718 17.9588C40.6429 17.701 41.118 17.4718 41.697 17.2713C42.276 17.0707 42.8847 16.9203 43.5231 16.82C44.1615 16.7054 44.7627 16.6481 45.3269 16.6481C47.153 16.6481 48.504 17.0277 49.3799 17.7869C50.2707 18.5461 50.7161 19.7423 50.7161 21.3753V27.456H47.0194V25.952C46.8264 26.4963 46.4775 26.926 45.9727 27.2412C45.4679 27.542 44.8518 27.6924 44.1243 27.6924ZM45.0151 25.1999C45.5644 25.1999 46.0247 25.028 46.3958 24.6842C46.7818 24.3404 46.9748 23.8892 46.9748 23.3306V22.9438H46.1286C45.2527 22.9438 44.6068 23.0369 44.1912 23.2231C43.7903 23.395 43.5899 23.6744 43.5899 24.0611C43.5899 24.3906 43.7087 24.6627 43.9462 24.8776C44.1986 25.0925 44.5549 25.1999 45.0151 25.1999Z"
        fill="white"
      />
      <path
        d="M52.9733 27.456V16.8845H56.8258V18.3241C57.2118 17.7798 57.7092 17.3644 58.3179 17.0779C58.9266 16.7914 59.6095 16.6481 60.3667 16.6481C61.6731 16.6481 62.6381 17.0206 63.2617 17.7655C63.9001 18.5103 64.2193 19.6563 64.2193 21.2034V27.456H60.2553V21.3538C60.2553 20.7092 60.1366 20.2508 59.899 19.9786C59.6763 19.7064 59.3423 19.5704 58.8969 19.5704C58.303 19.5704 57.828 19.7566 57.4717 20.129C57.1153 20.4871 56.9372 20.9742 56.9372 21.5901V27.456H52.9733Z"
        fill="white"
      />
      <path
        d="M72.068 27.6924C70.8357 27.6924 69.7594 27.4704 68.8389 27.0263C67.9333 26.5822 67.2281 25.9448 66.7233 25.114C66.2186 24.2831 65.9662 23.2948 65.9662 22.1488C65.9662 21.0028 66.2186 20.0216 66.7233 19.2051C67.2281 18.3743 67.9333 17.744 68.8389 17.3142C69.7594 16.8702 70.8357 16.6481 72.068 16.6481C72.7954 16.6481 73.5155 16.7484 74.2281 16.949C74.9555 17.1352 75.5494 17.4002 76.0096 17.744L74.963 20.3654C74.6215 20.1075 74.2207 19.907 73.7604 19.7637C73.315 19.6062 72.8919 19.5274 72.4911 19.5274C71.7042 19.5274 71.0955 19.7566 70.665 20.215C70.2345 20.6733 70.0192 21.318 70.0192 22.1488C70.0192 22.9939 70.2345 23.6529 70.665 24.1256C71.0955 24.584 71.7042 24.8132 72.4911 24.8132C72.8919 24.8132 73.315 24.7415 73.7604 24.5983C74.2207 24.4407 74.6215 24.233 74.963 23.9752L76.0096 26.5966C75.5494 26.9404 74.9555 27.2125 74.2281 27.4131C73.5006 27.5993 72.7806 27.6924 72.068 27.6924Z"
        fill="white"
      />
      <path
        d="M83.0158 27.6924C81.6796 27.6924 80.5216 27.4704 79.5417 27.0263C78.5767 26.5679 77.8344 25.9233 77.3148 25.0925C76.7952 24.2617 76.5354 23.2804 76.5354 22.1488C76.5354 21.0744 76.7803 20.1219 77.2703 19.291C77.775 18.4602 78.458 17.8156 79.319 17.3572C80.195 16.8845 81.1897 16.6481 82.3031 16.6481C83.4018 16.6481 84.3519 16.8702 85.1536 17.3142C85.9702 17.7583 86.5937 18.3957 87.0242 19.2266C87.4696 20.0431 87.6923 21.0028 87.6923 22.1058V22.9438H80.3212C80.4548 23.6314 80.7517 24.1256 81.2119 24.4264C81.6722 24.7272 82.3106 24.8776 83.1271 24.8776C83.6913 24.8776 84.2777 24.7988 84.8864 24.6413C85.5099 24.4694 86.0592 24.2258 86.5343 23.9107L87.5142 26.4247C86.95 26.8114 86.2597 27.1194 85.4431 27.3486C84.6266 27.5778 83.8174 27.6924 83.0158 27.6924ZM82.4813 19.1191C81.8874 19.1191 81.3975 19.291 81.0115 19.6348C80.6404 19.9786 80.4028 20.48 80.2989 21.1389H84.441C84.3965 20.4656 84.2035 19.9643 83.862 19.6348C83.5354 19.291 83.0751 19.1191 82.4813 19.1191Z"
        fill="white"
      />
      <path d="M0 39.9999V33.846H87.6923L0 39.9999Z" fill="#6270D0" />
    </SvgIcon>
  );
}

export default LogoIconWhite;
