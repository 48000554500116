import React, { useCallback, useEffect } from 'react';
import { Box, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import EditIcon from '../../../../packages/common/shared-ui/src/icons/EditIcon';
import useStyles from './SpreadsheetMobileDetail.styles';
import { formatWeek } from '../formatWeek';

const SpreadsheetMobileDetail = ({ query, setQuery, formattedIssues, intl }) => {
  const classes = useStyles();
  const currentIssue = formattedIssues[query.issue];
  const formattedWeek = formatWeek({ startOfWeek: query.start, issue: currentIssue, intl });
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('lg'));

  const handleEditCurrentIssue = useCallback(() => {
    setQuery({ editIssue: currentIssue?.issueUniqueId, issue: null });
  }, [currentIssue, setQuery]);

  useEffect(() => {
    if (!formattedIssues[query.issue] || !isSmallScreen) {
      return setQuery({ issue: null });
    }
  }, [formattedIssues, isSmallScreen, query.issue, setQuery]);

  return (
    <Grid container direction="column">
      <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="h3" fontWeight={700}>
            <FormattedMessage id="spreadsheet_table_issues" />
          </Typography>
          <Typography variant="h5">
            {dayjs(query.start).format('DD MMMM YYYY')} - {dayjs(query.end).format('DD MMMM YYYY')}
          </Typography>
        </Box>
        <IconButton disableRipple className={classes.editIssueButton} onClick={handleEditCurrentIssue}>
          <EditIcon width="24" height="24" viewBox="0 0 24 24" />
        </IconButton>
      </Grid>
      <Grid container direction="column" padding={4}>
        <Box marginBottom={2.5}>
          <Typography variant="h6">
            <FormattedMessage id="spreadsheet_table_project" />
          </Typography>
          <Typography variant="h4">{currentIssue?.projectName}</Typography>
        </Box>
        <Box marginBottom={2.5}>
          <Typography variant="h6">
            <FormattedMessage id="spreadsheet_table_issues" />
          </Typography>
          <Typography variant="h4">{currentIssue?.issueName}</Typography>
        </Box>
        {formattedWeek.map(day => {
          return (
            <Grid container wrap="nowrap" key={day.date} marginTop={2.5}>
              <Box marginRight={7} width={46}>
                <Typography variant="h6" color="#111322">
                  {day.dayOfWeek}, {dayjs(day.date).format('DD.MM')}
                </Typography>
                <Typography variant="h4">{day.hours}</Typography>
              </Box>
              <Box>
                <Typography variant="h6" color="#111322">
                  <FormattedMessage id="comment" />
                </Typography>
                <Typography variant="h4">{day.comments}</Typography>
              </Box>
            </Grid>
          );
        })}
        <Box marginY={2.5}>
          <Typography variant="h4">
            <FormattedMessage id="spreadsheet_week_sum" /> {formattedWeek.reduce((acc, day) => acc + day.hours, 0)}{' '}
            <FormattedMessage id="hour" />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SpreadsheetMobileDetail;
