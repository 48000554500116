import { makeStyles } from '@mui/styles';

const issueWatchersStyles = makeStyles(theme => ({
  filterSelect: {
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
      backgroundColor: '#FFFFFF',
      width: '100%',
      fontSize: '14px',
      lineHeight: '20px',
      border: '1px solid #E4E4EF',
      padding: '8px 10px',
      height: 'auto',
      '&:hover': {
        border: '1px solid #E4E4EF',
        backgroundColor: '#FFFFFF',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        padding: 0,
        border: 'none',
      },
      '& .MuiAutocomplete-input': {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#7174AC',
        padding: '0',
        width: '100%',
        '&::placeholder': {
          fontSize: '12px',
          color: '#7174AC',
        },
      },
      '& .MuiButtonBase-root.MuiChip-root': {
        margin: 0,
        height: '30px',
        borderColor: '#E4E4EF',
        fontSize: '12px',
        lineHeight: '20px',
        '& .MuiChip-label': {
          padding: '0 26px 0 8px',
        },
        '& .MuiSvgIcon-root': {
          marginRight: '8px',
          width: '16px',
          height: '100%',
        },
        '& + .MuiButtonBase-root.MuiChip-root': {
          marginTop: '2px',
        },
      },
    },
    '&.MuiAutocomplete-hasClearIcon': {
      '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
        padding: '3px',
      },
    },
    '&.Mui-expanded': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        //borderRadius: '16px 16px 0 0',
        '& .MuiOutlinedInput-notchedOutline': {
          padding: 0,
        },
      },
    },
    '& + .MuiAutocomplete-popper': {
      minWidth: '180px!important',
      width: 'auto!important',
      maxWidth: '300px!important',
      '& .MuiPaper-root.MuiAutocomplete-paper': {
        //borderRadius: '0 0 16px 16px',
        //borderTop: '1px solid #E4E4EF',
        boxShadow: '0px 5px 10px -8px #A0A1AA66',
        '& .MuiAutocomplete-listbox': {
          padding: '0',
          '& li': {
            whiteSpace: ' nowrap',
            fontSize: '12px',
            lineHeight: '20px',
            color: '#212346',
            padding: '8px 10px',
            '&:hover, &.Mui-focused': {
              backgroundColor: '#DCDFF4',
            },
            '& .MuiButtonBase-root.MuiCheckbox-root': {
              padding: 0,
              marginRight: '10px',
            },
          },
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#7174AC',
            borderRadius: '5px',
          },
        },
      },
    },
  },
}));

export default issueWatchersStyles;
