import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  contextPopover: {
    transform: 'unset !important',
    height: 'fit-content',
    borderRadius: theme.spacing(4),
    '& .MuiPaper-root': {
      overflowY: 'hidden',
      maxWidth: 'unset',
      maxHeight: 'unset',
      width: '100%',
      borderRadius: theme.spacing(4),
      boxShadow: '0px 5px 10px -8px rgba(33, 35, 70, 1)',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F1F2F8',
      marginRight: '2px',
    },
    '&::-webkit-scrollbar': {
      width: '4px',
      backgroundColor: 'white !important',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7174AC',
      borderRadius: '5px',
    },
    '& .MuiBackdrop-root': {
      display: 'none',
      position: 'unset',
    },
  },
  contextPopoverSubItems: {
    '& .MuiPaper-root': {
      boxShadow: 'rgb(0 0 0 / 70%) 5px 3px 12px -8px',
    },
  },
  contextMenuSubItem: {
    borderRadius: theme.spacing(0, 4, 4, 0),
    padding: theme.spacing(1.5, 0),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(220, 223, 244, 1)',
      borderRadius: 0,
    },
  },
  contextLeftUpperCorner: {
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(0, 4, 4, 4),
    },
    borderRadius: theme.spacing(0, 4, 4, 4),
  },
  contextLeftBottomCorner: {
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(4, 4, 4, 0),
    },
    borderRadius: theme.spacing(4, 4, 4, 0),
  },
  contextLeftSideCorners: {
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(0, 4, 4, 0),
    },
    borderRadius: theme.spacing(0, 4, 4, 0),
  },
}));

export default useStyles;
