import React, { useCallback, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Box, Button, CircularProgress, Grid, Tooltip, Pagination, Typography, IconButton } from '@mui/material';
import ButtonSwitcher from 'Common/shared-ui/src/components/ButtonSwitcher';
import Page from 'Common/shared-ui/src/components/Page';
import CustomFilter from 'Common/shared-ui/src/components/CustomFilter';
import IssuesTable from '../IssuesTable';
import IssuesModal from '../IssuesModal';
import SpentTimeQueryModal from '../IssuesQueryModal';
import { withIssuesRequests } from '../withIssuesRequests';
import FilterIcon from 'Common/shared-ui/src/icons/FilterIcon';
import ChevronIcon from 'Common/shared-ui/src/icons/ChevronIcon';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import useStyles from './IssuesContainer.styles';
import { useLocation, useNavigate } from 'react-router';
import WarningModal from '../../../../packages/common/shared-ui/src/components/WarningModal';
import { deleteSavedQuery, getIssuesCsv, getTimesheetCsv } from '../../../../packages/common/api';
import { useSelector } from 'react-redux';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import { store } from '../../app/redux';
import DownloadButton from '../../../../packages/common/shared-ui/src/icons/DownloadButton';

const IssuesContainer = ({
  isAddButtonDisplayed,
  isLoading,
  isOptionsFetching,
  allFilters,
  chosenFilters,
  setChosenFilters,
  timesheetsData,
  timesheetsColumns,
  tableErrorMessage,
  approveOrRejectTimesheets,
  handleChangeSpentTimeType,
  approveOrRejectOvertime,
  handleDeleteTimesheet,
  updateTable,
  setIsLoading,
  currentType,
  setQuery,
  query,
  intl,
}) => {
  const classes = useStyles();
  const pageTitle = intl.formatMessage({ id: 'page_title_issues' });
  const { state: locationState = null } = useLocation();
  const navigate = useNavigate();
  const state = store.getState();
  const currentUser = useSelector(state => state.user.info);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isQueryOpen, setIsQueryOpen] = useState(false);
  const [optionsParams, setOptionsParams] = useState({});
  const [queryModalTitle, setQueryModalTitle] = useState('');
  const [isNewQuery, setIsNewQuery] = useState(true);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOpenWarningModal = () => setIsDeleteConfirmOpen(true);
  const closeWarningModal = () => setIsDeleteConfirmOpen(false);

  const handleToggleDelete = useCallback(
    async e => {
      switch (e.currentTarget.attributes.value.value) {
        case 'save':
          await deleteSavedQuery({ id: query.savedQueryId });
          navigate('/issues');
          closeWarningModal();
          break;
        case 'delete':
          closeWarningModal();
          break;
      }
    },
    [query.savedQueryId, navigate],
  );

  const handleOpenOptions = useCallback(() => setIsOptionsOpen(true), []);
  const handleCloseOptions = useCallback(() => setIsOptionsOpen(false), []);

  const handleOpenQuery = useCallback(({ title, isClear }) => {
    setQueryModalTitle(title);
    setIsNewQuery(isClear);
    setIsQueryOpen(true);
  }, []);

  const goCreateTask = useCallback(() => {
    let url = '/issues/create';
    let project = state.user.project;
    if (project) {
      url = `/issues/create?projectId=${project.redmineId}&project=${project.redmineId}`;
    }
    window.open(url);
  }, [state]);
  const handleCloseQuery = useCallback(() => setIsQueryOpen(false), []);

  const handlePaginate = useCallback(
    (e, page) => {
      setIsLoading(true);
      setQuery({ page: page });
    },
    [setIsLoading, setQuery],
  );

  const formattedTimesheetsData = useCallback(
    () =>
      timesheetsData.reduce((acc, issue) => {
        if (issue.rowType === 'normal') {
          const newCols = issue.columns.reduce((columnAcc, _, i) => {
            if (issue.columns[i].fieldId == 'issue.subject') {
              issue.columns[i].fieldType = 'issue.subject';
            }
            switch (issue.columns[i].fieldId) {
              case 'issue.project':
              case 'issue.issue':
              case 'issue.user':
              case 'issue.parent_issue':
                return {
                  ...columnAcc,
                  [issue.columns[i]
                    .fieldId]: `${issue.columns[i].value}$$${issue.columns[i].valueId}$$${issue.columns[i].fieldType}`,
                };
              case 'issue.subject':
                return {
                  ...columnAcc,
                  [issue.columns[i]
                    .fieldId]: `${issue.columns[i].value}$$${issue.rowId}$$${issue.columns[i].fieldType}`,
                };
              default:
                return {
                  ...columnAcc,
                  [issue.columns[i].fieldId]: issue.columns[i].value,
                };
            }
          }, {});
          return [
            ...acc,
            { id: issue.rowId, ...newCols, level: issue.services.level, hasSubtasks: issue.services.hasSubtasks },
          ];
        }
        if (issue.rowType === 'group') {
          const newCols =
            issue.columns.length > 0
              ? issue.columns.reduce((columnAcc, _, i) => {
                  return {
                    ...columnAcc,
                    [issue.columns[i].fieldId]: issue.columns[i].value,
                    issueId: `${issue.columns[i].value}`,
                    groupName: issue.rowName,
                  };
                }, {})
              : {
                  groupName: issue.rowName,
                };
          return [
            ...acc,
            { id: issue.rowId, issueid: issue.rowId, ...newCols, renderCell: props => console.log(props) },
          ];
        }
        return [];
      }, []),
    [timesheetsData],
  );

  const formattedTimesheetsColumns = useCallback(
    () =>
      timesheetsColumns.map(column => {
        let newColumn = {
          field: column.fieldId,
          headerName: getTranslatedText(intl, 'filter', column.fieldId.split('.').join('_'), column.fieldName),
          renderHeader: props => (
            <Tooltip title={props.colDef.headerName} PopperProps={{ className: classes.commentTooltip }}>
              <Typography variant="h4" fontWeight={700}>
                {props.colDef.headerName}
              </Typography>
            </Tooltip>
          ),
          minWidth: column.fieldType === 'float' || column.fieldType === 'boolean' ? 150 : 280,
          flex: 1,
          disableExport: false,
          position: column.position,
          sortable: query.groups !== column.fieldId,
        };
        switch (column.fieldId) {
          case 'issue.spent_on':
          case 'issue.issue_id':
            newColumn = {
              ...newColumn,
              minWidth: 106,
              flex: 1,
            };
            break;
          case 'issue.is_approved':
          case 'issue.hours':
            newColumn = {
              ...newColumn,
              minWidth: 120,
              flex: 1,
            };
            break;
          case 'issue.cf_7':
            newColumn = {
              ...newColumn,
              minWidth: 160,
              flex: 1,
            };
            break;
          case 'issue.project':
            newColumn = {
              ...newColumn,
              minWidth: 228,
              flex: 1,
            };
            break;
        }
        return newColumn;
      }),
    [intl, query.groups, classes.commentTooltip, timesheetsColumns],
  );

  const [contextMenuData, setContextMenuData] = useState({
    isSecondStepVisible: false,
    secondPopoverStepValue: null,
    data: [],
    currentRow: {
      services: {
        isApproved: '',
        isOvertime: false,
        canApprove: false,
        canEdit: false,
        isProjectClosed: false,
      },
    },
  });
  const downloadCsv = useCallback(
    e => {
      async function downloadIssuesCsv() {
        try {
          setDownloadLoading(true);
          let query = searchParams.toString();
          query = query.replaceAll(/ThisWeek|LastWeek|LastTwoWeeks|ThisMonth|LastMonth|ThisYear/gi, 'btw');
          query = query.replaceAll('Today', 'eq');
          if (query?.length > 0) {
            const { data } = await getIssuesCsv(query);
            const url = window.URL.createObjectURL(new Blob(['\ufeff', data], { type: 'text/csv;charset=utf-8;' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `issues-${dayjs().format('YYYY-MM-DD')}`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
          }
        } catch (e) {
          console.error(e);
        } finally {
          setDownloadLoading(false);
        }
      }
      downloadIssuesCsv();
    },
    [searchParams, setDownloadLoading],
  );
  return (
    <Page
      title={pageTitle}
      width="100%"
      sx={{ height: 'calc(100vh - 80px)', display: 'flex', flexDirection: 'column' }}
    >
      <WarningModal
        simple
        isWarningModalOpen={isDeleteConfirmOpen}
        handleToggle={handleToggleDelete}
        closeWarningModal={closeWarningModal}
        title={`${intl.formatMessage({ id: 'spent_time_delete' })}?`}
      />
      {queryModalTitle && isQueryOpen && (
        <SpentTimeQueryModal
          locationState={locationState}
          isNewQuery={isNewQuery}
          query={query}
          isModalOpen={isQueryOpen}
          closeModal={handleCloseQuery}
          title={queryModalTitle}
        />
      )}
      {allFilters.hasOwnProperty('groups') && (
        <IssuesModal
          currentType={currentType}
          timesheetsColumns={timesheetsColumns}
          intl={intl}
          isModalOpen={isOptionsOpen}
          closeModal={handleCloseOptions}
          allFilters={allFilters}
          setChosenFilters={setChosenFilters}
          chosenFilters={chosenFilters}
          optionsParams={optionsParams}
          setOptionsParams={setOptionsParams}
          updateTable={updateTable}
          setIsLoading={setIsLoading}
          setQuery={setQuery}
          query={query}
        />
      )}
      <Grid container alignItems="center" marginBottom={5}>
        <Box display="flex" flexWrap="nowrap" alignItems="center" marginRight={6}>
          <Typography variant="h1" marginRight={locationState?.name ? 2.5 : 0}>
            {pageTitle}
          </Typography>
          {query.savedQueryId && (
            <Typography variant="h2" fontWeight={600}>
              {locationState?.name}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
        <Grid container alignItems="center">
          {isAddButtonDisplayed && (
            <Box marginRight={2.5}>
              <Button variant="defaultBlueSecondary" disableRipple onClick={() => goCreateTask()}>
                <Typography variant="h4" fontWeight={600}>
                  <FormattedMessage id="issues_add" />
                </Typography>
              </Button>
            </Box>
          )}
          <Box marginRight={2.5}>
            <Button
              variant="defaultGreyPrimary"
              disableRipple
              onClick={() => handleOpenQuery({ title: intl.formatMessage({ id: 'spent_time_save' }), isClear: true })}
            >
              <Typography variant="h4" fontWeight={600}>
                <FormattedMessage id="spent_time_save" />
              </Typography>
            </Button>
          </Box>
          {((query.savedQueryId && currentUser.isAdmin) || locationState?.userId === currentUser.id) && (
            <>
              <Box marginRight={2.5}>
                <Button
                  variant="defaultGreyPrimary"
                  disableRipple
                  onClick={() =>
                    handleOpenQuery({ title: intl.formatMessage({ id: 'spent_time_edit' }), isClear: false })
                  }
                >
                  <Typography variant="h4" fontWeight={600}>
                    <FormattedMessage id="spent_time_edit" />
                  </Typography>
                </Button>
              </Box>
              <Box marginRight={2.5}>
                <Button variant="defaultGreyPrimary" disableRipple onClick={handleOpenWarningModal}>
                  <Typography variant="h4" fontWeight={600}>
                    <FormattedMessage id="spent_time_delete" />
                  </Typography>
                </Button>
              </Box>
            </>
          )}
          <Box marginRight={2.5}>
            <Button variant="defaultGreyPrimaryWithIcon" disableRipple onClick={handleOpenOptions}>
              <Box display="flex" alignItems="center" marginRight={'4px'}>
                <FilterIcon width="20" height="20" viewBox="0 0 20 20" />
              </Box>
              <Typography variant="h4" fontWeight={600}>
                <FormattedMessage id="parameters_text" />
              </Typography>
            </Button>
          </Box>
          <Box marginLeft={'auto'}>
            {!downloadLoading ? (
              <>
                <IconButton onClick={e => downloadCsv(e)} disableRipple>
                  <DownloadButton width="24" height="24" viewBox="0 0 24 24" />
                </IconButton>
              </>
            ) : (
              <>
                <CircularProgress size={18} color="secondary" />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
        <Grid container alignItems="center">
          <Box>
            {chosenFilters.length > 0 &&
              chosenFilters?.map((filter, index) => {
                const newFirstStepValues = {
                  value: filter.value,
                  valueName: filter.label,
                  fieldType: filter.fieldType,
                  type: filter.fieldType !== 'text' ? 'eq' : 'in',
                };
                let prevFirstStepItems;
                if (filter.fieldType == 'user') {
                  filter.values[0].valueName = getTranslatedText(
                    intl,
                    '',
                    filter.values[0].valueName.toLowerCase(),
                    'Me',
                  );
                }
                if (filter.field == 'status') {
                  prevFirstStepItems = {
                    'project.status': {
                      valueName: 'Status',
                      type: 'eq',
                      fieldType: 'status',
                      subLabels: [
                        {
                          valueName: 'Opened',
                          valueId: '1',
                          isChecked: true,
                        },
                      ],
                    },
                  };
                  filter.values[2] = {
                    isChecked: true,
                    valueId: '',
                    valueName: 'ALL',
                  };
                }
                return (
                  <Box
                    width="100%"
                    className={clsx(classes.filterWrap, {
                      [classes.filterWrapDisabledInput]:
                        filter.fieldType == 'text' ||
                        filter.fieldType == 'string' ||
                        filter.fieldType == 'bool' ||
                        filter.fieldType == 'boolean' ||
                        filter.fieldType == 'int' ||
                        filter.fieldType == 'float',
                    })}
                    key={index}
                  >
                    <CustomFilter
                      intl={intl}
                      secondStepItems={filter.values || []}
                      chosenSecondStepItems={filterValues.chosenSecondStepItems}
                      setValues={setChosenFilters}
                      defaultStep={1}
                      firstStepValues={newFirstStepValues}
                      chosenFirstStepItems={prevFirstStepItems || []}
                    />
                  </Box>
                );
              })}
          </Box>
        </Grid>
      </Grid>
      {timesheetsData.length === 0 ? (
        isOptionsFetching ? (
          <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
            <Typography variant="h2" fontWeight={700}>
              <FormattedMessage id="not_found" />
            </Typography>
          </Grid>
        )
      ) : isLoading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Grid overflow={'hidden'} container flexDirection={'column'} wrap="nowrap">
          <IssuesTable
            intl={intl}
            query={query}
            setQuery={setQuery}
            tableErrorMessage={tableErrorMessage}
            originalTimesheetsData={timesheetsData}
            formattedTimesheetsData={formattedTimesheetsData}
            formattedTimesheetsColumns={formattedTimesheetsColumns}
            handleDeleteTimesheet={handleDeleteTimesheet}
            approveOrRejectTimesheets={approveOrRejectTimesheets}
            approveOrRejectOvertime={approveOrRejectOvertime}
            contextMenuData={contextMenuData}
            setContextMenuData={setContextMenuData}
            currentType={currentType}
            isLoading={isLoading}
            updateTable={updateTable}
            setIsLoading={setIsLoading}
            issusesColumnsValue={timesheetsColumns}
          />
          <Grid container marginY={10}>
            <Pagination
              onChange={handlePaginate}
              count={Math.ceil(Number(timesheetsData[0].services.count) / 100)}
              page={Number(query.page ?? 0)}
              shape="rounded"
              renderItem={item => {
                if (
                  (item.page === Math.ceil(Number(timesheetsData[0].services.count) / 100) || item.page === 1) &&
                  item.type === 'page'
                ) {
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      className={clsx(classes.paginateButton, { [classes.paginateButtonSelected]: item.selected })}
                      minWidth={28}
                      height={28}
                      marginX={0.5}
                      onClick={item.onClick}
                    >
                      <Typography variant="h3" paddingX={1}>
                        {item.page}
                      </Typography>
                    </Box>
                  );
                }
                if (item.type === 'start-ellipsis') {
                  return (
                    <Typography variant="h3" className={classes.paginateGoToPage} marginX={1.5}>
                      ...
                    </Typography>
                  );
                }
                if (item.type === 'end-ellipsis') {
                  return (
                    <Typography variant="h3" className={classes.paginateGoToPage} marginX={1.5}>
                      ...
                    </Typography>
                  );
                }
                if (item.type === 'previous') {
                  return Number(query.page) !== 1 ? (
                    <Box
                      display="flex"
                      flexWrap="nowrap"
                      alignItems="center"
                      onClick={item.onClick}
                      className={classes.paginateButton}
                      marginRight={2}
                      height={28}
                      paddingRight={2}
                      key={item.page}
                    >
                      <ChevronIcon width={20} height={20} direction="left" viewBox="0 0 24 24" color="#41424E" />
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography variant="h3" align="center" color="#41424E">
                        Предыдущая
                      </Typography>
                    </Box>
                  ) : null;
                }
                if (item.type === 'next') {
                  return Number(query.page) !==
                    Math.ceil(Number(timesheetsData[0]?.services.count) / Number(query.limit)) ? (
                    <Box
                      display="flex"
                      flexWrap="nowrap"
                      alignItems="center"
                      onClick={item.onClick}
                      className={classes.paginateButton}
                      height={28}
                      paddingLeft={2}
                      key={item.page}
                    >
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography variant="h3" align="center" color="#41424E">
                        Следующая
                      </Typography>
                      <ChevronIcon width={20} height={20} direction="right" viewBox="0 0 24 24" color="#41424E" />
                    </Box>
                  ) : null;
                }
                if (
                  item.type === 'page' &&
                  item.page === Math.ceil(Number(timesheetsData[0]?.services.count) / Number(query.limit))
                ) {
                  return Number(query.page) !== item.page ? (
                    <Typography variant="h3" className={classes.paginateGoToPage}>
                      ...
                    </Typography>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      className={clsx(classes.paginateButton, { [classes.paginateButtonSelected]: item.selected })}
                      minWidth={28}
                      height={28}
                      marginX={0.5}
                      onClick={item.onClick}
                    >
                      <Typography variant="h3" paddingX={1}>
                        {item.page}
                      </Typography>
                    </Box>
                  );
                }
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={clsx(classes.paginateButton, { [classes.paginateButtonSelected]: item.selected })}
                    minWidth={28}
                    height={28}
                    marginX={0.5}
                    onClick={item.onClick}
                    key={item.page}
                  >
                    <Typography variant="h3" paddingX={1}>
                      {item.page}
                    </Typography>
                  </Box>
                );
              }}
            />
          </Grid>
        </Grid>
      )}
    </Page>
  );
};

export default injectIntl(withIssuesRequests(IssuesContainer));
