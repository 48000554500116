import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  filterWrap: {
    display: 'inline-flex !important',
    width: '177px',
    verticalAlign: 'top',
    '& .MuiAutocomplete-input': {
      position: 'relative !important',
    },
    '& .autoCompletePopper': {
      position: 'relative !important',
    },
    '& defaultFirstStepPaper': {
      position: 'relative !important',
    },
    '& .MuiFormControl-root !important': {
      margin: '0px',
    },
  },
  filterWrapStatus: {
    width: 'auto !important',
  },
}));

export default useStyles;
