// ResourcePlanModal.jsx
import useStyles from './ResourcePlanModal.styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Typography,
  List,
  FormControl,
  ClickAwayListener,
  Checkbox,
  ListItem,
  ListItemText,
  ListItemButton,
  CircularProgress,
} from '@mui/material';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import DragDropLists from 'Common/shared-ui/src/components/DragDropLists/DragDropLists';
import DeleteIcon from 'Common/shared-ui/src/icons/DeleteIcon';
import { getResplanSettings, updateResplanSettings } from '../../../../packages/common/api';
import {
  clearResplanTableRows,
  setResplanTableRows,
  setStopLoadingRespanTableBySocket,
} from '../../app/redux/reducers/userSlice';
import { useDispatch } from 'react-redux';

const ResourcePlanModal = ({
  intl,
  open,
  handleClose,
  setRefreshKey,
  setFetchedQuery,
  query,
  setQuery,
  setIsLoading,
}) => {
  const classes = useStyles();
  const [filters, setFilters] = React.useState([]);
  const [fields, setFields] = React.useState([]);
  const [isModalLoading, setIsModalLoading] = useState(true);
  const [modalSessionParams, setModalSessionParams] = useState({
    availableFields: [],
    selectedFields: [],
  });
  const dispatch = useDispatch();

  const modifyQuery = initialQuery => {
    const params = initialQuery.split('$$');

    return params
      .filter(
        param =>
          param.startsWith('resourcePlan.group_by;') ||
          param.startsWith('resourcePlan.date_from;') ||
          param.startsWith('resourcePlan.date_to;'),
      )
      .join('$$');
  };

  const fetchSettingsData = async () => {
    try {
      const response = await getResplanSettings();
      console.log('ResplanSettings', response);

      const modifiedFields = response.fields.map(item => {
        if (
          item.fieldId === 'resourcePlan.project' ||
          item.fieldId === 'resourcePlan.user' ||
          item.fieldId === 'resourcePlan.monthly_distribution'
        ) {
          return { ...item, isChecked: true };
        }
        return item;
      });

      setFilters(response.filters);
      setFields(modifiedFields);
      setIsModalLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchSettingsData();
  }, []);

  const handleSelectChange = event => {
    const selectedFieldIds = event.target.value;
    setFilters(prevFilters =>
      prevFilters.map(filter =>
        selectedFieldIds.includes(filter.fieldId) ? { ...filter, isChecked: true } : { ...filter, isChecked: false },
      ),
    );
  };

  const handleRemoveFilter = useCallback(fieldId => {
    setFilters(prevFilters => {
      return prevFilters.map(filter => (filter.fieldId === fieldId ? { ...filter, isChecked: false } : filter));
    });
  }, []);

  useEffect(() => {
    if (fields.length > 0) {
      const nonDraggables = fields.filter(
        field => ['resourcePlan.project', 'resourcePlan.user'].includes(field.fieldId) && field.isChecked,
      );
      const restOfTheFields = fields.filter(
        field => !['resourcePlan.project', 'resourcePlan.user'].includes(field.fieldId) && field.isChecked,
      );

      setModalSessionParams({
        availableFields: fields.filter(field => !field.isChecked),
        selectedFields: [...nonDraggables, ...restOfTheFields.sort((a, b) => a.position - b.position)],
      });
    }
  }, [fields]);

  const handleClearSettings = async () => {
    fetchSettingsData();
  };
  const handleSaveSettings = async () => {
    const { selectedFields } = modalSessionParams;

    const payload = {
      settings: {
        filters: filters
          .filter(item => item.isChecked)
          .map(item => ({
            instance: item.instance,
            field: item.field,
          })),
        fields: selectedFields.map(item => ({
          instance: item.instance,
          field: item.field,
        })),
      },
    };

    console.log('SAVE');
    console.log('savedData', payload);

    await updateResplanSettings(payload);
    const modifiedQuery = modifyQuery(query);
    dispatch(setResplanTableRows({ fields: [], rows: [] }));
    dispatch(clearResplanTableRows());
    dispatch(setStopLoadingRespanTableBySocket(true));
    setFetchedQuery(modifiedQuery);
    setQuery(modifiedQuery);
    setRefreshKey(prevKey => prevKey + 1);
    handleClose();
  };

  return (
    <>
      {!isModalLoading ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalRoot}
        >
          <Grid container direction="column" wrap="nowrap" className={classes.modalPaper}>
            <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" marginBottom={8.5}>
              <Typography variant="h1">
                <FormattedMessage id="parameters_text" />
              </Typography>
              <IconButton disableRipple onClick={handleClose}>
                <Box width={24} height={24}>
                  <ClearIcon width={24} height={24} viewBox="0 0 24 24" />
                </Box>
              </IconButton>
            </Grid>
            <Grid container spacing={5} position="relative" className={classes.filtersWrap}>
              <Grid item xs={3}>
                <Typography variant="h5">
                  <FormattedMessage id="filter" />
                </Typography>
              </Grid>
              <Grid item xs={9} className={classes.formControlWrap}>
                <FormControl className={classes.formControl}>
                  <Select
                    id="filters"
                    multiple
                    value={filters.filter(filter => filter.isChecked).map(filter => filter.fieldId)}
                    MenuProps={{ className: classes.optionsGroupMenu }}
                    onChange={handleSelectChange}
                    renderValue={selected => {
                      return (
                        <div className={classes.chips}>
                          {selected
                            .filter(
                              item =>
                                (item != 'resourcePlan.group_by') &
                                (item != 'resourcePlan.date_to') &
                                (item != 'resourcePlan.date_from'),
                            )
                            .map(value => {
                              const filter = filters.find(filter => filter.fieldId === value);
                              return (
                                <Chip
                                  key={filter.field}
                                  label={getTranslatedText(
                                    intl,
                                    '',
                                    `filter_resourcePlan_${filter.field}`,
                                    filter.fieldName,
                                  )}
                                  clickable
                                  disabled={filter.field == 'status'}
                                  deleteIcon={
                                    <DeleteIcon
                                      width={10}
                                      height={10}
                                      viewBox="0 0 10 10"
                                      onMouseDown={event => event.stopPropagation()}
                                    />
                                  }
                                  className={classes.chip}
                                  onDelete={() => handleRemoveFilter(filter.fieldId)}
                                />
                              );
                            })}
                        </div>
                      );
                    }}
                  >
                    {filters
                      .filter(
                        item =>
                          (item.fieldId != 'resourcePlan.group_by') &
                          (item.fieldId != 'resourcePlan.date_to') &
                          (item.fieldId != 'resourcePlan.date_from'),
                      )
                      .sort((a, b) => b.isChecked - a.isChecked)
                      .map(filter => (
                        <MenuItem key={filter.field} value={filter.fieldId}>
                          <Checkbox
                            className={classes.menuItemCheckbox}
                            checked={filter.isChecked}
                            disabled={filter.field == 'status'}
                          />
                          <ListItemText
                            primary={getTranslatedText(
                              intl,
                              '',
                              `filter_resourcePlan_${filter.field}`,
                              filter.fieldName,
                            )}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              marginTop={5}
              marginLeft={0}
              justifyContent="space-between"
              display="flex"
              position="relative"
              spacing={5}
              className={classes.dragWrap}
            >
              <DragDropLists
                intl={intl}
                firstListOptions={modalSessionParams.availableFields}
                secondListOptions={modalSessionParams.selectedFields}
                setParentState={setModalSessionParams}
                getOptionLabel={option =>
                  `${option.fieldId};${getTranslatedText(intl, '', `resPlan_${option.field}`, option.fieldName)}`
                }
                nonDraggableIds={['resourcePlan.project', 'resourcePlan.user']}
              />
            </Grid>
            <Grid container justifyContent="center" marginTop={5}>
              <Button disableRipple variant="cutGreyPrimaryBig" onClick={handleClearSettings}>
                <Typography variant="h5" fontWeight={700}>
                  <FormattedMessage id="spreadsheet_table_clear" defaultMessage="Clear" />
                </Typography>
              </Button>
              <Box marginLeft={1}>
                <Button disableRipple variant="cutBlueSecondary" onClick={handleSaveSettings}>
                  <Typography variant="h5" fontWeight={700}>
                    <FormattedMessage id="save_text" defaultMessage="Save" />
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Modal>
      ) : (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </>
  );
};

export default injectIntl(ResourcePlanModal);
