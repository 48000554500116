import React, { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MenuItem from '../MenuItem';

import useStyles from './LeftDrawer.styles';
import ArrowIcon from 'Common/shared-ui/src/icons/ArrowIcon';
import palette from 'Common/shared-ui/src/theme/palette';
import AppLayout from '../../app/AppLayout';
import { useDispatch, useSelector } from 'react-redux';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import { useLocation, useNavigate } from 'react-router';

const LeftDrawer = ({ menuItems, children }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isUserAdmin = useSelector(state => state.user.info.isAdmin);
  const userLastName = useSelector(state => state.user.info.lastName);
  const userFirstName = useSelector(state => state.user.info.firstName);
  const userFullNameArray = useSelector(state => state.user.info.fullName).split(' ');
  const userInitials = `${userFullNameArray[0][0]}${userFullNameArray[userFullNameArray.length - 1][0]}`;
  const avatar = null;
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('sm'));
  const [isSpinnerShown, setIsSpinnerShown] = useState(false);

  const [drawerState, setDrawerState] = useState(false);
  const [drawerProps, setDrawerProps] = useState({
    modalProps: {
      className: classes.drawerModal,
      keepMounted: true,
    },
  });
  const redirectToAnotherUrl = useCallback(
    async url => {
      setDrawerState(false);
      if (process.env.NODE_ENV !== 'development' && url[0] !== '/') {
        setIsSpinnerShown(true);
        setTimeout(() => setIsSpinnerShown(false), 1000);
        return (window.location.href = url);
      }
      return navigate(url);
    },
    [navigate],
  );
  const redirectToExternalUrl = link => {
    window.open(link, '_blank');
  };
  const { current: menuItemStyles } = useRef({
    style: {
      width: 24,
      height: 24,
      color: palette.text.alt,
    },
  });
  const handleOpenDrawer = useCallback(state => {
    setDrawerState(state);
  }, []);

  return (
    <Grid container wrap="nowrap">
      <Drawer
        open={drawerState}
        onClose={() => handleOpenDrawer(false)}
        classes={{ paper: clsx(classes.drawerPaper, { [classes.drawerOpen]: drawerState }) }}
        ModalProps={drawerProps.modalProps}
      >
        {!isSmallScreen && !drawerState && (
          <IconButton onClick={() => handleOpenDrawer(true)} className={clsx(classes.drawerArrowIcon)}>
            <ArrowIcon direction="left" viewBox="0 0 24 24" />
          </IconButton>
        )}
        {isSmallScreen ? (
          <Grid container direction="column" className={classes.mobileMenuUserInfo}>
            <Box className={classes.avatar} marginBottom={2.5}>
              {avatar ? (
                <img src={avatar} alt="user avatar" />
              ) : (
                <Typography variant="h4" color="primary" fontWeight={600}>
                  {userInitials}
                </Typography>
              )}
            </Box>
            <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between" width={'auto'}>
              <Typography variant="h3" fontWeight={600} color="#E4E4EF" fontSize={'14px'}>
                {userFirstName} {userLastName}
              </Typography>
              <IconButton disableRipple>
                <ChevronIcon direction="down" width={24} height={24} viewBox="0 0 24 24" color="#E4E4EF" />
              </IconButton>
            </Grid>
          </Grid>
        ) : null}
        {drawerState && (
          <Grid container direction="column" className={clsx(classes.drawerMenu)}>
            {menuItems.map((menuItem, itemProps) => {
              if (isUserAdmin) {
                menuItem.isVisible = true;
                !menuItem.hasOwnProperty('isDropdownVisible') ? (menuItem.isDropdownVisible = true) : null;
              }
              return (
                menuItem.isVisible && (
                  <MenuItem
                    item={menuItem}
                    itemName={menuItem.lang_key}
                    itemIcon={menuItem.rowIcon && menuItem.rowIcon(menuItemStyles)}
                    path={menuItem.path}
                    key={menuItem.lang_key}
                    closeDrawer={() => handleOpenDrawer(false)}
                    redirectToExternalUrl={redirectToExternalUrl}
                    redirectToAnotherUrl={redirectToAnotherUrl}
                    {...itemProps}
                    {...{ currentItem: window.location.pathname === menuItem.path }}
                  />
                )
              );
            })}
          </Grid>
        )}
      </Drawer>
      {process.env.NODE_ENV !== 'development' && isSpinnerShown && (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100vh"
          position="absolute"
          className={classes.spinner}
        >
          <CircularProgress color="inherit" />
        </Grid>
      )}
      <Grid container className={classes.content}>
        <AppLayout className={classes.AppLayoutRoot}>{children}</AppLayout>
      </Grid>
    </Grid>
  );
};

export default memo(LeftDrawer);
