import React from 'react';
import { SvgIcon } from '@mui/material';

const UserNewEditIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M10.2733 1.86665L9.46667 1.05998C8.94667 0.539983 8.1 0.539983 7.58 1.05998L5.79333 2.84665L0 8.63998V11.3333H2.69333L8.52 5.50665L10.2733 3.75332C10.8 3.23332 10.8 2.38665 10.2733 1.86665ZM2.14 9.99998H1.33333V9.19332L7.10667 3.41998L7.91333 4.22665L2.14 9.99998ZM5.33333 11.3333L8 8.66665H12V11.3333H5.33333Z"
        fill="#DCDFF4"
      />
    </SvgIcon>
  );
};

export default UserNewEditIcon;
