import React from 'react';
import { SvgIcon } from '@mui/material';

const UserNewSaveIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M4.00091 7.8L1.20091 5L0.267578 5.93334L4.00091 9.66667L12.0009 1.66667L11.0676 0.733337L4.00091 7.8Z"
        fill="#37C670"
      />
    </SvgIcon>
  );
};

export default UserNewSaveIcon;
