import useStyles from './ResourcePlanTableCellGroup.styles';
import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import dayjs from 'dayjs';
import {
  Grid,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Select,
  MenuItem,
  Box,
  TextField,
  Tooltip,
} from '@mui/material';
import useResPlanWebsockets from '../useResplanWebSockets';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import PlusIcon from 'Common/shared-ui/src/icons/PlusIcon';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import clsx from 'clsx';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import CalendarTimesheetIcon from '../../../../packages/common/shared-ui/src/icons/CalendarTimesheetIcon';
import { store } from '../../app/redux';
import { useDispatch } from 'react-redux';
import {
  getResplanUsers,
  getResplanProjects,
  getResplanContracts,
  getResplanTrackers,
} from '../../../../packages/common/api';

const ResourcePlanTableCellGroup = ({
  props,
  handleMonthPercentChange,
  handleMonthFieldUpdate,
  handlePercentFieldUpdate,
  handleChangeDate,
  addNewRowAbove,
  groupType,
  getProjectUserId,
  handleIssueBlur,
  fetchedQuery,
  negativeColumns,
  idealColumns,
  handleIssueChange,
}) => {
  const classes = useStyles();
  const canEdit = props.row?.services?.canEdit;

  const formatDate = useCallback(dateStr => {
    if (dateStr) {
      return dayjs(dateStr).format('DD.MM.YYYY');
    }
    return dateStr;
  }, []);
  const fetchUserIssueData = async projectId => {
    try {
      const newUsers = await getResplanUsers(projectId);
      setUsers(newUsers);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchProjectIssueData = async userId => {
    try {
      const newProjects = await getResplanProjects(userId);
      setProjects(newProjects);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchContractIssueData = async projectId => {
    try {
      const newContracts = await getResplanContracts(projectId);
      setContracts(newContracts);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchTrackersIssueData = async () => {
    try {
      const newTrackers = await getResplanTrackers();
      setTrackers(newTrackers);
      console.log(newTrackers);
    } catch (e) {
      console.error(e);
    }
  };
  const handleGetRedirectUrl = useCallback(({ id, columnType }) => {
    switch (columnType) {
      case 'issue':
        return generateRedirectUrl({ host: window.location.origin, path: `/issues/${id}` });
      // return `${window.location.origin}/issues/${id}`;

      default:
        return '';
    }
  }, []);

  const renderCell = () => {
    switch (props.colDef.field) {
      default:
        return (
          <Box className={classes.tableCell} align="left">
            <Tooltip
              title={props.row.columns[props.colDef.field]?.value}
              PopperProps={{ className: classes.commentTooltip }}
            >
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                renderCell group
              </Typography>
            </Tooltip>
          </Box>
        );
    }
  };
  return <>{renderCell()}</>;
};

export default memo(ResourcePlanTableCellGroup);
