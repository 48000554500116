import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Page from '../../components/Page';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ErrorLogo from '../../icons/ErrorLogo';
import { injectIntl } from 'react-intl';
import { useLocation } from 'react-router';

function ErrorPage({ intl }) {
  const location = useLocation();
  const [errorCode, setErrorCode] = useState('');
  const [errorMessage, setErrorMessage] = useState(intl.formatMessage({ id: `error_404` }));
  useEffect(() => {
    location.pathname.includes('error-') && setErrorCode(location.pathname.slice(location.pathname.indexOf('-') + 1));
  }, [location.pathname]);
  useEffect(() => {
    errorCode && setErrorMessage(intl.formatMessage({ id: `error_${errorCode}` }));
  }, [errorCode, intl]);
  return (
    <Page display="flex" alignItems="center" justifyContent="center" title="Error 404" sx={{ height: '100%' }}>
      <Grid container direction="column" alignItems={'center'}>
        <Box marginBottom={'175px'}>
          <ErrorLogo></ErrorLogo>
        </Box>
        <Box marginBottom={'26px'}>
          <Typography variant="h1" align="center" display={'inline-flex'} flexDirection={'column'}>
            {`${intl.formatMessage({ id: 'error' })} ${errorCode}`}
            <Box marginTop={'16px'} component={'span'} sx={{ fontWeight: '400' }}>
              {errorMessage}
            </Box>
          </Typography>
        </Box>
        <Box marginX="auto">
          <Link to="/">
            <Button disableRipple variant="defaultBluePrimary">
              <Typography variant="h4" fontWeight={700}>
                {intl.formatMessage({ id: 'back_to_main_page' })}
              </Typography>
            </Button>
          </Link>
        </Box>
      </Grid>
    </Page>
  );
}

export default injectIntl(ErrorPage);
